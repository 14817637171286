import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Toolbar, Typography } from '@mui/material';
import { ApiContext } from '../../contexts/ApiContext';
import { formatReportId, normalizeId } from '../../utils/textFormatter';
import { useTranslation } from 'react-i18next';
import FormViewToolbar from '../FormView/FormViewToolbar';
import { styled } from '@mui/material/styles';
import FormActions from '../../components/FormActions';
import LoadingButton from '@mui/lab/LoadingButton';
import FormPaper from '../../components/FormPaper';
import { getActiveLanguage } from '../../utils/localizationUtils';
import { UI_LANGUAGES } from '../../constants/languages';

const RootContainer = styled(Container, { name: "ReportLoginDialog" })(({ theme }) => ({
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 4, 4, 4),
    },
}));

const noErrorText = " ";

const ReportLoginDialog = ({ error, onLogin, ...rest }) => {
    const {queryApi} = useContext(ApiContext);
    const { t, i18n } = useTranslation();
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState(noErrorText);
    const [isBusy, setIsBusy] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState(getActiveLanguage(i18n));

    const handleSubmit = async (event) => {
        const normalizedPassword = password.replace(" ", "");
        // normalizedId length will be 16 characters + 3 dashes
        if (!Boolean(normalizedPassword) || normalizedPassword.length !== 19) {
            setErrorText(t("invalidReportPassword", {lng: activeLanguage}));
            return;
        }

        setIsBusy(true);
        const resourcePassword = await queryApi.getResourcePasswordByPassword(normalizedPassword);
        if (!Boolean(resourcePassword)) {
            setErrorText(t("reportNotFoundOrCreated", {lng: activeLanguage}));
            setIsBusy(false);
            return;
        }

        setErrorText("");
        setIsBusy(false);
        onLogin({password: normalizedPassword, reportKey: resourcePassword.pk});
    };

    const handleTextChange = (event) => {
        if (event.target.value) {
            setPassword(event.target.value.trim());
        } else {
            setPassword("");
        }

        if (errorText.length != 1) {
            setErrorText(noErrorText);
        }
    }

    return (
        <RootContainer maxWidth="sm">
            <FormViewToolbar
                language={activeLanguage}
                supportedLanguages={UI_LANGUAGES}
                onLanguageChange={setActiveLanguage}
            />
            <Toolbar/>
            <FormPaper>
                <Typography variant="h4" align="center">
                    {t("loginToReport", { lng: activeLanguage })}
                </Typography>
                <TextField
                    required
                    fullWidth
                    label={t("reportPasswordFieldLabel", { lng: activeLanguage })}
                    variant="outlined"
                    error={errorText.length !== 1}
                    helperText={errorText}
                    value={formatReportId(normalizeId(password))}
                    onChange={handleTextChange}
                    sx={{
                        '& .MuiFormHelperText-root': {
                            position: "absolute",
                            top: "100%",
                        }
                    }}
                />
                <FormActions>
                    <LoadingButton
                        fullWidth
                        loading={isBusy}
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {t("continue", {lng: activeLanguage})}
                    </LoadingButton>
                </FormActions>
            </FormPaper>
        </RootContainer>
    )
};

ReportLoginDialog.propTypes = {
    className: PropTypes.string
};

export default ReportLoginDialog;
