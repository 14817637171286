// TODO: Uncomment if there will be any unexplained errors from MUI components
// import { createTheme, colors } from "@mui/material";
// Used to suppress React warning that findDOMNode is deprecated in StrictMode
import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { light, primaryTextColor } from './palette';
import shadows from './shadows';

const borderRadius = 8;

const customTypography = {
  bodyLarge: {
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: 'normal',
  },
  subtitleLarge: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: 'normal',
  },
};

export const rawTheme = createTheme();

const defaultTheme = responsiveFontSizes(
  createTheme({
    palette: light,
    shadows: shadows('light'),
    typography: {
      //fontFamily: "Inter, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, Sans-Serif",
      //fontFamily: "Public Sans, sans-serif",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      h1: {
        fontSize: '4rem',
        //lineHeight: 1.25,
        lineHeight: 1.75,
        letterSpacing: '2px',
        fontWeight: 500,
      },
      h2: {
        fontSize: '3rem',
        //lineHeight: 1.33333,
        lineHeight: 1.75,
        letterSpacing: '0rem',
        fontWeight: 500,
      },
      h3: {
        fontSize: '2rem',
        lineHeight: 1.75,
        letterSpacing: '0rem',
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.5rem',
        lineHeight: 1.75,
        letterSpacing: '0rem',
        fontWeight: 500,
      },
      h5: {
        fontSize: '1.25rem',
        lineHeight: 1.75,
        letterSpacing: '0rem',
        fontWeight: 500,
      },
      h6: {
        fontSize: '1.125rem',
        //lineHeight: 1.55556,
        lineHeight: 1.75,
        letterSpacing: '0rem',
        fontWeight: 500,
      },
      button: {
        textTransform: 'none',
        //fontWeight: 'medium' as React.CSSProperties['fontWeight'],
      },
      body1: {
        fontSize: '0.875rem',
        lineHeight: 1.75,
        //letterSpacing: "0.15px",
      },
      body2: {
        fontSize: '0.8125rem',
        lineHeight: 1.75,
        letterSpacing: 'normal',
      },
      subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.75,
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '0.8125rem',
        fontWeight: 500,
        lineHeight: 1.75,
        letterSpacing: 'normal',
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 2.4,
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
      ...customTypography,
    },
    shape: {
      borderRadius: borderRadius,
    },
    components: {
      // MuiPaper: {
      //     styleOverrides: {
      //         rounded: {
      //             borderRadius: 8,
      //         },
      //     },
      // },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'bodyLarge' },
            style: {
              ...customTypography.bodyLarge,
            },
          },
          {
            props: { variant: 'subtitleLarge' },
            style: {
              ...customTypography.subtitleLarge,
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { color: 'default' },
            style: {
              borderColor: grey[300],
              background: '#ffffff',
              '&:hover': {
                borderColor: grey[300],
              },
              '&:disabled': {
                border: `1px solid action.disabledBackground`,
                color: 'action.disabled',
              },
            },
          },
          {
            props: { color: 'default', variant: 'contained' },
            style: {
              backgroundColor: grey[300],
              color: primaryTextColor,
              '&:hover:not(.Mui-disabled)': {
                boxShadow:
                  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
                backgroundColor: grey[400],
              },
            },
          },
        ],
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 12,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '24px 24px',
            fontWeight: 700,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '44px 24px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            //padding: '16px 48px 40px 48px',
            padding: '0px 24px 24px 24px',
          },
          spacing: {
            '&>*:not(:first-of-type)': {
              marginLeft: 24,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            // Have to use css selector instead of rule name because of bug in the MuiListItemButton
            '&.Mui-selected': {
              backgroundColor: light.action.selected,
            },
            '&.Mui-selected:hover': {
              backgroundColor: light.action.selected,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            //padding: 0,  // Used for multiline textField,
            borderRadius: borderRadius,
          },
          input: {
            '--horizontal-padding': '12px',
            //padding: '17px 12px 18px 12px'
            padding: '14px var(--horizontal-padding)',
          },
          inputSizeSmall: {
            //padding: '14px 12px 13px 12px'
            '--horizontal-padding': '14px',
            padding: '8.5px var(--horizontal-padding)',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            fontWeight: 500,
            fontSize: '1rem',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-outlined': {
              fontWeight: 500,
              fontSize: '1rem',
            },
          },
          input: {
            '--horizontal-padding': '12px',
            padding: '14px var(--horizontal-padding)',
          },
          inputSizeSmall: {
            '--horizontal-padding': '14px',
            padding: '8.5px var(--horizontal-padding)',
          },
          multiline: {
            '&&&&': {
              padding: 0,
            },
          },
          notchedOutline: {
            fontWeight: 500,
            fontSize: '1rem',
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 0,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            padding: '6px',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: 12,
            boxShadow:
              'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px',
            //boxShadow: 'rgb(208, 215, 222) 0px 4px 30px',
            //border: '1px solid rgb(208, 215, 222)'
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            '&:hover': {
              '& .MuiListItemIcon-root': {
                color: grey[600],
              },
            },
            '& .MuiListItemIcon-root': {
              minWidth: 0,
            },
          },
        },
      },
      // PopperMenu: {
      //     styleOverrides: {
      //         paper: {
      //             borderRadius: 12,
      //             boxShadow: 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px'
      //             //boxShadow: 'rgb(208, 215, 222) 0px 4px 30px',
      //             //border: '1px solid rgb(208, 215, 222)'
      //         },
      //         list: {
      //             padding: '6px'
      //         }
      //     },
      // },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            '&&': {
              minWidth: 0,
            },
            marginRight: '8px',
            marginTop: '0.075em',
            color: grey[500],
          },
        },
      },
      MuiListItemAvatar: {
        styleOverrides: {
          root: {
            '&&': {
              minWidth: 0,
            },
            marginRight: '8px',
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 0,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
    },
    // breakpoints: {
    //     values: {
    //         xs: 0,
    //         sm: 640,
    //         md: 800,
    //         lg: 1280,
    //         xl: 1920,
    //     },
    // }
  }),
  { disableAlign: true },
);

export default defaultTheme;
