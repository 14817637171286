import { PrivateResource } from '../../API';
import { DeserializedPrivateResource } from '../../types/api';

export const MEMBERS_LOADED = 'MEMBERS_LOADED';
export const MEMBERS_ADDED = 'MEMBERS_ADDED';
export const MEMBER_UPDATED = 'MEMBER_UPDATED';
export const MEMBER_DELETED = 'MEMBER_DELETED';
export const MEMBERS_DELETED = 'MEMBERS_DELETED';

export interface LoadMembersAction {
  type: typeof MEMBERS_LOADED;
  payload: {
    members: DeserializedPrivateResource[];
  };
}

export interface AddMembersAction {
  type: typeof MEMBERS_ADDED;
  payload: {
    organizationKey: string;
    members: DeserializedPrivateResource[];
  };
}

export interface UpdateMemberAction {
  type: typeof MEMBER_UPDATED;
  payload: {
    pk: string;
    sk: string;
    data: DeserializedPrivateResource;
  };
}

export interface DeleteMemberAction {
  type: typeof MEMBER_DELETED;
  payload: {
    pk: string;
    sk: string;
  };
}

export interface DeleteMembersAction {
  type: typeof MEMBERS_DELETED;
  payload: {
    organizationKey: string;
  };
}

export type MemberActionTypes =
  | LoadMembersAction
  | AddMembersAction
  | UpdateMemberAction
  | DeleteMemberAction
  | DeleteMembersAction;
