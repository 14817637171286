import * as types from "./actionTypes";
import {stableSort} from "../../utils/arrayUtils";

export const loadComments = (comments) => async (dispatch, getState) => {
    dispatch({ type: types.COMMENTS_LOADED, payload: {comments} });
}

export const addComments = (comments) => async (dispatch, getState) => {
    dispatch({ type: types.COMMENTS_ADDED, payload: {comments} });
}

export const addOrUpdateComment = (pk, sk, data) => async (dispatch, getState) => {
    dispatch({ type: types.COMMENT_UPDATED, payload: {pk, sk, data} });
}

export const deleteWorkspaceComments = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.COMMENTS_DELETED, payload: { workspaceKey } });
}