export const reorder = (array, startIndex, endIndex) => {
    const updated = [...array];
    const [removed] = updated.splice(startIndex, 1);
    updated.splice(endIndex, 0, removed);
    return updated;
}

export const getAddedRemovedItems = (originalArray, newArray) => {
    if (!originalArray) {
        return { added: newArray, removed: [] };
    }
    if (!newArray) {
        return { added: [], removed: originalArray };
    }

    const added = [];
    const removed = [];
    for (const item of originalArray) {
        if (!newArray.includes(item)) {
            removed.push(item);
        }
    }
    for (const item of newArray) {
        if (!originalArray.includes(item)) {
            added.push(item);
        }
    }

    return {added, removed};
}

export const split = (array, predicate) => {
    if (!array) {
        return { 'true': [], 'false': [] };
    }

    return array.reduce((accumulator, currentItem) => {
        const partitionName = `${predicate(currentItem)}`;
        accumulator[partitionName].push(currentItem);
        return accumulator;
    }, { 'true': [], 'false': [] });
}

export const groupBy = (array, fieldName) => {
    return array.reduce((accumulator, current) => {
        (accumulator[current[fieldName]] = accumulator[current[fieldName]] || []).push(current);
        return accumulator;
    }, {});
};

export const toObject = (array, fieldName) => {
    return array.reduce((accumulator, current) => {
        accumulator[current[fieldName]] = current;
        return accumulator;
    }, {});
};

export const addToGroup = (groups, groupKey, item) => {
    if (Array.isArray(groups)) {
        throw new Error("Argument groups must be an object, not array");
    }

    const newGroups = {...groups};
    newGroups[groupKey] =  [...(groups[groupKey] ?? [])];
    newGroups[groupKey].push(item);
    return newGroups;
};

export const updateGroupItem = (groups, groupKey, predicate, item) => {
    if (Array.isArray(groups)) {
        throw new Error("Argument groups must be an object, not array");
    }

    const newGroups = {...groups};
    newGroups[groupKey] =  [...(groups[groupKey] ?? [])];
    const itemIndex = newGroups[groupKey].findIndex(predicate);
    if (itemIndex < 0) {
        newGroups[groupKey].push(item);
    } else {
        newGroups[groupKey][itemIndex] = {...newGroups[groupKey][itemIndex], ...item};
    }
    return newGroups;
};


export const filterGroupItems = (groups, groupKey, predicate) => {
    if (Array.isArray(groups)) {
        throw new Error("Argument groups must be an object, not array");
    }

    const newGroups = {...groups};
    if (!newGroups[groupKey]) {
        return newGroups;
    }
    newGroups[groupKey] = newGroups[groupKey].filter(predicate);
    return newGroups;
};

export const stableSort = (array, order, orderBy) => {
    const comparator = getComparator(order, orderBy);
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0)
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const moveItem = (array, fromIndex, toIndex) => {
    const item = array[fromIndex];
    const newArray = [...array];
    newArray.splice(fromIndex, 1);
    //const normalizedToIndex = toIndex > fromIndex ? toIndex - 1 : toIndex;
    newArray.splice(toIndex, 0, item);
    return newArray;
}

export const removeItemAtIndex = (array, index) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
}

export const removeItem = (array, value) => {
    // const index = array.indexOf(value);
    // const newArray = [...array];
    // newArray.splice(index, 1);
    // return newArray;

    return array.filter(item => item !== value);
}

export const removeItems = (array, values) => {
    if (values.length === 0) {
        return array;
    }
    // const index = array.indexOf(value);
    // const newArray = [...array];
    // newArray.splice(index, 1);
    // return newArray;

    return array.filter(item => item !== values.includes(item));
}

export const removeMatchingItem = (array, predicate) => {
    const index = array.findIndex(predicate);
    return removeItemAtIndex(array, index);
}

export const updateMatchingItemField = (array, predicate, fieldName, newValue) => {
    return updateItemFieldAtIndex(array, array.findIndex(predicate), fieldName, newValue);
}

export const updateItemFieldAtIndex = (array, index, fieldName, newValue) => {
    const newArray = [...array];
    newArray[index][fieldName] = newValue;
    return newArray;
}

export const replaceMatchingItem = (array, predicate, newValue) => {
    const index = array.findIndex(predicate);
    if (index < 0) {
        return array;
    }
    const newArray = [...array];
    newArray[index] = newValue;
    return newArray;
}

export const replaceItemAtIndex = (array, index, newValue) => {
    const newArray = [...array];
    newArray[index] = newValue;
    return newArray;
}

export const updateMatchingItem = (array, predicate, data) => {
    const index = array.findIndex(predicate);
    if (index < 0) {
        return array;
    }
    const newArray = [...array];
    newArray[index] = {...newArray[index], ...data};
    return newArray;
}

export const updateItemAtIndex = (array, index, value) => {
    const newArray = [...array];
    newArray[index] = value;
    return newArray;
}

export const updateItemAtIndexWithCallback = (array, index, callback) => {
    const newArray = [...array];
    callback(newArray[index]);
    return newArray;
}

export const insertItem = (array, index, newValue) => {
    const newArray = [...array];
    newArray.splice(index, 0, newValue);
    return newArray;
}

export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}