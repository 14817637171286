import React from 'react';
import { styled } from '@mui/material/styles';
import { Chip, ChipProps } from '@mui/material';

const RootChip = styled(Chip, {
  name: 'Tag',
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor: string }>(({ theme, customColor }) => ({
  color: customColor,
  borderColor: customColor,
  padding: 0,
  minWidth: '32px',
}));

interface Props extends ChipProps {
  value: {
    name: string;
    color: string;
  };
}

const Tag = ({ value, ...rest }: Props) => {
  return (
    <RootChip
      size="small"
      variant="outlined"
      label={value.name}
      customColor={value.color}
      {...rest}
    />
  );
};

export default Tag;
