import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';

interface Props {
  open: boolean;
  text: string;
  loading?: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const DeleteConfirmationDialog = ({
  open,
  text,
  loading,
  onDelete,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="delete_confirmation_dialog_title"
      PaperProps={{
        component: 'form',
        onSubmit: onDelete,
      }}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle id="delete_confirmation_dialog_title">
        {t('confirmDeletion')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="outlined"
          size="medium"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          size="medium"
          type="submit"
          onClick={onDelete}
          loading={loading}
        >
          {t('delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
