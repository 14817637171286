import * as types from "./actionTypes";

export const loadInvitations = (invitations) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATIONS_LOADED, payload: { invitations } });
}

export const addOrReplaceInvitations = (workspaceKey, invitations) => async (dispatch, getState) => {
    if (!invitations) {
        return;
    }
    dispatch({ type: types.INVITATIONS_ADDED, payload: {workspaceKey, invitations} });
}

export const addOrUpdateInvitation = (pk, sk, data) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATION_UPDATED, payload: { pk, sk, data } });
}

export const deleteInvitation = (pk, sk) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATION_DELETED, payload: { pk, sk } });
}

export const deleteWorkspaceInvitations = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.INVITATIONS_DELETED, payload: { workspaceKey } });
}