import React from "react";
import clsx from "clsx";
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";

const RootDiv = styled("div", { name: "DialogHeader", shouldForwardProp: (prop) => prop !== "showDivider" })(
    ({ theme, showDivider }) => ({
        padding: showDivider ? theme.spacing(3, 6) : theme.spacing(4, 6, 1, 6),
        display: "flex",
        alignItems: "center",
    })
);

const DialogHeader = ({
    className,
    showExitButton,
    showDivider,
    children,
    onExitClick,
    disableTypography,
    TypographyProps,
    ExitButtonProps,
    ...rest
}) => {
    return (
        <>
            <RootDiv showDivider={showDivider} {...rest}>
                {disableTypography ? (
                    <Box flex='1 1 auto'>
                        {children}
                    </Box>
                ) : (
                    <Typography variant="h4" sx={{ flex: "1 1 auto" }} {...TypographyProps}>
                        {children}
                    </Typography>
                )}
                {showExitButton && (
                    <IconButton size="small" onClick={onExitClick} edge="end" {...ExitButtonProps}>
                        <CloseIcon />
                    </IconButton>
                )}
            </RootDiv>
            {showDivider && <Divider />}
        </>
    );
};

DialogHeader.propTypes = {
    className: PropTypes.string,
    showExitButton: PropTypes.bool,
    showDivider: PropTypes.bool,
    disableTypography: PropTypes.bool,
    TypographyProps: PropTypes.object,
    ExitButtonProps: PropTypes.object,
    children: PropTypes.any,
    onExitClick: PropTypes.func,
};

DialogHeader.defaultProps = {
    onExitClick: () => {},
    TypographyProps: {},
};

export default DialogHeader;
