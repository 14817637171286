export function isObserver(userRole) {
    return userRole === 'observer';
}

export function isSolver(userRole) {
    return userRole === 'solver';
}

export function isAdmin(userRole) {
    return userRole === 'admin';
}

export function isManager(userRole) {
    return userRole === "manager";
}

export function getCognitoGroups(cognitoUser) {
    if (!cognitoUser) {
        return null;
    }
    return cognitoUser.signInUserSession.accessToken.payload["cognito:groups"] ?? [];
}