import React, { useState } from 'react';

import { AppBar, Avatar, Button, ListItemAvatar, ListItemText, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_LABELS } from '../../constants/languages';
import Menu from '@mui/material/Menu';
import { getAllLanguagePacksLanguages } from '../../utils/formUtils';
import { styled } from '@mui/material/styles';

const RootAppBar = styled(AppBar, { name: "FormViewToolbar" })(({ theme }) => ({}));

const LanguageAvatar = styled(Avatar)(({ theme }) => ({
    width: 28,
    height: 21,
    marginRight: theme.spacing(2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[300]}`,
}));

const FormViewToolbar = ({ language, supportedLanguages, onLanguageChange }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const appBarPosition = smallScreen ? "fixed" : "absolute";
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);

    const handleMenuItemClick = (value) => {
        onLanguageChange(value);
        setLanguageMenuAnchorEl(null);
    };

    return (
        <RootAppBar color="transparent" elevation={0} position={appBarPosition}>
            <Toolbar>
                <Button
                    endIcon={<ExpandMoreIcon />}
                    onClick={(e) => setLanguageMenuAnchorEl(e.currentTarget)}
                    sx={{
                        typography: "body1",
                        marginLeft: "auto",
                        minWidth: 120,
                        textTransform: "none",
                        marginRight: {
                            xs: 1,
                            md: 3,
                        },
                        color: 'text.primary'
                    }}
                >
                    <LanguageAvatar
                        component="span"
                        alt={language}
                        src={`/static/icons/flags/${language}.svg`}
                    />
                    {LANGUAGE_LABELS[language]}
                </Button>
            </Toolbar>
            <Menu
                keepMounted
                id="language-menu"
                open={Boolean(languageMenuAnchorEl)}
                anchorEl={languageMenuAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setLanguageMenuAnchorEl(null)}
            >
                {supportedLanguages.map((lang) => (
                    <MenuItem key={`menu-item-${lang}`} onClick={() => handleMenuItemClick(lang)}>
                        <ListItemAvatar>
                            <LanguageAvatar alt={lang} src={`/static/icons/flags/${lang}.svg`} />
                        </ListItemAvatar>
                        <ListItemText primary={LANGUAGE_LABELS[lang]} />
                    </MenuItem>
                ))}
            </Menu>
        </RootAppBar>
    );
};

FormViewToolbar.propTypes = {
    language: PropTypes.string.isRequired,
    supportedLanguages: PropTypes.array.isRequired,
    onLanguageChange: PropTypes.func,
};

FormViewToolbar.defaultProps = {
    onLanguageChange: () => {},
};

export default FormViewToolbar;
