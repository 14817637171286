import * as types from "./actionTypes";

const initialState = {};

export default function workspacesReducer(state = initialState, action) {
    switch (action.type) {
        case types.WORKSPACES_LOADED: {
            const newState = {...state};
            for (const workspace of action.payload.workspaces) {
                newState[workspace.pk] = workspace;
            }
            return newState;
        }
        case types.WORKSPACE_UPDATED: {
            const pk = action.payload.pk;
            const newState = {...state};
            newState[pk] = {...newState[pk], ...action.payload.data, pk: pk, sk: pk};
            return newState;
        }
        case types.WORKSPACE_DELETED: {
            const newState = {...state};
            delete newState[action.payload.pk];
            return newState;
        }
        default:
            return state
    }
}
