import React, {useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Stack,
    TextField, Typography,
} from "@mui/material";
import FieldLabel from "../FieldLabel";
import {MuiTelInput} from "mui-tel-input";
import {alpha, styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Menu from "@mui/material/Menu";

const RootDiv = styled('div', {name: 'PhoneInput'})(({ theme }) => ({
}));

const PhoneInput = ({ disabled, error, fullWidth, helperText, label, LabelProps, onChange, required, size, value }) => {
    return (
        <RootDiv>
            <FieldLabel label={label} required={required} {...LabelProps}/>
            <MuiTelInput
                autoFocus
                focusOnSelectCountry
                disabled={disabled}
                value={value}
                error={error}
                fullWidth={fullWidth}
                helperText={helperText}
                continents={['EU']}
                excludedCountries={['AX', 'AD', 'XK', 'FO', 'GG', 'GI', 'IM', 'JE', 'SJ']}
                onChange={onChange}
                size={size}
                MenuProps={{
                    keepMounted: true,
                    anchorOrigin: {vertical: "bottom", horizontal: "left"},
                    transformOrigin: {vertical: "top", horizontal: "left" },
                    sx: {
                        mt: 0.5,
                        '& .MuiPopover-paper': {
                            maxHeight: '33%'
                        }
                    }
                }}
                sx={(theme) => ({
                    '& .MuiTelInput-IconButton': {
                        borderRadius: 0,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: '100%',
                        px: 1.75,
                        //background: theme.palette.grey.A100,
                        background: theme.palette.grey[100],
                        '&:hover': {
                            //background: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
                            background: theme.palette.grey[300]
                        }
                    },
                    '& .MuiInputBase-input': {
                        ml: 6
                    }
                })}
            />
        </RootDiv>
    );
}

PhoneInput.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.node,
    LabelProps: PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default']),
    value: PropTypes.string,
}

PhoneInput.defaultProps = {
    onChange: () => {},
    size: "default"
};

export default PhoneInput;