import React, { useContext, useState } from 'react';
import {
  Box,
  Chip,
  Container,
  InputAdornment,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { toWorkspaceKey } from '../../utils/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import { activeMembersSelector } from '../../state/members/selectors';
import { useParams } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import { currentUserSelector } from '../../state/currentUser/selectors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RemoveMemberConfirmationDialog from './RemoveMemberConfirmationDialog';
import ClearIcon from '@mui/icons-material/Clear';
import UserRolePicker from './UserRolePicker';
import { invitationsSelector } from '../../state/invitations/selectors';
import InvitationDialog from './InvitationDialog';
import { insertItem, stableSort } from '../../utils/arrayUtils';
import HoverTableRow from '../../components/HoverTableRow';
import { ApiContext } from '../../contexts/ApiContext';
import {
  addOrUpdateInvitation,
  deleteInvitation,
} from '../../state/invitations/actions';
import { addOrUpdateMember } from '../../state/members/actions';
import { isAdmin } from '../../utils/user';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import SearchIcon from '@mui/icons-material/Search';
import { isNullOrEmpty } from '../../utils/textUtils';
import MemberAccessSettingButton from './MemberAccessSettingButton';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';
import ResourceAvatar from '../../components/ResourceAvatar';

const RootContainer = styled(Container, { name: 'MembersSettings' })(
  ({ theme, color }) => ({}),
);

const MoreIconButton = styled(IconButton)(({ theme }) => ({
  // visibility: "hidden",
  colo: theme.palette.grey[300],
}));

const isInvitation = (item) => !Boolean(item.name);

const filterMembers = (members, filter) => {
  if (isNullOrEmpty(filter)) {
    return members;
  }

  const lowercaseFilter = filter.toLowerCase();
  // Invitations can have null name
  return members.filter(
    (member) =>
      member.name?.toLowerCase().startsWith(lowercaseFilter) ||
      member.email.toLowerCase().startsWith(lowercaseFilter),
  );
};

const MembersSettingsView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const { logUserRoleChanged, logInvitationDeleted } =
    useContext(ActivityLogContext);
  const organizationKey = useOrganizationKey();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState();
  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [filter, setFilter] = useState('');
  const members = useSelector(activeMembersSelector(organizationKey));
  const invitations = useSelector(invitationsSelector(organizationKey)) ?? [];
  const currentUser = useSelector(currentUserSelector());
  const allMembers = members.concat(invitations);
  let sortedMembers = stableSort(
    allMembers.filter((member) => member.sk !== currentUser.sk),
    'asc',
    'email',
  );
  const currentUserMembership = members.find(
    (member) => member.sk === currentUser.sk,
  );
  if (currentUserMembership) {
    // currentUserMembership can be null if for example user removed himself from the organization. In that case
    // MembersSettings component will be rerendered because of the API subscriptions right after an API request to
    // deactivate the membership is sent.
    sortedMembers = insertItem(sortedMembers, 0, currentUserMembership);
  }
  const filteredMembers = filterMembers(sortedMembers, filter);
  //.map((user) => ({ pk: user.pk, sk: user.sk, name: user.name, email: user.email, role: user.role }));
  const isInvitationSelected = selectedMember
    ? isInvitation(selectedMember)
    : false;
  const adminsCount = members.filter((member) => isAdmin(member.role)).length;

  const canChangeRole = (member) => {
    return member?.sk !== currentUser.pk || adminsCount > 1;
  };

  const handleMoreButtonClick = (event, item) => {
    setSelectedMember(item);
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setMoreMenuAnchorEl(null);
    setSelectedMember(null);
  };

  const handleRemoveButtonClick = () => {
    setMoreMenuAnchorEl(null);
    if (isInvitationSelected) {
      mutationApi.deletePrivateResource(selectedMember.pk, selectedMember.sk);
      dispatch(deleteInvitation(selectedMember.pk, selectedMember.sk));
      logInvitationDeleted(selectedMember.email);
    } else {
      setRemoveUserDialogOpen(true);
    }
  };

  const handleRoleChange = (member, newRole) => {
    const action = isInvitation(member)
      ? addOrUpdateInvitation
      : addOrUpdateMember;
    const data = { role: newRole };
    if (newRole === 'admin') {
      data.access = null;
    }
    dispatch(action(member.pk, member.sk, data));
    mutationApi.updatePrivateResource(member.pk, member.sk, data);
    logUserRoleChanged(member.sk, newRole);
  };

  const handleMemberAccessChange = (member, newAccessValue) => {
    const action = isInvitation(member)
      ? addOrUpdateInvitation
      : addOrUpdateMember;
    dispatch(action(member.pk, member.sk, { access: newAccessValue }));
    mutationApi.updatePrivateResource(member.pk, member.sk, {
      access: newAccessValue,
    });
  };

  return (
    <Box>
      <Stack spacing={4}>
        <Stack direction="row" alignItems="center">
          <Box flexGrow={1}>
            <TextField
              id="member-sarch-field"
              placeholder={t('memberSearchPlaceholder')}
              variant="outlined"
              size="small"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ ml: 1, color: 'grey.500' }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 320 }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setInvitationDialogOpen(true)}
          >
            {t('inviteMembers')}
          </Button>
        </Stack>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>{t('name')}</TableCell>
              <TableCell sx={{ width: '20%' }} />
              <TableCell sx={{ width: '30%', pl: '30px' }}>
                {t('role')}
              </TableCell>
              <TableCell sx={{ width: '30%', pl: '24px' }}>
                {t('accessSettings')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMembers.map((member) => (
              <HoverTableRow
                key={member.sk}
                active={member.sk === selectedMember?.sk}
              >
                <TableCell>
                  <Stack direction="row" alignItems="center">
                    <ListItemAvatar>
                      <ResourceAvatar
                        name={member.name ?? member.email}
                        picture={member.picture}
                        variant="circular"
                        size={32}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        isInvitation(member) ? (
                          member.email
                        ) : (
                          <>
                            {member.name}
                            {member.sk === currentUser.sk && (
                              <Typography variant="body1" component="span">
                                {` (${t('you')})`}
                              </Typography>
                            )}
                          </>
                        )
                      }
                      primaryTypographyProps={{ variant: 'subtitle1' }}
                      secondary={
                        isInvitation(member) ? undefined : member.email
                      }
                      sx={{ m: 0 }}
                    />
                  </Stack>
                </TableCell>
                <TableCell sx={{ textAlign: 'left' }}>
                  {isInvitation(member) ? (
                    <Chip
                      label={t('invitation')}
                      variant="outlined"
                      color="secondary"
                      size="small"
                    />
                  ) : undefined}
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      canChangeRole(member) ? '' : t('cantChangeRoleTooltip')
                    }
                  >
                    <UserRolePicker
                      value={member.role}
                      hiddenLabel
                      fullWidth
                      size="small"
                      disabled={!canChangeRole(member)}
                      onOpen={() => setSelectedMember(member)}
                      onClose={() => setSelectedMember(null)}
                      onChange={(newRole) => handleRoleChange(member, newRole)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      isAdmin(member.role)
                        ? t('adminsHaveAccessToAllWorkspaces')
                        : ''
                    }
                  >
                    <MemberAccessSettingButton
                      disabled={isAdmin(member.role)}
                      value={member.access}
                      onChange={(value) =>
                        handleMemberAccessChange(member, value)
                      }
                    />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Tooltip title={t('more')}>
                    <MoreIconButton
                      edge="end"
                      size="small"
                      onClick={(e) => handleMoreButtonClick(e, member)}
                    >
                      <MoreHorizIcon />
                    </MoreIconButton>
                  </Tooltip>
                </TableCell>
              </HoverTableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
      <Menu
        anchorEl={moreMenuAnchorEl}
        open={Boolean(moreMenuAnchorEl)}
        id="member-context-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleContextMenuClose}
        sx={{ '& .MuiMenu-paper': { minWidth: 200 } }}
      >
        <MenuItem
          disabled={!canChangeRole(selectedMember)}
          onClick={handleRemoveButtonClick}
        >
          <ListItemIcon>
            <ClearIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {isInvitationSelected ? t('cancelInvitation') : t('remove')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <RemoveMemberConfirmationDialog
        open={removeUserDialogOpen}
        member={selectedMember}
        onClose={() => setRemoveUserDialogOpen(false)}
      />
      <InvitationDialog
        open={invitationDialogOpen}
        onClose={() => setInvitationDialogOpen(false)}
      />
    </Box>
  );
};

export default MembersSettingsView;
