import React from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  TypographyProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box/Box';

interface Props extends BoxProps {
  size?: number | string;
  label?: string;
  LabelProps?: TypographyProps;
}

const RootBox = styled(Box, { name: 'Loader' })(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  '&>p': {
    marginTop: theme.spacing(1),
  },
}));

export const Loader = ({
  size = 40,
  component,
  label,
  LabelProps,
  ...rest
}: Props) => {
  return (
    <RootBox component={component} {...rest}>
      <CircularProgress size={size} sx={{ color: '#9500ff' }} />
      {label && (
        <Typography color="textSecondary" {...LabelProps}>
          {label}
        </Typography>
      )}
    </RootBox>
  );
};

export default Loader;
