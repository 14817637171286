import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditorTextField from '../EditorTextField';
import AttachmentsWell from './AttachmentsWell';
import { Controller, useFormContext } from 'react-hook-form';
import { getBlockTemplate, getTranslations, translationRegistrationName } from '../utils';

const FormEditorAttachmentsBlock = ({ blockIndex }) => {
    const { t } = useTranslation();
    const { getValues} = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);

    return (
        <AttachmentsWell>
            <Button disabled variant="contained" color="default" component="div">
                <Controller
                    name={translationRegistrationName(blockTemplate.buttonText)}
                    render={({ field: { onChange, value } }) => (
                        <EditorTextField
                            textAlign="center"
                            placeholder={t("buttonText")}
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            textVariant="buttonMedium"
                            sx={{
                                pointerEvents: "all",
                                px: 3.5,
                            }}
                        />
                    )}
                />
            </Button>
            <Controller
                name={translationRegistrationName(blockTemplate.hintText)}
                render={({ field: { onChange, value } }) => (
                    <EditorTextField
                        textAlign="center"
                        placeholder={t("text")}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        sx={{
                            px: 3.5,
                        }}
                    />
                )}
            />
        </AttachmentsWell>
    );
};

FormEditorAttachmentsBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
};

FormEditorAttachmentsBlock.defaultProps = {};

export default FormEditorAttachmentsBlock;
