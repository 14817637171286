import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ColorPicker from '../../components/ColorPicker/ColorPicker';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const MoreButtonClassName = 'StatusDialog-MoreButton';
const HoverOutlineTextFieldClassName = 'StatusDialog-HoverOutlineTextField';

const RootTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'isDragging',
})(({ theme, isDragging }) => ({
  '&:last-child td, &:last-child th': { border: 0 },
  ...(isDragging && {
    backgroundColor: theme.palette.grey[100],
  }),
  [`& .${HoverOutlineTextFieldClassName}`]: {
    // '& input': {
    //     paddingLeft: 0,
    // },
    '& .MuiInputBase-root': {
      background: theme.palette.background.paper,
    },
  },
  '&:hover': {
    [`& .${MoreButtonClassName}`]: {
      visibility: 'visible',
    },
    [`& .${HoverOutlineTextFieldClassName}`]: {
      // '& input': {
      //     paddingLeft: 'var(--horizontal-padding)'
      // },
      '& fieldset': {
        borderStyle: 'solid',
      },
    },
  },
}));

const MoreIconButton = styled(IconButton)(({ theme }) => ({
  visibility: 'hidden',
}));

function areEqual(prevProps, nextProps) {
  if (
    prevProps.status.sk !== nextProps.status.sk ||
    prevProps.status.color !== nextProps.status.color ||
    prevProps.status.name !== nextProps.status.name ||
    prevProps.isDraggable !== nextProps.isDraggable ||
    prevProps.showMessageField !== nextProps.showMessageField ||
    prevProps.draggableIndex !== nextProps.draggableIndex ||
    prevProps.autoFocus !== nextProps.autoFocus ||
    prevProps.showMoreButton !== nextProps.showMoreButton ||
    prevProps.onChange !== nextProps.onChange ||
    prevProps.onMoreButtonClick !== nextProps.onMoreButtonClick
  ) {
    return false;
  }

  return true;
}

const StatusTableRow = React.memo(
  ({
    status,
    isDraggable,
    showMessageField,
    draggableIndex,
    autoFocus,
    showMoreButton,
    onChange,
    onMoreButtonClick,
    ColorPickerProps,
  }) => {
    const { t } = useTranslation();

    const handleStatusColorChange = (color) => {
      onChange({ ...status, color: color });
    };

    const handleStatusNameChange = (event) => {
      onChange({ ...status, name: event.target.value });
    };

    const handleStatusMessageChange = (event) => {
      onChange({ ...status, text: event.target.value });
    };

    const handleMoreClick = (event) => {
      onMoreButtonClick(event, status.sk);
    };

    const renderStatusTableRowCells = (status) => (
      <>
        <TableCell sx={{ px: '6px' }}>
          {isDraggable && (
            <DragIndicatorIcon
              fontSize="small"
              sx={{ color: 'text.disabled' }}
            />
          )}
        </TableCell>
        <TableCell sx={{ px: 0 }}>
          <Tooltip title={t('changeColor')}>
            <ColorPicker
              {...ColorPickerProps}
              defaultValue={status.color}
              onChange={handleStatusColorChange}
            />
          </Tooltip>
        </TableCell>
        {/*Width is set directly on cells instead of only on headers because of DragDrop*/}
        <TableCell
          component="th"
          scope="row"
          sx={{ width: showMessageField ? '30%' : '100%', px: 1 }}
        >
          <TextField
            fullWidth
            defaultValue={status.name}
            autoFocus={autoFocus}
            onChange={handleStatusNameChange}
            hiddenLabel
            variant="outlined"
          />
        </TableCell>
        {showMessageField && (
          <TableCell sx={{ width: '70%', px: 1 }}>
            <TextField
              fullWidth
              multiline
              defaultValue={status.text}
              onChange={handleStatusMessageChange}
              hiddenLabel
              variant="outlined"
            />
          </TableCell>
        )}
        <TableCell sx={{ px: 1 }}>
          <Tooltip title={t('more')}>
            <MoreIconButton
              className={MoreButtonClassName}
              edge="end"
              size="small"
              onClick={handleMoreClick}
              sx={{
                ...(showMoreButton && {
                  visibility: 'visible',
                }),
              }}
            >
              <MoreHorizIcon />
            </MoreIconButton>
          </Tooltip>
        </TableCell>
      </>
    );

    return !isDraggable ? (
      <RootTableRow key={status.sk} hover>
        {renderStatusTableRowCells(status)}
      </RootTableRow>
    ) : (
      <Draggable key={status.sk} draggableId={status.sk} index={draggableIndex}>
        {(provided, snapshot) => (
          <RootTableRow
            hover
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {renderStatusTableRowCells(status)}
          </RootTableRow>
        )}
      </Draggable>
    );
  },
  areEqual,
);

StatusTableRow.propTypes = {
  status: PropTypes.object.isRequired,
  isDraggable: PropTypes.bool,
  ColorPickerProps: PropTypes.object,
  showMessageField: PropTypes.bool,
  draggableIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  onChange: PropTypes.func,
  onMoreButtonClick: PropTypes.func,
};

StatusTableRow.defaultProps = {
  ColorPickerProps: {},
  onChange: () => {},
  onMoreButtonClick: () => {},
};

export default StatusTableRow;
