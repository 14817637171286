import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Box,
  Container,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkspaceCountry } from '../../constants/workspace';
import { getActiveLanguage } from '../../utils/localizationUtils';
import { ApiContext } from '../../contexts/ApiContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import { getWorkspaceRoute } from '../../utils/routeUtils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearState } from '../../state/rootActions';
import WorkspaceCountrySelect from '../../components/WorkspaceCountrySelect';

const RootBox = styled(Box, { name: 'OnboardingPage1' })(({ theme }) => ({}));

const OnboardingPage1 = () => {
  const { t, i18n } = useTranslation();
  const { mutationApi, authApi } = useContext(ApiContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const whitelabel = useContext(WhitelabelContext);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [companyCountry, setCompanyCountry] = useState('');
  const [companyCountryError, setCompanyCountryError] = useState<string | null>(
    null,
  );
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setName(value);
    setNameError(Boolean(value) ? null : 'nameIsRequired');
  };

  const handleCompanyCountryChange = (value: WorkspaceCountry) => {
    setCompanyCountry(value);
    setCompanyCountryError(Boolean(value) ? null : 'companyCountryRequired');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (nameError !== null || companyCountryError !== null) {
      return;
    }

    if (!Boolean(name)) {
      setNameError('nameIsRequired');
      return;
    }

    if (!Boolean(companyCountry)) {
      setNameError('companyCountryRequired');
      return;
    }

    try {
      setLoading(true);
      const workspaceName = `${name} - ${t('whistleblowing')}`;
      const language = getActiveLanguage(i18n);
      const { organization, workspace } = await mutationApi.createOrganization(
        name,
        workspaceName,
        language,
        companyCountry as WorkspaceCountry,
        whitelabel?.pk,
        '/icons/MegaphoneIcon',
      );
      await authApi.refreshAccessToken();
      setLoading(false);
      dispatch(clearState());

      navigate(getWorkspaceRoute(organization.pk, workspace.pk), {
        replace: true,
      });
    } catch (e: any) {
      console.error(e);
      setSubmitError('unknownOnboardingError');
      setLoading(false);
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs">
        <LinearProgress
          variant={'determinate'}
          value={66}
          color="primary"
          sx={{
            height: 4,
            mb: 3,
            borderRadius: 2,
            '& .MuiLinearProgress-bar': { borderRadius: 2 },
          }}
        />
        <Box sx={{ textAlign: 'center' }} mb={5}>
          <Typography variant="h4" component="h1">
            {t('onboardingPage1Title', {
              whitelabelName: whitelabel?.name || 'SafeTalk',
            })}
          </Typography>
          <Typography variant="bodyLarge" color="textSecondary">
            {t('onboardingPage1Subtitle')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <TextField
              id="company-name"
              autoFocus
              required
              fullWidth
              variant="outlined"
              type="text"
              label={t('companyNameFieldLabel')}
              value={name}
              onChange={handleNameChange}
              error={Boolean(nameError)}
              helperText={t(nameError!)}
            />
            <WorkspaceCountrySelect
              required
              fullWidth
              variant="outlined"
              error={Boolean(companyCountryError)}
              helperText={t(companyCountryError!)}
              value={companyCountry}
              onChange={handleCompanyCountryChange}
              label={t('companyCountryFieldLabel')}
            />
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              loading={loading}
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default OnboardingPage1;
