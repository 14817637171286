import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  InputAdornment,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';

const ColorIndicatorSize = 16;

const ColorPicker = React.forwardRef(
  ({ value, defaultValue, IndicatorIcon, onChange, ...rest }, ref) => {
    const [internalValue, setInternalValue] = useState(defaultValue);
    const currentValue = value ?? internalValue;

    const [menuAnchorEl, setMenuAnchorEl] = useState();
    const colors = [
      '#4F4F4F',
      '#828282',
      '#BDBDBD',
      '#E0E0E0',
      '#EB5757',
      '#F2994A',
      '#F2C94C',
      '#219653',
      '#27AE60',
      '#6FCF97',
      '#2F80ED',
      '#2D9CDB',
      '#56CCF2',
      '#9B51E0',
      '#BB6BD9',
    ];

    const handleColorSelected = (color) => {
      setMenuAnchorEl(null);
      if (internalValue != undefined) {
        setInternalValue(color);
      }
      onChange(color);
    };

    const defaultColorIndicator = (color, showCheckmarkWhenSelected) => (
      <svg
        width={ColorIndicatorSize}
        height={ColorIndicatorSize}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={ColorIndicatorSize}
          height={ColorIndicatorSize}
          rx="4"
          style={{ fill: color }}
        />
        {showCheckmarkWhenSelected && color === currentValue && (
          <CheckIcon fontSize="small" sx={{ color: 'primary.contrastText' }} />
        )}
      </svg>
    );

    return (
      <>
        <IconButton
          aria-describedby="color-menu"
          ref={ref}
          {...rest}
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        >
          {IndicatorIcon ? (
            <IndicatorIcon fontSize="small" style={{ color: currentValue }} />
          ) : (
            defaultColorIndicator(currentValue, false)
          )}
        </IconButton>
        <Popover
          id="color-menu"
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPopover-paper': {
              maxWidth: 200,
              p: 2,
            },
          }}
        >
          <Stack direction="row" flexWrap="wrap">
            {colors.map((color) => (
              <IconButton
                key={color}
                onClick={(e) => handleColorSelected(color)}
              >
                {defaultColorIndicator(color, true)}
              </IconButton>
            ))}
          </Stack>
        </Popover>
      </>
    );
  },
);

ColorPicker.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  IndicatorIcon: PropTypes.object,
  onChange: PropTypes.func,
};

ColorPicker.defaultProps = {
  onChange: () => {},
};

export default ColorPicker;
