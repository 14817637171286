import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { Button, ListItemIcon, ListItemText } from '@mui/material';
import FormPaper from '../../components/FormPaper';
import { getBlockLocalizationKeys } from '../../utils/formUtils';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import FormActions from '../../components/FormActions';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormBuilderFormBlock from './FormBuilderFormBlock';
import BlockSeparator from './BlockSeparator';
import { getDefaultTranslations, newFormBlock } from '../../utils/formBlockUtils';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ShortTextOutlinedIcon from '@mui/icons-material/ShortTextOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import {
    blocksRegistrationName,
    dictionaryRegistrationName,
    getDictionary, getFormTemplate,
    getPageTemplate,
    getTranslations,
    translationsRegistrationName
} from '../../components/FormBlock/utils';
import { s8 } from '../../utils/idGenerator';
import cloneDeep from 'lodash/cloneDeep';
import { removeObjectFields } from '../../utils/objectUtils';
import FormPrivacyPolicyBlock from '../../components/FormPrivacyPolicyBlock';

const components = [
    {
        type: "text",
        icon: <TextFieldsIcon />,
    },
    {
        type: "textField",
        icon: <ShortTextOutlinedIcon />,
    },
    {
        type: "multilineTextField",
        icon: <SubjectIcon />,
    },
    {
        type: "dateField",
        icon: <EventOutlinedIcon />,
    },
    {
        type: "dropdown",
        icon: <ArrowDropDownCircleOutlinedIcon />,
    },
    {
        type: "checkboxList",
        icon: <CheckBoxOutlinedIcon />,
    },
    {
        type: "attachments",
        icon: <AttachFileOutlinedIcon />,
    },
];

const FormBuilderPage = ({ workspace, pageIndex, pageCount, language }) => {
    const { t } = useTranslation();
    const [componentMenuAnchorEl, setComponentMenuAnchorEl] = useState();
    const [activeBlockIndex, setActiveBlockIndex] = useState();
    const { control, unregister, getValues, setValue, formState } = useFormContext();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: blocksRegistrationName(),
    });
    const formTemplate = getFormTemplate(getValues);

    const handleDragEnd = ({ draggableId, type, destination, source }) => {
        // dropped outside the list
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        swap(source.index, destination.index);
    };

    const openComponentContextMenu = (event, blockIndex) => {
        setActiveBlockIndex(blockIndex);
        setComponentMenuAnchorEl(event.currentTarget);
    };

    const handleBlockAdd = (blockType) => {
        setComponentMenuAnchorEl(null);

        const blockTemplate = newFormBlock(blockType);

        // Update active translations
        const translations = getTranslations(getValues);
        const defaultActiveTranslations = getDefaultTranslations(blockTemplate, language, t);
        setValue(
            translationsRegistrationName(),
            { ...translations, ...defaultActiveTranslations },
            { shouldDirty: true }
        );

        // Update language packs
        const dictionary = getDictionary(getValues);
        const newDictionary = {};
        for (const lang in dictionary) {
            const defaultTranslations = getDefaultTranslations(blockTemplate, lang, t);
            newDictionary[lang] = { ...dictionary[lang], ...defaultTranslations };
        }
        if (Object.keys(newDictionary).length > 0) {
            setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });
        }

        // Update page template
        insert(activeBlockIndex, blockTemplate);
    };

    const handleBlockDuplicate = (blockIndex) => {
        const pageTemplate = getPageTemplate(getValues);
        const blockTemplate = pageTemplate.blocks[blockIndex];
        const localizationKeys = getBlockLocalizationKeys(blockTemplate);
        const newLocalizationKeys = localizationKeys.map((_) => s8());

        // Update active translations
        const translations = getTranslations(getValues);
        const clonedActiveTranslations = Object.assign(
            ...localizationKeys.map((key, i) => ({ [newLocalizationKeys[i]]: cloneDeep(translations[key]) }))
        );
        setValue(
            translationsRegistrationName(),
            { ...translations, ...clonedActiveTranslations },
            { shouldDirty: true }
        );

        // Clone translations in other language packs
        const dictionary = getDictionary(getValues);
        const newDictionary = {};
        for (const lang in dictionary) {
            const clonedTranslations = Object.assign(
                ...localizationKeys.map((key, i) => ({ [newLocalizationKeys[i]]: cloneDeep(dictionary[lang][key]) }))
            );
            newDictionary[lang] = { ...dictionary[lang], ...clonedTranslations };
        }
        setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });

        // Clone block template
        let newBlockTemplateJson = JSON.stringify(blockTemplate);
        for (let i = 0; i < localizationKeys.length; i++) {
            newBlockTemplateJson = newBlockTemplateJson.replace(localizationKeys[i], newLocalizationKeys[i]);
        }
        const newBlockTemplate = JSON.parse(newBlockTemplateJson);
        newBlockTemplate.key = s8();
        insert(blockIndex + 1, newBlockTemplate);
    };

    const handleBlockDelete = (blockIndex) => {
        setComponentMenuAnchorEl(null);
        const pageTemplate = getPageTemplate(getValues);
        const localizationKeys = getBlockLocalizationKeys(pageTemplate.blocks[blockIndex]);

        // Update active translations
        const activeTranslations = getTranslations(getValues);
        const updatedActiveTranslations = removeObjectFields(activeTranslations, localizationKeys);
        setValue(translationsRegistrationName(), updatedActiveTranslations, { shouldDirty: true });

        // Update language packs
        const dictionary = getDictionary(getValues);

        const newDictionary = {};
        for (const lang in dictionary) {
            newDictionary[lang] = removeObjectFields(dictionary[lang], localizationKeys);
        }
        setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });

        // Update form template
        remove(blockIndex);
    };

    return (
        <>
            <FormPaper workspace={workspace} sx={{ px: 0 }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={`page-${pageIndex}`}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {/*{pageTemplate.blocks.length === 0 && <div className={classes.blockPlaceholder} />}*/}
                                <BlockSeparator onClick={(e) => openComponentContextMenu(e, 0)} />
                                {fields.map((blockTemplate, blockIndex) => (
                                    <React.Fragment key={blockTemplate.key}>
                                        <FormBuilderFormBlock
                                            blockIndex={blockIndex}
                                            language={language}
                                            sx={{ px: 6 }}
                                            onBlockDuplicate={() => handleBlockDuplicate(blockIndex)}
                                            onBlockDelete={() => handleBlockDelete(blockIndex)}
                                        />
                                        <BlockSeparator onClick={(e) => openComponentContextMenu(e, blockIndex + 1)} />
                                    </React.Fragment>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <FormActions sx={{ px: 6 }}>
                    {pageIndex > 0 && pageIndex < pageCount - 1 && (
                        <Button fullWidth size="large" color="default" variant="outlined" disabled>
                            {t("back", { lng: language })}
                        </Button>
                    )}
                    <LoadingButton fullWidth disabled size="large" color="primary" variant="contained">
                        {pageIndex === pageCount - 2
                            ? t("submit", { lng: language })
                            : t("continue", { lng: language })}
                    </LoadingButton>
                </FormActions>
            </FormPaper>
            {pageIndex === pageCount - 2 && formTemplate.privacyPolicy && (
                <FormPrivacyPolicyBlock mode='edit' language={language}/>
            )}
            <Menu
                open={Boolean(componentMenuAnchorEl)}
                anchorEl={componentMenuAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={() => setComponentMenuAnchorEl(null)}
            >
                {components.map((component) => (
                    <MenuItem key={`menu-item-${component.type}`} onClick={() => handleBlockAdd(component.type)}>
                        <ListItemIcon>{component.icon}</ListItemIcon>
                        <ListItemText>{t(component.type)}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

FormBuilderPage.propTypes = {
    workspace: PropTypes.object.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
};

FormBuilderPage.defaultProps = {
};

export default FormBuilderPage;
