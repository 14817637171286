export const LANGUAGE_LABELS = {
    en: "English",
    cs: "Čeština",
    de: "Deutsch",
    sk: "Slovenčina",
    pl: "Polski",
    hu: "Magyar",
    fr: "Français",
    es: "Español",
    pt: "Português",
    it: "Italiano",
    nl: "Nederlands",
    da: "Dansk",
    no: "Norsk",
    fi: "Suomi",
    sv: "Svenska",
    ro: "Română",
    sl: "Slovenščina",
    hr: "Hrvatski",
    lv: "Latviešu",
    et: "Eesti",
    ru: "Pусский",
    uk: "Українська",
    bg: "български език",
    el: "ελληνικά",
    vi: "Tiếng Việt",
    zh: "汉语"
};

export const UI_LANGUAGES = ['cs', 'en', "sk", "pl", "de", "hu", "et"];

export function getLocale(language) {
    // if (language === 'en') {
    //     return 'en-GB';
    // }
    if (language === 'no') {
        return 'nb';
    }
    return language;
}