import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import ResourceAvatar from '../ResourceAvatar';
import { styled } from '@mui/material/styles';
import { getSmallPictureVariant } from '../../utils/avatarUtils';

const RootStack = styled(Stack, { name: "FormPaperLogo" })(({ theme }) => ({
}));

const FormPaperLogo = ({workspace, ...rest}) => {
    return (
        <RootStack flexWrap="wrap" direction="row" alignItems="center" justifyContent="center" spacing={1} {...rest}>
            <ResourceAvatar variant='rounded' name={workspace.name} picture={workspace.picture}/>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
                {workspace.name}
            </Typography>
        </RootStack>
    );
};

FormPaperLogo.propTypes = {
    workspace: PropTypes.shape({
        name: PropTypes.string.isRequired,
        picture: PropTypes.string,
    }).isRequired,
};

FormPaperLogo.defaultProps = {};

export default FormPaperLogo;
