import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PopperMenu from '../PopperMenu';

const FormattingMenu = ({
  open,
  anchorEl,
  offset,
  formatters,
  selectedKey,
  onClick,
  onClose,
}) => {
  const { t } = useTranslation();

  const preventDefault = (e) => {
    // Prevents buttons from taking focus from the rich text editor.
    e.preventDefault();
  };

  return (
    <PopperMenu
      disableAutoFocusItem
      disablePortal
      id="text-formatting-menu"
      placement="top-start"
      open={open}
      anchorEl={anchorEl}
      offset={offset}
      onClose={onClose}
      onMouseDown={preventDefault}
      sx={{
        zIndex: 'modal',
      }}
    >
      {formatters.map((formatter) => {
        const Icon = formatter.Icon;
        return (
          <MenuItem
            key={`formattingMenuItem-${formatter.name}}`}
            onClick={(e) => onClick(formatter)}
            selected={formatter.name === selectedKey}
          >
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t(formatter.name)}</ListItemText>
            {formatter.shortcut && (
              <Typography variant="body2" color="text.secondary" sx={{ ml: 4 }}>
                {formatter.shortcut}
              </Typography>
            )}
          </MenuItem>
        );
      })}
    </PopperMenu>
  );
};

FormattingMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  selectedKey: PropTypes.string,
  offset: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }),
  formatters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      Icon: PropTypes.object,
      shortcut: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

FormattingMenu.defaultProps = {
  onClick: () => {},
  onClose: () => {},
};

export default FormattingMenu;
