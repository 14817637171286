import React, { ReactNode } from 'react';
import { alpha, Avatar, Box, Skeleton } from '@mui/material';
import {
  getAvatarColor,
  getInitials,
  getLargePictureVariant,
  getSmallPictureVariant,
  isIconPicture,
} from '../../utils/avatarUtils';
import { useAvatarPicture } from '../../hooks/useAvatarPicture';
import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box/Box';
import { WORKSPACE_COLORS } from '../../constants/workspace';
import WorkspaceIcon from '../WorkspaceIcon';

interface RootBoxExtraProps extends BoxProps {
  size: number;
}

const RootBox = styled(Box, {
  name: 'ResourceAvatar',
  shouldForwardProp: (prop) => prop !== 'size',
})<RootBoxExtraProps>(({ theme, size }) => ({
  width: size,
  height: size,
}));

interface Props extends BoxProps {
  name: string;
  picture?: string | null;
  size?: number;
  pictureSize?: 'small' | 'large';
  variant?: 'rounded' | 'circular';
}

const ResourceAvatar = React.forwardRef(
  (
    {
      name,
      picture,
      component,
      size = 40,
      pictureSize = 'small',
      variant = 'rounded',
      ...rest
    }: Props,
    ref,
  ) => {
    const imageKey = isIconPicture(picture)
      ? null
      : pictureSize === 'small'
        ? getSmallPictureVariant(picture)
        : getLargePictureVariant(picture);
    const [url] = useAvatarPicture(imageKey);

    if (isIconPicture(picture)) {
      return (
        <RootBox ref={ref} component={component} size={size} {...rest}>
          <Avatar
            alt={name}
            variant={variant}
            component={component as any}
            sx={{
              width: 1,
              height: 1,
              fontSize: `${size / 2 / 16}em`,
              bgcolor: alpha(WORKSPACE_COLORS[0], 0.2),
              color: WORKSPACE_COLORS[0],
            }}
          >
            <WorkspaceIcon
              iconName={picture?.replaceAll('/icons/', '') as any}
              sx={{ fontSize: 'inherit' }}
            />
          </Avatar>
        </RootBox>
      );
    }

    return (
      <RootBox ref={ref} component={component} size={size} {...rest}>
        {!url && imageKey ? (
          <Skeleton
            component={component as any}
            variant={variant}
            sx={{ width: 1, height: 1 }}
          />
        ) : (
          <Avatar
            component={component as any}
            variant={variant}
            sx={{
              width: 1,
              height: 1,
              fontSize: `${(size / 2 / 16) * 0.75}em`,
              fontWeight: 400,
              bgcolor: getAvatarColor(name),
              '& .MuiAvatar-img': {
                objectFit: 'initial',
              },
            }}
          >
            {getInitials(name, 2)}
          </Avatar>
        )}
      </RootBox>
    );
  },
);

export default ResourceAvatar;
