import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const FormActions = styled(Box, { name: "FormActions" })(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    '&>button:not(:last-child)': {
        marginRight: theme.spacing(3)
    }
}));

export default FormActions;
