// TODO: Move to text utils
export const formatText = (text, wordLength, separator = ' ') => {
    const pattern = new RegExp(`.{1,${wordLength}}`, 'g') // constructor with string pattern as first argument
    if (!text) {
        return "";
    }

    return text.match(pattern).join(separator);
}

export const formatFormId = (id) => {
    return formatText(id, 4, '-');
}

export const formatReportId = (id) => {
    return formatText(id, 4, '-');
}

export const formatWorkspaceId = (id) => {
    return formatText(id, 3);
}

export const normalizeId = (text)=> {
    if (!text) {
        return null;
    }

    return text.replaceAll(' ', '').replaceAll('-', '');
}

export function formatPhoneNumber(value) {
    const normalized = normalizeId(value);
    const number = normalized.startsWith('+') ? value.slice(1) : value;
    return `+${formatText(number, 3)}`;
}