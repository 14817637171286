import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const RootTypography = styled(Typography, { name: "Timestamp" })(({ theme }) => ({
    color: theme.palette.text.secondary,
    textWrap: 'nowrap'
}));

const Timestamp = ({ value, ...rest }) => {
    const { t } = useTranslation();

    return (
        <RootTypography variant="body2" {...rest}>
            {t("dateLong", { date: new Date(value) })}
        </RootTypography>
    );
};

Timestamp.propTypes = {
    value: PropTypes.string.isRequired
};

Timestamp.defaultProps = {
};

export default Timestamp;
