import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import ReportLoginDialog from './ReportLoginDialog';
import ReportingPersonChat from './ReportingPersonChat';
import Page from '../../components/Page/Page';

const FollowupView = () => {
  const [password, setPassword] = useState();
  const [reportKey, setReportKey] = useState();

  const handleReportLogin = (args) => {
    setReportKey(args.reportKey);
    setPassword(args.password);
  };

  const handleChatClose = () => {
    setReportKey(null);
    setPassword(null);
  };

  return (
    <Page component={'main'} sx={{ height: '100vh', overflow: 'hidden' }}>
      {reportKey && password ? (
        <ReportingPersonChat
          password={password}
          reportKey={reportKey}
          onChatClose={handleChatClose}
        />
      ) : (
        <ReportLoginDialog onLogin={handleReportLogin} />
      )}
    </Page>
  );
};

export default FollowupView;
