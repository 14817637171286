import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ApiContext } from '../../contexts/ApiContext';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MenuWithArrow from '../../components/MenuWithArrow/MenuWithArrow';
import { allOrganizationsSelector } from '../../state/organizations/selectors';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  currentUserRoleSelector,
  currentUserRolesInAllOrganizationsSelector,
} from '../../state/members/selectors';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckIcon from '@mui/icons-material/Check';
import PopperMenu from '../../components/PopperMenu';
import { toId } from '../../utils/apiUtils';
import { useStoredOrganizationId } from '../../hooks/useStoredOrganizationId';
import { currentUserLogout } from '../../state/currentUser/actions';
import {
  clearState,
  clearStateExceptCurrentUser,
} from '../../state/rootActions';
import { isAdmin } from '../../utils/user';
import AddIcon from '@mui/icons-material/Add';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ResourceAvatar from '../../components/ResourceAvatar';
import {
  isEnterpriseOrganization,
  isWhitelabelEnabled,
} from '../../utils/modelUtils';
import { getSmallPictureVariant } from '../../utils/avatarUtils';
import { getDefaultAppUrl } from '../../utils/routeUtils';
import { isWhitelabelOrigin } from '../../utils/appUtils';
import { useAuthContext } from '../../contexts/AuthContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledButton = styled(Button, { name: 'ProfileButton' })(
  ({ theme, position }) => ({
    textTransform: 'none',
    ...theme.typography.subtitle1,
  }),
);

const StyledListItemIcon = styled(ListItemIcon)(({ theme, position }) => ({
  minWidth: theme.spacing(5),
}));

const ProfileButton = ({ showName, ...rest }) => {
  const { t } = useTranslation();
  const { signOut } = useAuthContext();
  const navigate = useNavigate();
  const organizationKey = useOrganizationKey();
  const user = useSelector(currentUserSelector());
  const [mainMenuAnchorEl, setMainMenuAnchorEl] = useState(null);

  const handleProfileMenuOpen = (event) => {
    setMainMenuAnchorEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setMainMenuAnchorEl(null);
  };

  const handleLogoutClick = async (event) => {
    event.preventDefault();
    await signOut();
    //window.location.reload();
  };

  const handleAccountMenuItemClick = () => {
    closeProfileMenu();
    navigate(`/app/${toId(organizationKey)}/account`);
  };

  return (
    <>
      {showName ? (
        <StyledButton
          onClick={handleProfileMenuOpen}
          size="small"
          color="default"
          startIcon={
            <ResourceAvatar
              variant="circular"
              name={user.name}
              picture={user.picture}
              size={32}
            />
          }
          endIcon={<ExpandMoreIcon />}
          {...rest}
        >
          {user.name}
        </StyledButton>
      ) : (
        <IconButton size="small" onClick={handleProfileMenuOpen} {...rest}>
          <ResourceAvatar
            variant="circular"
            name={user.name}
            picture={user.picture}
          />
        </IconButton>
      )}
      <MenuWithArrow
        autoFocus={false}
        anchorEl={mainMenuAnchorEl}
        id="profile-menu"
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(mainMenuAnchorEl)}
        onClose={closeProfileMenu}
        sx={{ '& .MuiMenu-paper': { minWidth: 240 } }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            cursor: 'initial',
            '&:hover': {
              background: 'initial',
            },
          }}
        >
          <Typography variant="subtitle1">{user.email}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleAccountMenuItemClick}>
          <StyledListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary={t('myAccount')} />
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <StyledListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </StyledListItemIcon>
          <ListItemText primary={t('Logout')} />
        </MenuItem>
      </MenuWithArrow>
    </>
  );
};

ProfileButton.propTypes = {
  showName: PropTypes.bool,
};

ProfileButton.defaultProps = {
  showName: true,
};

export default ProfileButton;
