import { Storage } from 'aws-amplify';
import config from '../aws-exports';
import { ImageTools } from '../utils/ImageTools';
import { AvatarPictureSizes } from '../constants';
import { newUuid } from '../utils/idGenerator';
import { appendToFileName, getExtension } from '../utils/fileUtils';
import {
    CURRENT_STORAGE_VERSION,
    LargePictureSuffix,
    SmallPictureSuffix,
    ThumbnailPictureSuffix,
} from '../constants/storage';

const { aws_user_files_s3_bucket_region: region, aws_user_files_s3_bucket: bucket } = config;

type ProgressCallback = (progress: number) => void;

export default class StorageApi {
    static getPublicUrl(key?: string) {
        return key ? `https://${bucket}.s3.${region}.amazonaws.com/public/${key}` : null;
    }

    static async getSignedUrl(key?: string) {
        if (!key) {
            return null;
        }

        if (!key.startsWith('v1')) {
            return StorageApi.getPublicUrl(key);
        }

        return await Storage.get(key, { expires: 60 });
    }

    static async downloadObject(key?: string) {
        if (!key) {
            return null;
        }

        // TODO: Expiration was disabled, because it disabled caching, file were downloaded every time.
        // TODO: Cache blob urls instead of relying on StorageApi cache, then reenable expiration.
        return await Storage.get(key, { expires: 60, download: true, cacheControl: 'max-age=2419200' }); // 2419200 = four weeks
    }

    static async uploadProfilePicture(file: File, userId: string, progressCallback: ProgressCallback) {
        const pictureName = newUuid() + getExtension(file.name);
        return StorageApi._uploadAvatarPictures(
            file,
            `${CURRENT_STORAGE_VERSION}/profile-pictures/${userId}/${pictureName}`,
            progressCallback,
        );
    }

    static async uploadWorkspacePicture(file: File, workspaceId: string, progressCallback: ProgressCallback) {
        const pictureName = newUuid() + getExtension(file.name);
        return StorageApi._uploadAvatarPictures(
            file,
            `${CURRENT_STORAGE_VERSION}/workspace-pictures/${workspaceId}/${pictureName}`,
            progressCallback,
        );
    }

    static async deleteAttachment(key: string) {
        if (!key.startsWith('v1')) {
            // Delete avatar picture that have been uploaded before small/large picture
            // keys have been implemented. Such avatars were uploaded to "public" folder.
            await StorageApi._deleteObject(key);
            return;
        }

        let keysToRemove = [key];
        if (StorageApi._isThumbnailPicture(key)) {
            const imageKey = key.replace(ThumbnailPictureSuffix, '');
            keysToRemove = [...keysToRemove, imageKey];
        }

        try {
            for (const keyToRemove of keysToRemove) {
                await Storage.remove(keyToRemove);
            }
        } catch (err) {
            console.warn('error: ', err);
        }
    }

    static async deleteAvatarPictures(key: string) {
        const isSmallKey = StorageApi._isSmallPictureKey(key);
        const isLargeKey = StorageApi._isLargePictureKey(key);
        if (!isSmallKey && !isLargeKey) {
            // Delete avatar picture that have been uploaded before small/large picture
            // keys have been implemented. Such avatars were uploaded to "public" folder.
            await StorageApi._deleteObject(key);
            return;
        }

        let smallKey = key;
        let largeKey = key;
        if (isSmallKey) {
            largeKey = key.replace(SmallPictureSuffix, LargePictureSuffix);
        } else if (isLargeKey) {
            smallKey = key.replace(LargePictureSuffix, SmallPictureSuffix);
        }

        try {
            // Remove small avatar
            await Storage.remove(smallKey);

            // Remove large avatar
            await Storage.remove(largeKey);
        } catch (err) {
            console.warn('error: ', err);
        }
    }

    // TODO: Upload svg avatars only once
    static async _uploadAvatarPictures(file: File, key: string, progressCallback: ProgressCallback) {
        try {
            // Resize avatar picture to small size and upload it to S3
            const smallKey = appendToFileName(key, SmallPictureSuffix);
            const smallPictureBlob = await ImageTools.cropToSquare(file, AvatarPictureSizes.small);
            await Storage.put(smallKey, smallPictureBlob, {
                contentType: file.type,
                progressCallback: (progress: any) => {
                    progressCallback((progress.loaded / progress.total) * 50);
                },
            });

            // Resize avatar picture to large size and upload it to S3
            const largeKey = appendToFileName(key, LargePictureSuffix);
            const largePictureBlob = await ImageTools.cropToSquare(file, AvatarPictureSizes.large);
            await Storage.put(largeKey, largePictureBlob, {
                contentType: file.type,
                progressCallback: (progress: any) => {
                    progressCallback(50 + (progress.loaded / progress.total) * 50);
                },
            });

            return smallKey;
        } catch (err) {
            console.warn('error: ', err);
        }
    }

    static async _deleteObject(key: string) {
        try {
            await Storage.remove(key);
        } catch (err) {
            console.log('error: ', err);
        }
    }

    static _isSmallPictureKey(key: string) {
        return key.includes(SmallPictureSuffix);
    }

    static _isLargePictureKey(key: string) {
        return key.includes(LargePictureSuffix);
    }

    static _isThumbnailPicture(key: string) {
        return key.includes(ThumbnailPictureSuffix);
    }
}
