import defaultTheme from "./theme";

const globalStyles = {
    '*': {
        scrollbarWidth: "thin",
        // https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
        scrollbarColor: "#d2d2d2 #eeeeee"
        // boxSizing: 'border-box',
        // margin: 0,
        // padding: 0,
    },
    // Chrome/Edge/Safari
    "*::-webkit-scrollbar": {
        width: "10px",
        height: "10px"
    },
    "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#d2d2d2"
    },
    "*::-webkit-scrollbar-track": {
        background: "#eeeeee"
    },
    "*::-webkit-scrollbar-corner": {
        background: "#eeeeee"
    },
    html: {
        webkitFontSmoothing: 'antialiased',
        mozOsxFontSmoothing: 'grayscale',
        height: '100%',
        width: '100%'
    },
    body: {
        height: '100%',
        width: '100%',
        // fontSize: "14px",
        // lineHeight: 1
        //overflow: "hidden",
    },
    div: {
        // lineHeight: 'initial',
        // fontFamily: 'initial',
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
    },
    '#root': {
        height: '100%',
        width: '100%',
        maxHeight: "100%",
        // "--amplify-primary-color": "#1F81F7",
        // "--amplify-primary-tint": "#3397FF",
        // "--amplify-primary-shade": "#0057B2"
    },
    '.ps > .ps__rail-y': {
        opacity: 0.6
    }
};

export default globalStyles;