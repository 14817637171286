import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import Page from '../../components/Page/Page';
import ForgottenPasswordPage1 from './ForgottenPasswordPage1';
import ForgottenPasswordPage2 from './ForgottenPasswordPage2';
import { CognitoUser } from 'amazon-cognito-identity-js';

const ForgottenPasswordView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string | null>(state?.email ?? null);

  console.log('email: %o', email);

  const handlePage2Submit = (user: CognitoUser, password: string) => {
    console.log('resolveCognitoChallenge, user: %o', user);
    if (
      user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'NEW_PASSWORD_REQUIRED'
    ) {
      navigate('/login', {
        replace: true,
        state: { from: state?.from, cognitoUser: user, password: password },
      });
      return;
    }

    navigate(state?.from ?? '/app', { replace: true });
  };

  return (
    <Page>
      {!Boolean(email) ? (
        <ForgottenPasswordPage1 onSubmit={setEmail} />
      ) : (
        <ForgottenPasswordPage2 email={email!} onSubmit={handlePage2Submit} />
      )}
    </Page>
  );
};

export default ForgottenPasswordView;
