import React from "react";
import { Button, Divider, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { RichUtils } from "draft-js";
import { moveFocusToEnd } from "../../utils/richTextUtils";
import PropTypes from "prop-types";

const RootStack = styled(Stack, { name: "EditorToolbar" })(({ theme }) => ({
    position: "sticky",
    bottom: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTop: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
    }),
    "&>button": {
        margin: theme.spacing(0, 0.25),
    },
}));

const ToolbarButton = styled(Button, { shouldForwardProp: (prop) => prop !== "active" })(({ theme, active }) => ({
    color: theme.palette.text.secondary,
    ...(active && {
        color: theme.palette.primary.main,
    }),
}));

const ToolbarIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== "active" })(
    ({ theme, active }) => ({
        color: theme.palette.text.secondary,
        ...(active && {
            color: theme.palette.primary.main,
        }),
    })
);

const ToolbarDivider = styled(Divider)(({ theme, active }) => ({
    height: theme.spacing(2),
    margin: theme.spacing(0, 0.5),
}));

const FormattingToolbar = ({ editorRootRef, editorState, layout, formatters, onChange, onCallbackFormatterSelected, ...rest }) => {
    const { t } = useTranslation();
    const isEditorFocused = editorRootRef.current?.editor === document.activeElement;
    const currentInlineStyle = isEditorFocused ? editorState.getCurrentInlineStyle() : null;
    const currentBlockType = isEditorFocused
        ? editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType()
        : null;

    const handleMouseDown = (event, type, value) => {
        event.preventDefault();
        if (type === "callback") {
            onCallbackFormatterSelected(value);
            return;
        }

        let newEditorState = isEditorFocused ? editorState : moveFocusToEnd(editorState);
        event.preventDefault();
        newEditorState =
            type === "inline"
                ? RichUtils.toggleInlineStyle(newEditorState, value)
                : RichUtils.toggleBlockType(newEditorState, value);
        onChange(newEditorState, true);
    };

    const handleToolbarMouseDown = (event) => {
        // Prevent toolbar from taking focus from the editor if a user accidentally clicks on the toolbar and not on
        // the buttons
        event.preventDefault();
    };

    return (
        <RootStack
            direction="row"
            alignItems="center"
            justifyContent="center"
            onMouseDown={handleToolbarMouseDown}
            {...rest}
        >
            {layout.map((name, i) => {
                if (name === "divider") {
                    return <ToolbarDivider key={`divider-${i}`} />;
                }
                const formatter = formatters.find((item) => item.name === name);
                let active = false;
                if (formatter.type === "inline" && currentInlineStyle) {
                    active = currentInlineStyle.has(formatter.styleName);
                } else if (formatter.type === "block" && currentBlockType) {
                    active = currentBlockType === formatter.blockType;
                }

                const Icon = formatter.Icon;
                return Boolean(Icon) ? (
                    <Tooltip key={formatter.name} title={t(formatter.name)}>
                        <ToolbarIconButton
                            size="small"
                            active={active}
                            onMouseDown={(e) =>
                                handleMouseDown(
                                    e,
                                    formatter.type,
                                    formatter.styleName ?? formatter.blockType ?? formatter.action
                                )
                            }
                        >
                            <Icon fontSize="small" />
                        </ToolbarIconButton>
                    </Tooltip>
                ) : (
                    <ToolbarButton
                        key={formatter.name}
                        size="small"
                        active={active}
                        onMouseDown={(e) =>
                            handleMouseDown(
                                e,
                                formatter.type,
                                formatter.styleName ?? formatter.blockType ?? formatter.action
                            )
                        }
                    >
                        {t(formatter.name)}
                    </ToolbarButton>
                );
            })}
        </RootStack>
    );
};

FormattingToolbar.propTypes = {
    editorRootRef: PropTypes.object.isRequired,
    editorState: PropTypes.object.isRequired,
    layout: PropTypes.arrayOf(PropTypes.string).isRequired,
    formatters: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            styleName: PropTypes.string,
            blockType: PropTypes.string,
        })
    ),
    onChange: PropTypes.func.isRequired,
    onCallbackFormatterSelected: PropTypes.func,
};

FormattingToolbar.defaultProps = {
    onCallbackFormatterSelected: () => {},
};

export default FormattingToolbar;
