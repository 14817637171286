import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useStoredOrganizationId } from '../hooks/useStoredOrganizationId';
import { getCognitoGroups } from '../utils/user';
import PropTypes from 'prop-types';
import { WhitelabelContext } from '../contexts/WhitelabelContext';
import { isOrganization, toId, toOrganizationKey } from '../utils/apiUtils';
import NotAuthorizedView from '../views/NotAuthorizedView';

const NavigateToEntryPage = ({cognitoUser}) => {
    const whitelabel = useContext(WhitelabelContext);
    const [storedOrganizationId, _] = useStoredOrganizationId();

    const authorizedOrganizations = getCognitoGroups(cognitoUser);
    if (!authorizedOrganizations || authorizedOrganizations.length === 0) {
        return <Navigate replace to={'/app/onboarding'}/>;
    } else if (storedOrganizationId && authorizedOrganizations.includes(storedOrganizationId)) {
        return <Navigate replace to={`/app/${storedOrganizationId}/home`}/>;
    } else {
        // Redirect to the first organization from the cognito:groups attribute.
        let organizationId = authorizedOrganizations[0];

        // A whitelabel can either be an organization or a partner resource. If it's an organization redirect to the
        // organization entry page.
        if (isOrganization(whitelabel)) {
            const authorizedOrganizationId = authorizedOrganizations.find(id => toOrganizationKey(id) === whitelabel.pk);
            if (!authorizedOrganizationId) {
                return <NotAuthorizedView/>
            } else {
                organizationId = authorizedOrganizationId;
            }
        }

        return <Navigate replace to={`/app/${organizationId}/home`}/>;
    }
};

NavigateToEntryPage.propTypes = {
    cognitoUser: PropTypes.object
};

NavigateToEntryPage.defaultProps = {
};

export default NavigateToEntryPage;
