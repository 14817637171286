import dayjs from 'dayjs';
import { TFunction } from 'i18next';

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function base64ToBytes(base64: string): Uint8Array {
  const binString = atob(base64);
  const bytes = new Uint8Array(binString.length);
  for (let i = 0; i < binString.length; i++) {
    bytes[i] = binString.charCodeAt(i);
  }
  return bytes;
}

export function bytesToBase64(bytes: Uint8Array): string {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('');
  return btoa(binString);
}

export function isJSONString(text?: string): boolean {
  if (isNullOrEmpty(text)) {
    return false;
  }

  const jsonRegex = /^\s*(\{.*\}|\[.*\])\s*$/;
  return jsonRegex.test(text!);
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function isNullOrEmpty(text?: string | null) {
  return !Boolean(text) || text === '' || isOnlySpaces(text!);
}

export function isOnlySpaces(str: string) {
  return str.match(/^ *$/) !== null;
}

// TODO: Remove
export function getReportName(
  report: { name: string; createdAt: string | number },
  t: TFunction,
) {
  if (report.name) {
    return report.name;
  }

  return t('reportFrom', { value: new Date(report.createdAt) });
}

export function isValidEmail(email: string) {
  const regex = /\S+@\S+\.\S+/;
  return regex.test(email);
}

export function isValidPhoneNumber(phoneNumber: string) {
  const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  return regex.test(phoneNumber);
}

export function isValidDate(date: string | number) {
  return dayjs(date).isValid();
}

export function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export function insertAtCaret(textArea: any, text: string) {
  text = text || '';
  // @ts-ignore
  if (document.selection) {
    // IE
    textArea.focus();
    // @ts-ignore
    const sel = document.selection.createRange();
    sel.text = text;
  } else if (textArea.selectionStart || textArea.selectionStart === 0) {
    // Others
    const startPos = textArea.selectionStart;
    const endPos = textArea.selectionEnd;
    textArea.value =
      textArea.value.substring(0, startPos) +
      text +
      textArea.value.substring(endPos, textArea.value.length);
    textArea.selectionStart = startPos + text.length;
    textArea.selectionEnd = startPos + text.length;
  } else {
    textArea.value += text;
  }
}

export function maskPhoneNumber(phoneNumber: string) {
  const normalized = phoneNumber.replace(/\s/g, '').replace('+', '');
  const countryCode = normalized.slice(0, normalized.length - 9);
  return `+${countryCode} *** *** ${phoneNumber.slice(-3)}`;
}

export function maskEmail(email: string) {
  const parts = email.split('@');
  const maskedName = parts[0].slice(1, -1).replace(/./g, '*');
  return `${parts[0].slice(0, 1)}${maskedName}${parts[0].slice(-1)}@${parts[1]}`;
}

export function removeWhitespace(text: string) {
  return text.replace(/\s/g, '');
}

export function addPrefixIfMissing(url: string) {
  const hasPrefix = /^(https?:\/\/|mailto:|callto:)/i.test(url);
  if (!hasPrefix) {
    return 'https://' + url;
  }
  return url;
}
