import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import Page from '../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { activeWorkspacesSelector } from '../../state/workspaces/selectors';
import WorkspaceTile from './WorkspaceTile';
import AddWorkspaceButton from './AddWorkspaceButton';
import { makeStyles } from 'tss-react/mui';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { currentUserRoleSelector } from '../../state/members/selectors';
import { isAdmin } from '../../utils/user';
import ViewContainer from '../../components/ViewContainer';

const useStyles = makeStyles()((theme) => ({
  accordion: {
    boxShadow: 'none',
  },
  accordionDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2, 1),
  },
  header: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const HomeView = () => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const organizationKey = useOrganizationKey();
  const workspaces = useSelector(activeWorkspacesSelector(organizationKey));
  const currentUserRole = useSelector(currentUserRoleSelector(organizationKey));

  return (
    <Page sx={{ flex: '1 1 auto', overflow: 'auto' }}>
      <ViewContainer>
        <Accordion className={classes.accordion} defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.header} variant="subtitle1">
              {t('workspaces')}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.accordionDetails}>
            {workspaces.map((workspace) => (
              <WorkspaceTile key={workspace.pk} workspace={workspace} />
            ))}
            {isAdmin(currentUserRole) && <AddWorkspaceButton />}
          </AccordionDetails>
        </Accordion>
      </ViewContainer>
    </Page>
  );
};

export default HomeView;
