import React from 'react';
import { Container, Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import FormPublishingInfo from './FormPublishingInfo';
import VoicemailPublishingInfo from './VoicemailPublishingInfo';
import EmailPublishingInfo from './EmailPublishingInfo';
import Page from '../../components/Page';
import ViewContainer from '../../components/ViewContainer';
import { useSelector } from 'react-redux';
import { workspaceVoicemailSelector } from '../../state/voicemails/selectors';
import { workspaceEmailSelector } from '../../state/emails/selectors';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
const PublishingOptionsView = () => {
  const { t } = useTranslation();
  const workspaceKey = useWorkspaceKey();
  const voicemail = useSelector(workspaceVoicemailSelector(workspaceKey));
  const emailInbox = useSelector(workspaceEmailSelector(workspaceKey));

  return (
    <Page sx={{ flex: '1 1 auto', overflow: 'auto' }}>
      <ViewContainer>
        <Stack
          direciton="column"
          spacing={6}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          <FormPublishingInfo />
          {voicemail && <VoicemailPublishingInfo voicemail={voicemail} />}
          {emailInbox && <EmailPublishingInfo emailInbox={emailInbox} />}
        </Stack>
      </ViewContainer>
    </Page>
  );
};

export default PublishingOptionsView;
