import React, { useContext, useState } from "react";
import { alpha, Box, Container, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import AvatarPicker from "../../components/AvatarPicker";
import { useDispatch, useSelector } from "react-redux";
import { addOrUpdateWorkspace } from "../../state/workspaces/actions";
import { workspaceSelector } from "../../state/workspaces/selectors";
import { ApiContext } from "../../contexts/ApiContext";
import { CardShadow } from "../../constants";
import { toId, toWorkspaceKey } from "../../utils/apiUtils";
import { useTranslation } from "react-i18next";
import { useAvatarPicture } from "../../hooks/useAvatarPicture";
import { useParams } from "react-router-dom";
import { LargePictureSuffix, SmallPictureSuffix } from "../../constants/storage";
import { styled } from "@mui/material/styles";
import FixedLabelTextField from "../../components/FixedLabelTextField";
import LoadingButton from '@mui/lab/LoadingButton';
import { getLargePictureVariant } from '../../utils/avatarUtils';

const StyledContainer = styled(Container, { name: "GeneralSettings" })(({ theme, color }) => ({}));

const GeneralWorkspaceSettingsView = ({}) => {
    const { mutationApi, storageApi } = useContext(ApiContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { workspaceId } = useParams();
    const workspaceKey = toWorkspaceKey(workspaceId);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    const [selectedPictureFile, setSelectedPictureFile] = useState();
    const [pictureUrl, setPictureUrl] = useAvatarPicture(getLargePictureVariant(workspace?.picture));
    const [uploading, setUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [changed, setChanged] = React.useState(false);
    const [name, setName] = React.useState(workspace?.name);
    const [nameError, setNameError] = useState();
    const [saving, setSaving] = useState(false);
    const handleOnAvatarSelected = (file) => {
        // Release previously created object URL if any
        if (selectedPictureFile) {
            URL.revokeObjectURL(pictureUrl);
        }
        setPictureUrl(URL.createObjectURL(file));
        setSelectedPictureFile(file);
        setChanged(true);
    };

    const handleAvatarCleared = () => {
        // Release previously created object URL if any
        if (selectedPictureFile) {
            URL.revokeObjectURL(pictureUrl);
        }
        setPictureUrl(null);
        setSelectedPictureFile(null);
        setChanged(true);
    };

    const handleNameChange = (event) => {
        const value = event.target.value;
        setNameError(value.length === 0);
        setName(value);
        setChanged(true);
    };

    const handleSaveButtonClick = async () => {
        const data = {};
        if (name !== workspace.name) {
            data.name = name;
        }

        // Remove team's original avatar if user clicked on Remove button
        if (!pictureUrl && workspace.picture) {
            await storageApi.deleteAvatarPictures(workspace.picture);
            data.picture = null;
        }

        if (selectedPictureFile) {
            // Remove user's original avatar if another image was selected
            if (workspace.picture) {
                await storageApi.deleteAvatarPictures(workspace.picture);
            }

            setUploading(true);
            const key = await storageApi.uploadWorkspacePicture(
                selectedPictureFile,
                toId(workspaceKey),
                setUploadProgress
            );
            data.picture = key;
            setUploading(false);
        }

        // Data can have zero keys, when for example name is changed and then changed
        // back to the original value. The value won't be added to the data object but
        // changed state will be true, which caused the save button to be enabled
        if (Object.keys(data) === 0) {
            return;
        }

        setSaving(true);
        dispatch(addOrUpdateWorkspace(workspaceKey, data));
        await mutationApi.updateWorkspace(workspaceKey, data);
        setChanged(false);
        setSaving(false);
    };

    return (
        <StyledContainer maxWidth="md" sx={{ px: 6, py: 8 }}>
            <Stack spacing={4}>
                <Typography variant="h3">
                    {t("generalSettings")}
                </Typography>
                <Stack direction="row" spacing={4} alignItems="center">
                    <AvatarPicker
                        name={name}
                        imageUrl={pictureUrl}
                        variant="rounded"
                        isUploading={uploading}
                        uploadProgress={uploadProgress}
                        onFileSelected={handleOnAvatarSelected}
                        onClear={handleAvatarCleared}
                    />
                    <Box flex="1 1 auto">
                        <FixedLabelTextField
                            required
                            fullWidth
                            label={t("workspaceName")}
                            variant="outlined"
                            value={name || ""}
                            error={nameError}
                            helperText={nameError ? t("nameIsRequired") : null}
                            onChange={handleNameChange}
                        />
                    </Box>
                </Stack>
                <Box sx={{'&&': {ml: 'auto'}}}>
                    <LoadingButton
                        autoFocus
                        color="primary"
                        variant="contained"
                        size="large"
                        loading={saving}
                        disabled={!changed || nameError || uploading}
                        onClick={handleSaveButtonClick}
                    >
                        {t("Save changes")}
                    </LoadingButton>
                </Box>
            </Stack>
        </StyledContainer>
    );
};

export default GeneralWorkspaceSettingsView;
