import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { s8 } from "../../utils/idGenerator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { removeItem, reorder } from "../../utils/arrayUtils";
import { NestedDragDropContext } from "../../contexts/NestedDragDropContext";
import { styled } from "@mui/material/styles";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import IconButton from "@mui/material/IconButton";
import { Controller, useFormContext } from "react-hook-form";
import {
    blockFieldRegistrationName,
    blockTemplateRegistrationName,
    getBlockTemplate,
    getDictionary, dictionaryRegistrationName, translationRegistrationName,
    translationsRegistrationName, getTranslations
} from './utils';
import { removeObjectFields } from '../../utils/objectUtils';

const RootBox = styled(Box, { name: "FormDropdownBlock" })(({ theme }) => ({
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(0.5),
}));

export const setBlockTemplateOptions = (setValue, blockIndex, options) => {
    setValue(blockFieldRegistrationName(blockIndex, 'options'), options, { shouldDirty: true });
};

const DropdownOptionsEditor = ({ blockIndex, language, ItemIcon, ...rest }) => {
    const { t } = useTranslation();
    const { register, unregister, getValues, setValue } = useFormContext();
    const [autoFocusLast, setAutoFocusLast] = useState(false);

    useEffect(() => {
        setAutoFocusLast(false);
    }, [autoFocusLast]);

    const handleDragEnd = ({ source, destination }) => {
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
            return;
        }

        const blockTemplate = getBlockTemplate(getValues, blockIndex);
        const newOptions = reorder(blockTemplate.options, source.index, destination.index);
        setBlockTemplateOptions(setValue, blockIndex, newOptions);
    };

    const handleAddOptionClick = () => {
        const optionKey = s8();

        // Update block template
        const blockTemplate = getBlockTemplate(getValues, blockIndex);
        const newOptions = [...blockTemplate.options, optionKey];
        setBlockTemplateOptions(setValue, blockIndex, newOptions);

        // Add key to translations
        const updatedActiveTranslations = {...getTranslations(getValues), [optionKey]: ""};
        setValue(translationsRegistrationName(), updatedActiveTranslations, { shouldDirty: true });

        // Add key to other language translations
        const dictionary = getDictionary(getValues);
        const newDictionary = {};
        for (const lang in dictionary) {
            newDictionary[lang] = {...dictionary[lang]};
            newDictionary[lang][optionKey] = "";
        }
        setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });

        setAutoFocusLast(true);
    };

    const handleOptionDelete = (keyToDelete) => {
        // Remove key from active translations
        unregister(translationRegistrationName(keyToDelete));
        const activeTranslations = getTranslations(getValues);
        const updatedActiveTranslations = removeObjectFields(activeTranslations, [keyToDelete]);
        setValue(translationsRegistrationName(), updatedActiveTranslations, { shouldDirty: true });

        // Remove key from all language packs
        const dictionary = getDictionary(getValues);
        const newDictionary = {};
        for (const lang in dictionary) {
            newDictionary[lang] = removeObjectFields(dictionary[lang], [keyToDelete]);;
        }
        setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });

        // Remove option from template
        const blockTemplate = getBlockTemplate(getValues, blockIndex);
        const newOptions = removeItem(blockTemplate.options, keyToDelete);
        setBlockTemplateOptions(setValue, blockIndex, newOptions);
    };

    return (
        <RootBox {...rest}>
            <Typography variant="caption" color="textSecondary" gutterBottom sx={{ p: 0.5 }}>
                {t("options")}
            </Typography>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={`block#${blockIndex}-options`} type={`options`}>
                    {(provided) => (
                        <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ ml: "-24px" }}>
                            <Controller
                                name={blockFieldRegistrationName(blockIndex, 'options')}
                                render={({ field: { onChange, value } }) =>
                                    value.map((key, index) => (
                                        <Draggable key={key} draggableId={`option-${key}`} index={index}>
                                            {(provided, snapshot) => (
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                        zIndex: 10000,
                                                        py: 1,
                                                        // pt: 1,
                                                        position: "relative",
                                                        // borderRadius: 1,
                                                        "&:hover": {
                                                            backgroundColor: "grey.200",
                                                            "& .DropdownFieldEditor-icon": {
                                                                visibility: "visible",
                                                            },
                                                        },
                                                        ...(snapshot.isDragging && {
                                                            backgroundColor: "grey.200",
                                                            "& .DropdownFieldEditor-icon": {
                                                                visibility: "visible",
                                                            },
                                                        }),
                                                    }}
                                                >
                                                    <DragIndicatorOutlinedIcon
                                                        fontSize="small"
                                                        className="DropdownFieldEditor-icon"
                                                        sx={{
                                                            visibility: "hidden",
                                                            color: "text.disabled",
                                                            mx: 0.5,
                                                        }}
                                                    />
                                                    {Boolean(ItemIcon) && <ItemIcon color="disabled" sx={{ mr: 1 }} />}
                                                    <TextField
                                                        {...register(translationRegistrationName(key))}
                                                        autoFocus={index === value.length - 1 && autoFocusLast}
                                                        variant="standard"
                                                        fullWidth
                                                        hiddenLabel
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip title={t("delete")}>
                                                                        <IconButton
                                                                            onClick={() => handleOptionDelete(key)}
                                                                            size="small"
                                                                        >
                                                                            <ClearOutlinedIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        sx={{
                                                            flex: "1 1 auto",
                                                        }}
                                                    />
                                                </Stack>
                                            )}
                                        </Draggable>
                                    ))
                                }
                            />
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
            <Button
                variant="text"
                size="small"
                color="primary"
                startIcon={<AddOutlinedIcon />}
                onClick={handleAddOptionClick}
                sx={{
                    ml: 0,
                }}
            >
                {t("addNewOption")}
            </Button>
        </RootBox>
    );
};

DropdownOptionsEditor.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    ItemIcon: PropTypes.object,
};

DropdownOptionsEditor.defaultProps = {};

export default DropdownOptionsEditor;
