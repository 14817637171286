import { s8 } from './idGenerator';
import React from 'react';
import { emptyEditorState, serializeEditorState } from './richTextUtils';

export function newFormBlock(type) {
    const commonFields = {
        key: s8(),
        type: type === "multilineTextField" ? "textField" : type,
        required: false
    }

    switch (type) {
        case "text": {
            return {
                ...commonFields,
                content: s8(),
            };
        }
        case "textField": {
            return {
                ...commonFields,
                label: s8(),
            };
        }
        case "multilineTextField": {
            return {
                ...commonFields,
                label: s8(),
                multiline: true
            };
        }
        case "dateField": {
            return {
                ...commonFields,
                label: s8(),
            };
        }
        case "dropdown": {
            return {
                ...commonFields,
                label: s8(),
                options: [],
            };
        }
        case "checkboxList": {
            return {
                ...commonFields,
                label: s8(),
                options: [],
            };
        }
        case "attachments": {
            return {
                ...commonFields,
                label: s8(),
                buttonText: s8(),
                hintText: s8(),
            };
        }
        default:
            throw new Error(`Cannot create block, an unknown form block type '${type}' was specified.`);
    }
}

export const newPrivacyPolicy = () => {
    return {
        label: s8(),
        content: s8()
    }
}

export const getDefaultPrivacyPolicyTranslations = (privacyPolicyTemplate) => {
    return {
        [privacyPolicyTemplate.label]: "",
        [privacyPolicyTemplate.content]: serializeEditorState(emptyEditorState())
    }
}

export const getDefaultTranslations = (blockTemplate, language, t) => {
    if (blockTemplate.type === "text") {
        return {
            [blockTemplate.content]: serializeEditorState(emptyEditorState())
        }
    }

    const translations = {
        [blockTemplate.label]: ""
    }
    if (blockTemplate.type === 'attachments') {
        translations[blockTemplate.buttonText] = t("selectFiles", { lng: language })
        translations[blockTemplate.hintText] = t("dragDropHint", { lng: language })
    }
    return translations;
}

export const isDateBlock = (block) => block.type === "dateField";

// TODO: Delete
export const defaultTitle = (blockType, t, language) => {
    switch (blockType) {
        case "attachments": {
            return t("attachmentsTitle", { lng: language });
        }
        case "dropdown": {
            return t("dropdownTitle", { lng: language });
        }
        case "checkboxList": {
            return t("checkboxListTitle", { lng: language });
        }
        default: {
            return "";
        }
    }
};

// TODO: Change textField to text, Merge all text section types
export const newTextSection = (value, label = undefined) => ({
    key: s8(),
    type: "textField",
    label: label,
    value: value,
});

export const newRichTextSection = (value, label = undefined) => ({
    key: s8(),
    type: "richTextField",
    label: label,
    value: value,
});

export const newAttachmentsSection = (value, label = undefined) => ({
    key: s8(),
    type: "attachments",
    label: label,
    value: value,
});

// TODO: Move to separate file
export const isAttachmentBlock = (block) => block.type === "attachments";

export const isDateSection = (section) => section.type === "dateField";

export const isRichTextFieldSection = (section) => section.type === "richTextField";


export const isTextFieldBlock = (block) => block.type === "textField" || block.type === "multilineTextField";

export const isCheckboxListBlock = (block) => block.type === "checkboxList";

export const isTextBlock = (block) => block.type === "text";

export const isDropdownBlock = (block) => block.type === "dropdown";

export const isReportPasswordBlock = (block) => block.type === "reportPassword" || block.type === "reportIdField";  // reportIdField is legacy type

export const isUserEmailBlock = (block) => block.type === "notificationEmailField" || block.type === "reportEmailField";  // reportEmailField is legacy type

// TODO: Merge richTextField and multilineTextField
export const isMultilineFieldSection = (section) => section.type === 'multilineTextField';
