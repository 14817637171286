import { capitalizeFirstLetter } from '../utils/textUtils';

export const DEFAULT_LANGUAGE = 'en';
export const LANGUAGES = [
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'hr',
  'hu',
  'it',
  'lv',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'uk',
  'vi',
  'zh',
] as const;
export type Language = (typeof LANGUAGES)[number];
export const UI_LANGUAGES: Language[] = [
  'cs',
  'en',
  'sk',
  'pl',
  'de',
  'hu',
  'et',
] as const;
export const LANGUAGE_NAMES = getLocalizedLanguageNames(LANGUAGES);

function getLocalizedLanguageNames(locales: readonly Language[]) {
  const localizedLanguageNames = {} as Record<Language, string>;

  // Iterate through each language code
  locales.forEach((locale) => {
    const lang = locale.split('-')[0];
    // Create a new DisplayNames object for the language
    const languageDisplayNames = new Intl.DisplayNames([lang], {
      type: 'language',
    });
    // Get the localized language name
    const languageName = languageDisplayNames.of(lang);
    // Add the language name to the localizedLanguageNames object
    localizedLanguageNames[locale] = capitalizeFirstLetter(languageName!);
  });

  return localizedLanguageNames;
}
