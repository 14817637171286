import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import EmptyLayout from '../layouts/EmptyLayout';
import InvitationView from '../views/InvitationView/InvitationView';
import FollowupView from '../views/FollowupView';
import NotFoundView from '../views/errors/NotFoundView';
import OrganizationLayout from '../layouts/OrganizationLayout';
import PrivateRouter from './PrivateRouter';
import WorkspaceRouter from './WorkspaceRouter';
import HomeView from '../views/HomeView/HomeView';
import SignupView from '../views/SignupView';
import AuthenticatedRoute from '../contexts/AuthenticatedRoute';
import ReportsLayout from '../layouts/ReportsLayout/ReportsLayout';
import ReportView from '../views/ReportView/ReportView';
import MembersSettingsView from '../views/MembersSettingsView';
import LandingPageView from '../views/LandingPageView/LandingPageView';
import GeneralWorkspaceSettingsView from '../views/GeneralWorkspaceSettingsView/GeneralWorkspaceSettingsView';
import AccountSettingsView from '../views/AccountSettingsView';
import GeneralOrganizationSettingsView from '../views/GeneralOrganizationSettingsView';
import OrganizationSettingsLayout from '../layouts/OrganizationSettingsLayout/OrganizationSettingsLayout';
import FormView from '../views/FormView';
import FormSettingsView from '../views/FormSettingsView';
import ActivityLogView from '../views/ActivityLogView/ActivityLogView';
import PublicRouter from './PublicRouter';
import PublishingOptionsView from '../views/PublishingOptionsView';
import OverviewView from '../views/OverviewView';
import AuthLayout from '../layouts/AuthLayout';
import LoginView from '../views/LoginView';
import ForgottenPasswordView from '../views/ForgottenPasswordView';
import OnboardingView from '../views/OnboardingView';

// TODO: Remove API context providers
// TODO: Fix warning
// TODO: Rename
// TODO: Paths to constants
// TODO: Use lazy loading for components
// TODO: Use Suspense for data fetching
// TODO: Move portal redirection to first team to routes
export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthenticatedRoute />}>
          <Route path="" element={<AuthLayout />}>
            <Route path="signup" element={<SignupView />} />
            <Route path="login" element={<LoginView />} />
            <Route
              path="forgotten-password"
              element={<ForgottenPasswordView />}
            />
            <Route path="" element={<Navigate replace to="/app" />} />
          </Route>
          <Route path="app" element={<PrivateRouter />}>
            <Route path="" element={<EmptyLayout />}>
              <Route path="onboarding" element={<OnboardingView />} />
              <Route
                path="invitation/:organizationId/:invitationId"
                element={<InvitationView />}
              />
            </Route>
            <Route path=":organizationId" element={<OrganizationLayout />}>
              <Route path="" element={<Navigate replace to="./home" />} />
              <Route path="account" element={<AccountSettingsView />} />
              <Route path="home" element={<HomeView />} />
              <Route path="activity" element={<ActivityLogView />} />
              <Route path="reports" element={<ReportsLayout />}>
                <Route path=":reportId" element={<ReportView />} />
              </Route>
              <Route path="settings" element={<OrganizationSettingsLayout />}>
                <Route path="" element={<Navigate replace to="./general" />} />
                <Route
                  path="general"
                  element={<GeneralOrganizationSettingsView />}
                />
                <Route path="members" element={<MembersSettingsView />} />
              </Route>
              <Route path=":workspaceId" element={<WorkspaceRouter />}>
                <Route path="" element={<Navigate replace to="./overview" />} />
                <Route path="overview" element={<OverviewView />} />
                <Route path="reports" element={<ReportsLayout />}>
                  <Route path=":reportId" element={<ReportView />} />
                </Route>
                <Route path="form" element={<FormSettingsView />} />
                <Route path="publishing" element={<PublishingOptionsView />} />
                <Route path="settings">
                  <Route
                    path=""
                    element={<Navigate replace to="./general" />}
                  />
                  <Route
                    path="general"
                    element={<GeneralWorkspaceSettingsView />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="404" element={<NotFoundView />} />
        <Route path="/" element={<PublicRouter />}>
          <Route path="" element={<EmptyLayout />}>
            <Route path="f/:formId" element={<FormView />} />
            <Route path="followup" element={<FollowupView />} />
            <Route path="f_internal/:formId" element={<FormView />} />
            <Route path="followup_internal" element={<FollowupView />} />
            <Route path="landing-page/:domain" element={<LandingPageView />} />
            {/*<Route path="rich" element={<RteContainer />} />*/}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
