import * as types from "./actionTypes";
import {toId} from "../../utils/apiUtils";

export const loadWorkspaces = (workspaces) => async (dispatch, getState) => {
    dispatch({ type: types.WORKSPACES_LOADED, payload: {workspaces} });
}

export const addOrUpdateWorkspace = (pk, data) => async (dispatch, getState) => {
    if (!Boolean(pk)) {
        throw new Error("pk cannot be null");
    }
    dispatch({ type: types.WORKSPACE_UPDATED, payload: {pk, data } });
}

export const deleteWorkspace = (key) => async (dispatch, getState) => {
    dispatch({ type: types.WORKSPACE_DELETED, payload: {pk: key} });
}