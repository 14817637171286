import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
    alpha,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../contexts/ApiContext';
import { useDispatch, useSelector } from 'react-redux';
import { deactivateMember } from '../../state/members/actions';
import DialogHeader from '../../components/DialogHeader/DialogHeader';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { clearState } from '../../state/rootActions';
import { useNavigate } from 'react-router';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';

const RemoveMemberConfirmationDialog = ({ open, member, onClose }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { mutationApi, authApi } = useContext(ApiContext);
    const currentUser = useSelector(currentUserSelector());
    const { logUserRemoved } = useContext(ActivityLogContext);

    const handleDeleteButtonClick = async () => {
        await mutationApi.deactivatePrivateResource(member.pk, member.sk);
        logUserRemoved(member.sk);
        if (member.sk === currentUser.pk) {
            await authApi.refreshAccessToken();
            dispatch(clearState());
            navigate('/app', {replace: true});
        } else {
            //dispatch(deactivateMember(member.pk, member.sk));
            onClose();
        }
    };

    return (
        <Dialog
            id="delete-member-confirmation-dialog"
            maxWidth="sm"
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            aria-labelledby="delete-member-confirmation-dialog-title"
        >
            <DialogHeader id="invitation-dialog-title" showExitButton onExitClick={onClose}>
                {t("removeUserDialogHeader", {userName: member?.name})}
            </DialogHeader>
            <DialogContent>
                <Typography color="textSecondary" variant="body1">
                    {t("removeUserDialogText")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button size="medium" color="default" variant="outlined" onClick={onClose}>
                    {t("cancel")}
                </Button>
                <Button
                    autoFocus
                    color="primary"
                    variant="contained"
                    onClick={handleDeleteButtonClick}
                    sx={{
                        backgroundColor: alpha(theme.palette.error.main, 0.85),
                        color: theme.palette.error.contrastText,
                        "&:hover": {
                            backgroundColor: theme.palette.error.main,
                        },
                    }}
                >
                    {t("delete")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RemoveMemberConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    member: PropTypes.object,
    onClose: PropTypes.func,
};

RemoveMemberConfirmationDialog.defaultProps = {
    onClose: () => {},
};

export default RemoveMemberConfirmationDialog;
