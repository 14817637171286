import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const CheckmarkListItem = styled(({ children, ...rest }) => (
    <ListItem {...rest}>
        <ListItemIcon>
            <CheckIcon fontSize='small' sx={{color: 'primary.main'}}/>
        </ListItemIcon>
        <ListItemText disableTypography>
            <Typography>
                {children}
            </Typography>
        </ListItemText>
    </ListItem>
))(({}) => {});

CheckmarkListItem.propTypes = {
};

CheckmarkListItem.defaultProps = {};

export default CheckmarkListItem;