import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import DialogHeader from '../../components/DialogHeader/DialogHeader';
import Button from '@mui/material/Button';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  arraysEqual,
  insertItem,
  moveItem,
  removeMatchingItem,
  split,
  replaceMatchingItem,
} from '../../utils/arrayUtils';
import MenuItem from '@mui/material/MenuItem';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MenuWithArrow from '../../components/MenuWithArrow/MenuWithArrow';
import AddIcon from '@mui/icons-material/Add';
import { newAwsDateTime } from '../../utils/time';
import { isOpeningStatus, newStatusKey, toId } from '../../utils/apiUtils';
import StatusTableRow from './StatusTableRow';
import HelpIcon from '@mui/icons-material/Help';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeWorkspaceStatusesSelector,
  workspaceStatusesSelector,
} from '../../state/statuses/selectors';
import { workspaceSelector } from '../../state/workspaces/selectors';
import { ApiContext } from '../../contexts/ApiContext';
import { addOrUpdateReport } from '../../state/reports/actions';
import { addOrUpdateWorkspace } from '../../state/workspaces/actions';
import { addOrUpdateStatus, deleteStatus } from '../../state/statuses/actions';
import { activeWorkspaceReportsSelector } from '../../state/reports/selectors';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';

const StyledHeaderCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isEmpty',
})(({ theme, isEmpty }) => ({
  ...(isEmpty && {
    border: 'none',
  }),
}));

const isModified = (originalStatus, newStatus) =>
  newStatus.name !== originalStatus.name ||
  newStatus.text !== originalStatus.text ||
  newStatus.color !== originalStatus.color ||
  newStatus.type !== originalStatus.type;

const StatusCustomizationDialog = ({ open, workspaceKey, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const statuses = useSelector(activeWorkspaceStatusesSelector(workspaceKey));
  const workspace = useSelector(workspaceSelector(workspaceKey));
  const autoFocusedKeyRef = useRef();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [selectedStatusKey, setSelectedStatusKey] = useState();
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pendingStatuses, setPendingStatuses] = useState([]);
  const activeStatusesCount = pendingStatuses.filter(
    (status) => status.type === 'active',
  ).length;

  useEffect(() => {
    // Refresh status collection on each dialog opening
    if (open) {
      setPendingStatuses(
        workspace.statuses.map((key, i) =>
          statuses.find((status) => status.sk === key),
        ),
      );
    }
  }, [open]);

  useEffect(() => {
    // Reset the autofocus key on first render, otherwise the status row would be autofocused on each rerender.
    autoFocusedKeyRef.current = null;
  }, [autoFocusedKeyRef.current]);

  const handleMoreButtonClick = useCallback((event, statusKey) => {
    setSelectedStatusKey(statusKey);
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleContextMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedStatusKey(null);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // Math.max is used to make sure that no status can be dropped before the Open status.
    let normalizedDestinationIndex = Math.max(destination.index, 1);

    if (
      source.droppableId === 'active' &&
      destination.droppableId === 'closed'
    ) {
      normalizedDestinationIndex -= 1;
    }

    // If an Active status is being dropped into an empty Closed status table then the destination index will be 0,
    // since the Closed status table contains only a placeholder that is not draggable and has no draggableIndex.
    // In such scenario we want to move the status to the end of the status collection.
    const closedStatusesCount = pendingStatuses.length - activeStatusesCount;
    if (
      closedStatusesCount === 0 &&
      destination.droppableId === 'closed' &&
      destination.index === 0
    ) {
      normalizedDestinationIndex = pendingStatuses.length - 1;
    }

    const newInternalStatuses =
      normalizedDestinationIndex !== source.index
        ? moveItem(pendingStatuses, source.index, normalizedDestinationIndex)
        : [...pendingStatuses];

    if (destination.droppableId !== source.droppableId) {
      newInternalStatuses[normalizedDestinationIndex] = {
        ...newInternalStatuses[normalizedDestinationIndex],
        type: destination.droppableId,
      };
    }

    setPendingStatuses(newInternalStatuses);
    setChanged(true);
  };

  const handleStatusDeleteClick = () => {
    setPendingStatuses((prev) =>
      removeMatchingItem(prev, (status) => status.sk === selectedStatusKey),
    );
    setChanged(true);
    handleContextMenuClose();
  };

  const handleAddNewState = (type) => {
    const statusKey = newStatusKey();
    const createdAt = newAwsDateTime();
    // TODO: Move api models to a common utils class
    const data = {
      pk: workspaceKey,
      sk: statusKey,
      group: toId(organizationKey),
      organizationKey: organizationKey,
      workspaceKey: workspaceKey,
      createdAt: createdAt,
      updatedAt: createdAt,
      type: type,
      color: '#2F80ED',
    };

    // Insert new active status just behind the last active statusS
    const destinationIndex =
      type === 'active' ? activeStatusesCount : pendingStatuses.length;
    setPendingStatuses((prev) => insertItem(prev, destinationIndex, data));
    setChanged(true);
    autoFocusedKeyRef.current = statusKey;
  };

  const handleStatusChange = useCallback((updatedStatus) => {
    setPendingStatuses((prev) =>
      replaceMatchingItem(
        prev,
        (status) => status.sk === updatedStatus.sk,
        updatedStatus,
      ),
    );
    setChanged(true);
  }, []);

  const handleSaveButtonClick = async () => {
    setSaving(true);

    // Delete removed statuses
    const originalStatusKeys = workspace.statuses;
    const currentStatusKeys = pendingStatuses.map((status) => status.sk);
    const removedStatusKeys = originalStatusKeys.filter(
      (key) => !currentStatusKeys.includes(key),
    );
    for (const key of removedStatusKeys) {
      const status = statuses.find((status) => status.sk === key);
      dispatch(addOrUpdateStatus(status.pk, status.sk, { disabled: true }));
      await mutationApi.deactivatePrivateResource(status.pk, status.sk);
    }

    // Update existing or create new statuses
    for (const newStatus of pendingStatuses) {
      const originalStatus = statuses.find(
        (status) => status.sk === newStatus.sk,
      );

      if (!originalStatus) {
        // Create new status if it didn't exist
        dispatch(addOrUpdateStatus(newStatus.pk, newStatus.sk, newStatus));
        await mutationApi.createPrivateResource(
          newStatus.pk,
          newStatus.sk,
          newStatus,
        );
      } else if (isModified(originalStatus, newStatus)) {
        // Update attributes of an existing status
        const data = {
          name: newStatus.name,
          text: newStatus.text,
          color: newStatus.color,
          type: newStatus.type,
        };
        dispatch(addOrUpdateStatus(newStatus.pk, newStatus.sk, data));
        await mutationApi.updatePrivateResource(
          newStatus.pk,
          newStatus.sk,
          data,
        );
      }
    }

    if (!arraysEqual(workspace.statuses, pendingStatuses.length)) {
      // Update status order in the workspace resource
      const data = { statuses: currentStatusKeys };
      dispatch(addOrUpdateWorkspace(workspaceKey, data));
      await mutationApi.updateWorkspace(workspaceKey, data);
    }

    setSaving(false);
    onClose();
  };

  const renderStatusTable = (statusType) => {
    const filteredStatuses = pendingStatuses.filter(
      (status) => status.type === statusType,
    );
    const isEmpty = filteredStatuses.length === 0;

    return (
      <Table
        size="small"
        sx={{ tableLayout: 'fixed', mt: 0.75, overflow: 'hidden' }}
      >
        <TableHead>
          <TableRow>
            <StyledHeaderCell isEmpty={isEmpty} sx={{ width: 32 }} />
            <StyledHeaderCell isEmpty={isEmpty} sx={{ width: 32 }} />
            <StyledHeaderCell
              isEmpty={isEmpty}
              sx={{ pl: '22px', width: '30%' }}
            >
              {t('name')}
            </StyledHeaderCell>
            <StyledHeaderCell
              isEmpty={isEmpty}
              sx={{ pl: '22px', width: '70%' }}
            >
              <div>
                <span>{t('message')}</span>
                <Tooltip title={t('statusMessageHint')}>
                  <HelpIcon
                    sx={{
                      ml: 0.5,
                      mt: '-0.125em',
                      fontSize: '1.15rem',
                      color: 'grey.500',
                    }}
                  />
                </Tooltip>
              </div>
            </StyledHeaderCell>
            <StyledHeaderCell isEmpty={isEmpty} sx={{ width: 56 }} />
          </TableRow>
        </TableHead>
        <Droppable droppableId={statusType}>
          {(provided) => (
            <TableBody ref={provided.innerRef} {...provided.droppableProps}>
              {filteredStatuses.map((status, i) => (
                <StatusTableRow
                  key={status.sk}
                  showMessageField
                  status={status}
                  isDraggable={!isOpeningStatus(status.sk)}
                  draggableIndex={
                    status.type === 'active' ? i : i + activeStatusesCount
                  }
                  autoFocus={autoFocusedKeyRef.current === status.sk}
                  showMoreButton={selectedStatusKey === status.sk}
                  onChange={handleStatusChange}
                  onMoreButtonClick={handleMoreButtonClick}
                />
              ))}
              {isEmpty && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={5}
                    sx={{
                      color: 'text.secondary',
                      py: '14px',
                      border: '1px dashed',
                      borderColor: 'grey.300',
                    }}
                  >
                    {t('emptyStatusTablePlaceholder')}
                  </TableCell>
                </TableRow>
              )}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </Table>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="status-customization-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <DialogHeader
        id="status-customization-dialog-title"
        showExitButton
        showDivider
        onExitClick={onClose}
        ExitButtonProps={{ disabled: saving }}
      >
        {t('statusDialogHeader')}
      </DialogHeader>
      <DialogContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Typography variant="h6" sx={{ mb: 0.25 }}>
            {t('activeStatuses')}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {t('activeStatusesSubtitle')}
          </Typography>
          {renderStatusTable('active')}
          <Button
            size="small"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => handleAddNewState('active')}
            sx={{ ml: '4px' }}
          >
            {t('add')}
          </Button>
          <Typography variant="h6" sx={{ mb: 0.25, mt: 4 }}>
            {t('closedStatuses')}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {t('closedStatusesSubtitle')}
          </Typography>
          {renderStatusTable('closed')}
          <Button
            size="small"
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => handleAddNewState('closed')}
            sx={{ ml: '4px' }}
          >
            {t('add')}
          </Button>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          color="default"
          variant="outlined"
          disabled={saving}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          loading={saving}
          disabled={!changed}
          onClick={handleSaveButtonClick}
        >
          {t('Save changes')}
        </LoadingButton>
      </DialogActions>
      <MenuWithArrow
        keepMounted
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleContextMenuClose}
      >
        <MenuItem
          disabled={isOpeningStatus(selectedStatusKey)}
          onClick={handleStatusDeleteClick}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('delete')}</ListItemText>
        </MenuItem>
      </MenuWithArrow>
    </Dialog>
  );
};

StatusCustomizationDialog.propTypes = {
  open: PropTypes.bool,
  workspaceKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

StatusCustomizationDialog.defaultProps = {
  onClose: () => {},
};

export default StatusCustomizationDialog;
