import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, TableCell, TableFooter, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AVATAR_SIZES } from '../../utils/avatarUtils';

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    height: 32
}));

const LogTableFooter = ({ loading }) => {

    return (
        <TableFooter>
            {loading && (
                <>
                    <TableRow>
                        <TableCell colSpan={4}>
                            <StyledSkeleton variant='rectangular'/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}>
                            <StyledSkeleton variant='rectangular'/>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={4}>
                            <StyledSkeleton variant='rectangular'/>
                        </TableCell>
                    </TableRow>
                </>
            )}
        </TableFooter>
    );
};

LogTableFooter.propTypes = {
    loading: PropTypes.bool
};

LogTableFooter.defaultProps = {};

export default LogTableFooter;
