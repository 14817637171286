import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isComment, isReport } from '../utils/apiUtils';
import { addComments } from '../state/comments/actions';
import { addOrUpdateReport } from '../state/reports/actions';
import { reportCommentsSelector } from '../state/comments/selectors';
import { reportSelector } from '../state/reports/selectors';

export const usePrivateReportResources = (reportKey) => {
    const { queryApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const comments = useSelector(reportCommentsSelector(reportKey));
    const report = useSelector(reportSelector(reportKey));
    const notFoundRef = useRef(false);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const isLoaded = Boolean(report?.loaded);

    const fetch = async () => {
        const resources = await queryApi.listPrivateResources(reportKey);
        const report = resources.find((r) => isReport(r));
        const comments = resources.filter((r) => isComment(r));

        if (!report) {
            notFoundRef.current = true;
            forceUpdate();
            return;
        }

        notFoundRef.current = false;
        batch(() => {
            dispatch(addOrUpdateReport(report.pk, { ...report, loaded: true }));
            dispatch(addComments(comments));
        });
    };

    useEffect(() => {
        if (isLoaded) {
            return;
        }

        fetch();
    }, [reportKey]);

    return { loaded: isLoaded, notFound: notFoundRef.current, comments: comments };
};
