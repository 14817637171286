import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { copyTextToClipboard } from "../../utils/clipboardUtils";
import { Controller, useFormContext } from "react-hook-form";
import { reportDataRegistrationName } from "./utils";
import { styled } from "@mui/material/styles";
import ClipboardNotification from "../ClipboardNotification";

const RootBox = styled(Box, { name: "FormReportPasswordBlock" })(({ theme }) => ({}));

const FormReportPasswordBlock = ({ disabled, language }) => {
    const { t } = useTranslation();
    const [showCopiedNotification, setShowCopiedNotification] = useState(false);

    const handleCopyButtonOnClick = async (password) => {
        await copyTextToClipboard(password);
        setShowCopiedNotification(true);
    };

    const copyButton = (value) => (
        <IconButton onClick={() => handleCopyButtonOnClick(value)} disabled={disabled} size="large">
            <FileCopyOutlinedIcon />
        </IconButton>
    );

    return (
        <RootBox>
            <Controller
                name={reportDataRegistrationName("password")}
                render={({ field: { value } }) => (
                    <TextField
                        fullWidth
                        hiddenLabel
                        variant="filled"
                        disabled={disabled}
                        defaultValue={value}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {disabled ? (
                                        copyButton(value)
                                    ) : (
                                        <Tooltip title={t("copy", { lng: language })}>{copyButton(value)}</Tooltip>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: false }}
                    />
                )}
            />
            <ClipboardNotification
                open={showCopiedNotification}
                language={language}
                onClose={() => setShowCopiedNotification(false)}
            />
        </RootBox>
    );
};

FormReportPasswordBlock.propTypes = {
    disabled: PropTypes.bool,
    language: PropTypes.string.isRequired,
};

FormReportPasswordBlock.defaultProps = {};

export default FormReportPasswordBlock;
