import React, { useState } from 'react';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { Outlet, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { useUnsupportedScreenSizeOverlay } from '../../hooks/useUnsupportedScreenSizeOverlay';
import { useOrganization } from '../../hooks/useOrganization';
import { toId, toOrganizationKey } from '../../utils/apiUtils';
import { Loader } from '../../components/Loader/Loader';
import { OrganizationKeyContext } from '../../contexts/OrganizationKeyContext';
import { useStoredOrganizationId } from '../../hooks/useStoredOrganizationId';
import TopBar from './TopBar';
import { styled } from '@mui/material/styles';
import Sidebar from './Sidebar';
import { NAVBAR_WIDTH } from '../../constants/ui';
import BarsBottomLeftIcon from '../../icons/BarsBottomLeftIcon';

const RootStack = styled(Stack, { name: 'OrganizationLayout' })(
  ({ theme }) => ({
    overflow: 'hidden',
    height: '100%',
    position: 'relative',
  }),
);

const OutletStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'sidebarOpen',
})(({ theme, sidebarOpen }) => ({
  overflow: 'hidden',
  minWidth: 0,
  height: '100%',
  flex: '1 1 auto',
  transition: theme.transitions.create('marginLeft', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '& .Topbar .MuiToolbar-root': {
    transition: theme.transitions.create('marginLeft', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  ...(!sidebarOpen && {
    transition: theme.transitions.create('marginLeft', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${NAVBAR_WIDTH}px`,
    '& .Topbar .MuiToolbar-root': {
      transition: theme.transitions.create('marginLeft', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: '48px',
    },
  }),
}));

const OrganizationLayout = () => {
  const { t } = useTranslation();
  const overlay = useUnsupportedScreenSizeOverlay();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { organizationId } = useParams();
  const organizationKey = toOrganizationKey(organizationId);
  const { loaded, organization } = useOrganization(organizationKey);
  const [storedOrganizationId, setStoredOrganizationId] =
    useStoredOrganizationId();

  if (organizationKey && toId(organizationKey) !== storedOrganizationId) {
    setStoredOrganizationId(toId(organizationKey));
  }

  if (overlay) {
    return overlay;
  }

  if (!loaded) {
    return <Loader />;
  }

  return (
    <RootStack direction="row">
      <OrganizationKeyContext.Provider value={organizationKey}>
        <Sidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
        <Tooltip title={t('expandSidebar')}>
          <IconButton
            edge="start"
            onClick={() => setSidebarOpen(true)}
            sx={[
              { position: 'absolute', ml: 2, mt: 1.5, zIndex: 'drawer' },
              sidebarOpen && { display: 'none' },
            ]}
          >
            <BarsBottomLeftIcon />
          </IconButton>
        </Tooltip>
        <OutletStack
          component="main"
          direction="column"
          sidebarOpen={sidebarOpen}
        >
          <TopBar className="Topbar" />
          <Outlet />
        </OutletStack>
      </OrganizationKeyContext.Provider>
    </RootStack>
  );
};

export default OrganizationLayout;
