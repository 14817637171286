import { flatttenGroups } from '../../utils/objectUtils';
import { toObject } from '../../utils/arrayUtils';

export const allTagsSelector = () => state => flatttenGroups(state.tags);

export const workspaceTagsSelector = (workspaceKey) => state => workspaceKey ? state.tags[workspaceKey] : null;

export const activeWorkspaceTagsSelector = (workspaceKey) => state => workspaceKey ? state.tags[workspaceKey]?.filter(tag => !tag.disabled) : null;

export const tagsSelector = (workspaceKey, tagKey) => state => {
    if (!Boolean(workspaceKey) || !Boolean(tagKey)) {
        return null;
    }

    return state.tags[workspaceKey]?.find(t => t.sk === tagKey);
};