import React from 'react';
import AuthApi from '../api/AuthApi';
import QueryApi from '../api/QueryApi';
import MutationApi from '../api/MutationApi';
import SubscriptionApi from '../api/SubscriptionApi';
import StorageApi from '../api/StorageApi';

export interface Api {
  queryApi: typeof QueryApi;
  mutationApi: typeof MutationApi;
  subscriptionApi: typeof SubscriptionApi;
  storageApi: typeof StorageApi;
  authApi: typeof AuthApi;
}

export const ApiContext = React.createContext<Api>({
  queryApi: QueryApi,
  mutationApi: MutationApi,
  subscriptionApi: SubscriptionApi,
  storageApi: StorageApi,
  authApi: AuthApi,
});
