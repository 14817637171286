import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { workspaceSelector } from '../state/workspaces/selectors';
import { addOrUpdateOrganization } from '../state/organizations/actions';
import { organizationSelector } from '../state/organizations/selectors';

export const useFetchPublicOrganizationResource = (organizationKey, forceFetch = false) => {
    const {queryApi} = useContext(ApiContext);
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();
    const [notFound, setNotFound] = useState(false);
    const organization = useSelector(organizationSelector(organizationKey));
    //const isLoaded = workspaceKey ? Boolean(store.getState().workspaces[workspaceKey]) : false;

    const fetchResources = async () => {
        const resource = await queryApi.getPublicResource(organizationKey, organizationKey);
        if (!resource) {
            setNotFound(true);
            return;
        }

        dispatch(addOrUpdateOrganization(resource.pk, resource));
        setNotFound(false);
    }

    useEffect(() => {
        if (!organizationKey) {
            return;
        }

        if (forceFetch || !Boolean(organization)) {
            fetchResources();
        }
    }, [organizationKey]);

    return {organization, notFound, loaded: Boolean(organization)};
}

