import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogHeader from '../../components/DialogHeader/DialogHeader';
import { newInvitationKey, toWorkspaceKey } from '../../utils/apiUtils';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import UserRoleSelect from '../../components/UserRoleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { useParams } from 'react-router-dom';
import { workspaceSelector } from '../../state/workspaces/selectors';
import HoverTableRow from '../../components/HoverTableRow';
import {
  removeItemAtIndex,
  updateItemAtIndex,
  updateMatchingItem,
} from '../../utils/arrayUtils';
import { validateEmail_old } from '../../utils/validationUtils';
import { activeMembersSelector } from '../../state/members/selectors';
import { addOrUpdateInvitation } from '../../state/invitations/actions';
import { ApiContext } from '../../contexts/ApiContext';
import LoadingButton from '@mui/lab/LoadingButton';
import UserRolePicker from './UserRolePicker';
import { invitationsSelector } from '../../state/invitations/selectors';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { emptyInvitationRequest } from '../../utils/modelUtils';
import { isAdmin } from '../../utils/user';
import MemberAccessSettingButton from './MemberAccessSettingButton';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';

const validateInvitationRequest = (
  invitationRequest,
  members,
  invitations,
  t,
) => {
  const emailError = validateEmail_old(invitationRequest.email, t);
  if (emailError) {
    return emailError;
  }
  if (members.some((member) => member.email === invitationRequest.email)) {
    return t('memberAlreadyExistsError');
  }
  if (
    invitations.some(
      (invitation) => invitation.email === invitationRequest.email,
    )
  ) {
    return t('invitationAlreadyExistsError');
  }
  return null;
};

// TODO: Rename
// TODO: Add Exit icon to the top of the dialog
const InvitationDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const organizationKey = useOrganizationKey();
  const currentUser = useSelector(currentUserSelector());
  const members = useSelector(activeMembersSelector(organizationKey));
  const invitations = useSelector(invitationsSelector(organizationKey)) ?? [];
  const [invitationRequests, setInvitationRequests] = useState([
    emptyInvitationRequest(currentUser),
  ]);
  const [validationErrors, setValidationErrors] = useState([false]);
  const [sending, setSending] = useState(false);
  const [selectedInvitationKey, setSelectedInvitationKey] = useState();
  const [autoFocusLastItem, setAutoFocusLastItem] = useState(true);
  const { logInvitationCreated } = useContext(ActivityLogContext);

  useEffect(() => {
    // Reset the autofocus key on first render, otherwise the status row would be autofocused on each rerender.
    if (open) {
      setAutoFocusLastItem(true);
    }
  }, [open]);

  const handleSendButtonClick = async () => {
    const errors = invitationRequests.map((invitation) =>
      validateInvitationRequest(invitation, members, invitations, t),
    );
    setValidationErrors(errors);
    // Early exit if some of the invitations are invalid
    if (errors.some((error) => Boolean(error))) {
      return;
    }

    setSending(true);
    for (const invitation of invitationRequests) {
      invitation.email = invitation.email.toLowerCase();
      dispatch(
        addOrUpdateInvitation(
          organizationKey,
          invitation.invitationKey,
          invitation,
        ),
      );
      mutationApi.createInvitation(
        organizationKey,
        invitation.email,
        invitation.role,
        invitation.access,
        invitation.language,
        invitation.invitationKey,
      );
      await logInvitationCreated(invitation.email, invitation.role);
    }

    setSending(false);
    handleOnClose();
  };

  const handleAddButtonClick = () => {
    setAutoFocusLastItem(true);
    setValidationErrors((prev) => [...prev, false]);
    setInvitationRequests((prev) => [
      ...prev,
      emptyInvitationRequest(currentUser),
    ]);
  };

  const handleInvitationChange = (key, data) => {
    setAutoFocusLastItem(false);
    const index = invitationRequests.findIndex(
      (invitation) => invitation.invitationKey === key,
    );
    // Reset validation errors of the update invitation
    if (Boolean(validationErrors[index])) {
      setValidationErrors((prev) => updateItemAtIndex(prev, index, null));
    }
    setInvitationRequests((prev) =>
      updateMatchingItem(
        prev,
        (invitation) => invitation.invitationKey === key,
        data,
      ),
    );
  };

  const handleDeleteInvitation = (key) => {
    setAutoFocusLastItem(false);
    const index = invitationRequests.findIndex(
      (invitation) => invitation.invitationKey === key,
    );
    setValidationErrors((prev) => removeItemAtIndex(prev, index));
    setInvitationRequests((prev) => removeItemAtIndex(prev, index));
  };

  const handleOnClose = () => {
    onClose();
    setValidationErrors([false]);
    setInvitationRequests([emptyInvitationRequest(currentUser)]);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      onClose={handleOnClose}
      aria-labelledby="invitation-dialog-title"
    >
      <DialogHeader
        id="invitation-dialog-title"
        showExitButton
        onExitClick={handleOnClose}
      >
        {t('invitationDialogHeader')}
      </DialogHeader>
      <DialogContent>
        <Typography color="textSecondary" variant="body2">
          {t('invitationDialogSubheader')}
        </Typography>
        <Table size="small" sx={{ mt: 1.75, tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '40%', pl: '30px' }}>
                {t('email')}
              </TableCell>
              <TableCell sx={{ width: '30%', pl: '30px' }}>
                {t('role')}
              </TableCell>
              <TableCell sx={{ width: '30%', pl: '24px' }}>
                {t('accessSettings')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {invitationRequests.map((invitationRequest, i) => (
              <HoverTableRow
                key={invitationRequest.invitationKey}
                active={
                  invitationRequest.invitationKey === selectedInvitationKey
                }
                sx={{
                  ...(Boolean(validationErrors[i]) && {
                    '& fieldset': { borderStyle: 'solid' },
                  }),
                }}
              >
                <TableCell>
                  <TextField
                    fullWidth
                    autoComplete="email"
                    size="small"
                    defaultValue={invitationRequest.email}
                    autoFocus={
                      autoFocusLastItem && i === invitationRequests.length - 1
                    }
                    error={Boolean(validationErrors[i])}
                    helperText={validationErrors[i] ?? undefined}
                    onChange={(e) =>
                      handleInvitationChange(invitationRequest.invitationKey, {
                        email: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell
                  sx={{
                    ...(Boolean(validationErrors[i]) && {
                      verticalAlign: 'baseline',
                    }),
                  }}
                >
                  <UserRolePicker
                    value={invitationRequest.role}
                    hiddenLabel
                    fullWidth
                    size="small"
                    onOpen={() =>
                      setSelectedInvitationKey(invitationRequest.invitationKey)
                    }
                    onClose={() => setSelectedInvitationKey(null)}
                    onChange={(newRole) =>
                      handleInvitationChange(invitationRequest.invitationKey, {
                        role: newRole,
                      })
                    }
                  />
                </TableCell>
                <TableCell
                  sx={{
                    ...(Boolean(validationErrors[i]) && {
                      verticalAlign: 'baseline',
                    }),
                  }}
                >
                  <Tooltip
                    title={
                      isAdmin(invitationRequest.role)
                        ? t('adminsHaveAccessToAllWorkspaces')
                        : ''
                    }
                  >
                    <MemberAccessSettingButton
                      disabled={isAdmin(invitationRequest.role)}
                      value={invitationRequest.access}
                      onChange={(newAccess) =>
                        handleInvitationChange(
                          invitationRequest.invitationKey,
                          {
                            access: newAccess,
                          },
                        )
                      }
                    />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Tooltip title={t('remove')}>
                    <IconButton
                      edge="end"
                      size="small"
                      onClick={() =>
                        handleDeleteInvitation(invitationRequest.key)
                      }
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </HoverTableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          size="small"
          variant="text"
          startIcon={<AddIcon />}
          onClick={handleAddButtonClick}
          sx={{ ml: '4px' }}
        >
          {t('add')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          color="default"
          variant="outlined"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={sending}
          disabled={invitationRequests.length === 0}
          onClick={handleSendButtonClick}
        >
          {t('send')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

InvitationDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onSendClick: PropTypes.func,
  onClose: PropTypes.func,
};

InvitationDialog.defaultProps = {
  onSendClick: () => {},
  onClose: () => {},
};

export default InvitationDialog;
