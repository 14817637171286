import {
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import { activeWorkspacesSelector } from '../../state/workspaces/selectors';
import { useDispatch, useSelector } from 'react-redux';
import ResourceAvatar from '../../components/ResourceAvatar';
import {
  LargePictureSuffix,
  SmallPictureSuffix,
} from '../../constants/storage';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { ApiContext } from '../../contexts/ApiContext';
import { arraysEqual, removeItem } from '../../utils/arrayUtils';

const RootBox = styled(Box, { name: 'MemberAccessSettingButton' })(
  ({ theme }) => ({}),
);

const MemberAccessSettingButton = React.forwardRef(
  ({ value, onChange, ...rest }, ref) => {
    const { t } = useTranslation();
    const { mutationApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const organizationKey = useOrganizationKey();
    const workspaces = useSelector(activeWorkspacesSelector(organizationKey));
    const [access, setAccess] = useState();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const selectedKeys = access ?? workspaces.map((workspace) => workspace.pk);
    const selectedWorkspaces = access
      ? selectedKeys.map((key) =>
          workspaces.find((workspace) => workspace.pk === key),
        )
      : workspaces;

    useEffect(() => {
      setAccess(value);
    }, [value]);

    const handleWorkspaceItemClick = (workspaceKey) => {
      const isSelected = selectedKeys.includes(workspaceKey);
      const newAccess = isSelected
        ? removeItem(selectedKeys, workspaceKey)
        : [...selectedKeys, workspaceKey];
      setAccess(newAccess);
    };

    const handleCheckboxChange = (e, workspaceKey) => {
      const checked = e.target.checked;
      const newAccess = checked
        ? [...selectedKeys, workspaceKey]
        : removeItem(selectedKeys, workspaceKey);
      setAccess(newAccess);
    };

    const handleMenuClose = () => {
      setMenuAnchorEl(null);

      if (!arraysEqual(access, value)) {
        const normalizedAccess = workspaces.every((key) =>
          access.some((workspace) => workspace.pk === key),
        )
          ? null
          : access;
        onChange(normalizedAccess);
      }
    };

    return (
      <RootBox ref={ref} {...rest}>
        <Button
          component="div"
          fullWidth
          endIcon={<ExpandMoreIcon />}
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          sx={{
            typography: 'body1',
            color: 'text.primary',
            textTransform: 'none',
          }}
          {...rest}
        >
          <Stack direction="row" flex="1 1 auto">
            {selectedWorkspaces.length === 0 ? (
              t('noAccess')
            ) : selectedWorkspaces.length === workspaces.length ? (
              t('allWorkspaces')
            ) : (
              <AvatarGroup
                max={5}
                sx={{
                  '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 14 },
                }}
              >
                {selectedWorkspaces.map((workspace) => (
                  <Tooltip key={workspace.pk} title={workspace.name}>
                    <ResourceAvatar
                      variant="rounded"
                      name={workspace.name}
                      picture={workspace.picture}
                    />
                  </Tooltip>
                ))}
              </AvatarGroup>
            )}
          </Stack>
        </Button>
        <Menu
          id="access-settings-menu"
          open={Boolean(menuAnchorEl)}
          anchorEl={menuAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleMenuClose}
        >
          {workspaces.map((workspace) => (
            <MenuItem
              dense
              key={`menu-item-${workspace.pk}`}
              onClick={() => handleWorkspaceItemClick(workspace.pk)}
            >
              <ResourceAvatar
                variant="rounded"
                name={workspace.name}
                picture={workspace.picture}
                size={24}
              />
              <ListItemText
                id={`menu-item-${workspace.pk}-label`}
                primary={workspace.name}
                sx={{ ml: 1, mr: 3 }}
              />
              <Checkbox
                disableRipple
                size="small"
                edge="end"
                onChange={(e) => handleCheckboxChange(e, workspace.pk)}
                checked={selectedKeys.includes(workspace.pk)}
                inputProps={{
                  'aria-labelledby': `menu-item-${workspace.pk}-label`,
                }}
              />
            </MenuItem>
          ))}
        </Menu>
      </RootBox>
    );
  },
);

MemberAccessSettingButton.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
};

MemberAccessSettingButton.defaultProps = {
  onChange: () => {},
};

export default MemberAccessSettingButton;
