import React, { useState } from 'react';
import {
  Badge,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { activeWorkspaceTagsSelector } from '../../state/tags/selectors';
import { styled } from '@mui/material/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import Tag from '../../components/Tag';
import Menu from '@mui/material/Menu';
import DeleteBadgeButton from '../../components/DeleteBadgeButton';
import TagCustomizationDialog from './TagCustomizationDialog';
import EditIcon from '@mui/icons-material/Edit';

const RootStack = styled(Stack, { name: 'StatusPicker' })(({ theme }) => ({}));

const TagPicker = ({ value, workspaceKey, onChange, ...rest }) => {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [customizationDialogOpen, setCustomizationDialogOpen] = useState(false);
  const workspaceTags =
    useSelector(activeWorkspaceTagsSelector(workspaceKey)) ?? [];
  const selectedTags = value
    .map((key) => workspaceTags.find((tag) => tag.sk === key))
    .filter(Boolean);
  const remainingTags = workspaceTags.filter((tag) => !value.includes(tag.sk));

  const handleRemoveTagButtonClick = (tagKey) => {
    onChange(value.filter((key) => key !== tagKey));
  };

  const handleMenuItemClick = (tagKey) => {
    setMenuAnchorEl(null);
    onChange([...(value ?? []), tagKey]);
  };

  const handleCustomizeButtonClick = () => {
    setCustomizationDialogOpen(true);
  };

  return (
    <>
      <RootStack
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        spacing={1}
        {...rest}
      >
        {selectedTags.map((tag) => (
          <Tag
            key={tag.sk}
            value={tag}
            onDelete={() => handleRemoveTagButtonClick(tag.sk)}
          />
        ))}
        <Tooltip title={t('add')}>
          <IconButton
            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
            size="small"
            sx={{
              borderRadius: '50%',
              border: '1px dashed rgba(0, 0, 0, 0.23)',
            }}
          >
            <AddOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </RootStack>
      <Menu
        id="tag-menu"
        autoFocus={false}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {remainingTags.map((tag) => (
          <MenuItem
            key={tag.sk}
            value={tag.sk}
            onClick={() => handleMenuItemClick(tag.sk)}
          >
            <Tag value={tag} sx={{ pointerEvents: 'none' }} />
          </MenuItem>
        ))}
        {remainingTags.length > 0 && <Divider />}
        <MenuItem onClick={handleCustomizeButtonClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('customize')}</ListItemText>
        </MenuItem>
      </Menu>
      <TagCustomizationDialog
        open={customizationDialogOpen}
        workspaceKey={workspaceKey}
        onClose={() => setCustomizationDialogOpen(false)}
      />
    </>
  );
};

TagPicker.propTypes = {
  value: PropTypes.array.isRequired,
  workspaceKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

TagPicker.defaultProps = {
  value: [],
  onChange: () => {},
};

export default TagPicker;
