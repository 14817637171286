import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  ButtonGroup,
  TextField,
  Backdrop,
  Tooltip,
  LinearProgress,
  FormHelperText,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { ApiContext } from '../../contexts/ApiContext';
import { numericId, s8 } from '../../utils/idGenerator';
import { useTranslation } from 'react-i18next';
import { getAvatarColor, getInitials } from '../../utils/avatarUtils';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';

const avatarPickerClasses = {
  root: 'AvatarPicker-root',
  placeholder: 'AvatarPicker-avatar',
  backdrop: 'AvatarPicker-backdrop',
};

const RootDiv = styled('div', {
  name: 'AvatarPicker',
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => ({
  position: 'relative',
  padding: theme.spacing(0.5),
  border: `1px dashed ${theme.palette.grey[400]}`,
  borderRadius: variant === 'circular' ? '50%' : theme.shape.borderRadius * 1.5,
}));

const StyledAvatar = styled(Avatar, {
  name: 'AvatarPicker',
  slot: 'avatar',
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})(({ theme, backgroundColor, variant }) => ({
  //padding: theme.spacing(4.5),
  width: 72,
  height: 72,
  fontSize: '1.5rem',
  backgroundColor: backgroundColor,
  borderRadius: variant === 'circular' ? '50%' : theme.shape.borderRadius,
}));

const AvatarPicker = ({
  className,
  name,
  imageUrl,
  variant,
  onFileSelected,
  onClear,
  isUploading,
  uploadProgress,
  ...rest
}) => {
  const background = imageUrl ? null : getAvatarColor(name);
  const { t } = useTranslation();
  const [mouseOver, setMouseOver] = React.useState(false);
  const [avatarError, setAvatarError] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const inputId = useMemo(() => `image-input-${s8()}`, []);

  const handleUploadAvatarClick = (event) => {
    const {
      target: { value, files },
    } = event;
    const file = files[0];
    if (files[0].size > 5242880) {
      setAvatarError(true);
      return;
    }

    setAvatarError(false);
    onFileSelected(file);
    // Reset input value, otherwise the input's onChange event would not fire if user would pick same file as before
    document.getElementById(inputId).value = '';
  };

  const handleBackdropButtonClick = (event) => {
    if (imageUrl) {
      setMenuAnchorEl(event.currentTarget);
    } else {
      document.getElementById(inputId).click();
    }
  };

  const handleUploadMenuItemClick = () => {
    setMenuAnchorEl(null);
    document.getElementById(inputId).click();
  };

  const handleRemoveMenuItemClick = () => {
    setMenuAnchorEl(null);
    onClear();
  };

  // TODO: Sizing doesnt work, remove fixed size in avatarContainer
  return (
    <RootDiv
      className={avatarPickerClasses.root}
      variant={variant}
      {...rest}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <StyledAvatar
        src={imageUrl}
        variant={variant}
        backgroundColor={background}
      >
        {getInitials(name)}
      </StyledAvatar>
      <Backdrop
        className={avatarPickerClasses.backdrop}
        open={mouseOver || isUploading}
        variant={variant}
        sx={{
          position: 'absolute',
          zIndex: 'drawer',
          borderRadius: variant === 'circular' ? '50%' : 1.5,
          m: 0.5,
        }}
      >
        {isUploading ? (
          <LinearProgress
            value={uploadProgress}
            variant="determinate"
            sx={{
              flexGrow: '0.6',
              height: '6px',
              borderRadius: 6,
              backgroundColor: 'grey.300',
              '. .MuiLinearProgress-barColorPrimary': {
                backgroundColor: 'grey.500',
              },
            }}
          />
        ) : (
          <>
            <Tooltip title={t('changeAvatar')}>
              <IconButton
                disableRipple
                component="span"
                aria-label="upload-image"
                size="large"
                onClick={handleBackdropButtonClick}
                sx={{ color: 'grey.300', height: '100%', width: '100%' }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Backdrop>
      {avatarError && (
        <FormHelperText error sx={{ textAlign: 'center' }}>
          {t('The image must be smaller than 5 MB')}
        </FormHelperText>
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        id={inputId}
        onChange={handleUploadAvatarClick}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        id="avatar-picker-menu"
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ '& .MuiMenu-paper': { minWidth: 200 } }}
      >
        <MenuItem onClick={handleUploadMenuItemClick}>
          <ListItemIcon>
            <AddAPhotoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('upload')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleRemoveMenuItemClick}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('remove')}</ListItemText>
        </MenuItem>
      </Menu>
    </RootDiv>
  );
};

AvatarPicker.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  variant: PropTypes.oneOf(['rounded', 'circular']),
  onFileSelected: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  isUploading: PropTypes.bool,
  uploadProgress: PropTypes.number,
};

AvatarPicker.defaultProps = {
  variant: 'circular',
  isUploading: false,
  onFileSelected: () => {},
  onClear: () => {},
};

export default AvatarPicker;
