import React from 'react';
import { useTranslation } from 'react-i18next';
import FormPaper from '../../components/FormPaper';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import FormActions from '../../components/FormActions';
import FormBlock from '../../components/FormBlock';
import { Box, Button } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { blocksRegistrationName, getFormTemplate } from '../../components/FormBlock/utils';
import FormPrivacyPolicyBlock from '../../components/FormPrivacyPolicyBlock';

const FormPage = ({ workspace, pageIndex, pageCount, busy, language, onContinueClick, onBackClick }) => {
    const { t } = useTranslation();
    const { control, getValues} = useFormContext();
    const { fields} = useFieldArray({
        control,
        name: blocksRegistrationName(),
    });
    const formTemplate = getFormTemplate(getValues);

    return (
        <>
            <FormPaper workspace={workspace}>
                {fields.map((blockTemplate, blockIndex) => (
                    <FormBlock
                        key={blockTemplate.key}
                        blockIndex={blockIndex}
                        language={language}
                        mode="view"
                    />
                ))}
                <FormActions>
                    {pageIndex > 0 && pageIndex <  pageCount - 1 && (
                        <Button
                            fullWidth
                            size="large"
                            color="default"
                            variant="outlined"
                            disabled={busy}
                            onClick={onBackClick}
                        >
                            {t("back", {lng: language})}
                        </Button>
                    )}
                    {/*Key is neccessary due to React issue https://github.com/facebook/react/issues/25089*/}
                    <LoadingButton
                        key={`button-${pageIndex}`}
                        fullWidth
                        loading={busy}
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={onContinueClick}
                        type={pageIndex < pageCount - 2 ? undefined : "submit"}
                    >
                        {pageIndex === pageCount - 2 ? t("submit", {lng: language}) : t("continue", {lng: language})}
                    </LoadingButton>
                </FormActions>
            </FormPaper>
            {pageIndex === pageCount - 2 && formTemplate.privacyPolicy && (
                <FormPrivacyPolicyBlock mode='view' language={language}/>
            )}
        </>
    );
};

FormPage.propTypes = {
    workspace: PropTypes.object.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    busy: PropTypes.bool,
    onContinueClick: PropTypes.func,
    onBackClick: PropTypes.func,
};

FormPage.defaultProps = {
    onContinueClick: () => {},
    onBackClick: () => {},
};

export default FormPage;
