import * as types from './actionTypes';
import { toId } from '../../utils/apiUtils';

const initialState = {};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case types.REPORTS_LOADED: {
      const newState = {};
      for (const report of action.payload.reports) {
        newState[report.pk] = report;
      }
      return newState;
    }
    case types.REPORTS_ADDED: {
      const newState = { ...state };
      for (const report of action.payload.reports) {
        newState[report.pk] = report;
      }
      return newState;
    }
    case types.REPORT_UPDATED: {
      const pk = action.payload.pk;
      return {
        ...state,
        [pk]: {
          ...state[pk],
          ...action.payload.data,
          pk: pk,
          sk: pk,
        },
      };
    }
    case types.REPORTS_DELETED: {
      const workspaceId = toId(action.payload.workspaceKey);
      const filtered = state.filter((t) => t.group === workspaceId);
      const keysToDelete = filtered.map((t) => t.pk);

      const newState = { ...state };
      for (const key of keysToDelete) {
        delete newState[key];
      }
      return newState;
    }
    default:
      return state;
  }
}
