import * as types from "./actionTypes";
import { groupBy, stableSort } from '../../utils/arrayUtils';
import { toId } from '../../utils/apiUtils';

const initialState = {};

export default function commentsReducer(state = initialState, action) {
    switch (action.type) {
        case types.COMMENTS_LOADED: {
            const comments = action.payload.comments;
            const uniquePartitionKeys = [...new Set(comments.map(item => item.pk))];
            const newState = {...state};
            for (const partitionKey of uniquePartitionKeys) {
                const filtered =  comments.filter(item => item.pk === partitionKey);
                const sorted = stableSort(filtered, 'asc', "createdAt");
                newState[partitionKey] = sorted;
            }

            return newState;
        }
        case types.COMMENTS_ADDED: {
            const { comments } = action.payload;
            const groupedComments = groupBy(comments, "pk");
            const newState = {...state};
            for (const reportKey in groupedComments) {
                const existingComments = (newState[reportKey] ?? []);
                const uniqueComments = groupedComments[reportKey].filter(comment => !existingComments.some(existing => existing.sk === comment.sk));
                const newComments = [...existingComments, ...uniqueComments];
                const sorted = stableSort(newComments, 'asc', "createdAt");
                newState[reportKey] = sorted;
            }

            return newState;
        }
        case types.COMMENT_UPDATED: {
            const pk = action.payload.pk;
            const sk = action.payload.sk;
            const newStateComments = [...(state[pk] ?? [])];
            const index = newStateComments.findIndex(item => item.sk === sk);
            if (index < 0) {
                newStateComments.push({...action.payload.data, pk: pk, sk: sk});
            } else {
                newStateComments[index] = {...newStateComments[index], ...action.payload.data};
            }

            const newState = {...state};
            newState[pk] = newStateComments;
            return newState;
        }
        case types.COMMENTS_DELETED: {
            const workspaceId = toId(action.payload.workspaceKey);
            const keysToDelete = [];
            for (const pk in state) {
                const comment = state[pk][0];
                if (comment.group === workspaceId) {
                    keysToDelete.push(pk);
                }
            }

            const newState = {...state};
            for (const pk of keysToDelete) {
                delete newState[pk];
            }
            return newState;
        }
        default:
            return state
    }
}
