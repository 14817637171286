import * as types from "./actionTypes";

const initialState = null;

//  Users state is a collection of user profiles grouped by team ID, e.g. {
//     miton: [{id:"user1"},{id:"user2"}],
//     cola: [{id:"user3"}]
// }
export default function currentUserReducer(state = initialState, action) {
    switch (action.type) {
        case types.CURRENT_USER_LOADED: {
            return action.payload;
        }
        default:
            return state
    }
}
