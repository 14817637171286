import React, { useState } from 'react';
import Button from '@mui/material/Button';
import {
  AppBar,
  Box,
  Container,
  Stack,
  Toolbar,
  useScrollTrigger,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo';
import LanguageButton from '../../components/LanguageButton';
import { useNavigate } from 'react-router-dom';
import { Language, UI_LANGUAGES } from '../../i18n/config';
import i18n from 'i18next';
import { I18n } from 'aws-amplify';
import { useLocation } from 'react-router';
import { getActiveLanguage } from '../../utils/localizationUtils';

const AuthTopbar = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const { pathname, state } = useLocation();
  const buttonAction = pathname === '/login' ? 'signup' : 'login';
  const [locale, setLocale] = useState(
    state?.locale ?? getActiveLanguage(i18n),
  );

  const onLocaleChange = async (newLocale: Language) => {
    setLocale(newLocale);
    await i18n.changeLanguage(newLocale);
    I18n.setLanguage(newLocale);
  };

  return (
    <AppBar
      component="nav"
      position={'fixed'}
      sx={{
        top: 0,
        bgcolor: 'background.paper',
        borderBottom: 1,
        borderColor: trigger ? 'none' : 'divider',
      }}
      elevation={trigger ? 1 : 0}
    >
      <Toolbar>
        <Container maxWidth="lg">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
            py={1}
          >
            <Box display={'flex'} component="a" href="/" title="SafeTalk">
              <Logo size="medium" />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 1,
                md: 3,
              }}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <LanguageButton
                value={locale}
                supportedLanguages={UI_LANGUAGES}
                onLocaleChange={onLocaleChange}
              />
              <Button
                variant="outlined"
                size="medium"
                color="default"
                onClick={() => navigate(`/${buttonAction}`)}
              >
                {t(buttonAction)}
              </Button>
            </Stack>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default AuthTopbar;
