import React, {useState} from 'react';
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateWorkspaceDialog from "../../components/CreateWorkspaceDialog";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    addWorkspaceButton: {
        width: theme.spacing(14),
        minWidth: theme.spacing(14),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: theme.spacing(3, 0)
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        },
        borderRadius: 6,
        padding: theme.spacing(2),
        textTransform: "none",
        textAlign: "center",
        ...theme.typography.body2
    },
    label: {
        display: "block",
    },
    startIcon: {
        margin: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: theme.spacing(10),
        height: theme.spacing(10),
        borderRadius: "12px",
        border: `1px dashed ${theme.palette.grey[400]}`,
        marginBottom: theme.spacing(1)
    }
}));

const AddWorkspaceButton = ({...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);

    return (
        <>
            <Button
                className={classes.addWorkspaceButton}
                component="div"
                size="large"
                onClick={() => setWorkspaceDialogOpen(true)}
                classes={{
                    label: classes.label
                }}
                {...rest}
            >
                <span className={classes.startIcon}>
                    <AddCircleIcon color="primary" fontSize="large"/>
                </span>
                <span>
                    {t("addWorkspace")}
                </span>
            </Button>
            <CreateWorkspaceDialog
                open={workspaceDialogOpen}
                //onClose={({redirect}) => {setWorkspaceDialogOpen(false); redirect();}}
                onClose={() => setWorkspaceDialogOpen(false)}
            />
        </>
    );
};

export default AddWorkspaceButton;