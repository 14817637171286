import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    formBlockContainer: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.up("xs")]: {
            margin: theme.spacing(0, 2),
        },
        [theme.breakpoints.up("md")]: {
            margin: theme.spacing(0, 3),
        },
        [theme.breakpoints.up("lg")]: {
            margin: theme.spacing(0, 7) //6
        },
    },
}));

// TODO: DELETE
const FormBlockContainer = forwardRef((props, ref) => {
    const {
        className,
        children
    } = props;
    const classes = useStyles();

    return (
        <div ref={ref} className={clsx(classes.formBlockContainer, className)}>
            {children}
        </div>
    );
});

FormBlockContainer.propTypes = {
    className: PropTypes.string
};

FormBlockContainer.defaultProps = {
};

export default FormBlockContainer;
