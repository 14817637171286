import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Switch, Typography,} from "@mui/material";
import {withStyles} from "@mui/styles";
import Button from "@mui/material/Button";

export const styles = theme => ({
    root: {
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
    },
    titleContainer: {
        flex: "1 1 auto",
        marginRight: theme.spacing(3)
    },
    title: {
    }
})

const ActionPanel = ({ children, className, classes, actionSwitchChecked, actionButtonText, actionControlType, onActionControlClick, subtitle, title, actionControlProps}) => {
    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <Typography className={classes.title} gutterBottom variant="h6">
                        {title}
                    </Typography>
                    {Boolean(subtitle) && (
                        <Typography variant="body2" color="textSecondary">
                            {subtitle}
                        </Typography>
                    )}
                </div>
                {actionControlType === 'switch' ? (
                    <Switch checked={actionSwitchChecked} color='secondary' onChange={onActionControlClick} {...actionControlProps}/>
                ) : (
                    <Button color="default" size="medium" variant="outlined" onClick={onActionControlClick} {...actionControlProps}>
                        {actionButtonText}
                    </Button>
                )}
            </div>
            {children}
        </div>
    );
}

ActionPanel.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    actionControlType: PropTypes.oneOf(["switch", 'button']),
    actionSwitchChecked: PropTypes.bool,
    actionButtonText: PropTypes.string,
    actionControlProps: PropTypes.object,
    onActionControlClick: PropTypes.func
}

ActionPanel.defaultProps = {
    onActionControlClick: () => {},
    actionControlType: "button",
    actionControlProps: {}

};

export default withStyles(styles, {name: 'ActionPanel-'})(ActionPanel);