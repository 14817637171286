import * as types from './actionTypes';
import { ACTIVITY_LOG_ENTRY_ADDED } from './actionTypes';

export const loadActivityLog = (organizationKey, items) => async (dispatch, getState) => {
    dispatch({ type: types.ACTIVITY_LOG_LOADED, payload: {organizationKey, items} });
}

export const addActivityLogEntries = (organizationKey, items) => async (dispatch, getState) => {
    dispatch({ type: types.ACTIVITY_LOG_ENTRIES_ADDED, payload: {organizationKey, items} });
}

export const addActivityLogEntry = (organizationKey, item) => async (dispatch, getState) => {
    dispatch({ type: types.ACTIVITY_LOG_ENTRY_ADDED, payload: {organizationKey, item} });
}