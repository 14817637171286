import * as types from "./actionTypes";
import { VOICEMAIL_UPDATED, VOICEMAILS_LOADED } from './actionTypes';

export const loadVoicemails = (voicemails) => async (dispatch, getState) => {
    dispatch({ type: types.VOICEMAILS_LOADED, payload: {voicemails} });
}

export const addOrUpdateVoicemail = (pk, data) => async (dispatch, getState) => {
    if (!Boolean(pk)) {
        throw new Error("pk cannot be null");
    }
    dispatch({ type: types.VOICEMAIL_UPDATED, payload: {pk, data} });
}