import {
  DeserializedPrivateResource,
  UpdateDeserializedPrivateResourceInput,
} from '../../types/api';

export const LOAD_POLICIES = 'LOAD_POLICIES';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const DELETE_POLICY = 'DELETE_POLICY';

export interface LoadPoliciesAction {
  type: typeof LOAD_POLICIES;
  payload: {
    policies: DeserializedPrivateResource[];
  };
}

export interface UpdatePolicyAction {
  type: typeof UPDATE_POLICY;
  payload: {
    pk: string;
    sk: string;
    data: UpdateDeserializedPrivateResourceInput;
  };
}

export interface DeletePolicyAction {
  type: typeof DELETE_POLICY;
  payload: {
    pk: string;
    sk: string;
  };
}

export type PolicyActionTypes =
  | LoadPoliciesAction
  | UpdatePolicyAction
  | DeletePolicyAction;
