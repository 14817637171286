import React, { useContext, useState } from 'react';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { activeWorkspacesSelector } from '../../state/workspaces/selectors';
import { toId } from '../../utils/apiUtils';
import ResourceAvatar from '../../components/ResourceAvatar';
import CreateWorkspaceDialog from '../../components/CreateWorkspaceDialog';
import { StyledMenu, StyledMenuItem } from '../../components/StyledMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { currentUserRoleSelector } from '../../state/members/selectors';
import { isAdmin } from '../../utils/user';
import Logo from '../../components/Logo';
import { NAVBAR_WIDTH } from '../../constants/ui';
import NavbarItem from './NavbarItem';
import OrganizationPicker from './OrganizationPicker';
import NavbarLinkItem from './NavbarLinkItem';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import { organizationSelector } from '../../state/organizations/selectors';
import EllipsisHorizontalIcon from '../../icons/EllipsisHorizontalIcon';
import NewspaperIcon from '../../icons/NewspaperIcon';
import ChatBubbleOvalEllipsisIcon from '../../icons/ChatBubbleOvalEllipsisIcon';
import BarsBottomLeftIcon from '../../icons/BarsBottomLeftIcon';
import PlusIcon from '../../icons/PlusIcon';
import CogIcon from '../../icons/CogIcon';
import InboxIcon from '../../icons/InboxIcon';
import HomeIcon from '../../icons/HomeIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import { getOrganizationRoute } from '../../utils/routeUtils';
import { addOrUpdateWorkspace } from '../../state/workspaces/actions';
import { ApiContext } from '../../contexts/ApiContext';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';

const RootDrawer = styled(Drawer, { name: 'Sidebar' })(({ theme }) => ({
  width: NAVBAR_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: NAVBAR_WIDTH,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.level1,
  },
  // "& .active": {
  //     color: theme.palette.sidebarPrimary.contrastText,
  //     backgroundColor: "rgba(255, 255, 255, 0.16)",
  // },
}));

const NavbarItemList = styled(List)(({ theme }) => ({
  padding: theme.spacing(2, 2),
}));

const Sidebar = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const { logWorkspaceDeleted } = useContext(ActivityLogContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const organization = useSelector(organizationSelector(organizationKey));
  const workspaces = useSelector(activeWorkspacesSelector(organizationKey));
  const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
  const [workspaceContextMenuAnchorEl, setWorkspaceContextMenuAnchorEl] =
    useState(null);
  const [selectedWorkspaceKey, setSelectedWorkspaceKey] = useState();
  const organizationRoute = `/app/${toId(organizationKey)}`;
  const currenUserRole = useSelector(currentUserRoleSelector(organizationKey));
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingWorkspace, setDeletingWorkspace] = useState(false);

  const handleWorkspaceMoreButtonClick = (event, workspaceKey) => {
    setWorkspaceContextMenuAnchorEl(event.currentTarget);
    setSelectedWorkspaceKey(workspaceKey);
  };

  const handleWorkspaceContextMenuClose = () => {
    setWorkspaceContextMenuAnchorEl(null);
    setSelectedWorkspaceKey(null);
  };

  const handleWorkspaceSettingsClick = () => {
    setWorkspaceContextMenuAnchorEl(null);
    setSelectedWorkspaceKey(null);
    navigate(
      `${organizationRoute}/${toId(selectedWorkspaceKey)}/settings/general`,
      {
        state: { from: window.location.pathname },
      },
    );
  };

  const handleSupportChatButtonClick = () => {
    const parentDiv = document.getElementById('smartsupp-widget-container');
    parentDiv.style.display = 'initial';
    if (parentDiv) {
      const iframe = parentDiv.querySelector('#widgetButtonFrame');

      if (iframe) {
        const innerDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        const buttonContainer = innerDoc.getElementById(
          'smartsupp-widget-button',
        );

        if (buttonContainer) {
          const button = buttonContainer.querySelector('div[role="button"]');
          button.click();
        }
      }
    }
  };

  const handleDeleteWorkspaceClick = async () => {
    setDeletingWorkspace(true);
    await logWorkspaceDeleted(selectedWorkspaceKey);
    await mutationApi.deleteWorkspace(selectedWorkspaceKey);

    setWorkspaceContextMenuAnchorEl(null);
    setDeletingWorkspace(false);
    setDeleteDialogOpen(false);
    dispatch(addOrUpdateWorkspace(selectedWorkspaceKey, { isDeleted: true }));

    navigate(`${getOrganizationRoute(organizationKey)}/home`, {
      replace: true,
    });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setWorkspaceContextMenuAnchorEl(null);
  };

  return (
    <RootDrawer variant="persistent" component="nav" anchor="left" open={open}>
      <Stack
        direction="row"
        alignItems="center"
        height={70}
        pt={4}
        pb={2}
        px={3.5}
      >
        <Logo
          sx={{
            flex: '1 1 auto',
            '& .MuiSkeleton-root': { bgcolor: 'grey.300' },
          }}
        />
        <Tooltip title={t('collapseSidebar')}>
          <IconButton size="small" edge="end" onClick={onClose}>
            <BarsBottomLeftIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
      <NavbarItemList>
        <NavbarLinkItem
          title={t('home')}
          path={`${organizationRoute}/home`}
          icon={<HomeIcon />}
        />
        <NavbarLinkItem
          title={t('myReports')}
          path={`${organizationRoute}/reports`}
          icon={<InboxIcon />}
        />
      </NavbarItemList>
      <Divider sx={{ mx: 3 }} />
      {/*<Stack direction='row'><Box height={50} width={50} backgroundColor='rgba(0, 0, 0, 0.54)'/><Box height={50} width={50} backgroundColor='#637381'/></Stack>*/}
      {/*<Stack direction='row'><Box height={50} width={50} backgroundColor='rgba(0, 0, 0, 0.87)'/><Box height={50} width={50} backgroundColor='#212B36'/></Stack>*/}
      <Box flex="1 1 auto" py={2} overflow="auto">
        <Stack direction="row" alignItems="center" sx={{ px: 3.5 }}>
          <Typography
            component="div"
            variant="subtitle2"
            color="text.secondary"
            sx={{ lineHeight: 1.5, flex: '1 1 auto' }}
          >
            {t('workspaces')}
          </Typography>
          {isAdmin(currenUserRole) && (
            <Tooltip title={t('addWorkspace')}>
              <IconButton
                size="small"
                edge="end"
                onClick={() => setWorkspaceDialogOpen(true)}
              >
                <PlusIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <NavbarItemList sx={{ overflow: 'auto', pt: 0 }}>
          {workspaces.map((workspace) => (
            <NavbarLinkItem
              key={workspace.pk}
              title={workspace.name}
              path={`${organizationRoute}/${toId(workspace.pk)}/overview`}
              highlighted={workspace.pk === selectedWorkspaceKey}
              icon={
                <ResourceAvatar
                  component="span"
                  variant="rounded"
                  name={workspace.name}
                  picture={workspace.picture}
                  size={24}
                  sx={{ display: 'inline-block' }}
                />
              }
              secondaryAction={
                isAdmin(currenUserRole) && (
                  <IconButton
                    size="small"
                    edge="end"
                    aria-label="more"
                    onClick={(e) =>
                      handleWorkspaceMoreButtonClick(e, workspace.pk)
                    }
                  >
                    <EllipsisHorizontalIcon />
                  </IconButton>
                )
              }
            />
          ))}
        </NavbarItemList>
      </Box>
      <NavbarItemList>
        <NavbarItem
          title={t('talkToSupport')}
          icon={<ChatBubbleOvalEllipsisIcon />}
          ButtonProps={{
            id: 'supportChatButton',
            onClick: handleSupportChatButtonClick,
          }}
        />
        {isEnterpriseOrganization(organization) && (
          <NavbarLinkItem
            title={t('activityLog')}
            path={`${organizationRoute}/activity`}
            icon={<NewspaperIcon />}
          />
        )}
        <NavbarLinkItem
          title={t('settings')}
          path={`${organizationRoute}/settings`}
          icon={<CogIcon />}
        />
      </NavbarItemList>
      {/*<Well sx={{ mx: 2, mb: 2 }}>Trial</Well>*/}
      <Divider sx={{ mx: 3 }} />
      <OrganizationPicker sx={{ px: 2, py: 2 }} />
      <StyledMenu
        anchorEl={workspaceContextMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(workspaceContextMenuAnchorEl)}
        onClose={handleWorkspaceContextMenuClose}
      >
        <MenuItem onClick={handleWorkspaceSettingsClick}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('settings')} />
        </MenuItem>
        <MenuItem onClick={() => setDeleteDialogOpen(true)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('delete')} />
        </MenuItem>
      </StyledMenu>
      <CreateWorkspaceDialog
        open={workspaceDialogOpen}
        onClose={() => setWorkspaceDialogOpen(false)}
      />
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        loading={deletingWorkspace}
        text={t('deleteWorkspaceConfirmationText')}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteWorkspaceClick}
      />
    </RootDrawer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

Sidebar.defaultProps = {
  onClose: () => {},
};

export default React.memo(Sidebar);
