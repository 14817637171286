import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const RootPopper = styled(Popper, {
    name: "PopperMenu",
    slot: "Root",
    overridesResolver: (props, styles) => styles.root,
})({});

const MenuPaper = styled(Paper, {
    name: "PopperMenu",
    slot: "Paper",
    overridesResolver: (props, styles) => styles.paper,
})({
    // specZ: The maximum height of a simple menu should be one or more rows less than the view
    // height. This ensures a tapable area outside of the simple menu with which to dismiss
    // the menu.
    maxHeight: "calc(100% - 96px)",
    // Add iOS momentum scrolling for iOS < 13.0
    WebkitOverflowScrolling: "touch",
});

const MenuMenuList = styled(MenuList, {
    name: "PopperMenu",
    slot: "List",
    overridesResolver: (props, styles) => styles.list,
})({
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
});

const PopperMenu = ({
    open,
    anchorEl,
    placement,
    disablePortal,
    disableAutoFocusItem,
    disableRestoreFocus,
    children,
    onClose,
    offset,
    PaperProps,
    MenuListProps,
    ...rest
}) => {
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (!disableRestoreFocus && anchorEl && prevOpen.current === true && open === false) {
            anchorEl.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <RootPopper
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            placement={placement}
            disablePortal={disablePortal}
            transition
            modifiers={[
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [offset.left, offset.top],
                    },
                },
                // {
                //     name: "flip",
                //     enabled: true,
                //     options: {
                //         altBoundary: true,
                //         rootBoundary: "document",
                //         padding: 8,
                //     },
                // },
                {
                    name: "preventOverflow",
                    enabled: true,
                    options: {
                        tether: true,
                        rootBoundary: "document",
                        padding: 8,
                    },
                },
            ]}
            {...rest}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                    }}
                >
                    <MenuPaper {...PaperProps}>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuMenuList autoFocusItem={!disableAutoFocusItem && open} {...MenuListProps}>{children}</MenuMenuList>
                        </ClickAwayListener>
                    </MenuPaper>
                </Grow>
            )}
        </RootPopper>
    );
};

PopperMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    disableAutoFocusItem: PropTypes.bool,
    disableRestoreFocus: PropTypes.bool,
    offset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),
    placement: PropTypes.oneOf([
        "auto-end",
        "auto-start",
        "auto",
        "bottom-end",
        "bottom-start",
        "bottom",
        "left-end",
        "left-start",
        "left",
        "right-end",
        "right-start",
        "right",
        "top-end",
        "top-start",
        "top",
    ]),
    PaperProps: PropTypes.object,
    MenuListProps: PropTypes.object,
    onClose: PropTypes.func,
};

PopperMenu.defaultProps = {
    placement: "bottom-start",
    offset: { left: 0, top: 0 },
    onClose: () => {},
};

export default PopperMenu;
