import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../utils/validationUtils';
import { ApiContext } from '../../contexts/ApiContext';
import { useLocation } from 'react-router';
import { getActiveLanguage } from '../../utils/localizationUtils';
import { styled } from '@mui/material/styles';

const RootBox = styled(Box, { name: 'ForgottenPasswordPage1' })(
  ({ theme }) => ({}),
);

interface Props {
  onSubmit: (email: string) => void;
}

const ForgottenPasswordPage1 = ({ onSubmit }: Props) => {
  const { authApi } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const [email, setEmail] = useState(state?.email ?? '');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const autoFocusEmailField = !Boolean(state?.email);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setEmail(value);

    setEmailError(!Boolean(value) ? 'emailIsRequired' : null);
    setSubmitError(null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (emailError !== null) {
      return;
    }

    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    const language = getActiveLanguage(i18n);
    try {
      setLoading(true);
      const response = await authApi.forgotPassword(email, language);
      console.log('forgotten, response: %o', response);
      onSubmit(email);
    } catch (e: any) {
      console.error(e);
      setLoading(false);

      if (e.name === 'LimitExceededException') {
        setSubmitError(e.name);
      } else {
        setSubmitError('unknownLoginError');
      }
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs">
        <Box sx={{ textAlign: 'center' }} mb={6}>
          <Typography variant="h3">
            {t('forgottenPasswordPage1Title')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <TextField
              id="email"
              autoFocus={autoFocusEmailField}
              required
              fullWidth
              variant="outlined"
              autoComplete="email"
              label={t('email')}
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              helperText={t(emailError!)}
            />
            <LoadingButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default ForgottenPasswordPage1;
