/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getPrivateResource =
  /* GraphQL */ `query GetPrivateResource($pk: ID!, $sk: ID!) {
  getPrivateResource(pk: $pk, sk: $sk) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetPrivateResourceQueryVariables,
    APITypes.GetPrivateResourceQuery
  >;
export const listPrivateResources = /* GraphQL */ `query ListPrivateResources(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelPrivateResourceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPrivateResources(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPrivateResourcesQueryVariables,
  APITypes.ListPrivateResourcesQuery
>;
export const privateResourcesByOrganizationKey =
  /* GraphQL */ `query PrivateResourcesByOrganizationKey(
  $organizationKey: ID!
  $sk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrivateResourceFilterInput
  $limit: Int
  $nextToken: String
) {
  privateResourcesByOrganizationKey(
    organizationKey: $organizationKey
    sk: $sk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PrivateResourcesByOrganizationKeyQueryVariables,
    APITypes.PrivateResourcesByOrganizationKeyQuery
  >;
export const privateResourcesByUserKey =
  /* GraphQL */ `query PrivateResourcesByUserKey(
  $userKey: ID!
  $pk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrivateResourceFilterInput
  $limit: Int
  $nextToken: String
) {
  privateResourcesByUserKey(
    userKey: $userKey
    pk: $pk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PrivateResourcesByUserKeyQueryVariables,
    APITypes.PrivateResourcesByUserKeyQuery
  >;
export const privateResourcesByEmail =
  /* GraphQL */ `query PrivateResourcesByEmail(
  $email: AWSEmail!
  $pk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPrivateResourceFilterInput
  $limit: Int
  $nextToken: String
) {
  privateResourcesByEmail(
    email: $email
    pk: $pk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PrivateResourcesByEmailQueryVariables,
    APITypes.PrivateResourcesByEmailQuery
  >;
export const getPublicResource =
  /* GraphQL */ `query GetPublicResource($pk: ID!, $sk: ID!) {
  getPublicResource(pk: $pk, sk: $sk) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetPublicResourceQueryVariables,
    APITypes.GetPublicResourceQuery
  >;
export const listPublicResources = /* GraphQL */ `query ListPublicResources(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelPublicResourceFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPublicResources(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPublicResourcesQueryVariables,
  APITypes.ListPublicResourcesQuery
>;
export const publicResourcesByOrganizationKey =
  /* GraphQL */ `query PublicResourcesByOrganizationKey(
  $organizationKey: ID!
  $sk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPublicResourceFilterInput
  $limit: Int
  $nextToken: String
) {
  publicResourcesByOrganizationKey(
    organizationKey: $organizationKey
    sk: $sk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PublicResourcesByOrganizationKeyQueryVariables,
    APITypes.PublicResourcesByOrganizationKeyQuery
  >;
export const publicResourcesByPath = /* GraphQL */ `query PublicResourcesByPath(
  $path: String!
  $sk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPublicResourceFilterInput
  $limit: Int
  $nextToken: String
) {
  publicResourcesByPath(
    path: $path
    sk: $sk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PublicResourcesByPathQueryVariables,
  APITypes.PublicResourcesByPathQuery
>;
export const getPartner = /* GraphQL */ `query GetPartner($pk: ID!) {
  getPartner(pk: $pk) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartnerQueryVariables,
  APITypes.GetPartnerQuery
>;
export const listPartners = /* GraphQL */ `query ListPartners(
  $pk: ID
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPartners(
    pk: $pk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      appUrl
      name
      picture
      emailDomain
      phoneNumbers
      translations
      colors
      statuses
      faviconUrl
      privacyPolicyUrl
      termsOfServiceUrl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPartnersQueryVariables,
  APITypes.ListPartnersQuery
>;
export const partnersByAppUrl = /* GraphQL */ `query PartnersByAppUrl(
  $appUrl: String!
  $pk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPartnerFilterInput
  $limit: Int
  $nextToken: String
) {
  partnersByAppUrl(
    appUrl: $appUrl
    pk: $pk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      appUrl
      name
      picture
      emailDomain
      phoneNumbers
      translations
      colors
      statuses
      faviconUrl
      privacyPolicyUrl
      termsOfServiceUrl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PartnersByAppUrlQueryVariables,
  APITypes.PartnersByAppUrlQuery
>;
export const getActivityLogEntry =
  /* GraphQL */ `query GetActivityLogEntry($pk: ID!, $sk: ID!) {
  getActivityLogEntry(pk: $pk, sk: $sk) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetActivityLogEntryQueryVariables,
    APITypes.GetActivityLogEntryQuery
  >;
export const listActivityLogEntries =
  /* GraphQL */ `query ListActivityLogEntries(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelActivityLogEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listActivityLogEntries(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      group
      createdAt
      workspaceKey
      senderKey
      event
      tagKey
      statusKey
      messageKey
      commentKey
      reportKey
      userKey
      email
      role
      dueDate
      description
      name
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListActivityLogEntriesQueryVariables,
    APITypes.ListActivityLogEntriesQuery
  >;
export const getResourcePassword =
  /* GraphQL */ `query GetResourcePassword($pk: ID!, $sk: ID!) {
  getResourcePassword(pk: $pk, sk: $sk) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetResourcePasswordQueryVariables,
    APITypes.GetResourcePasswordQuery
  >;
export const listResourcePasswords = /* GraphQL */ `query ListResourcePasswords(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelResourcePasswordFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listResourcePasswords(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      password
      group
      organizationKey
      workspaceKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListResourcePasswordsQueryVariables,
  APITypes.ListResourcePasswordsQuery
>;
export const resourcePasswordsByPassword =
  /* GraphQL */ `query ResourcePasswordsByPassword(
  $password: String!
  $pk: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelResourcePasswordFilterInput
  $limit: Int
  $nextToken: String
) {
  resourcePasswordsByPassword(
    password: $password
    pk: $pk
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      password
      group
      organizationKey
      workspaceKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ResourcePasswordsByPasswordQueryVariables,
    APITypes.ResourcePasswordsByPasswordQuery
  >;
export const getPublicResourceAlias =
  /* GraphQL */ `query GetPublicResourceAlias($pk: ID!, $sk: ID!) {
  getPublicResourceAlias(pk: $pk, sk: $sk) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetPublicResourceAliasQueryVariables,
    APITypes.GetPublicResourceAliasQuery
  >;
export const listPublicResourceAliases =
  /* GraphQL */ `query ListPublicResourceAliases(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelPublicResourceAliasFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPublicResourceAliases(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      aliasId
      group
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListPublicResourceAliasesQueryVariables,
    APITypes.ListPublicResourceAliasesQuery
  >;
export const publicResourceAliasByAliasId =
  /* GraphQL */ `query PublicResourceAliasByAliasId(
  $aliasId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPublicResourceAliasFilterInput
  $limit: Int
  $nextToken: String
) {
  publicResourceAliasByAliasId(
    aliasId: $aliasId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      aliasId
      group
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.PublicResourceAliasByAliasIdQueryVariables,
    APITypes.PublicResourceAliasByAliasIdQuery
  >;
export const getEmailConversation =
  /* GraphQL */ `query GetEmailConversation($pk: ID!, $sk: ID!) {
  getEmailConversation(pk: $pk, sk: $sk) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetEmailConversationQueryVariables,
    APITypes.GetEmailConversationQuery
  >;
export const listEmailConversations =
  /* GraphQL */ `query ListEmailConversations(
  $pk: ID
  $sk: ModelIDKeyConditionInput
  $filter: ModelEmailConversationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listEmailConversations(
    pk: $pk
    sk: $sk
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      pk
      sk
      emailAddress
      rootEmailId
      organizationKey
      workspaceKey
      subject
      emailIDs
      type
      language
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEmailConversationsQueryVariables,
    APITypes.ListEmailConversationsQuery
  >;
export const emailConversationByRootEmailId =
  /* GraphQL */ `query EmailConversationByRootEmailId(
  $rootEmailId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEmailConversationFilterInput
  $limit: Int
  $nextToken: String
) {
  emailConversationByRootEmailId(
    rootEmailId: $rootEmailId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      pk
      sk
      emailAddress
      rootEmailId
      organizationKey
      workspaceKey
      subject
      emailIDs
      type
      language
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.EmailConversationByRootEmailIdQueryVariables,
    APITypes.EmailConversationByRootEmailIdQuery
  >;
export const getVoicemailRecording =
  /* GraphQL */ `query GetVoicemailRecording($contactId: ID!) {
  getVoicemailRecording(contactId: $contactId) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetVoicemailRecordingQueryVariables,
    APITypes.GetVoicemailRecordingQuery
  >;
export const listVoicemailRecordings =
  /* GraphQL */ `query ListVoicemailRecordings(
  $contactId: ID
  $filter: ModelVoicemailRecordingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listVoicemailRecordings(
    contactId: $contactId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      contactId
      organizationKey
      workspaceKey
      password
      phoneNumber
      completed
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListVoicemailRecordingsQueryVariables,
    APITypes.ListVoicemailRecordingsQuery
  >;
export const translateLanguagePack =
  /* GraphQL */ `query TranslateLanguagePack($input: TranslateLanguagePackInput!) {
  translateLanguagePack(input: $input) {
    items {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.TranslateLanguagePackQueryVariables,
    APITypes.TranslateLanguagePackQuery
  >;
export const checkInviteeRegistrationStatus =
  /* GraphQL */ `query CheckInviteeRegistrationStatus(
  $input: CheckInviteeRegistrationStatusInput!
) {
  checkInviteeRegistrationStatus(input: $input) {
    registered
    email
    __typename
  }
}
` as GeneratedQuery<
    APITypes.CheckInviteeRegistrationStatusQueryVariables,
    APITypes.CheckInviteeRegistrationStatusQuery
  >;
export const verifyCaptchaToken =
  /* GraphQL */ `query VerifyCaptchaToken($input: VerifyCaptchaTokenInput!) {
  verifyCaptchaToken(input: $input) {
    success
    __typename
  }
}
` as GeneratedQuery<
    APITypes.VerifyCaptchaTokenQueryVariables,
    APITypes.VerifyCaptchaTokenQuery
  >;
export const checkUserExists =
  /* GraphQL */ `query CheckUserExists($input: CheckUserExistsInput) {
  checkUserExists(input: $input) {
    exists
    __typename
  }
}
` as GeneratedQuery<
    APITypes.CheckUserExistsQueryVariables,
    APITypes.CheckUserExistsQuery
  >;
