import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { getBlockTemplate, translationRegistrationName } from './utils';
import RichText from '../RichText/RichText';
import RichTextField from '../RichTextField';
import { useTranslation } from 'react-i18next';

const FormTextBlock = ({ blockIndex, language, mode }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const blockTemplate = getBlockTemplate(getValues, blockIndex);

  return (
    <Controller
      name={translationRegistrationName(blockTemplate.content)}
      render={({ field: { onChange, value } }) => {
        return mode === 'view' ? (
          <RichText content={value} />
        ) : (
          <RichTextField
            value={value}
            onChange={onChange}
            placeholder={t('textEditorPlaceholder')}
            toolbarControls={[
              'heading1',
              'heading2',
              'divider',
              'bold',
              'italic',
              'underline',
              'unorderedList',
              'orderedList',
              'link',
            ]}
          />
        );
      }}
    />
  );
};

FormTextBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['view', 'edit']),
};

FormTextBlock.defaultProps = {
  mode: 'view',
};

export default FormTextBlock;
