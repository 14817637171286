import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  deserializeEditorState,
  emptyEditorState,
  isHeading1,
  isHeading2,
} from '../../utils/richTextUtils';
import { deminifyDraftEditorState } from '../../utils/draftJsUtils';
import { Editor } from 'draft-js';

const RootDiv = styled('div', { name: 'RichText' })(({ theme, size }) => ({
  '.defaultText': {
    ...theme.typography.body1,
  },
  '.heading1': {
    ...theme.typography.h3,
  },
  '.heading2': {
    ...theme.typography.h5,
  },
}));

const RichText = ({ className, content, blockStyleFn, sx, ...rest }) => {
  const deserializedState = useMemo(
    () => deserializeEditorState(content),
    [content],
  );

  function blockStyleFunction(contentBlock) {
    const styleName = blockStyleFn?.(contentBlock);
    if (styleName) {
      return styleName;
    }

    const type = contentBlock.getType();
    if (isHeading1(type)) {
      return 'heading1';
    } else if (isHeading2(type)) {
      return 'heading2';
    } else {
      return 'defaultText';
    }
  }

  // if (isEmptyState(deserializedState)) {
  //     return <></>;
  // }

  return (
    <RootDiv className={className} sx={sx}>
      <Editor
        readOnly
        editorState={deserializedState}
        blockStyleFn={blockStyleFunction}
        {...rest}
      />
    </RootDiv>
  );
};

RichText.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  blockStyleFn: PropTypes.func,
};

RichText.defaultProps = {};

export default RichText;
