import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../contexts/ApiContext';
import UserPicker from '../../components/UserPicker';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { useTranslation } from 'react-i18next';
import { currentUserRoleSelector } from '../../state/members/selectors';
import MessageSkeleton from '../../components/MessageSkeleton/MessageSkeleton';
import { scrollToView } from '../../utils/ui';
import { addOrUpdateReport } from '../../state/reports/actions';
import AttributeField from './AttributeField';
import AttributeLabel from './AttributeLabel';
import { workspaceStatusesSelector } from '../../state/statuses/selectors';
import DueDatePicker from '../../components/DueDatePicker';
import { newCommentKey } from '../../utils/apiUtils';
import { addOrUpdateComment } from '../../state/comments/actions';
import { newAwsDateTime } from '../../utils/time';
import ChatMessage from '../../components/ChatMessage';
import { useMessageSenders } from '../../hooks/useMessageSenders';
import { usePrivateReportResources } from '../../hooks/usePrivateReportResources';
import { Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import StatusCustomizationDialog from './StatusCustomizationDialog';
import StatusPicker from './StatusPicker';
import TagPicker from './TagPicker';
import EventLogMessage from '../../components/EventLogMessage/EventLogMessage';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { isClosedStatus, isEventMessage } from '../../utils/reportUtils';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';
import { getAddedRemovedItems } from '../../utils/arrayUtils';
import { isObserver, isSolver } from '../../utils/user';
import ResourceAvatar from '../../components/ResourceAvatar';
import ChatInput from '../../components/ChatInput';
import RichTextField from '../../components/RichTextField';
import { FormattingToolbarClassName } from '../../components/RichTextField/richTextFieldClasses';

const RootDiv = styled('div', { name: 'ReportSidePanel' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

// TODO: Reduce number of flexboxes
const ReportSidePanel = ({ report, ...rest }) => {
  const { mutationApi } = useContext(ApiContext);
  const { t } = useTranslation();
  const {
    loaded: commentsLoaded,
    notFound,
    comments,
  } = usePrivateReportResources(report.pk);
  const { loaded: membersLoaded, members: senders } =
    useMessageSenders(comments);
  const organizationKey = useOrganizationKey();
  const currentUserRole = useSelector(currentUserRoleSelector(organizationKey));
  const currentUser = useSelector(currentUserSelector());
  const startRef = useRef(null);
  const endRef = useRef(null);
  const dispatch = useDispatch();
  const {
    logNewComment,
    logStatusChanged,
    logTagAdded,
    logTagRemoved,
    logDueDateChanged,
    logAssigneeAdded,
    logAssigneeRemoved,
    logDescriptionChanged,
  } = useContext(ActivityLogContext);
  const mailingList = [
    ...new Set(
      comments
        ?.filter((comment) => comment.ccEmailAddresses)
        ?.flatMap((comment) => comment.ccEmailAddresses),
    ),
  ];

  useEffect(() => {
    // Scroll to bottom only if a new comment is added. If the report argument has changed the scroll
    // will be reset to top.
    // if (!comments) {
    //     return;
    // }
    // if (report.pk !== report.pk) {
    //     scrollToView(startRef);
    //     report.pk = report.pk;
    //     return;
    // }
    scrollToView(endRef);
  }, [report.pk, comments?.length]);

  if (notFound) {
    return <Navigate to="/404" />;
  }

  const handleStatusChanged = async (newStatus) => {
    const data = { status: newStatus.sk };
    dispatch(
      addOrUpdateReport(report.pk, {
        ...data,
        closed: isClosedStatus(newStatus),
      }),
    );
    await mutationApi.updateReport(report.pk, data);
    logStatusChanged(report.pk, newStatus.sk);
  };

  const handleAssignedChanged = async (newValue) => {
    const data = { assigned: newValue };
    dispatch(addOrUpdateReport(report.pk, data));
    await mutationApi.updateReport(report.pk, data);

    const { added, removed } = getAddedRemovedItems(report.assigned, newValue);
    if (added.length > 0) {
      logAssigneeAdded(report.pk, added[0]);
    } else if (removed.length > 0) {
      logAssigneeRemoved(report.pk, removed[0]);
    }
  };

  const handleDueDateChanged = async (date) => {
    const data = { dueDate: date?.toISOString() ?? null };
    dispatch(addOrUpdateReport(report.pk, data));
    await mutationApi.updateReport(report.pk, data);
    logDueDateChanged(report.pk, data.dueDate);
  };

  const handleDescriptionChanged = async (value) => {
    const data = { description: value };
    dispatch(addOrUpdateReport(report.pk, data));
    await mutationApi.updateReport(report.pk, data);
    logDescriptionChanged(report.pk, value);
  };

  const handleTagsChanged = async (tagKeys) => {
    const data = { tags: tagKeys };
    dispatch(addOrUpdateReport(report.pk, data));
    await mutationApi.updateReport(report.pk, data);

    const { added, removed } = getAddedRemovedItems(report.tags, tagKeys);

    if (added.length > 0) {
      logTagAdded(report.pk, added[0]);
    } else if (removed.length > 0) {
      logTagRemoved(report.pk, removed[0]);
    }
  };

  // TODO: Add E2E encryption
  const handleCommentSubmit = async ({ messageContent, ccEmailAddresses }) => {
    const commentKey = newCommentKey();
    const createdAt = newAwsDateTime();
    const data = {
      content: messageContent,
      reportKey: report.sk,
      group: report.group,
      createdAt: createdAt,
      updatedAt: createdAt,
      senderKey: currentUser.pk,
      ccEmailAddresses: ccEmailAddresses,
    };
    dispatch(addOrUpdateComment(report.pk, commentKey, data));
    await mutationApi.createComment(
      report.organizationKey,
      report.pk,
      commentKey,
      messageContent,
      ccEmailAddresses,
    );

    logNewComment(report.pk, commentKey);
  };

  const getMessageSender = (comment) => {
    if (comment.email) {
      return {
        name: comment.email,
      };
    }

    return senders.find((member) => member.sk === comment.senderKey);
  };

  const renderChatMessage = (comment) => {
    if (isEventMessage(comment)) {
      return (
        <EventLogMessage
          {...comment}
          key={comment.sk}
          workspaceKey={report.workspaceKey}
          sx={{ my: 0.75 }}
        />
      );
    }

    const sender = getMessageSender(comment);
    return (
      <ChatMessage
        key={comment.sk}
        message={comment}
        sx={{ my: 2 }}
        avatar={
          <ResourceAvatar
            variant="circular"
            name={sender.name}
            picture={sender.picture}
            size={32}
          />
        }
        senderName={sender.name}
      />
    );
  };

  return (
    <RootDiv {...rest}>
      <div ref={startRef} />
      <Stack spacing={3} px={4} pt={3.75} overflow="auto" flex="1">
        {!isObserver(currentUserRole) && (
          <>
            <Typography variant="h6">{t('attributes')}</Typography>
            <AttributeField>
              <AttributeLabel id="status-field-label">
                {t('status')}
              </AttributeLabel>
              <StatusPicker
                labelId="status-field-label"
                value={report.status}
                workspaceKey={report.workspaceKey}
                onChange={handleStatusChanged}
                sx={{ minWidth: 120 }}
              />
            </AttributeField>
            <AttributeField>
              <AttributeLabel>{t('assigned')}</AttributeLabel>
              <UserPicker
                value={report.assigned ?? []}
                readOnly={currentUserRole === 'observer'}
                onChange={handleAssignedChanged}
              />
            </AttributeField>
            <AttributeField>
              <AttributeLabel>{t('dueDateFieldLabel')}</AttributeLabel>
              <DueDatePicker
                startDate={report.createdAt}
                value={report.dueDate}
                onChange={handleDueDateChanged}
              />
            </AttributeField>
            <AttributeField alignItems="flex-start">
              <AttributeLabel id="tag-field-label">
                {t('tagFieldLabel')}
              </AttributeLabel>
              <TagPicker
                value={report.tags ?? []}
                workspaceKey={report.workspaceKey}
                onChange={handleTagsChanged}
              />
            </AttributeField>
            <AttributeField alignItems="flex-start" sx={{ pb: 1 }}>
              <AttributeLabel>{t('description')}</AttributeLabel>
              <RichTextField
                fullWidth
                placeholder={`${t('addDescription')}...`}
                value={report.description}
                onChange={handleDescriptionChanged}
                sx={{
                  marginLeft: '-12px',
                  '&:after': {
                    borderStyle: 'none',
                  },
                  [`& .${FormattingToolbarClassName}`]: {
                    borderTopStyle: 'none',
                    visibility: 'hidden',
                    paddingTop: 0,
                    paddingBottom: 0.5,
                    background: 'background.paper',
                  },
                  '&:hover': {
                    '&:after': {
                      borderStyle: 'solid',
                    },
                  },
                  '&:focus-within': {
                    [`& .${FormattingToolbarClassName}`]: {
                      visibility: 'visible',
                    },
                  },
                }}
              />
            </AttributeField>
          </>
        )}

        <Stack>
          <Typography variant="h6">{t('comments')}</Typography>
          {commentsLoaded && membersLoaded ? (
            comments.map(renderChatMessage)
          ) : (
            <>
              <MessageSkeleton />
              <MessageSkeleton />
            </>
          )}
        </Stack>
      </Stack>
      <div ref={endRef} />
      <Box py={3} px={4}>
        <ChatInput
          placeholder={t('commentInputPlaceholder')}
          mailingList={mailingList}
          onSubmit={handleCommentSubmit}
        />
      </Box>
    </RootDiv>
  );
};

ReportSidePanel.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportSidePanel;
