import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18n } from 'aws-amplify';
import { DEFAULT_LANGUAGE, UI_LANGUAGES } from './config';
import translationEn from '../locales/en/translation.json';
import translationCs from '../locales/cs/translation.json';
import translationDe from '../locales/de/translation.json';
import translationSk from '../locales/sk/translation.json';
import translationPl from '../locales/pl/translation.json';
import translationHu from '../locales/hu/translation.json';
import translationFr from '../locales/fr/translation.json';
import translationEs from '../locales/es/translation.json';
import translationPt from '../locales/pt/translation.json';
import translationIt from '../locales/it/translation.json';
import translationNl from '../locales/nl/translation.json';
import translationDa from '../locales/da/translation.json';
import translationNo from '../locales/no/translation.json';
import translationFi from '../locales/fi/translation.json';
import translationSv from '../locales/sv/translation.json';
import translationRo from '../locales/ro/translation.json';
import translationSl from '../locales/sl/translation.json';
import translationHr from '../locales/hr/translation.json';
import translationLv from '../locales/lv/translation.json';
import translationEt from '../locales/et/translation.json';
import translationRu from '../locales/ru/translation.json';
import translationUk from '../locales/uk/translation.json';
import translationBg from '../locales/bg/translation.json';
import translationEl from '../locales/el/translation.json';
import translationVi from '../locales/vi/translation.json';
import translationZh from '../locales/zh/translation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEn,
  },
  cs: {
    translation: translationCs,
  },
  de: { translation: translationDe },
  sk: { translation: translationSk },
  pl: { translation: translationPl },
  hu: { translation: translationHu },
  fr: { translation: translationFr },
  es: { translation: translationEs },
  pt: { translation: translationPt },
  it: { translation: translationIt },
  nl: { translation: translationNl },
  da: { translation: translationDa },
  no: { translation: translationNo },
  fi: { translation: translationFi },
  sv: { translation: translationSv },
  ro: { translation: translationRo },
  sl: { translation: translationSl },
  hr: { translation: translationHr },
  lv: { translation: translationLv },
  et: { translation: translationEt },
  ru: { translation: translationRu },
  uk: { translation: translationUk },
  bg: { translation: translationBg },
  el: { translation: translationEl },
  vi: { translation: translationVi },
  zh: { translation: translationZh },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: UI_LANGUAGES,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    fallbackLng: DEFAULT_LANGUAGE,
    debug: true,
    resources: resources,
    interpolation: {
      escapeValue: false, // react already saves from xss,
    },
    ns: ['translation'],
    defaultNS: 'translation',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

// Amplify uses a different i18n instance
I18n.setLanguage(i18n.language);

export default i18n;
