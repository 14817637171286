import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, FormControl, FormHelperText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { defaultTitle } from "../../../utils/formBlockUtils";
import { styled } from "@mui/material/styles";
import AttachmentsWell from "./AttachmentsWell";
import { getFileKey } from "../../../utils/fileUtils";
import { removeItem, removeMatchingItem, updateMatchingItemField } from "../../../utils/arrayUtils";
import { MAX_ATTACHMENT_SIZE } from "../../../constants";
import AttachmentUploader from "../../AttachmentUploader";
import AttachmentsContainer from "../../AttachmentsContainer";
import ErrorDialog from "../../ErrorDialog";
import { Controller, useFormContext } from "react-hook-form";
import {
    answerRegistrationName,
    blockTemplateRegistrationName,
    getAnswersError,
    getBlockTemplate,
    getTranslations, translationsRegistrationName
} from '../utils';
import LocalAttachment from '../../LocalAttachment';
import S3Attachment from '../../S3Attachment';

const FormViewAttachmentsBlock = ({ blockIndex, language }) => {
    const { t } = useTranslation();
    const { getValues, formState } = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);
    // attachments uploading or uploaded during the existence of the FormViewAttachmentsBlock control
    const [localAttachments, setLocalAttachments] = useState([]);
    const [invalidFile, setInvalidFile] = useState("");
    const [highlighted, setHighlighted] = useState(false);
    const dragCounterRef = useRef(0);
    const errorText = getAnswersError(formState, blockTemplate.key)?.message;
    const translations = getTranslations(getValues);

    const handleDragEnter = (event) => {
        event.preventDefault();
        dragCounterRef.current++;
        if (dragCounterRef.current === 1) {
            setHighlighted(true);
        }
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        dragCounterRef.current--;
        if (dragCounterRef.current === 0) {
            setHighlighted(false);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        let files = event.dataTransfer.files;
        dragCounterRef.current = 0;
        setHighlighted(false);

        for (const file of files) {
            if (file.size > MAX_ATTACHMENT_SIZE) {
                setInvalidFile(file.name);
            } else {
                handleFileAdded(file);
            }
        }
    };

    const handleFileAdded = (file) => {
        const attachmentId = getFileKey(file);
        const isIncluded = localAttachments.some((attachment) => attachment.id === attachmentId);
        if (!isIncluded) {
            setLocalAttachments((prev) => [...prev, { file: file, id: attachmentId }]);
        }
    };

    const handleAttachmentUploaded = (formValue, attachment, key, onFormValueChange) => {
        setLocalAttachments((prev) => {
            return updateMatchingItemField(prev, (item) => item.id === attachment.id, "key", key);
        });

        const newValue = [...(formValue ?? []), key];
        invokeFormOnChange(newValue, onFormValueChange);
    };

    const handleAttachmentDeleted = (formValue, attachment, onFormValueChange) => {
        setLocalAttachments((prev) => {
            return removeMatchingItem(prev, (item) => item.id === attachment.id);
        });

        const s3Key = localAttachments.find((item) => item.id === attachment.id).key;
        deletePreviouslyUploadedAttachment(formValue, s3Key, onFormValueChange);
    };

    const deletePreviouslyUploadedAttachment = (formValue, removedS3Key, onFormValueChange) => {
        const newValue = removeItem(formValue ?? [], removedS3Key);
        invokeFormOnChange(newValue, onFormValueChange);
    };

    const invokeFormOnChange = (value, onFormValueChange) => {
        const event = { target: { name: blockTemplate.key, value: value } };
        onFormValueChange(event);
    };

    // TODO: Convert file size error dialog to component, used also in addAttachment button
    return (
        <FormControl
            fullWidth
            variant="standard"
            required={blockTemplate.required}
            error={Boolean(errorText)}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            sx={{
                ...(highlighted && {
                    borderColor: "primary.main",
                }),
            }}
        >
            <AttachmentsWell>
                <AttachmentUploader
                    language={language}
                    variant="standard"
                    component="div"
                    onFileAdded={handleFileAdded}
                >
                    <Button variant="contained" color="default" component="div">
                        {translations[blockTemplate.buttonText] ?? t("selectFiles", { lng: language })}
                    </Button>
                </AttachmentUploader>
                <Typography variant="body1">
                    {translations[blockTemplate.hintText] ?? t("dragDropHint", { lng: language })}
                </Typography>
                <Controller
                    name={answerRegistrationName(blockTemplate.key)}
                    rules={{ required: blockTemplate.required && "requiredFieldError" }}
                    render={({ field: { onChange, value } }) =>
                        localAttachments.length > 0 || value?.length > 0 ? (
                            <AttachmentsContainer sx={{ mt: 2, width: "100%" }}>
                                {localAttachments.map((attachment) => (
                                    <LocalAttachment
                                        key={attachment.id}
                                        file={attachment.file}
                                        onUploaded={(s3Key) =>
                                            handleAttachmentUploaded(value, attachment, s3Key, onChange)
                                        }
                                        onDeleted={() => handleAttachmentDeleted(value, attachment, onChange)}
                                    />
                                ))}
                                {value
                                    ?.filter((s3Key) => localAttachments.every((item) => item.key !== s3Key))
                                    .map((s3Key) => (
                                        <S3Attachment
                                            key={s3Key}
                                            s3Key={s3Key}
                                            canDelete
                                            // classes={{ imageAttachment: classes.imageAttachment }}
                                            onDelete={() => deletePreviouslyUploadedAttachment(value, s3Key, onChange)}
                                        />
                                    ))}
                            </AttachmentsContainer>
                        ) : (
                            <></>
                        )
                    }
                />
            </AttachmentsWell>
            {Boolean(errorText) && (
                <FormHelperText error variant="outlined" sx={{ position: "absolute", top: "100%", left: 0 }}>
                    {t(errorText, { lng: language })}
                </FormHelperText>
            )}
            <ErrorDialog
                open={Boolean(invalidFile)}
                title={t("cannotUploadAttachment", { fileName: invalidFile, lng: language })}
                text={t("attachmentSizeError", { lng: language })}
                onClose={() => setInvalidFile(null)}
            />
        </FormControl>
    );
};

FormViewAttachmentsBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
};

FormViewAttachmentsBlock.defaultProps = {};

export default FormViewAttachmentsBlock;
