import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { isImage } from '../../utils/fileUtils';
import S3Attachment from '../S3Attachment/S3Attachment';
import { split } from '../../utils/arrayUtils';

const MessageAttachments = ({ attachments, ...rest }) => {
    const { true: imageAttachments, false: fileAttachments } = split(attachments, isImage);

    return (
        <Box {...rest}>
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 0.5 }}>
                {imageAttachments.map((attachmentKey) => (
                    <S3Attachment key={attachmentKey} s3Key={attachmentKey} />
                ))}
                {fileAttachments.map((attachmentKey) => (
                    <S3Attachment key={attachmentKey} s3Key={attachmentKey} />
                ))}
            </Stack>
        </Box>
    );
};

MessageAttachments.propTypes = {
    attachments: PropTypes.array.isRequired,
};

MessageAttachments.defaultProps = {
};

export default MessageAttachments;
