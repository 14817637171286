import * as types from "./actionTypes";
import { toId } from "../../utils/apiUtils";
import { removeItems } from "../../utils/arrayUtils";

const initialState = {};

export default function languagePacksReducer(state = initialState, action) {
    switch (action.type) {
        case types.LANGUAGE_PACKS_LOADED: {
            const languagePacks = action.payload.languagePacks;
            const uniquePartitionKeys = [...new Set(languagePacks.map((res) => res.pk))];
            const newState = { ...state };
            for (const partitionKey of uniquePartitionKeys) {
                newState[partitionKey] = languagePacks.filter((res) => res.pk === partitionKey);
            }

            return newState;
        }
        case types.LANGUAGE_PACKS_ADDED: {
            const formKey = action.payload.formKey;
            const languagePacks = action.payload.languagePacks;

            const updatedLanguagePacks = [...(state[formKey] ?? [])];
            for (const languagePack of languagePacks) {
                // Add new languagePack to the state if it doesn't contain the item with the same SK
                // already. If it does, the state item will be replaced by the new languagePack.
                const index = updatedLanguagePacks.findIndex((item) => item.sk === languagePack.sk);
                if (index < 0) {
                    updatedLanguagePacks.push(languagePack);
                } else {
                    updatedLanguagePacks[index] = languagePack;
                }
            }

            const newState = { ...state };
            newState[formKey] = updatedLanguagePacks;
            return newState;
        }
        case types.LANGUAGE_PACK_UPDATED: {
            const pk = action.payload.pk;
            const sk = action.payload.sk;
            const newLanguagePacks = [...(state[pk] ?? [])]; // clone language pack of the specified form
            const index = newLanguagePacks.findIndex((item) => item.sk === sk);
            if (index < 0) {
                newLanguagePacks.push({ ...action.payload.data, pk: pk, sk: sk });
            } else {
                newLanguagePacks[index] = { ...newLanguagePacks[index], ...action.payload.data };
            }

            const newState = { ...state };
            newState[pk] = newLanguagePacks;
            return newState;
        }
        case types.LANGUAGE_PACK_DELETED: {
            const pk = action.payload.pk;
            const sk = action.payload.sk;
            const newState = { ...state };
            newState[pk] = state[pk].filter((t) => t.sk !== sk);
            return newState;
        }
        case types.LANGUAGE_PACKS_DELETED: {
            const workspaceId = toId(action.payload.workspaceKey);
            const keysToDelete = [];
            for (const pk in state) {
                const comment = state[pk][0];
                if (comment.group === workspaceId) {
                    keysToDelete.push(pk);
                }
            }

            const newState = { ...state };
            for (const pk of keysToDelete) {
                delete newState[pk];
            }
            return newState;
        }
        default:
            return state;
    }
}
