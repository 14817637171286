import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useOrganizationKey } from '../hooks/useOrganizationKey';
import { toId } from '../utils/apiUtils';
import { useSelector } from 'react-redux';
import { useWorkspaceKey } from '../hooks/useWorkspaceKey';
import { activeWorkspacesSelector } from '../state/workspaces/selectors';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useLocation } from 'react-router';
import { getWorkspaceRoute } from '../utils/routeUtils';
import { isNullOrEmpty } from '../utils/textUtils';

const WorkspaceRouter = () => {
  const { pathname } = useLocation();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const workspaces = useSelector(activeWorkspacesSelector(organizationKey));
  const workspaceRoute = getWorkspaceRoute(organizationKey, workspaceKey);
  const page = pathname.replace(`${workspaceRoute}/`, '').split('/')[0];
  const [activeTabs, setActiveTabs] = useLocalStorage(
    `${organizationKey}-tabs`,
    {},
  );

  useEffect(() => {
    if (!isNullOrEmpty(page) && page !== 'settings') {
      setActiveTabs((prev) => ({ ...prev, [workspaceKey]: page }));
    }
  }, [page]);

  if (!workspaces.some((workspace) => workspace.pk === workspaceKey)) {
    return <Navigate replace to={`/app/${toId(organizationKey)}/home`} />;
  }

  if (isNullOrEmpty(page) && activeTabs[workspaceKey]) {
    return (
      <Navigate
        replace
        to={`${getWorkspaceRoute(organizationKey, workspaceKey)}/${activeTabs[workspaceKey]}`}
      />
    );
  }

  return <Outlet />;
};

export default WorkspaceRouter;
