import {COUNTRIES} from "../constants/countries";


export function getCountriesForTimezone(timezone) {
    // Remove region from the timezone, e.g. Europe/Bratislava is converted to Bratislava
    const normalized = timezone.includes('/') ? timezone.split('/')[1] : timezone;
    const countries = [];
    for (const country of COUNTRIES) {
        if (country.timezones.includes(normalized)) {
            countries.push(country);
        }
    }

    return countries;
}

export function getCurrentCountry() {
    const supported = typeof Intl.DateTimeFormat !== 'undefined' && Intl.DateTimeFormat.prototype.resolvedOptions;
    if (!supported) {
        return undefined;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countries = getCountriesForTimezone(timezone);
    return Boolean(countries) ? countries[0] : undefined;
}