import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { clearState } from '../state/rootActions';
import { ApiContext } from './ApiContext';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { currentUserSelector } from '../state/currentUser/selectors';
import { isMembership, isUser, toId, toUserKey } from '../utils/apiUtils';
import { getCognitoGroups } from '../utils/user';
import { loadOrUpdateCurrentUser } from '../state/currentUser/actions';
import { loadMembers } from '../state/members/actions';
import { loadOrganizations } from '../state/organizations/actions';
import { API, Auth, Hub } from 'aws-amplify';
import Loader from '../components/Loader';
import { DeserializedPrivateResource } from '../types/api';

interface Auth {
  user: DeserializedPrivateResource;
  signOut: () => void;
  authenticated: boolean;
}

const AuthContext = createContext<Auth | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { queryApi, authApi } = useContext(ApiContext);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState<boolean | undefined>();
  const reduxUser = useSelector(currentUserSelector());
  const isFetchingRef = useRef(false);

  // Function to handle sign-out
  const signOut = useCallback(async () => {
    await authApi.signOut();
    navigate('/login');
    setAuthenticated(false);
    dispatch(clearState());
  }, []);

  const fetchAuthenticatedUser = async () => {
    isFetchingRef.current = true;
    const cognitoUser = await authApi.currentAuthenticatedUser();
    setAuthenticated(Boolean(cognitoUser));
    if (!cognitoUser) {
      isFetchingRef.current = false;
      return;
    }

    const userKey = toUserKey(cognitoUser.username);
    const resources = await queryApi.listPrivateResourcesByUserKey(userKey);
    const userResource = resources.find((res) => isUser(res));
    const memberships = resources.filter(
      (resource) => isMembership(resource) && !resource.disabled,
    );

    // Sometimes the current JWT token may not include all workspaces the user is member of in the
    // cognito:groups. This can happen if the user joins workspace on a different device. In that case
    // only application on that device would receive new token. The current application would keep using
    // the old token, since it is not expired. This would make the current application crash since it
    // wouldn't have rights to access this new workspace.
    const authenticatedGroups = getCognitoGroups(cognitoUser);
    const isOutdatedToken = memberships.some(
      (membership) => !authenticatedGroups.includes(toId(membership.pk)),
    );
    if (isOutdatedToken) {
      console.log('Auth token outdated');
      await authApi.refreshAccessToken();
    }

    // Retrieve organizations
    const fetchTasks = [];
    for (const membership of memberships) {
      fetchTasks.push(
        queryApi.getPrivateResource(membership.pk, membership.pk),
      );
    }

    if (userResource?.language) {
      console.log('Switching language to :%o', userResource.language);
      await i18n.changeLanguage(userResource.language);
    }

    const fetchResults = await Promise.all(fetchTasks);
    const organizations = fetchResults.flat();
    batch(() => {
      dispatch(loadOrUpdateCurrentUser({ ...userResource, ...cognitoUser }));
      dispatch(loadMembers(memberships));
      dispatch(loadOrganizations(organizations));
    });

    isFetchingRef.current = false;
  };

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        fetchAuthenticatedUser();
      }
    });
  }, []);

  useEffect(() => {
    if (!reduxUser && !isFetchingRef.current) {
      fetchAuthenticatedUser();
    }
  }, [reduxUser]);

  return (
    <AuthContext.Provider
      value={{
        user: reduxUser,
        signOut,
        authenticated: Boolean(authenticated),
      }}
    >
      {reduxUser || authenticated === false ? children : <Loader />}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};
