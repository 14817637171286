import { isValidEmail } from './textUtils';
import { Language } from '../i18n/config';
import { TFunction } from 'i18next/index';

export function validatePassword(password: string) {
  if (!Boolean(password)) {
    return 'passwordIsRequired';
  } else if (password.length < 8) {
    return 'passwordTooShort';
  } else {
    return null;
  }
}

// TODO: remove
export function validateEmail_old(
  email: string,
  t: TFunction,
  language?: Language,
) {
  if (!Boolean(email)) {
    return t('emailIsRequired', { lng: language });
  } else if (!isValidEmail(email)) {
    return t('emailIsInvalid', { lng: language });
  } else {
    return null;
  }
}

export function validateEmail(email: string) {
  if (!Boolean(email)) {
    return 'emailIsRequired';
  } else if (!isValidEmail(email)) {
    return 'emailIsInvalid';
  } else {
    return null;
  }
}
