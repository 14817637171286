import * as queries from '../graphql/queries';
import { API } from 'aws-amplify';
import {
  ActivityLogEntry,
  CheckInviteeRegistrationStatusQuery,
  CheckUserExistsQuery,
  GetPrivateResourceQuery,
  GetPublicResourceQuery,
  GetResourcePasswordQuery,
  ListActivityLogEntriesQuery,
  ListPrivateResourcesQuery,
  ListPublicResourcesQuery,
  Partner,
  PartnersByAppUrlQuery,
  PrivateResource,
  PrivateResourcesByOrganizationKeyQuery,
  PrivateResourcesByUserKeyQuery,
  PublicResource,
  PublicResourcesByOrganizationKeyQuery,
  PublicResourcesByPathQuery,
  ResourcePassword,
  ResourcePasswordsByPasswordQuery,
  TranslateLanguagePackQuery,
  VerifyCaptchaTokenQuery,
} from '../API';
import { deserializeObject } from './serializationUtils';
import {
  DeserializedPartner,
  DeserializedPrivateResource,
  DeserializedPublicResource,
} from '../types/api';

const privateAuthMode = 'AMAZON_COGNITO_USER_POOLS';
const publicAuthMode = 'AWS_IAM';

export default class QueryApi {
  static async checkUserExists(email: string) {
    const query = (await API.graphql({
      query: queries.checkUserExists,
      variables: { input: { email } },
      authMode: publicAuthMode,
    })) as { data: CheckUserExistsQuery };

    return query.data.checkUserExists;
  }

  static async verifyCaptchaToken(token: string) {
    const query = (await API.graphql({
      query: queries.verifyCaptchaToken,
      variables: { input: { token } },
      authMode: publicAuthMode,
    })) as { data: VerifyCaptchaTokenQuery };

    return query.data.verifyCaptchaToken;
  }

  static async getPrivateResource(pk: string, sk: string) {
    const query = (await API.graphql({
      query: queries.getPrivateResource,
      variables: { pk: pk, sk: sk },
      authMode: privateAuthMode,
    })) as { data: GetPrivateResourceQuery };

    return deserializeObject(query.data.getPrivateResource as PrivateResource);
  }

  static async listPrivateResources(pk: string) {
    let resources: DeserializedPrivateResource[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.listPrivateResources,
        variables: { pk, nextToken },
        authMode: privateAuthMode,
      })) as { data: ListPrivateResourcesQuery };
      const responseData = response?.data.listPrivateResources;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((resource) =>
          deserializeObject(resource as PrivateResource),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async listActivityLogEntries(
    pk: string,
    sk?: string,
    limit?: number,
    nextToken?: string,
  ) {
    const response = (await API.graphql({
      query: queries.listActivityLogEntries,
      variables: { pk, sk, limit, nextToken, sortDirection: 'DESC' },
      authMode: privateAuthMode,
    })) as { data: ListActivityLogEntriesQuery };
    const responseData = response?.data.listActivityLogEntries;
    const items = await Promise.all(
      (responseData?.items || []).map((entry) =>
        deserializeObject(entry as ActivityLogEntry),
      ),
    );

    return { items, nextToken: responseData?.nextToken };
  }

  static async listPublicResources(pk: string) {
    let resources: DeserializedPublicResource[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.listPublicResources,
        variables: { pk, nextToken },
        authMode: publicAuthMode,
      })) as { data: ListPublicResourcesQuery };
      const responseData = response?.data.listPublicResources;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((resource) =>
          deserializeObject(resource as PublicResource),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async listPrivateResourcesByOrganizationKey(organizationKey: string) {
    let resources: DeserializedPrivateResource[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.privateResourcesByOrganizationKey,
        variables: { organizationKey, nextToken },
        authMode: privateAuthMode,
      })) as { data: PrivateResourcesByOrganizationKeyQuery };
      const responseData = response?.data.privateResourcesByOrganizationKey;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((resource) =>
          deserializeObject(resource as PrivateResource),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async listPublicResourcesByOrganizationKey(organizationKey: string) {
    let resources: DeserializedPublicResource[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.publicResourcesByOrganizationKey,
        variables: { organizationKey, nextToken },
        authMode: publicAuthMode,
      })) as { data: PublicResourcesByOrganizationKeyQuery };
      const responseData = response?.data.publicResourcesByOrganizationKey;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((resource) =>
          deserializeObject(resource as PublicResource),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async listPrivateResourcesByUserKey(userKey: string) {
    let resources: DeserializedPrivateResource[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.privateResourcesByUserKey,
        variables: { userKey, nextToken },
        authMode: privateAuthMode,
      })) as { data: PrivateResourcesByUserKeyQuery };
      const responseData = response?.data.privateResourcesByUserKey;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((resource) =>
          deserializeObject(resource as PrivateResource),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async getPublicResource(pk: string, sk: string) {
    const query = (await API.graphql({
      query: queries.getPublicResource,
      variables: { pk: pk, sk: sk },
      authMode: publicAuthMode,
    })) as { data: GetPublicResourceQuery };

    return await deserializeObject(
      query.data.getPublicResource as PublicResource,
    );
  }

  static async listPartnersByAppUrl(url: string) {
    let resources: DeserializedPartner[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.partnersByAppUrl,
        variables: { appUrl: url, nextToken },
        authMode: publicAuthMode,
      })) as { data: PartnersByAppUrlQuery };
      const responseData = response?.data.partnersByAppUrl;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((partner) =>
          deserializeObject(partner as Partner),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async listPublicResourcesByPath(path: string) {
    let resources: DeserializedPublicResource[] = [];
    let nextToken;

    do {
      const response = (await API.graphql({
        query: queries.publicResourcesByPath,
        variables: { path, nextToken },
        authMode: publicAuthMode,
      })) as { data: PublicResourcesByPathQuery };
      const responseData = response?.data.publicResourcesByPath;
      nextToken = responseData?.nextToken;
      const responseResources = await Promise.all(
        (responseData?.items || []).map((resource) =>
          deserializeObject(resource as PublicResource),
        ),
      );
      resources = resources.concat(responseResources);
    } while (nextToken);

    return resources;
  }

  static async getResourcePasswordByPassword(password: string) {
    const query = (await API.graphql({
      query: queries.resourcePasswordsByPassword,
      variables: { password },
      authMode: publicAuthMode,
    })) as { data: ResourcePasswordsByPasswordQuery };

    const items = query.data.resourcePasswordsByPassword?.items;
    if (!items || items.length === 0) {
      return null;
    }
    return items[0] as ResourcePassword;
  }

  static async getResourcePassword(resourceKey: string) {
    const query = (await API.graphql({
      query: queries.getResourcePassword,
      variables: { pk: resourceKey, sk: resourceKey },
      authMode: publicAuthMode,
    })) as { data: GetResourcePasswordQuery };

    return query.data.getResourcePassword as ResourcePassword;
  }

  static async translateLanguagePack(
    organizationKey: string,
    formKey: string,
    content: {},
    sourceLanguage: string,
    targetLanguages: string[],
  ) {
    const input = {
      organizationKey: organizationKey,
      formKey: formKey,
      content: JSON.stringify(content),
      sourceLanguage: sourceLanguage,
      targetLanguages: targetLanguages,
    };
    const query = (await API.graphql({
      query: queries.translateLanguagePack,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: TranslateLanguagePackQuery };

    //return JSON.parse(query.data?.translateLanguagePack ?? "[]");
    return await Promise.all(
      (query.data.translateLanguagePack?.items || []).map((item) =>
        deserializeObject(item as PublicResource),
      ),
    );
  }

  static async checkInviteeRegistrationStatus(
    organizationKey: string,
    invitationKey: string,
  ) {
    const input = {
      organizationKey: organizationKey,
      invitationKey: invitationKey,
    };
    const query = (await API.graphql({
      query: queries.checkInviteeRegistrationStatus,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: CheckInviteeRegistrationStatusQuery };

    return query.data.checkInviteeRegistrationStatus;
  }
}
