import React, { useEffect, useRef } from "react";
import { InputAdornment, TextField } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const RootTextField = styled(TextField, {
    name: "FormEditorTextField",
    shouldForwardProp: (prop) => prop !== "textAlign" && prop !== "textVariant",
})(({ theme, textAlign, textVariant, fullWidth }) => ({
    "&& .MuiInputBase-input": {
        ...theme.typography[textVariant],
    },
    ...(textAlign === "center" && {
        "& .MuiInputBase-input": {
            textAlign: "center",
            paddingLeft: 36, // width of the end adornment
            paddingRight: 8, // width of the end adornment
        },
    }),
    ...(!fullWidth && {
        "&::after": {
            content: "attr(data-value)",
            whiteSpace: "pre-wrap",
            paddingLeft: textAlign === "center" ? 36 : 4,
            paddingRight: textAlign === "center" ? 36 : 4,
            visibility: "hidden",
            ...theme.typography[textVariant],
            lineHeight: 0, // to collapse vertical space taken by :after, must be placed after theme.typography.h5 to override system lineHeight
        },
    }),
    "&:focus-within": {
        background: "background.paper",
        "& .MuiInputAdornment-root": {
            visibility: "hidden",
        },
    },
}));

const EditorTextField = React.forwardRef((props, ref) => {
    const { defaultValue, value, textAlign, textVariant, fullWidth, placeholder, onChange, ...rest } = props;
    const fieldRef = useRef(null);
    const timerRef = useRef();

    useEffect(() => {
        if (!fullWidth) {
            adjustTextFieldWidth(value ?? defaultValue);
        }
    }, [value, defaultValue]);

    const resetTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    const handleChange = (event) => {
        if (!fullWidth) {
            adjustTextFieldWidth(event.target.value);
        }

        onChange(event);
        //resetTimer();
        //timerRef.current = setTimeout(() => onChange(event), DONE_TYPING_INTERVAL);
    };

    const adjustTextFieldWidth = (newValue) => {
        let datasetValue = Boolean(newValue) ? newValue : placeholder;
        fieldRef.current.dataset.value = datasetValue;
    };

    return (
        <RootTextField
            {...rest}
            textAlign={textAlign}
            textVariant={textVariant}
            fullWidth={fullWidth}
            ref={fieldRef}
            inputRef={ref}
            variant="standard"
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            inputProps={{
                size: "1",
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <EditOutlinedIcon fontSize="small" />
                    </InputAdornment>
                ),
            }}
            onChange={handleChange}
        />
    );
});

EditorTextField.propTypes = {
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    textAlign: PropTypes.oneOf(["left", "center"]),
    textVariant: PropTypes.oneOf([
        "h6",
        "h5",
        "h4",
        "h3",
        "h2",
        "h1",
        "body1",
        "body2",
        "subtitle1",
        "subtitle2",
        "caption",
        "buttonSmall",
        "buttonMedium",
        "buttonLarge",
    ]),
    onChange: PropTypes.func,
};

EditorTextField.defaultProps = {
    textAlign: "left",
    textVariant: "body1",
    onChange: () => {},
};

export default EditorTextField;
