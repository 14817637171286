import React from 'react';
import PropTypes from 'prop-types';
import {
  Fade,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { groupBy } from '../../utils/arrayUtils';
import PopperMenu from '../PopperMenu';
import { useSelector } from 'react-redux';
import { allMembersSelector } from '../../state/members/selectors';
import { useParams } from 'react-router-dom';
import { toWorkspaceKey } from '../../utils/apiUtils';
import clsx from 'clsx';

const AtMentionMenu = ({
  open,
  anchorEl,
  offset,
  selectedKey,
  items,
  onClick,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const grouped = groupBy(items, 'group');

  const preventDefault = (e) => {
    e.preventDefault(); // prevents control from taking focus from rich editor
  };

  return (
    <PopperMenu
      disableAutoFocusItem
      disablePortal
      id="at-mention-menu"
      placement="top-start"
      open={open}
      anchorEl={anchorEl}
      offset={offset}
      onClose={onClose}
      onMouseDown={preventDefault}
      PaperProps={{
        sx: {
          width: 360,
        },
      }}
      sx={{
        zIndex: 'modal',
      }}
      {...rest}
    >
      {!items || items.length === 0 ? (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            p: 1,
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        >
          {t('noMatchesFound')}
        </Typography>
      ) : (
        Object.keys(grouped).map((group) => [
          <MenuItem
            key={`atMentionMenuGroup-${group}`}
            disableRipple
            disableTouchRipple
            sx={{
              cursor: 'initial',
              '&:hover': {
                background: 'initial',
              },
            }}
          >
            <Typography variant="subtitle1">{group}</Typography>
          </MenuItem>,
          grouped[group].map((item) => (
            <MenuItem
              key={`atMentionMenuItem-${item.key}}`}
              selected={item.key === selectedKey}
              onClick={() => onClick(item)}
            >
              {item.component}
            </MenuItem>
          )),
        ])
      )}
    </PopperMenu>
  );
};

AtMentionMenu.propTypes = {
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  top: PropTypes.number,
  left: PropTypes.number,
  selectedKey: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      group: PropTypes.string.isRequired,
      component: PropTypes.object.isRequired,
    }),
  ),
  onClick: PropTypes.func,
};

AtMentionMenu.defaultProps = {
  top: 0,
  left: 0,
  selectedKey: '',
  items: [],
  onClose: () => {},
  onClick: () => {},
};

export default AtMentionMenu;
