import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { useDispatch, useSelector } from 'react-redux';
import { resourcePasswordSelector } from '../state/resourcePasswords/selectors';
import { addOrUpdateResourcePassword } from '../state/resourcePasswords/actions';

export const useResourcePassword = (resourceKey) => {
    const {queryApi} = useContext(ApiContext);
    const dispatch = useDispatch();
    const [notFound, setNotFound] = useState(false);
    const resourcePassword = useSelector(resourcePasswordSelector(resourceKey));
    const loaded = Boolean(resourcePassword);

    useEffect(() => {
        let isMounted = true;
        if (!resourceKey || loaded) {
            return;
        }

        const fetch = async () => {
            const resourcePassword = await queryApi.getResourcePassword(resourceKey);
            if (!resourcePassword) {
                isMounted && setNotFound(true);
                return;
            }

            dispatch(addOrUpdateResourcePassword(resourceKey, resourcePassword));
            isMounted && setNotFound(false);
        }

        fetch();

        return () => {
            isMounted = false
        }
    }, [resourceKey]);

    return { loaded, notFound, resourcePassword };
}

