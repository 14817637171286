import { useEffect, useState } from 'react';
import { isWhitelabelOrigin } from '../utils/appUtils';
import QueryApi from '../api/QueryApi';
import defaultTheme from '../theme';
import { createTheme } from '@mui/material';

export interface Whitelabel {
  pk: string;
  name: string | null | undefined;
  favIcon: string | null | undefined;
  picture: string | null | undefined;
  termsOfServiceUrl: string | null | undefined;
  privacyPolicyUrl: string | null | undefined;
  appUrl: string | null | undefined;
}

export const useWhitelabel = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [whitelabel, setWhitelabel] = useState<Whitelabel | undefined>(
    undefined,
  );
  const [theme, setTheme] = useState(defaultTheme);

  console.log('useWhitelabel, loaded: %o, whitelabel: %o', loaded, whitelabel);

  useEffect(() => {
    if (loaded || !isWhitelabelOrigin()) {
      setLoaded(true);
      return;
    }

    (async () => {
      const partner = (
        await QueryApi.listPartnersByAppUrl(window.location.origin)
      )[0];
      if (!partner) {
        setWhitelabel(undefined);
        setLoaded(true);
        return;
      }

      setWhitelabel({
        pk: partner.pk,
        name: partner.name,
        favIcon: partner.faviconUrl,
        picture: partner.picture,
        termsOfServiceUrl: partner.termsOfServiceUrl,
        privacyPolicyUrl: partner.privacyPolicyUrl,
        appUrl: partner.appUrl,
      });

      if (partner.colors) {
        const expandedPalette: any = {};
        if (partner.colors.primary) {
          expandedPalette.primary = theme.palette.augmentColor({
            color: { main: partner.colors.primary },
          });
        }
        if (partner.colors.secondary) {
          expandedPalette.secondary = theme.palette.augmentColor({
            color: { main: partner.colors.secondary },
          });
        }
        if (partner.colors.sidebarPrimaryBackground) {
          expandedPalette.background = {
            ...expandedPalette.background,
            sidebarPrimary: partner.colors.sidebarPrimaryBackground,
          };
        }
        if (partner.colors.sidebarSecondaryBackground) {
          expandedPalette.background = {
            ...expandedPalette.background,
            sidebarSecondary: partner.colors.sidebarSecondaryBackground,
          };
        }
        const newTheme = createTheme(defaultTheme, {
          palette: expandedPalette,
        });
        setTheme(newTheme);
      }

      setLoaded(true);
    })();
  }, []);

  return { loaded, whitelabel, theme };
};
