import React from 'react';
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { allOrganizationsSelector } from '../../state/organizations/selectors';
import AddIcon from '@mui/icons-material/Add';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { clearState } from '../../state/rootActions';
import { isWhitelabelOrigin } from '../../utils/appUtils';
import { getDefaultAppUrl } from '../../utils/routeUtils';
import { toId } from '../../utils/apiUtils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ResourceAvatar from '../../components/ResourceAvatar';
import Menu from '@mui/material/Menu';

import ChevronUpDownIcon from '../../icons/ChevronUpDownIcon';

const RootBox = styled(Box, { name: 'OrganizationSelect' })(
  ({ theme }) => ({}),
);

const OrganizationPicker = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const organizations = useSelector(allOrganizationsSelector());
  const activeOrganization = organizations.find(
    (org) => org.pk === organizationKey,
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleAddOrganizationClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/app/onboarding`);
  };

  const handleOrganizationSelected = (newValue) => {
    if (newValue === organizationKey) {
      return;
    }

    dispatch(clearState());
    const newOrganization = organizations.find(
      (organization) => organization.pk === newValue,
    );
    // Some whitelabel customers may have mutliple organizations with the same path. In that case we should
    // just use React Router to change location
    if (
      isWhitelabelOrigin() &&
      newOrganization.path !== activeOrganization.path
    ) {
      window.location.assign(
        `${getDefaultAppUrl()}/app/${toId(newValue)}/home`,
      );
    } else {
      navigate(`/app/${toId(newValue)}/home`);
    }
  };

  return (
    <RootBox {...props}>
      <Button
        fullWidth
        component="div"
        id="organizationdemo-positioned-button"
        aria-controls={open ? 'organization-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="large"
        color="default"
        startIcon={
          <ResourceAvatar
            variant="circular"
            name={activeOrganization.name}
            picture={activeOrganization.picture}
          />
        }
        endIcon={<ChevronUpDownIcon />}
        sx={{
          textTransform: 'none',
          px: 1.5,
        }}
      >
        <Stack flex="1 1 auto">
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ lineHeight: 1.5 }}
          >
            {t('organization')}
          </Typography>
          <Typography variant="subtitle1" sx={{ lineHeight: 1.5 }}>
            {activeOrganization.name}
          </Typography>
        </Stack>
      </Button>
      <Menu
        id="organization-menu"
        aria-labelledby="organization-button"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {[
          ...(organizations.length < 9
            ? [
                <MenuItem
                  key="addOrgButton"
                  onClick={handleAddOrganizationClick}
                >
                  <ListItemIcon>
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('add')}</ListItemText>
                </MenuItem>,
                <Divider key="addOrgDivider" />,
              ]
            : []),
          ...organizations.map((org) => (
            <MenuItem
              key={org.pk}
              selected={org.pk === activeOrganization.pk}
              onClick={() => handleOrganizationSelected(org.pk)}
            >
              <ResourceAvatar
                name={org.name}
                picture={org.picture}
                variant="circular"
                sx={{
                  ml: -0.5,
                  mr: 1,
                }}
              />
              <Typography variant="subtitle1">{org.name}</Typography>
            </MenuItem>
          )),
        ]}
      </Menu>
    </RootBox>
  );
};

OrganizationPicker.propTypes = {};

OrganizationPicker.defaultProps = {};

export default OrganizationPicker;
