import React from 'react';
import { Backdrop, Box, Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DescriptionIcon from '@mui/icons-material/Description';
import { isAudio, isImage } from '../../utils/fileUtils';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import AttachmentDeleteButton from '../LocalAttachment/AttachmentDeleteButton';
import { styled } from '@mui/material/styles';
import { ThumbnailHeight, ThumbnailMaxWidth } from '../../constants';

const classNames = {
    "root": "AttachmentPreview-root",
    "image": "AttachmentPreview-image",
    "file": "AttachmentPreview-file",
    "audio": "AttachmentPreview-audio",
};

const RootBox = styled("div", { name: "AttachmentPreview"})(({ theme, size }) => ({
}));

const FileAttachmentStack = styled(Stack)(({ theme }) => ({
    height: 64,
    borderRadius: theme.shape.borderRadius,
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    padding: theme.spacing(1),
}));

const AttachmentPreview = ({ name, url, canDelete, onDownload, onDelete }) => {
    //const attachmentName = url.split('/')?.pop();
    const [hover, setHover] = React.useState(false);

    return (
        <RootBox>
            {isImage(name) && (
                <Box
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    className={classNames.image}
                    sx={{
                        display: "inline-block",
                        maxWidth: 160,
                        minWidth: 40,
                        height: 64,
                        position: "relative",
                        zIndex: 0,
                        "&>img": {
                            maxHeight: "100%",
                            maxWidth: "100%",
                            borderRadius: 1,
                        },
                    }}
                >
                    <img src={url} />
                    <Link href={url} target="_blank" download onClick={onDownload} underline="hover">
                        <Backdrop open={hover} sx={{ position: "absolute", zIndex: 1, borderRadius: 1 }} />
                    </Link>
                    {canDelete && (
                        <AttachmentDeleteButton
                            visible={hover}
                            color="inherit"
                            sx={{
                                position: "absolute",
                                top: "50%",
                                right: 0,
                                transform: "translate(0, -50%)",
                                zIndex: 2,
                                color: "primary.contrastText",
                            }}
                            onClick={onDelete}
                        />
                    )}
                </Box>
            )}
            {isAudio(name) && (
                <FileAttachmentStack direction="row" alignItems="center" className={classNames.audio}>
                    <AudioPlayer src={url} controlsList="nodownload noplaybackrate" />
                    {canDelete && <AttachmentDeleteButton onClick={onDelete} />}
                </FileAttachmentStack>
            )}
            {!isImage(name) && !isAudio(name) && (
                <FileAttachmentStack direction="row" alignItems="center" className={classNames.file}>
                    <Link href={url} underline="none" color="textPrimary" target="_blank" download onClick={onDownload}>
                        <Typography variant="body2" noWrap>
                            <DescriptionIcon sx={{ width: 40, height: 40, color: "grey.400", marginRight: 1 }} />
                            {name}
                        </Typography>
                    </Link>
                    {canDelete && <AttachmentDeleteButton onClick={onDelete} />}
                </FileAttachmentStack>
            )}
        </RootBox>
    )
};

AttachmentPreview.propTypes = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    downloadUrl: PropTypes.string,
    imageShowName: PropTypes.bool,
    onDownload: PropTypes.func,
};

AttachmentPreview.defaultProps = {
    onDownload: () => {},
};

export default AttachmentPreview;
