import { s16 } from './idGenerator';

// TODO: Move to idUtils class
export const toAdminsGroup = (workspaceId) => {
  return `admin#${workspaceId}`;
};

export const toWorkspaceKey = (id) => {
  return Boolean(id) ? `space#${id}` : null;
};

export const toOrganizationKey = (id) => {
  return Boolean(id) ? `org#${id}` : null;
};

export const toReportKey = (id) => {
  return Boolean(id) ? `report#${id}` : null;
};

export const toMessageKey = (id) => {
  return `message#${id}`;
};

export const toCommentKey = (id) => {
  return `comment#${id}`;
};

export const toUserKey = (id) => {
  return `user#${id}`;
};

export const toFormKey = (id) => {
  return `form#${id}`;
};

export const toLanguagePackKey = (language) => {
  return `languagePack#${language}`;
};

export const toInvitationKey = (id) => {
  return `invitation#${id}`;
};

export const toId = (key) => {
  return key.split('#')[1];
};

export const isUser = (resource) => {
  return resource?.pk.startsWith('user#') && resource.pk === resource.sk;
};

export const isWorkspace = (resource) => {
  return resource?.pk.startsWith('space#') && resource.pk === resource.sk;
};

export const isPartner = (resource) => {
  return resource?.pk.startsWith('partner#');
};

export const isOrganization = (resource) => {
  return resource?.pk.startsWith('org#') && resource.pk === resource.sk;
};
export const isMembership = (resource) => {
  return resource?.pk.startsWith('org#') && resource.sk.startsWith('user#');
};

export const isReport = (resource) => {
  return resource?.pk.startsWith('report#') && resource.pk === resource.sk;
};

export const isMessage = (resource) => {
  return resource?.sk.startsWith('message#');
};

export const isComment = (resource) => {
  return resource?.sk.startsWith('comment#');
};

export const isStatus = (resource) => {
  return resource?.sk.startsWith('status#');
};

export const isTag = (resource) => {
  return resource?.sk.startsWith('tag#');
};
export const isForm = (resource) => {
  return resource?.pk.startsWith('form#') && resource.pk === resource.sk;
};

export const isEmail = (resource) => {
  return resource?.pk.startsWith('email#') && resource.pk === resource.sk;
};

export const isVoicemail = (resource) => {
  return resource?.pk.startsWith('voicemail#') && resource.pk === resource.sk;
};

export const isInvitation = (resource) => {
  return resource?.sk.startsWith('invitation#');
};

export const isPolicy = (resource) => {
  return resource?.sk.startsWith('policy#');
};

export const isLanguagePack = (resource) => {
  return resource?.sk.startsWith('languagePack#');
};

export const isFormKey = (key) => key.startsWith('form#');

export const isWorkspaceKey = (key) => key.startsWith('space#');

export const isOpeningStatus = (key) => key === 'status#open';

export function newCommentKey() {
  return `comment#${s16()}`;
}

export function newStatusKey() {
  return `status#${s16()}`;
}

export function newTagKey() {
  return `tag#${s16()}`;
}

export function newMessageKey() {
  return `message#${s16()}`;
}

export function newInvitationKey() {
  return `invitation#${s16()}`;
}

export function removePrivateFields(query) {
  // Remove any fields starting with 'private' from the query string while preserving the JSON formatting.
  return query.replace(/\s\sprivate.*\s+/g, '');
}
