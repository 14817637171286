import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import NavbarItem from './NavbarItem';
import { NavLink } from 'react-router-dom';

const NavbarLinkItem = ({ path, activeMask, ...rest }) => {
    const location = useLocation();
    const isSelected = location.pathname.startsWith(activeMask ?? path);

    return (
        <NavbarItem selected={isSelected} ButtonProps={{component: NavLink, to: path}} {...rest}/>
    );
};

NavbarLinkItem.propTypes = {
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    activeMask: PropTypes.string,
    icon: PropTypes.object,
};

NavbarLinkItem.defaultProps = {};

export default NavbarLinkItem;
