import React, { useContext } from 'react';
import {
  useTheme,
  MenuList,
  MenuItem,
  Avatar,
  Box,
  Tooltip,
  IconButton,
  Popover,
  Popper,
  alpha,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { Autocomplete } from '@mui/lab';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import ResourceAvatar from './ResourceAvatar';
import DenseListItemText from './DenseListItemText';
import { getSmallPictureVariant } from '../utils/avatarUtils';

const useStyles = makeStyles((theme) => ({
  userMenuRoot: {
    minWidth: theme.spacing(80),
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
  },
  popperDisablePortal: {
    position: 'relative',
    width: 'initial!important',
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  readOnlyItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
}));

const UserMenu = ({
  className,
  open,
  anchorEl,
  users,
  readOnly,
  onClose,
  onUserSelected,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    onClose();
  };

  // TODO: Use new Menu component
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id="user-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        '& .MuiMenu-paper': {
          minWidth: 200,
        },
      }}
    >
      {!users || users.length === 0 ? (
        <MenuItem disabled>
          <ListItemText
            primary={t('noMatchesFound')}
            primaryTypographyProps={{ textAlign: 'center' }}
          />
        </MenuItem>
      ) : (
        users.map((user) => (
          <MenuItem
            key={`userMenuItem-${user.sk}}`}
            onClick={() => !readOnly && onUserSelected(user)}
          >
            <ListItemAvatar>
              <ResourceAvatar
                name={user.name}
                picture={user.picture}
                variant="circular"
              />
            </ListItemAvatar>
            <DenseListItemText
              primary={user.name}
              secondary={user.role ? t(user.role) : undefined}
            />
          </MenuItem>
        ))
      )}
    </Menu>
  );
};

UserMenu.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  anchorEl: PropTypes.object,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      picture: PropTypes.string,
      role: PropTypes.string,
    }),
  ),
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
  onUserSelected: PropTypes.func,
};

UserMenu.defaultProps = {
  onClose: () => {},
  onUserSelected: () => {},
};

export default UserMenu;
