import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { WhitelabelContext } from '../contexts/WhitelabelContext';
import { isWhitelabelOrigin } from '../utils/appUtils';
import NotFoundView from '../views/errors/NotFoundView';

const PublicRouter = () => {
    const whitelabel = useContext(WhitelabelContext);
    const whitelabelOrigin = isWhitelabelOrigin();

    // Return NotFound if whitelabel is no longer available for the current origin
    if (whitelabelOrigin && !whitelabel) {
        return <NotFoundView/>;
    }

    return <Outlet/>
};

export default PublicRouter;
