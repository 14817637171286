import React, { useState } from 'react';
import { Divider, ListItemIcon, ListItemText, MenuItem, Select, Typography } from '@mui/material';
import AttributeField from "./AttributeField";
import PropTypes from "prop-types";
import Status from '../../components/Status';
import StatusCustomizationDialog from './StatusCustomizationDialog';
import { useSelector } from 'react-redux';
import { activeWorkspaceStatusesSelector, workspaceStatusesSelector } from '../../state/statuses/selectors';
import { workspaceSelector } from '../../state/workspaces/selectors';
import { split } from '../../utils/arrayUtils';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

// TODO: Statuses should not be undefined, remove null check
const StatusPicker = ({ value, workspaceKey, onChange, ...rest }) => {
    const { t } = useTranslation();
    const [customizationDialogOpen, setCustomizationDialogOpen] = useState(false);
    const statuses = useSelector(activeWorkspaceStatusesSelector(workspaceKey));
    const workspace = useSelector(workspaceSelector(workspaceKey));

    const statusKeys = workspace.statuses;
    const orderedStatuses = statusKeys.map((key, i) => statuses?.find((status) => status.sk === key)).filter(Boolean);
    const normalizedValue = statuses?.find(status => status.sk === value)?.sk ?? "";

    const handleChange = async (event) => {
        if (!event.target.value) {
            // Early exit if user clicked on the Customize menu item
            return;
        }
        console.log("Status picker change, normalizedValue: %o, event: %o", normalizedValue, event);
        onChange(statuses.find(status => status.sk === event.target.value));
    };

    const handleCustomizeButtonClick = () => {
        setCustomizationDialogOpen(true);
    }

    return (
        <>
            <Select
                displayEmpty
                value={normalizedValue}
                disableUnderline
                variant="standard"
                onChange={handleChange}
                renderValue={(selectedKey) => {
                    const status = statuses?.find((item) => item.sk === selectedKey);
                    return Boolean(status) ? (
                        <Status workspaceKey={status.pk} statusKey={status.sk} />
                    ) : (
                        <Typography color='textSecondary' sx={{py: 0.25}}>{t("notSelected")}</Typography>
                    )
                }}
                MenuProps={{
                    autoFocus: false
                }}
                {...rest}
            >
                {orderedStatuses.map((item) => (
                    <MenuItem key={item.sk} value={item.sk}>
                        <Status workspaceKey={item.pk} statusKey={item.sk} sx={{ pointerEvents: "none" }} />
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem onClick={handleCustomizeButtonClick}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('customize')}</ListItemText>
                </MenuItem>
            </Select>
            <StatusCustomizationDialog open={customizationDialogOpen} workspaceKey={workspaceKey} onClose={() => setCustomizationDialogOpen(false)}/>
        </>
    );
};

StatusPicker.propTypes = {
    value: PropTypes.string,
    workspaceKey: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

StatusPicker.defaultProps = {
    statuses: [],
    value: "",
    onChange: () => {},
};

export default StatusPicker;
