import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography, Skeleton
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    messageSkeleton: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: props => props.position === "right" ? "row": "row-reverse",
        alignItems: "flex-start"
    },
    messageContainer: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        alignItems: props => props.position === "right" ? "flex-end" : "flex-start"
    },
    avatar: {
        marginRight: props => props.position === "left" ? theme.spacing(1) : 0,
        marginLeft: props => props.position === "right" ? theme.spacing(1) : 0,
    }
}));

const MessageSkeleton = ({className, position}) => {
    const classes = useStyles({position});

    return (
        <div className={clsx(classes.messageSkeleton, className)}>
            <div className={classes.messageContainer}>
                <Skeleton width="100%">
                    <Typography variant="body2">.</Typography>
                </Skeleton>
                <Skeleton width="60%">
                    <Typography variant="body2">.</Typography>
                </Skeleton>
            </div>
            <Skeleton className={classes.avatar} variant="circular" width={40} height={40}/>
        </div>
    );
}

MessageSkeleton.propTypes = {
    position: PropTypes.oneOf(["left", "right"])
}

MessageSkeleton.defaultProps = {
    position: "left",
};

export default MessageSkeleton;