import React, { useContext } from 'react';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceEmailSelector } from '../../state/emails/selectors';
import SwitchSection from '../../components/SwitchSection/SwitchSection';
import { ApiContext } from '../../contexts/ApiContext';
import { addOrUpdateEmail } from '../../state/emails/actions';
import CodeBlock from '../../components/CodeBlock';
import PublishingSectionHeader from './PublishingSectionHeader';

const EmailPublishingInfo = ({ emailInbox }) => {
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const active = !emailInbox.disabled ?? false;

  const handleEmailActiveChange = (event) => {
    const disabled = !event.target.checked;
    if (disabled !== emailInbox.disabled) {
      dispatch(addOrUpdateEmail(emailInbox.pk, { disabled }));
      mutationApi.updatePublicResource(emailInbox.pk, emailInbox.sk, {
        disabled,
      });
    }
  };

  return (
    <Stack spacing={4}>
      <PublishingSectionHeader
        title={t('email')}
        subtitle={t('emailPublishingOptionsSubtitle')}
        active={active}
      />
      <CodeBlock fullWidth label={t('emailAddress')} text={emailInbox.path} />
      <SwitchSection
        header={t('active')}
        subheader={t('emailActiveDescription')}
        checked={active}
        onChange={handleEmailActiveChange}
      />
    </Stack>
  );
};

export default EmailPublishingInfo;
