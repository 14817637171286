import { Link } from '@mui/material';
import {
  AtomicBlockUtils,
  CompositeDecorator,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
} from 'draft-js';
import ContentState from 'draft-js/lib/ContentState';
import getVisibleSelectionRect from 'draft-js/lib/getVisibleSelectionRect';
import MentionLink from '../components/MentionLink';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughIcon from '@mui/icons-material/StrikethroughS';
import HighlightIcon from '@mui/icons-material/Highlight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React from 'react';
import SelectionState from 'draft-js/lib/SelectionState';
import RichTextLink from '../components/RichTextLink';
import KeyBindingUtil from 'draft-js/lib/KeyBindingUtil';

export const DEFAULT_FORMATTERS = getDefaultTextFormatters();

function getDefaultTextFormatters() {
  const userAgent = window.navigator.userAgent;
  const osType = userAgent.match(/Mac/i) ? 'mac' : 'other';
  const systemLanguage = navigator.language;
  const commandKey =
    osType === 'mac' ? '⌘' : systemLanguage === 'de' ? 'Strg+' : 'Ctrl+';
  const shiftKey = osType === 'mac' ? '⇧' : 'Shift+';

  return [
    {
      name: 'bold',
      type: 'inline',
      styleName: 'BOLD',
      shortcut: `${commandKey}B`,
      Icon: FormatBoldIcon,
    },
    {
      name: 'italic',
      type: 'inline',
      styleName: 'ITALIC',
      shortcut: `${commandKey}I`,
      Icon: FormatItalicIcon,
    },
    {
      name: 'underline',
      type: 'inline',
      styleName: 'UNDERLINE',
      shortcut: `${commandKey}U`,
      Icon: FormatUnderlinedIcon,
    },
    {
      name: 'strikethrough',
      type: 'inline',
      styleName: 'STRIKETHROUGH',
      Icon: StrikethroughIcon,
    },
    {
      name: 'highlight',
      type: 'inline',
      styleName: 'HIGHLIGHT',
      Icon: HighlightIcon,
    },
    {
      name: 'unorderedList',
      type: 'block',
      blockType: 'unordered-list-item',
      shortcut: `${commandKey}${shiftKey}8`,
      keyBinding: (e) =>
        KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 56,
      Icon: FormatListBulletedIcon,
    },
    {
      name: 'orderedList',
      type: 'block',
      blockType: 'ordered-list-item',
      shortcut: `${commandKey}${shiftKey}7`,
      keyBinding: (e) =>
        KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 55,
      Icon: FormatListNumberedIcon,
    },
    {
      name: 'blockquote',
      type: 'block',
      blockType: 'blockquote',
      Icon: FormatQuoteIcon,
    },
    {
      name: 'heading1',
      type: 'block',
      blockType: 'header-one',
    },
    {
      name: 'heading2',
      type: 'block',
      blockType: 'header-two',
    },
    {
      name: 'heading3',
      type: 'block',
      blockType: 'header-three',
    },
    {
      name: 'heading4',
      type: 'block',
      blockType: 'header-four',
    },
    {
      name: 'heading5',
      type: 'block',
      blockType: 'header-five',
    },
    {
      name: 'heading6',
      type: 'block',
      blockType: 'header-six',
    },
    {
      name: 'link',
      type: 'callback',
      action: 'link',
      shortcut: `${commandKey}K`,
      keyBinding: (e) =>
        KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 75,
      Icon: InsertLinkIcon,
    },
  ];
}

export function isHeading1(blockType) {
  return blockType === 'header-one' || blockType === 'title1';
}

export function isHeading2(blockType) {
  return blockType === 'header-two' || blockType === 'title2';
}

export function removeSelectedBlocksStyle(editorState) {
  const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
  if (newContentState) {
    return EditorState.push(editorState, newContentState, 'change-block-type');
  }
  return editorState;
}

export function resetEditorState(editorState) {
  // The only reliable way to reset the editorState, see https://github.com/facebookarchive/draft-js/issues/1630
  const blocks = editorState.getCurrentContent().getBlockMap().toList();
  const updatedSelection = editorState.getSelection().merge({
    anchorKey: blocks.first().get('key'),
    anchorOffset: 0,
    focusKey: blocks.last().get('key'),
    focusOffset: blocks.last().getLength(),
  });
  const newContentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    updatedSelection,
    'forward',
  );

  const newState = EditorState.push(
    editorState,
    newContentState,
    'remove-range',
  );
  return removeSelectedBlocksStyle(newState);
}

export function insertAutocompleteSuggestionAsAtomicBlock(
  editorState,
  selection,
  autocompleteBlockName,
  data,
) {
  // Remove selected text
  const removedSelectionContentState = Modifier.removeRange(
    editorState.getCurrentContent(),
    selection,
    'forward',
  );
  const removedSelectionState = EditorState.push(
    editorState,
    removedSelectionContentState,
    'remove-range',
  );
  // Create entity
  // TODO: May need to call getCurrentContent() to get content state from the editor state returned from above method instead of reusing removedSelectionContentState
  const createdEntityContentState = removedSelectionContentState.createEntity(
    autocompleteBlockName.toUpperCase(),
    'IMMUTABLE',
    data,
  );
  const entityKey = createdEntityContentState.getLastCreatedEntityKey();
  const createdEntityEditorStateRaw = EditorState.set(editorState, {
    currentContent: createdEntityContentState,
    selection: removedSelectionState.getCurrentContent().getSelectionAfter(),
  });
  // Insert atomic block
  const withAtomicBlockEditorState = AtomicBlockUtils.insertAtomicBlock(
    createdEntityEditorStateRaw,
    entityKey,
    ' ',
  );
  return withAtomicBlockEditorState;
  // return insertSpaceAfter ? insertSpace(withAtomicBlockEditorState) : withAtomicBlockEditorState;
}

export function insertAutocompleteSuggestionAsText(
  editorState,
  selection,
  autocompleteText,
  autocompleteData,
  insertSpaceAfter = true,
) {
  const currentContentState = editorState.getCurrentContent();
  const entityKey = currentContentState
    .createEntity('MENTION', 'IMMUTABLE', autocompleteData)
    .getLastCreatedEntityKey();
  const contentState = Modifier.replaceText(
    currentContentState,
    selection,
    autocompleteText,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );
  const newEditorState = EditorState.push(
    editorState,
    contentState,
    'insert-characters',
  );
  return insertSpaceAfter ? addText(newEditorState, ' ') : newEditorState;
}

export function replaceSelectionWithText(editorState, replacementText) {
  const selectionState = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();

  // Replace selected text with the provided replacementText
  const contentWithReplacement = Modifier.replaceText(
    currentContent,
    selectionState,
    replacementText,
  );

  const newEditorState = EditorState.push(
    editorState,
    contentWithReplacement,
    'insert-characters',
  );

  // Calculate the new selection point after the replacement
  const newSelection = newEditorState.getSelection().merge({
    anchorOffset: selectionState.getAnchorOffset() + replacementText.length,
    focusOffset: selectionState.getAnchorOffset() + replacementText.length,
  });

  return EditorState.forceSelection(newEditorState, newSelection);
}

export function addText(editorState, text) {
  const newContentState = Modifier.insertText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text,
    editorState.getCurrentInlineStyle(),
  );
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-characters',
  );

  const selection = editorState.getSelection();
  const nextOffSet = selection.getFocusOffset() + text.length;
  const newSelection = selection.merge({
    focusOffset: nextOffSet,
    anchorOffset: nextOffSet,
  });
  return EditorState.forceSelection(newEditorState, newSelection);
}

export function getEditorBounds(editor) {
  let fakeClientRect = getVisibleSelectionRect(window);
  return {
    selectionRect: fakeClientRect
      ? {
          top: fakeClientRect?.top,
          left: fakeClientRect?.left,
        }
      : null,
    editorRect: editor.getBoundingClientRect(),
  };
}

export function moveFocusToEnd(editorState) {
  return EditorState.moveFocusToEnd(editorState);
  //editorState = EditorState.moveSelectionToEnd(editorState);
  //return EditorState.forceSelection(editorState, editorState.getSelection());
}

export function clearSelection(editorState) {
  //return EditorState.moveFocusToEnd(editorState);

  const newSelection = SelectionState.createEmpty();
  //const newState = EditorState.moveFocusToEnd(editorState);
  return EditorState.acceptSelection(editorState, newSelection);
}

export function getLineNumber(editorState) {
  const currentBlockKey = editorState.getSelection().getStartKey();
  return editorState
    .getCurrentContent()
    .getBlockMap()
    .keySeq()
    .findIndex((k) => k === currentBlockKey);
}

export function emptyEditorState(linkProps = undefined, customDecorators = []) {
  return deserializeEditorState(null, linkProps, customDecorators);
}

export function isEmptyState(editorState) {
  return !Boolean(editorState) || !editorState.getCurrentContent().hasText();
}

export function serializeEditorState(editorState) {
  return convertToRaw(editorState.getCurrentContent());
}

export function deserializeEditorState(
  content,
  linkProps = undefined,
  customDecorators = [],
) {
  const decorators = [
    {
      strategy: findLinkEntities,
      component: RichTextLink,
      props: linkProps,
    },
    {
      strategy: findMentionEntities,
      component: MentionLink,
    },
  ];
  if (customDecorators) {
    customDecorators.forEach((deco) =>
      decorators.push({
        strategy: (contentBlock, callback) => {
          findDecoWithRegex(deco.regex, contentBlock, callback);
        },
        component: deco.component,
      }),
    );
  }
  const decorator = new CompositeDecorator(decorators);
  if (!content) {
    return EditorState.createEmpty(decorator);
  }

  const isRichValue = Boolean(content) && typeof content === 'object';
  return isRichValue
    ? EditorState.createWithContent(convertFromRaw(content), decorator)
    : EditorState.createWithContent(
        ContentState.createFromText(content),
        decorator,
      );
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

const findMentionEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'MENTION'
    );
  }, callback);
};

function findDecoWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

export function selectWholeWord(editorState) {
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const contentState = editorState.getCurrentContent();
  const currentContentBlock = contentState.getBlockForKey(anchorKey);
  const currentBlockText = currentContentBlock.getText();

  const anchorOffset = selectionState.getAnchorOffset();
  let start = anchorOffset;
  let end = anchorOffset;

  while (start > 0 && currentBlockText[start - 1] !== ' ') {
    start--;
  }

  while (end < currentBlockText.length && currentBlockText[end] !== ' ') {
    end++;
  }

  const newSelection = selectionState.merge({
    anchorOffset: start,
    focusOffset: end,
  });

  return EditorState.forceSelection(editorState, newSelection);
}

export function verifySelectionValidity(editorState, selection) {
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  const currentContentState = editorState.getCurrentContent();

  const startBlockExists =
    currentContentState.getBlockForKey(startKey) !== undefined;
  const endBlockExists =
    currentContentState.getBlockForKey(endKey) !== undefined;

  let isSelectionValid = false;
  if (startBlockExists && endBlockExists) {
    const startBlock = currentContentState.getBlockForKey(startKey);
    const endBlock = currentContentState.getBlockForKey(endKey);
    isSelectionValid =
      startOffset >= 0 &&
      startOffset <= startBlock.getText().length &&
      endOffset >= 0 &&
      endOffset <= endBlock.getText().length;
  }

  return isSelectionValid;
}

export function findEntityRange(editorState, entityKey) {
  const contentState = editorState.getCurrentContent();
  let entityRange = null;

  contentState.getBlockMap().forEach((block) => {
    block.findEntityRanges(
      (character) => {
        const characterEntityKey = character.getEntity();
        return characterEntityKey === entityKey;
      },
      (start, end) => {
        // Assuming the entity is within a single block
        entityRange = {
          start,
          end,
          blockKey: block.getKey(),
        };
      },
    );
  });

  return entityRange;
}
