import React from 'react';
import { Badge, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import UserMenu from '../UserMenu';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { split } from '../../utils/arrayUtils';
import DeleteBadgeButton from '../DeleteBadgeButton';
import ResourceAvatar from '../ResourceAvatar';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { activeMembersSelector } from '../../state/members/selectors';
import { useSelector } from 'react-redux';
import { getSmallPictureVariant } from '../../utils/avatarUtils';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';

const RootStack = styled(Stack, { name: 'UserPicker' })(({ theme }) => ({}));

const UserPicker = ({ value, readOnly, onChange, ...rest }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const activeMembers = useSelector(activeMembersSelector(organizationKey));
  const filteredMembers = activeMembers.filter(
    (member) => !member.access || member.access.includes(workspaceKey),
  );
  const { true: selectedMembers, false: otherMembers } = split(
    filteredMembers,
    (member) => value.includes(member.sk),
  );

  const handleMemberSelected = (addedMember) => {
    setAnchorEl(null);
    onChange([...(value ?? []), addedMember.sk]);
  };

  const handleRemoveMember = (removedMember) => {
    const newValue = (value ?? []).filter(
      (userKey) => userKey !== removedMember.sk,
    );
    onChange(newValue);
  };

  const handleAddUserButtonClick = (e) => {
    //event.currentTarget.blur(); // removes focus outline from button and hides tooltip
    setAnchorEl(e.currentTarget);
  };

  if (readOnly) {
    return (
      <RootStack direction="row" spacing={0.5} {...rest}>
        {selectedMembers.length > 0 ? (
          selectedMembers.map((member) => (
            <Tooltip key={member.sk} title={member.name}>
              <ResourceAvatar
                component="span"
                variant="circular"
                name={member.name}
                picture={member.picture}
                sx={{
                  display: 'inline-flex',
                  verticalAlign: 'middle',
                }}
              />
            </Tooltip>
          ))
        ) : (
          <Typography>{t('notAssigned')}</Typography>
        )}
      </RootStack>
    );
  }

  return (
    <>
      <RootStack direction="row" spacing={0.5} {...rest}>
        {selectedMembers.map((member) => (
          <Tooltip key={member.sk} title={member.name}>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={
                <DeleteBadgeButton
                  disableRipple
                  disableFocusRipple
                  size="small"
                  onClick={() => handleRemoveMember(member)}
                  sx={{
                    display: 'none',
                  }}
                >
                  <CancelIcon fontSize="inherit" />
                </DeleteBadgeButton>
              }
              sx={{
                '&:hover': {
                  '& .MuiIconButton-root': {
                    display: 'initial',
                  },
                },
              }}
            >
              <ResourceAvatar
                component="span"
                variant="circular"
                name={member.name}
                picture={member.picture}
                sx={{ display: 'inline-block' }}
              />
            </Badge>
          </Tooltip>
        ))}
        <Tooltip title={t('addUser')}>
          <IconButton
            onClick={handleAddUserButtonClick}
            size="small"
            sx={{
              borderRadius: '50%',
              border: '1px dashed rgba(0, 0, 0, 0.23)',
            }}
          >
            <AddOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </RootStack>
      <UserMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        users={otherMembers}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
        onUserSelected={handleMemberSelected}
      />
    </>
  );
};

UserPicker.propTypes = {
  value: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  members: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

UserPicker.defaultProps = {
  value: [],
  members: [],
  onChange: () => {},
};

export default UserPicker;
