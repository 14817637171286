import React from "react";
import {styled} from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";

const HelperTextErrorIcon = styled(ErrorIcon)(({ theme }) => ({
    width: theme.typography.caption.fontSize,
    height: theme.typography.caption.fontSize,
    verticalAlign: '-2px'
}));

export default HelperTextErrorIcon;
