import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export default class AuthApi {
  // TODO: Move to auth roots
  static async changePassword(oldPassword: string, newPassword: string) {
    await Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .catch((err) => console.warn(err));
  }

  static async currentAuthenticatedUser() {
    try {
      return await Auth.currentAuthenticatedUser({ bypassCache: false });
    } catch (e) {
      return null;
    }
  }

  static async updateCurrentUserAttributes(attributes: {}) {
    const user = await this.currentAuthenticatedUser();
    return await Auth.updateUserAttributes(user, attributes);
  }

  static async verifyCurrentUserAttribute(attributeName: string) {
    return Auth.verifyCurrentUserAttribute(attributeName);
  }

  static async verifyCurrentUserAttributeSubmit(
    attributeName: string,
    code: string,
  ) {
    return Auth.verifyCurrentUserAttributeSubmit(attributeName, code);
  }

  static async setPreferredMFA(
    mfaMethod: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA',
  ) {
    const user = await this.currentAuthenticatedUser();
    return Auth.setPreferredMFA(user, mfaMethod);
  }

  static async resendSignUp() {
    const user = await this.currentAuthenticatedUser();
    return Auth.resendSignUp(user.username);
  }

  static async refreshAccessToken() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        if (err) return reject(err);
        resolve(session);
      });
    });
  }

  static async signIn(
    username: string,
    password: string,
    language?: string,
  ): Promise<CognitoUser> {
    return Auth.signIn(username.toLowerCase(), password, {
      website: window.location.origin,
      ...(language && { locale: language }),
    });
  }

  static async forgotPassword(username: string, language?: string) {
    return Auth.forgotPassword(username.toLowerCase(), {
      website: window.location.origin,
      ...(language && { locale: language }),
    });
  }

  static async completeNewPassword(
    user: CognitoUser,
    newPassword: string,
  ): Promise<CognitoUser> {
    return Auth.completeNewPassword(user, newPassword);
  }

  static async forgotPasswordSubmit(
    username: string,
    code: string,
    newPassword: string,
  ) {
    return Auth.forgotPasswordSubmit(username.toLowerCase(), code, newPassword);
  }

  static async confirmSignIn(
    cognitoUser: CognitoUser,
    verificationCode: string,
    mfaType: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null,
  ): Promise<CognitoUser> {
    return Auth.confirmSignIn(
      cognitoUser, // Return object from Auth.signIn()
      verificationCode, // Confirmation code
      mfaType, // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );
  }

  static async signOut() {
    //const cognitoUser = await Auth.currentAuthenticatedUser();
    //const value = `user#${cognitoUser.attributes.sub}`;
    //notificationContext.unsubscribe();
    //mutationApi.updatePrivateResource(value, value, {pushSubscription: null});
    await Auth.signOut();
  }

  static async signUp(
    email: string,
    password: string,
    name: string,
    language: string,
  ) {
    return await Auth.signUp({
      username: email.toLowerCase(),
      password: password,
      attributes: {
        name,
        website: window.location.origin,
        locale: language,
        //phone_number,   // optional - E.164 number convention
        // other custom attributes
      },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: true,
      },
    });
  }

  static async confirmSignUp(username: string, code: string) {
    return Auth.confirmSignUp(username.toLowerCase(), code);
  }

  static async resendConfirmationCode(username: string) {
    return Auth.resendSignUp(username.toLowerCase());
  }
}
