import React, { useState } from 'react';
import { Box, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { StyledMenu } from '../../components/StyledMenu';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { Draggable } from 'react-beautiful-dnd';
import FormBlock from '../../components/FormBlock';
import { useFormContext } from 'react-hook-form';
import { getBlockTemplate } from '../../components/FormBlock/utils';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { isReportPasswordBlock, isUserEmailBlock } from '../../utils/formBlockUtils';

const toolbarClassName = "FormBuilderFormBlock-toolbar";

const RootBox = styled(Box, { name: "FormBuilderFormBlock", shouldForwardProp: (prop) => prop !== "isDragging" && prop !== "menuOpen" })(
    ({ theme, isDragging, menuOpen }) => ({
        position: "relative",
        paddingBottom: theme.spacing(5),
        "&:before": {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            borderBottom: `1px dashed rgba(0, 0, 0, 0.42)`,
            borderTop: `1px dashed rgba(0, 0, 0, 0.42)`,
            content: '"\\00a0"',
            pointerEvents: "none",
            visibility: "hidden",
        },
        "&:hover": {
            "&:before": {
                visibility: "visible",
                backgroundColor: theme.palette.grey[50],
            },
            [`& .${toolbarClassName}`]: {
                visibility: "visible",
            },
        },
        "&:focus-within": {
            "&:before": {
                visibility: "visible",
                backgroundColor: theme.palette.grey[50],
            },
            [`& .${toolbarClassName}`]: {
                visibility: "visible",
            },
        },
        ...(isDragging && {
            backgroundColor: theme.palette.grey[100],
            "&:before": {
                visibility: "visible",
                backgroundColor: theme.palette.grey[100],
            },
            [`& .${toolbarClassName}`]: {
                visibility: "visible",
            },
        }),
        ...(menuOpen && {
            [`& .${toolbarClassName}`]: {
                visibility: "visible",
            },
        })
    })
);

const FormBuilderFormBlock = ({ blockIndex, language, onBlockDuplicate, onBlockDelete, ...rest }) => {
    const { t } = useTranslation();
    const { getValues } = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState();

    const handleDuplicateClick = () => {
        setContextMenuAnchorEl(null);
        onBlockDuplicate();
    };

    const handleDeleteClick = () => {
        setContextMenuAnchorEl(null);
        onBlockDelete();
    };

    return (
        <>
            <Draggable draggableId={`block-${blockTemplate.key}`} index={blockIndex}>
                {(provided, snapshot) => (
                    <RootBox
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        menuOpen={Boolean(contextMenuAnchorEl)}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        {...rest}
                    >
                        <Stack
                            className={toolbarClassName}
                            direction="row"
                            alignItems="center"
                            sx={{ mt: 1, position: "relative", visibility: "hidden", height: 42 }}
                        >
                            <Typography variant="body2" sx={{ color: "text.disabled" }}>
                                {t(blockTemplate.type)}
                            </Typography>
                            <Box flex="1 1 auto" />
                            {!isReportPasswordBlock(blockTemplate) && !isUserEmailBlock(blockTemplate) && (
                                <Tooltip title={t("more")}>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={(e) => setContextMenuAnchorEl(e.currentTarget)}
                                    >
                                        <MoreHorizOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <DragIndicatorOutlinedIcon
                                fontSize="small"
                                sx={{
                                    color: "text.disabled",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%) rotate(90deg)",
                                }}
                            />
                        </Stack>
                        <FormBlock
                            blockIndex={blockIndex}
                            language={language}
                            mode="edit"
                            sx={{ cursor: "initial", zIndex: 1, position: "relative" }}
                        />
                    </RootBox>
                )}
            </Draggable>
            <Menu
                open={Boolean(contextMenuAnchorEl)}
                anchorEl={contextMenuAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setContextMenuAnchorEl(null)}
            >
                <MenuItem onClick={handleDuplicateClick}>
                    <ListItemIcon>
                        <FileCopyOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("copy")}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>
                    <ListItemIcon>
                        <DeleteOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("delete")}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

FormBuilderFormBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    onBlockDuplicate: PropTypes.func.isRequired,
    onBlockDelete: PropTypes.func.isRequired,
};

FormBuilderFormBlock.defaultProps = {};

export default FormBuilderFormBlock;
