import { toLanguagePackKey } from '../../utils/apiUtils';

export const allLanguagePacksSelector = (formKey) => state => {
    if (!Boolean(formKey)) {
        return null;
    }

    return state.languagePacks[formKey];
}

export const languagePackSelector = (formKey, language) => state => {
    if (!Boolean(formKey) || !Boolean(language)) {
        return null;
    }

    const sk = toLanguagePackKey(language);
    return state.languagePacks[formKey]?.find(languagePack => languagePack.sk === sk);
}