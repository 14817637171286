import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const useUnsupportedScreenSizeOverlay = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return smallScreen ? (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="h5" color="textSecondary">
                {t("screenSizeNotSupported")}
            </Typography>
        </Box>
    ) : undefined;
}

