import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../../utils/validationUtils';
import { ApiContext } from '../../contexts/ApiContext';
import { useLocation } from 'react-router';
import { styled } from '@mui/material/styles';
import { maskEmail } from '../../utils/textUtils';
import PasswordTextField from '../../components/PasswordTextField';
import { CognitoUser } from 'amazon-cognito-identity-js';

const RootBox = styled(Box, { name: 'ForgottenPasswordPage1' })(
  ({ theme }) => ({}),
);

interface Props {
  email: string;
  onSubmit: (user: CognitoUser, password: string) => void;
}

const ForgottenPasswordPage2 = ({ email, onSubmit }: Props) => {
  const { authApi } = useContext(ApiContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState<
    string | null
  >(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  console.log('ForgottenPasswordPage2, email: %o', email);

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.currentTarget.value;
    setVerificationCode(value);

    setVerificationCodeError(
      !Boolean(value) ? 'verificationCodeRequired' : null,
    );
    setSubmitError(null);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPassword(value);
    setPasswordError(!Boolean(value) ? 'passwordIsRequired' : null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    console.log('verificationCode: %o', verificationCode);
    if (verificationCodeError !== null || passwordError !== null) {
      return;
    }

    if (!Boolean(verificationCode)) {
      setVerificationCodeError('verificationCodeRequired');
      return;
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    try {
      setLoading(true);
      await authApi.forgotPasswordSubmit(email, verificationCode, password);
      const user = await authApi.signIn(email, password);
      onSubmit(user, password);
    } catch (e: any) {
      console.error(e);
      setLoading(false);

      if (
        e.name === 'CodeMismatchException' ||
        e.name === 'ExpiredCodeException'
      ) {
        setVerificationCodeError(e.name);
      } else if (e.name === 'LimitExceededException') {
        setSubmitError(e.name);
      } else {
        setSubmitError('unknownLoginError');
      }
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs">
        <Box sx={{ textAlign: 'center' }} mb={6}>
          <Typography variant="h3">
            {t('forgottenPasswordPage2Title')}
          </Typography>
          <Typography variant="bodyLarge" color="textSecondary">
            {t('emailConfirmationText', { email: maskEmail(email) })}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <TextField
              id="email-verification-code"
              autoFocus
              required
              fullWidth
              type="text"
              autoComplete="one-time-code"
              variant="outlined"
              label={t('verificationCode')}
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              error={Boolean(verificationCodeError)}
              helperText={t(verificationCodeError!)}
            />
            <PasswordTextField
              id="new-password"
              required
              fullWidth
              variant="outlined"
              autoComplete="new-password"
              label={t('password')}
              value={password}
              onChange={handlePasswordChange}
              error={Boolean(passwordError)}
              helperText={t(passwordError!)}
            />
            <LoadingButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default ForgottenPasswordPage2;
