/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createPrivateResource =
  /* GraphQL */ `mutation CreatePrivateResource(
  $input: CreatePrivateResourceInput!
  $condition: ModelPrivateResourceConditionInput
) {
  createPrivateResource(input: $input, condition: $condition) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreatePrivateResourceMutationVariables,
    APITypes.CreatePrivateResourceMutation
  >;
export const updatePrivateResource =
  /* GraphQL */ `mutation UpdatePrivateResource(
  $input: UpdatePrivateResourceInput!
  $condition: ModelPrivateResourceConditionInput
) {
  updatePrivateResource(input: $input, condition: $condition) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdatePrivateResourceMutationVariables,
    APITypes.UpdatePrivateResourceMutation
  >;
export const deletePrivateResource =
  /* GraphQL */ `mutation DeletePrivateResource(
  $input: DeletePrivateResourceInput!
  $condition: ModelPrivateResourceConditionInput
) {
  deletePrivateResource(input: $input, condition: $condition) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeletePrivateResourceMutationVariables,
    APITypes.DeletePrivateResourceMutation
  >;
export const createPublicResource =
  /* GraphQL */ `mutation CreatePublicResource(
  $input: CreatePublicResourceInput!
  $condition: ModelPublicResourceConditionInput
) {
  createPublicResource(input: $input, condition: $condition) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreatePublicResourceMutationVariables,
    APITypes.CreatePublicResourceMutation
  >;
export const updatePublicResource =
  /* GraphQL */ `mutation UpdatePublicResource(
  $input: UpdatePublicResourceInput!
  $condition: ModelPublicResourceConditionInput
) {
  updatePublicResource(input: $input, condition: $condition) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdatePublicResourceMutationVariables,
    APITypes.UpdatePublicResourceMutation
  >;
export const deletePublicResource =
  /* GraphQL */ `mutation DeletePublicResource(
  $input: DeletePublicResourceInput!
  $condition: ModelPublicResourceConditionInput
) {
  deletePublicResource(input: $input, condition: $condition) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeletePublicResourceMutationVariables,
    APITypes.DeletePublicResourceMutation
  >;
export const createPartner = /* GraphQL */ `mutation CreatePartner(
  $input: CreatePartnerInput!
  $condition: ModelPartnerConditionInput
) {
  createPartner(input: $input, condition: $condition) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePartnerMutationVariables,
  APITypes.CreatePartnerMutation
>;
export const updatePartner = /* GraphQL */ `mutation UpdatePartner(
  $input: UpdatePartnerInput!
  $condition: ModelPartnerConditionInput
) {
  updatePartner(input: $input, condition: $condition) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePartnerMutationVariables,
  APITypes.UpdatePartnerMutation
>;
export const deletePartner = /* GraphQL */ `mutation DeletePartner(
  $input: DeletePartnerInput!
  $condition: ModelPartnerConditionInput
) {
  deletePartner(input: $input, condition: $condition) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePartnerMutationVariables,
  APITypes.DeletePartnerMutation
>;
export const createActivityLogEntry =
  /* GraphQL */ `mutation CreateActivityLogEntry(
  $input: CreateActivityLogEntryInput!
  $condition: ModelActivityLogEntryConditionInput
) {
  createActivityLogEntry(input: $input, condition: $condition) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateActivityLogEntryMutationVariables,
    APITypes.CreateActivityLogEntryMutation
  >;
export const updateActivityLogEntry =
  /* GraphQL */ `mutation UpdateActivityLogEntry(
  $input: UpdateActivityLogEntryInput!
  $condition: ModelActivityLogEntryConditionInput
) {
  updateActivityLogEntry(input: $input, condition: $condition) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateActivityLogEntryMutationVariables,
    APITypes.UpdateActivityLogEntryMutation
  >;
export const deleteActivityLogEntry =
  /* GraphQL */ `mutation DeleteActivityLogEntry(
  $input: DeleteActivityLogEntryInput!
  $condition: ModelActivityLogEntryConditionInput
) {
  deleteActivityLogEntry(input: $input, condition: $condition) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteActivityLogEntryMutationVariables,
    APITypes.DeleteActivityLogEntryMutation
  >;
export const createResourcePassword =
  /* GraphQL */ `mutation CreateResourcePassword(
  $input: CreateResourcePasswordInput!
  $condition: ModelResourcePasswordConditionInput
) {
  createResourcePassword(input: $input, condition: $condition) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateResourcePasswordMutationVariables,
    APITypes.CreateResourcePasswordMutation
  >;
export const updateResourcePassword =
  /* GraphQL */ `mutation UpdateResourcePassword(
  $input: UpdateResourcePasswordInput!
  $condition: ModelResourcePasswordConditionInput
) {
  updateResourcePassword(input: $input, condition: $condition) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateResourcePasswordMutationVariables,
    APITypes.UpdateResourcePasswordMutation
  >;
export const deleteResourcePassword =
  /* GraphQL */ `mutation DeleteResourcePassword(
  $input: DeleteResourcePasswordInput!
  $condition: ModelResourcePasswordConditionInput
) {
  deleteResourcePassword(input: $input, condition: $condition) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteResourcePasswordMutationVariables,
    APITypes.DeleteResourcePasswordMutation
  >;
export const createPublicResourceAlias =
  /* GraphQL */ `mutation CreatePublicResourceAlias(
  $input: CreatePublicResourceAliasInput!
  $condition: ModelPublicResourceAliasConditionInput
) {
  createPublicResourceAlias(input: $input, condition: $condition) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreatePublicResourceAliasMutationVariables,
    APITypes.CreatePublicResourceAliasMutation
  >;
export const updatePublicResourceAlias =
  /* GraphQL */ `mutation UpdatePublicResourceAlias(
  $input: UpdatePublicResourceAliasInput!
  $condition: ModelPublicResourceAliasConditionInput
) {
  updatePublicResourceAlias(input: $input, condition: $condition) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdatePublicResourceAliasMutationVariables,
    APITypes.UpdatePublicResourceAliasMutation
  >;
export const deletePublicResourceAlias =
  /* GraphQL */ `mutation DeletePublicResourceAlias(
  $input: DeletePublicResourceAliasInput!
  $condition: ModelPublicResourceAliasConditionInput
) {
  deletePublicResourceAlias(input: $input, condition: $condition) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeletePublicResourceAliasMutationVariables,
    APITypes.DeletePublicResourceAliasMutation
  >;
export const createEmailConversation =
  /* GraphQL */ `mutation CreateEmailConversation(
  $input: CreateEmailConversationInput!
  $condition: ModelEmailConversationConditionInput
) {
  createEmailConversation(input: $input, condition: $condition) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEmailConversationMutationVariables,
    APITypes.CreateEmailConversationMutation
  >;
export const updateEmailConversation =
  /* GraphQL */ `mutation UpdateEmailConversation(
  $input: UpdateEmailConversationInput!
  $condition: ModelEmailConversationConditionInput
) {
  updateEmailConversation(input: $input, condition: $condition) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEmailConversationMutationVariables,
    APITypes.UpdateEmailConversationMutation
  >;
export const deleteEmailConversation =
  /* GraphQL */ `mutation DeleteEmailConversation(
  $input: DeleteEmailConversationInput!
  $condition: ModelEmailConversationConditionInput
) {
  deleteEmailConversation(input: $input, condition: $condition) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEmailConversationMutationVariables,
    APITypes.DeleteEmailConversationMutation
  >;
export const createVoicemailRecording =
  /* GraphQL */ `mutation CreateVoicemailRecording(
  $input: CreateVoicemailRecordingInput!
  $condition: ModelVoicemailRecordingConditionInput
) {
  createVoicemailRecording(input: $input, condition: $condition) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateVoicemailRecordingMutationVariables,
    APITypes.CreateVoicemailRecordingMutation
  >;
export const updateVoicemailRecording =
  /* GraphQL */ `mutation UpdateVoicemailRecording(
  $input: UpdateVoicemailRecordingInput!
  $condition: ModelVoicemailRecordingConditionInput
) {
  updateVoicemailRecording(input: $input, condition: $condition) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateVoicemailRecordingMutationVariables,
    APITypes.UpdateVoicemailRecordingMutation
  >;
export const deleteVoicemailRecording =
  /* GraphQL */ `mutation DeleteVoicemailRecording(
  $input: DeleteVoicemailRecordingInput!
  $condition: ModelVoicemailRecordingConditionInput
) {
  deleteVoicemailRecording(input: $input, condition: $condition) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteVoicemailRecordingMutationVariables,
    APITypes.DeleteVoicemailRecordingMutation
  >;
export const createOrganization =
  /* GraphQL */ `mutation CreateOrganization($input: CreateOrganizationInput!) {
  createOrganization(input: $input) {
    organization {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    workspace {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateOrganizationMutationVariables,
    APITypes.CreateOrganizationMutation
  >;
export const createWorkspace =
  /* GraphQL */ `mutation CreateWorkspace($input: CreateWorkspaceInput!) {
  createWorkspace(input: $input) {
    workspace {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    statuses {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    form {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    email {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    voicemail {
      pk
      sk
      organizationKey
      path
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      text
      content
      senderKey
      signature
      disabled
      template
      hasPassword
      encryptedContent
      plan
      colors
      whitelabelEnabled
      systemMessage
      partnerKey
      captchaEnabled
      isDeleted
      __typename
    }
    policy {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    tags {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    reports {
      pk
      sk
      organizationKey
      userKey
      workspaceKey
      owner
      group
      createdAt
      updatedAt
      updatedBy
      name
      picture
      email
      plan
      address
      phone
      country
      language
      role
      description
      excerpt
      excerptUpdatedAt
      text
      content
      senderKey
      openedAt
      channel
      signature
      assigned
      dueDate
      color
      type
      ccEmailAddresses
      status
      tags
      event
      value
      disabled
      access
      encryptedContent
      encryptedExcerpt
      statuses
      termLength
      partnerKey
      templateName
      closed
      isDeleted
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateWorkspaceMutationVariables,
    APITypes.CreateWorkspaceMutation
  >;
export const updateWorkspace =
  /* GraphQL */ `mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWorkspaceMutationVariables,
    APITypes.UpdateWorkspaceMutation
  >;
export const deleteWorkspace =
  /* GraphQL */ `mutation DeleteWorkspace($input: DeleteWorkspaceInput!) {
  deleteWorkspace(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWorkspaceMutationVariables,
    APITypes.DeleteWorkspaceMutation
  >;
export const createReport =
  /* GraphQL */ `mutation CreateReport($input: CreateReportInput!) {
  createReport(input: $input) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateReportMutationVariables,
    APITypes.CreateReportMutation
  >;
export const deleteReport =
  /* GraphQL */ `mutation DeleteReport($input: DeleteReportInput!) {
  deleteReport(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteReportMutationVariables,
    APITypes.DeleteReportMutation
  >;
export const createMessage =
  /* GraphQL */ `mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateMessageMutationVariables,
    APITypes.CreateMessageMutation
  >;
export const updateUser =
  /* GraphQL */ `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserMutationVariables,
    APITypes.UpdateUserMutation
  >;
export const createInvitation =
  /* GraphQL */ `mutation CreateInvitation($input: CreateInvitationInput!) {
  createInvitation(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvitationMutationVariables,
    APITypes.CreateInvitationMutation
  >;
export const acceptInvitation =
  /* GraphQL */ `mutation AcceptInvitation($input: AcceptInvitationInput!) {
  acceptInvitation(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.AcceptInvitationMutationVariables,
    APITypes.AcceptInvitationMutation
  >;
export const createComment =
  /* GraphQL */ `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateCommentMutationVariables,
    APITypes.CreateCommentMutation
  >;
export const updateReport =
  /* GraphQL */ `mutation UpdateReport($input: UpdateReportInput!) {
  updateReport(input: $input) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateReportMutationVariables,
    APITypes.UpdateReportMutation
  >;
export const sendSignupNotification =
  /* GraphQL */ `mutation SendSignupNotification($input: SendSignupNotificationInput!) {
  sendSignupNotification(input: $input) {
    success
    __typename
  }
}
` as GeneratedMutation<
    APITypes.SendSignupNotificationMutationVariables,
    APITypes.SendSignupNotificationMutation
  >;
