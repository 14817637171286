import React, { useEffect, useRef, useState } from 'react';
import BlobCache from '../utils/BlobCache';

export const useAvatarPicture = (s3Key) => {
    const [url, setUrl] = useState();
    const urlRef = useRef(null);
    const s3KeyRef = useRef(null);

    useEffect(() => {
        let isMounted = true;
        if (!s3Key) {
            // Avatar with already loaded images might have their key set to null if a user for example would
            // want to reset the avatar. The blobUrl must be then revoked and the url state set to null to avoid
            // rendering the old image in the avatar.
            if (urlRef.current) {
                BlobCache.destroyBlobByUrl(urlRef.current);
                isMounted && setUrl(null);
            }
            return;
        }

        const blobUrl = BlobCache.getBlobUrl(s3Key);
        if (blobUrl) {
            setUrl(blobUrl);
            return;
        }

        const downloadAvatar = async () => {
            const urls = await BlobCache.cacheObjectsFromS3([s3Key]);
            if (isMounted) {
                urlRef.current = urls[0];
                s3KeyRef.current = s3Key;
                setUrl(urls[0]);
            }
        }

        downloadAvatar();

        return () => {
            isMounted = false;
        }
    }, [s3Key]);

    return [url, setUrl];
}