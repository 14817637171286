export const formSelector = (formKey) => (state) => {
  if (!Boolean(formKey)) {
    return null;
  }

  return state.forms[formKey];
};

export const workspaceFormSelector = (workspaceKey) => (state) => {
  if (!Boolean(workspaceKey)) {
    return null;
  }

  const formKey = Object.keys(state?.forms).find(
    (key) => state?.forms[key].workspaceKey === workspaceKey,
  );
  return state?.forms[formKey];
};

export const selectFormByPath = (path) => (state) => {
  if (!Boolean(path) || !state?.forms) {
    return null;
  }

  return Object.values(state.forms).find((form) => form.path === path);
};
