import React, { useContext, useState } from "react";
import { Box, Collapse, Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ApiContext } from "../../contexts/ApiContext";
import { useTranslation } from "react-i18next";
import FixedLabelTextField from "../../components/FixedLabelTextField";
import { currentUserSelector } from "../../state/currentUser/selectors";
import { styled } from "@mui/material/styles";
import ActionPanel from "../../components/ActionPanel/ActionPanel";
import Button from "@mui/material/Button";
import Well from "../../components/Well";
import PhoneInput from "../../components/PhoneInput";
import { isNullOrEmpty, isValidPhoneNumber, removeWhitespace } from '../../utils/textUtils';
import { getCurrentCountry } from "../../utils/countryUtils";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneVerificationDialog from './PhoneVerificationDialog';

function getCallingCode() {
    const callingCode = getCurrentCountry().callingCode;
    return callingCode ? `+${callingCode}` : "";
}

const ProfileSettingsSection = ({}) => {
    const { authApi } = useContext(ApiContext);
    const { t } = useTranslation();
    const currentUser = useSelector(currentUserSelector());
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(" ");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(" ");
    const [repeatedPassword, setRepeatedPassword] = useState("");
    const [repeatedPasswordError, setRepeatedPasswordError] = useState(" ");
    const [changePasswordExpanded, setChangePasswordExpanded] = useState(false);
    const [mfaEnabled, setMfaEnabled] = useState(currentUser.preferredMFA !== "NOMFA");
    const [mfaEditing, setMfaEditing] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(currentUser.attributes.phone_number ?? "");
    const [phoneError, setPhoneError] = useState();
    const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
    const [savingPassword, setSavingPassword] = useState(false);
    const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);
    const savePasswordDisabled = newPassword === "" || repeatedPassword === "" || currentPasswordError !== " " || newPasswordError !== " " || repeatedPasswordError !== " ";

    const handleCurrentPasswordChange = (event) => {
        const value = event.target.value;
        setCurrentPassword(value);

        if (!value) {
            setCurrentPasswordError(t("currentPasswordRequiredError"));
        } else {
            setCurrentPasswordError(" ");
        }
    };

    const handleNewPasswordChange = (event) => {
        const value = event.target.value;
        setNewPassword(value);
        if (!value) {
            setNewPasswordError(t("passwordIsRequired"));
        } else if (value.length < 8) {
            setNewPasswordError(t("passwordTooShort"));
        } else {
            setNewPasswordError(" ");
        }
    };

    const handleRepeatPasswordChange = (event) => {
        const value = event.target.value;
        setRepeatedPassword(value);
        if (value !== newPassword) {
            setRepeatedPasswordError(t("confirmPasswordMatchError"));
        } else {
            setRepeatedPasswordError(" ");
        }
    };

    const handleSavePasswordClick = async () => {
        if (repeatedPassword !== newPassword) {
            setRepeatedPasswordError(t("confirmPasswordMatchError"));
            return;
        }

        try {
            setSavingPassword(true);
            await authApi.changePassword(currentPassword, newPassword);
            setSavingPassword(false);
            setChangePasswordExpanded(false);
        } catch {
            setCurrentPasswordError(t("currentPasswordInvalidError"));
        }
    };

    const handlePhoneInputChange = (value, info) => {
        setPhoneError(null);
        setPhoneNumber(value);
    };

    const handleVerifyPhoneNumberClick = async () => {
        const normalized = removeWhitespace(phoneNumber);
        if (isNullOrEmpty(normalized)) {
            setPhoneError(t("phoneNumberRequired"));
            return;
        } else if (!isValidPhoneNumber(normalized)) {
            setPhoneError(t("phoneNumberInvalid"));
            return;
        }

        try {
            setVerifyButtonLoading(true);
            const response = await authApi.updateCurrentUserAttributes({ phone_number: normalized });
            const verificationResponse = await authApi.verifyCurrentUserAttribute('phone_number');
            setVerifyButtonLoading(false);
            setVerificationDialogOpen(true);
            console.log("verificationResponse: %o", verificationResponse);
            //setChangePasswordExpanded(false);
        } catch (e) {
            console.error(e);
            //setCurrentPasswordError(t("currentPasswordInvalidError"));
        }
    };

    const handleMfaActionButtonClick = async () => {
        if (mfaEnabled) {
            // If MFA is being disabled
            setMfaEnabled(false);
            const reponse = await authApi.setPreferredMFA("NOMFA");
            console.log("mfa being disabled, response: %o", reponse);
        } else {
            // If MFA is being enabled
            setMfaEnabled(true);
            if (Boolean(phoneNumber)) {
                const response = await authApi.setPreferredMFA("SMS");
                console.log("mfa being disabled, response: %o", response);
            } else {
                // Open phone editor if the user has no phone number
                setMfaEditing(true);
            }
        }
    };

    const handlePhoneNumberVerified = async () => {
        setVerificationDialogOpen(false);
        setMfaEnabled(true);
        setMfaEditing(false);
        const reponse = await authApi.setPreferredMFA("SMS");
        console.log("mfa response: %o", reponse);
    };

    const handleMfaCancelButtonclick = () => {
        setMfaEditing(false);
        if (!currentUser.attributes.phone_number && (!phoneNumber || phoneNumber === "" || Boolean(phoneError))) {
            setMfaEnabled(false);
        }
    }

    return (
        <Stack spacing={4}>
            <ActionPanel
                title={t("changePasswordSectionTitle")}
                subtitle={t("changePasswordSectionSubtitle")}
                actionButtonText={t("change")}
                onActionControlClick={() => setChangePasswordExpanded(true)}
                actionControlProps={{ hidden: changePasswordExpanded }}
            >
                <Collapse in={changePasswordExpanded}>
                    <Box>
                        <FixedLabelTextField
                            required
                            fullWidth
                            label={t("currentPassword")}
                            type="password"
                            autoComplete="password"
                            value={currentPassword}
                            onChange={handleCurrentPasswordChange}
                            error={currentPasswordError !== " "}
                            helperText={currentPasswordError}
                        />
                        <FixedLabelTextField
                            required
                            fullWidth
                            label={t("newPassword")}
                            type="password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            error={newPasswordError !== " "}
                            helperText={newPasswordError}
                        />
                        <FixedLabelTextField
                            required
                            fullWidth
                            label={t("confirmPassword")}
                            type="password"
                            value={repeatedPassword}
                            onChange={handleRepeatPasswordChange}
                            error={repeatedPasswordError !== " "}
                            helperText={repeatedPasswordError}
                        />
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ mt: 1 }}>
                            <Button
                                color="default"
                                size="medium"
                                variant="outlined"
                                onClick={() => setChangePasswordExpanded(false)}
                            >
                                {t("cancel")}
                            </Button>
                            <LoadingButton
                                color="primary"
                                size="medium"
                                variant="contained"
                                loading={savingPassword}
                                disabled={savePasswordDisabled}
                                onClick={handleSavePasswordClick}
                            >
                                {t("Save changes")}
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Collapse>
            </ActionPanel>
            <Divider />
            <ActionPanel
                title={t("mfaSectionTitle")}
                subtitle={t("mfaSectionSubtitle")}
                onActionControlClick={handleMfaActionButtonClick}
                actionButtonText={mfaEnabled ? t("disable") : t("enable")}
                actionControlProps={{ hidden: mfaEditing && !Boolean(phoneNumber) }}
            >
                <Collapse in={mfaEnabled && !mfaEditing}>
                    <Well sx={[{ pr: 1, mt: 2 }]}>
                        <Stack direction="row" alignItems="center">
                            <Stack flex="1 1 auto">
                                <Typography variant="subtitle2">{t("phoneNumber")}</Typography>
                                <Typography variant="body2">{phoneNumber}</Typography>
                            </Stack>
                            <Button
                                color="primary"
                                size="medium"
                                variant="text"
                                onClick={() => setMfaEditing((prev) => !prev)}
                            >
                                {t("edit")}
                            </Button>
                        </Stack>
                    </Well>
                </Collapse>
                <Collapse in={mfaEnabled && mfaEditing}>
                    <Box sx={{ mt: 2 }}>
                        <PhoneInput
                            autoFocus
                            fullWidth
                            label={t("phoneNumber")}
                            value={!isNullOrEmpty(phoneNumber) ? phoneNumber : getCallingCode()}
                            error={Boolean(phoneError)}
                            helperText={Boolean(phoneError) ? phoneError : null}
                            onChange={handlePhoneInputChange}
                        />
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
                            <Button
                                color="default"
                                size="medium"
                                variant="outlined"
                                disabled={verifyButtonLoading}
                                onClick={handleMfaCancelButtonclick}
                            >
                                {t("cancel")}
                            </Button>
                            <LoadingButton
                                color="primary"
                                size="medium"
                                variant="contained"
                                loading={verifyButtonLoading}
                                onClick={handleVerifyPhoneNumberClick}
                            >
                                {t("verify")}
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Collapse>
            </ActionPanel>
            <PhoneVerificationDialog
                open={verificationDialogOpen}
                phoneNumber={phoneNumber}
                onClose={() => setVerificationDialogOpen(false)}
                onSuccess={handlePhoneNumberVerified}
            />
        </Stack>
    );
};

export default ProfileSettingsSection;
