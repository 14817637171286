import * as types from './actionTypes';
import { filterGroupItems, groupBy, updateGroupItem } from '../../utils/arrayUtils';

const initialState = {};

// TODO: Rename redux state variables
export default function statusesReducer(state = initialState, action) {
    switch (action.type) {
        case types.STATUSES_LOADED: {
            const statuses = action.payload.statuses;
            return groupBy(statuses,  'pk');
        }
        case types.STATUSES_ADDED: {
            const workspaceKey = action.payload.workspaceKey;
            const statuses = action.payload.statuses;

            const updatedStatuses = [...(state[workspaceKey] ?? [])];
            for (const state of statuses) {
                // Add new state to the state if it doesn't contain the item with the same SK
                // already. If it does, the state item will be replaced by the new state.
                const index = updatedStatuses.findIndex(item => item.sk === state.sk);
                if (index < 0) {
                    updatedStatuses.push(state);
                } else {
                    updatedStatuses[index] = state;
                }
            }

            const newState = {...state};
            newState[workspaceKey] = updatedStatuses;
            return newState;
        }
        case types.STATUS_UPDATED: {
            const pk = action.payload.pk;
            const sk = action.payload.sk;
            return updateGroupItem(state, pk, status => status.sk === sk, {...action.payload.data, pk: pk, sk: sk});
        }
        case types.STATUS_DELETED: {
            const pk = action.payload.pk;
            const sk = action.payload.sk;
            return filterGroupItems(state, pk, status => status.sk !== sk);
        }
        case types.STATUSES_DELETED: {
            const newState = {...state};
            delete newState[action.payload.workspaceKey];
            return newState;
        }
        default:
            return state
    }
}
