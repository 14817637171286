import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

const ViewContainer = styled(({maxWidth, ...rest}) => <Container maxWidth={maxWidth ?? "md"} {...rest} />, {
    name: "ViewContainer",
})(({ theme }) => ({
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
}));

export default ViewContainer;
