import React from 'react';
import PropTypes from 'prop-types';
import { Editor, getDefaultKeyBinding, RichUtils } from 'draft-js';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Immutable from 'immutable';
import { isHeading1, isHeading2 } from '../../utils/richTextUtils';

const RootBox = styled(Box, {
  name: 'RichInput',
  shouldForwardProp: (prop) => prop !== 'size',
})(({ theme, size }) => ({
  position: 'relative',
  ...theme.typography.body1,
  '.defaultText': {
    ...theme.typography.body1,
  },
  '.heading1': {
    ...theme.typography.h3,
  },
  '.heading2': {
    ...theme.typography.h5,
  },
  '& .DraftEditor-root': {
    position: 'relative',
    '&:focus-within': {
      '& .public-DraftEditorPlaceholder-root': {
        display: 'none',
      },
    },
  },
  '& .public-DraftEditorPlaceholder-root': {
    position: 'absolute',
    padding: size === 'medium' ? '14px 12px 13px 12px' : '8.5px 14px',
    pointerEvents: 'none',
    color: theme.palette.text.secondary,
    ...theme.typography.body1,
  },
  '& .public-DraftEditorPlaceholder-inner': {},
  '& .public-DraftEditor-content': {
    whiteSpace: 'break-spaces!important',
    padding: size === 'medium' ? '14px 12px 13px 12px' : '8.5px 14px',
  },
}));

const styleMap = {
  HIGHLIGHT: {
    backgroundColor: '#D6EBFF', // Example: light blue background
  },
};

const RichTextInput = React.forwardRef((props, ref) => {
  const {
    editorState,
    placeholder,
    maxLength,
    size,
    disabled,
    onChange,
    keyBindingFn,
    blockStyleFn,
    onTextInsertion,
    onKeyCommand,
    plugins,
    formatters,
    onCallbackFormatterSelected,
    sx,
    ...rest
  } = props;
  const isMaxLengthHandled = (nextLength, maxLength, currentEditorState) => {
    if (!maxLength) {
      return 'not-handled';
    }
    const currentLength = currentEditorState
      .getCurrentContent()
      .getPlainText('').length;
    return currentLength + nextLength > maxLength ? 'handled' : 'not-handled';
  };

  const handleChange = (newEditorState) => {
    const contentChanged = !Immutable.is(
      editorState.getCurrentContent(),
      newEditorState.getCurrentContent(),
    );
    onChange(newEditorState, contentChanged);
  };

  const handleKeyCommand = (command, currentEditorState) => {
    const newState = RichUtils.handleKeyCommand(currentEditorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }

    const selectedFormatter = formatters?.find(
      (formatter) => formatter.name === command,
    );
    if (selectedFormatter?.type === 'callback') {
      onCallbackFormatterSelected(selectedFormatter.name);
      return 'handled';
    } else if (selectedFormatter) {
      const newEditorState =
        selectedFormatter.type === 'inline'
          ? RichUtils.toggleInlineStyle(
              currentEditorState,
              selectedFormatter.styleName,
            )
          : RichUtils.toggleBlockType(
              currentEditorState,
              selectedFormatter.blockType,
            );
      onChange(newEditorState, true);
      return 'handled';
    }

    return Boolean(onKeyCommand)
      ? onKeyCommand(command, currentEditorState)
      : 'not-handled';
  };

  const handleBeforeInput = (chars, currentEditorState) => {
    // Early exit if the text with the new character will be over the specified max length.
    if (maxLength) {
      const currentLength = currentEditorState
        .getCurrentContent()
        .getPlainText('').length;
      if (currentLength + 1 > maxLength) {
        return 'handled';
      }
    }

    return Boolean(onTextInsertion)
      ? onTextInsertion(chars, currentEditorState)
      : 'not-handled';
  };

  const defaultKeyBindingFn = (e) => {
    let binding = keyBindingFn?.(e);
    if (binding) {
      return binding;
    }

    const selectedFormatter = formatters?.find((formatter) =>
      Boolean(formatter.keyBinding?.(e)),
    );
    if (selectedFormatter) {
      return selectedFormatter.name;
    }

    return getDefaultKeyBinding(e);
  };

  const blockStyleFunction = (contentBlock) => {
    const styleName = blockStyleFn?.(contentBlock);
    if (styleName) {
      return styleName;
    }

    const type = contentBlock.getType();
    if (isHeading1(type)) {
      return 'heading1';
    } else if (isHeading2(type)) {
      return 'heading2';
    } else {
      return 'defaultText';
    }
  };

  return (
    <RootBox size={size} sx={sx}>
      <Editor
        ref={ref}
        stripPastedStyles
        readOnly={disabled}
        editorState={editorState}
        placeholder={placeholder}
        onChange={handleChange}
        keyBindingFn={defaultKeyBindingFn}
        blockStyleFn={blockStyleFunction}
        handleKeyCommand={handleKeyCommand}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={(text, html, currentEditorState) =>
          isMaxLengthHandled(text.length, maxLength, currentEditorState)
        }
        handleReturn={(e, currentEditorState) =>
          isMaxLengthHandled(1, maxLength, currentEditorState)
        }
        customStyleMap={styleMap}
        {...rest}
      />
      {plugins}
    </RootBox>
  );
});

RichTextInput.propTypes = {
  editorState: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  formatters: PropTypes.arrayOf(PropTypes.object),
  plugins: PropTypes.arrayOf(PropTypes.element),
  keyBindingFn: PropTypes.func,
  blockStyleFn: PropTypes.func,
  onChange: PropTypes.func,
  onTextInsertion: PropTypes.func,
  onKeyCommand: PropTypes.func,
  onCallbackFormatterSelected: PropTypes.func,
};

RichTextInput.defaultProps = {
  size: 'medium',
  keyBindingFn: () => {},
  onChange: () => {},
  onTextInsertion: () => {},
  onKeyCommand: () => {},
  onCallbackFormatterSelected: () => {},
};

export default RichTextInput;
