import React, {useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Typography,
    CardHeader,
    Grid,
    TextField,
    CardActionArea,
    Container,
    Paper,
    FormControlLabel,
    Checkbox,
    alpha,
    InputAdornment, Tooltip
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FormBlock from "./index";
import {isValidEmail} from "../../utils/textUtils";
import { styled } from '@mui/material/styles';
import { answerRegistrationName, getAnswersError, getReportDataError, reportDataRegistrationName } from './utils';
import { useFormContext } from 'react-hook-form';

const RootTextField = styled(TextField, { name: "FormUserEmailBlock" })(({ theme }) => ({}));

const FormUserEmailBlock = ({ disabled, language}) => {
    const { t } = useTranslation();
    const { register, formState } = useFormContext();
    const errorText = getReportDataError(formState, 'email')?.message;

    return (
        <RootTextField
            fullWidth
            hiddenLabel
            disabled={disabled}
            variant="outlined"
            defaultValue=''
            placeholder={t("emailAddress", {lng: language})}
            error={Boolean(errorText)}
            helperText={Boolean(errorText) ? t(errorText, { lng: language }) : ""}
            InputProps={{
                startAdornment: (
                    <InputAdornment disablePointerEvents position="start">
                        <EmailOutlinedIcon color="action"/>
                    </InputAdornment>
                )
            }}
            {...register(reportDataRegistrationName('email'), { validate: (value) => !value || value === "" || isValidEmail(value) || "emailIsInvalid" })}
        />
    )
};

FormUserEmailBlock.propTypes = {
    language: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};

FormUserEmailBlock.defaultProps = {
};

export default FormUserEmailBlock;
