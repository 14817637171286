import { getReportName, isNullOrEmpty } from './textUtils';
import { downloadFile } from './storageUtils';
import { downloadZip } from 'client-zip';
import { isAttachmentBlock } from './formBlockUtils';
import { ThumbnailPictureSuffix } from '../constants/storage';
import { isImage } from './fileUtils';
import { isEventMessage, isSystemMessage } from './reportUtils';
import BlobCache from './BlobCache';

export async function exportReportToCsv(report, messages, comments, members, statuses, tags, t, storageApi) {
    const files = [
        {
            name: `${report.name}.csv`,
            lastModified: new Date(),
            input: `\ufeff${reportToCsv(report, members, statuses, tags, t)}`,
        },
    ];
    if (messages && messages.length > 0) {
        const messagesFolder = t('reportingPersonConversation').toLowerCase().replace(' ', '_');
        // \ufeff is Byte order mark (BOM), text will be saved as UTF8-BOM. Without it Excel won't be able to properly parse the csv.
        const messagesFile = {
            name: `${messagesFolder}/data.csv`,
            lastModified: new Date(),
            input: `\ufeff${messagesToCsv(messages, members, t)}`,
        };
        const messageAttachments = await getAttachmentFiles(messages, storageApi, messagesFolder);
        files.push(...[messagesFile, ...messageAttachments]);
    }
    const nonEventComments = comments?.filter((comment) => !isEventMessage(comment));
    if (nonEventComments && nonEventComments.length > 0) {
        const commentsFolder = t('comments').toLowerCase().replace(' ', '_');
        const commentsFile = {
            name: `${commentsFolder}/data.csv`,
            lastModified: new Date(),
            input: messagesToCsv(nonEventComments, members, t),
        };
        const commentsAttachments = await getAttachmentFiles(nonEventComments, storageApi, commentsFolder);
        files.push(...[commentsFile, ...commentsAttachments]);
    }

    const zipBlob = await downloadZip(files).blob();
    const url = window.URL.createObjectURL(zipBlob);
    downloadFile(url, `${getReportName(report, t)}.zip`, false);
    window.URL.revokeObjectURL(url);
}

async function getAttachmentFiles(messages, storageApi, folderName) {
    const attachmentKeys = messages.flatMap(getMessageAttachmentKeys);

    const attachments = await Promise.all(
        attachmentKeys.map(async (key) => {
            const fileName = `${folderName}/${getAttachmentPath(key)}`;
            const normalizedKey = isImage(key) ? key.replace(ThumbnailPictureSuffix, '') : key;
            const blobUrl = (await BlobCache.cacheObjectsFromS3([normalizedKey]))[0];
            const response = await fetch(blobUrl);
            return { name: fileName, lastModified: new Date(), input: response };
        }),
    );

    return attachments;
}

function getAttachmentPath(s3Key) {
    return s3Key.replace(ThumbnailPictureSuffix, '').split('/').slice(-2).join('/');
}

function getMessageAttachmentKeys(message) {
    if (!message.content) {
        return [];
    }

    return message.content
        .filter((section) => isAttachmentBlock(section) && section.value && section.value.length > 0)
        .flatMap((section) => section.value);
}

function reportToCsv(report, members, statuses, tags, t) {
    const headers = ['date', 'status', 'assigned', 'dueDate', 'tags', 'description'];
    const rows = [headers.map((header) => t(header)).join(';')];

    const statusName = statuses?.find((item) => item.sk === report.status).name;
    const assignedNames =
        report.assigned?.map((key) => members.find((member) => member.sk === key).name).join(',') ?? '';
    const tagNames = report.tags?.map((key) => tags.find((tag) => tag.sk === key).name).join(',') ?? '';
    const description = convertToCSVQuotedString(
        report.description?.blocks.map((block) => block.text).filter((text) => !isNullOrEmpty(text)),
    );
    rows.push([report.createdAt, statusName, assignedNames, report.dueDate, tagNames, description].join(';'));

    return rows.join('\r\n');
}

function messagesToCsv(messages, members, t) {
    const headers = ['date', 'sender', 'content'];
    const rows = [];
    rows.push(headers.map((header) => t(header)).join(';'));
    const messagesAsRows = messages
        .map((message) => ({
            date: message.createdAt,
            sender: getSenderName(message, members, t),
            content: getMessageContent(message, t),
        }))
        .map(objectToCsvRow);
    rows.push(...messagesAsRows);
    return rows.join('\r\n');
}

function objectToCsvRow(data) {
    return Object.values(data).join(';');
}
function getMessageContent(message, t) {
    if (message.text) {
        return normalizeText(message.text);
    }

    const paragraphs = message.content
        .map((section) => {
            if (!section.value) {
                return null;
            }

            let sectionText = section.label ? `${section.label}:\r\n` : '';
            if (section.type === 'richTextField') {
                const blockTexts = section.value.blocks
                    .map((block) => block.text)
                    .filter((text) => !isNullOrEmpty(text));
                sectionText += blockTexts.length > 0 ? blockTexts.join('\r\n') : '';
            } else if (section.type === 'dateField') {
                sectionText += t('dateShort', { date: new Date(section.value) });
            } else if (section.type === 'attachments') {
                sectionText += section.value.map((key) => getAttachmentPath(key)).join('\r\n');
            } else {
                sectionText += section.value;
            }
            return sectionText;
        })
        .filter(Boolean);

    return convertToCSVQuotedString(paragraphs);
}

function convertToCSVQuotedString(paragraphs) {
    return paragraphs?.length > 0 ? `\"${normalizeText(paragraphs.join('\r\n\r\n'))}\"` : '';
}

function getSenderName(message, members, t) {
    if (isSystemMessage(message)) {
        return t('autoMessage');
    } else if (isEventMessage(message)) {
        return t('event');
    } else if (message.email) {
        return message.email;
    }

    const sender = members.find((member) => member.sk === message.senderKey);
    if (!sender) {
        console.log('no sender, senderKey: %o', message.senderKey);
    }
    return sender?.name ?? t('reportingPerson');
}

function normalizeText(text) {
    return text.replaceAll('"', '""');
}
