import * as types from "./actionTypes";

export const loadLanguagePacks = (languagePacks) => (dispatch, getState) => {
    dispatch({ type: types.LANGUAGE_PACKS_LOADED, payload: { languagePacks } });
}

export const addOrReplaceLanguagePacks = (formKey, languagePacks) => (dispatch, getState) => {
    dispatch({ type: types.LANGUAGE_PACKS_ADDED, payload: { formKey,languagePacks } });
}

export const addOrUpdateLanguagePack = (pk, sk, data) => (dispatch, getState) => {
    dispatch({ type: types.LANGUAGE_PACK_UPDATED, payload: { pk, sk, data} });
}

export const deleteLanguagePack = (pk, sk) => (dispatch, getState) => {
    dispatch({ type: types.LANGUAGE_PACK_DELETED, payload: { pk, sk } });
}

export const deleteWorkspaceLanguagePacks = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.LANGUAGE_PACKS_DELETED, payload: { workspaceKey }});
}