export const activeWorkspacesSelector = (organizationKey) => (state) => {
  return allWorkspacesSelector(organizationKey)(state).filter(
    (workspace) => !workspace.isDeleted,
  );
};

export const allWorkspacesSelector = (organizationKey) => (state) => {
  if (!state.currentUser || !state.members[organizationKey]) {
    return [];
  }

  // Filter out deleted workspaces
  const workspaces = Object.values(state.workspaces);
  const membership = state.members[organizationKey].find(
    (t) => t.sk === state.currentUser.pk,
  );
  if (!membership.access) {
    return workspaces;
  }

  return workspaces.filter((workspace) =>
    membership.access.includes(workspace.pk),
  );
};

export const workspaceSelector = (workspaceKey) => (state) => {
  if (!Boolean(workspaceKey)) {
    return null;
  }

  return state.workspaces[workspaceKey];
};

export const workspaceNameSelector = (workspaceKey) => (state) => {
  if (!Boolean(workspaceKey)) {
    return null;
  }

  return state.workspaces[workspaceKey]?.name;
};
