import React, { useContext, useState } from 'react';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addOrUpdateWorkspace } from '../../state/workspaces/actions';
import { useTranslation } from 'react-i18next';
import { toId } from '../../utils/apiUtils';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { ApiContext } from '../../contexts/ApiContext';
import { addOrReplaceStatuses } from '../../state/statuses/actions';
import { addOrUpdateForm } from '../../state/forms/actions';
import { addOrUpdateVoicemail } from '../../state/voicemails/actions';
import { addOrUpdateEmail } from '../../state/emails/actions';
import { WorkspaceCountry } from '../../constants/workspace';
import { getActiveLanguage } from '../../utils/localizationUtils';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';
import DialogContentText from '@mui/material/DialogContentText';
import WorkspaceCountrySelect from '../WorkspaceCountrySelect';
import LoadingButton from '@mui/lab/LoadingButton';
import { clearState } from '../../state/rootActions';
import { addOrUpdatePolicy } from '../../state/policies/actions';
import Button from '@mui/material/Button';
import { addOrReplaceTags } from '../../state/tags/actions';
import { addOrUpdateReports } from '../../state/reports/actions';

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateWorkspaceDialog = ({ open, onClose }: Props) => {
  const { t, i18n } = useTranslation();
  const { logWorkspaceCreated } = useContext(ActivityLogContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { mutationApi } = useContext(ApiContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [companyCountry, setCompanyCountry] = useState('');
  const [companyCountryError, setCompanyCountryError] = useState<string | null>(
    null,
  );
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setName(value);
    setNameError(Boolean(value) ? null : 'nameIsRequired');
  };

  const handleCompanyCountryChange = (value: WorkspaceCountry) => {
    setCompanyCountry(value);
    setCompanyCountryError(Boolean(value) ? null : 'companyCountryRequired');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (nameError !== null || companyCountryError !== null) {
      return;
    }

    if (!Boolean(name)) {
      setNameError('nameIsRequired');
      return;
    }

    if (!Boolean(companyCountry)) {
      setNameError('companyCountryRequired');
      return;
    }

    try {
      setLoading(true);
      const language = getActiveLanguage(i18n);
      const {
        workspace,
        statuses,
        tags,
        reports,
        form,
        voicemail,
        email,
        policy,
      } = await mutationApi.createWorkspace(
        organizationKey!,
        name,
        language,
        companyCountry as WorkspaceCountry,
        //'/icons/MegaphoneIcon',
      );
      dispatch(addOrUpdateWorkspace(workspace.pk, workspace));
      dispatch(addOrReplaceStatuses(workspace.pk, statuses));
      dispatch(addOrReplaceTags(workspace.pk, tags));
      dispatch(addOrUpdateReports(reports));
      if (form) {
        dispatch(addOrUpdateForm(form.pk, form));
      }
      if (voicemail) {
        dispatch(addOrUpdateVoicemail(voicemail.pk, voicemail));
      }
      if (email) {
        dispatch(addOrUpdateEmail(email.pk, email));
      }
      if (policy) {
        dispatch(addOrUpdatePolicy(policy.pk, policy.sk, policy));
      }
      setLoading(false);
      dispatch(clearState());

      logWorkspaceCreated(workspace.pk);
      setLoading(false);
      navigate(`/app/${toId(organizationKey)}/${toId(workspace.pk)}`);
    } catch (e: any) {
      console.error(e);
      setSubmitError('unknownWorkspaceCreationError');
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="create_workspace_dialog_title"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit,
      }}
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle id="create_workspace_dialog_title">
        {t('createNewWorkspace')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          {t('workspaceDialogSubtitle')}
        </DialogContentText>
        <Stack spacing={3}>
          {Boolean(submitError) && (
            <Alert severity="error">{t(submitError!)}</Alert>
          )}
          <TextField
            id="company-name"
            autoFocus
            required
            fullWidth
            variant="outlined"
            type="text"
            label={t('companyNameFieldLabel')}
            value={name}
            onChange={handleNameChange}
            error={Boolean(nameError)}
            helperText={t(nameError!)}
          />
          <WorkspaceCountrySelect
            required
            fullWidth
            variant="outlined"
            error={Boolean(companyCountryError)}
            helperText={t(companyCountryError!)}
            value={companyCountry}
            onChange={handleCompanyCountryChange}
            label={t('companyCountryFieldLabel')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="outlined"
          size="medium"
          disabled={loading}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          type="submit"
          loading={loading}
        >
          {t('continue')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateWorkspaceDialog;
