import { isCheckboxListBlock, isDropdownBlock } from '../../utils/formBlockUtils';

export const getBlockTemplate = (getValues, blockIndex) => getValues(`pageTemplate.blocks[${blockIndex}]`);

export const getPageTemplate = (getValues) => getValues(`pageTemplate`);

export const getFormTemplate = (getValues) => getValues(`formTemplate`);

export const getAnswers = (getValues) => getValues(`answers`);

export const getReportdata = (getValues) => getValues(`reportData`);

export const getDictionary = (getValues) => getValues('dictionary');

export const getTranslations = (getValues) => getValues(`translations`);

export const getPrivacyPolicy = (getValues) => getValues(`formTemplate.privacyPolicy`);

export const getAnswersError = (formState, blockKey) => formState.errors?.answers?.[blockKey];

export const getReportDataError = (formState, blockKey) => formState.errors?.reportData?.[blockKey];

export const pageTemplateRegistrationName = () => `pageTemplate`;

export const formTemplateRegistrationName = () => `formTemplate`;

export const translationsRegistrationName = () => `translations`;

export const dictionaryRegistrationName = () => `dictionary`;

export const translationRegistrationName = (translationKey) => `translations.${translationKey}`;

export const blocksRegistrationName = () => `pageTemplate.blocks`;

export const privacyPolicyRegistrationName = () => `formTemplate.privacyPolicy`;

export const privacyPolicyFieldRegistrationName = (fieldName) => `formTemplate.privacyPolicy.${fieldName}`;

export const blockTemplateRegistrationName = (blockIndex) => `pageTemplate.blocks[${blockIndex}]`;

export const blockFieldRegistrationName = (blockIndex, fieldName) => `pageTemplate.blocks[${blockIndex}].${fieldName}`;

export const answerRegistrationName = (key) => `answers.${key}`;

export const reportDataRegistrationName = (key) => `reportData.${key}`;

export const answersToMessageContent = (formData, formDefaultValue) => {
    const answers = formData.answers;
    const formTemplate = formDefaultValue.formTemplate;
    const translations = formDefaultValue.translations;

    const messageContent = [];
    for (const blockKey in answers) {
        const answer = answers[blockKey];
        const blockTemplate = findBlockTemplate(formTemplate, blockKey);

        const answerSection = {
            key: blockTemplate.key,
            type: blockTemplate.type,
            label: translations[blockTemplate.label]
        };
        if (isDropdownBlock(blockTemplate)) {
            answerSection.value = translations[answer];
            answerSection.valueKeys = [answer];
        } else if (isCheckboxListBlock(blockTemplate)) {
            answerSection.value = answer?.map(key => translations[key]) ?? [];
            answerSection.valueKeys = answer ?? [];
        } else {
            answerSection.value = answer === "" ? undefined : answer;
        }

        messageContent.push(answerSection);
    }

    return messageContent;
}

const findBlockTemplate = (formTemplate, blockKey) => {
    for (const page of formTemplate.pages) {
        for (const block of page.blocks) {
            if (block.key === blockKey) {
                return block;
            }
        }
    }

    return null;
}