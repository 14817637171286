import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Chip, TableRow } from "@mui/material";

const activeStyle = (theme) => ({
    background: theme.palette.action.hover,
    "& fieldset": {
        borderStyle: "solid",
    },
    "& .MuiSvgIcon-root": {
        visibility: "visible",
    }
});

export default styled((props) => <TableRow hover {...props} />, {
    name: "HoverTableRow",
    shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
    "&:last-child td, &:last-child th": { border: 0 },
    "& .MuiOutlinedInput-root": {
        background: theme.palette.background.paper,
    },
    "& fieldset": {
        borderStyle: "none",
    },
    "& .MuiSvgIcon-root": {
        visibility: "hidden",
    },
    "&:hover": {
        "& fieldset": {
            borderStyle: "solid",
        },
        "& .MuiSvgIcon-root": {
            visibility: "visible",
        },
    },
    "&:focus-within": activeStyle(theme),
    ...(active && activeStyle(theme)),
}));
