import React, { useContext } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import CheckmarkListItem from '../../components/CheckmarkListItem';
import { workspaceFormSelector } from '../../state/forms/selectors';
import { getFormUrl } from '../../utils/formUtils';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import { workspaceVoicemailSelector } from '../../state/voicemails/selectors';
import { workspaceEmailSelector } from '../../state/emails/selectors';
import FaqLink from './FaqLink';
import FaqCodeBlock from './FaqCodeBlock';
import { useResourcePassword } from '../../hooks/useResourcePassword';
import InlineCode from '../../components/InlineCode';
import { TFunction } from 'i18next';
import { Whitelabel } from '../../hooks/useWhitelabel';
import { BoxProps } from '@mui/material/Box/Box';
import theme from '../../theme';
import { DeserializedPublicResource } from '../../types/api';

const RootBox = styled(Box, { name: 'FaqSection' })(({ theme }) => ({}));

const StyledAccordion = styled(MuiAccordion)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  borderBottom: `thin solid ${theme.palette.divider}`,
  '&::before': {
    display: 'none',
  },
}));

const getEntries = (
  t: TFunction,
  form: DeserializedPublicResource,
  voicemail?: DeserializedPublicResource,
  emailInbox?: DeserializedPublicResource,
  voicemailCode?: string,
  whitelabel?: Whitelabel,
) => {
  const components = {
    list: <List dense disablePadding />,
    listItem: <CheckmarkListItem />,
    title: <span style={{ fontWeight: 500 }} />,
    inlineCode: <InlineCode />,
    faqLink: <FaqLink />,
    link: <FaqLink />,
    codeBlock: <FaqCodeBlock />,
  };

  const formUrl = getFormUrl(form, whitelabel);
  let answer2Items = t('whistleblowingFaqAnswer2FormItem', { formUrl });
  if (voicemail) {
    answer2Items += t('whistleblowingFaqAnswer2VoicemailItem', {
      voicemailUrl: voicemail.path,
      voicemailCode: voicemailCode ?? '',
    });
  }
  if (emailInbox) {
    answer2Items += t('whistleblowingFaqAnswer2EmailItem', {
      emailInboxUrl: emailInbox.path,
    });
  }

  const entries = [];
  const stepsCount = 5;
  for (let i = 0; i < stepsCount; i++) {
    if (i === 3) {
      // Temporarily disabled 4th faq question
      continue;
    }
    entries[i] = {
      question: t(`whistleblowingFaqQuestion${i + 1}`),
      answer: (
        <Trans
          i18nKey={`whistleblowingFaqAnswer${i + 1}`}
          components={components}
          values={{ formUrl, answer2Items }}
        />
      ),
    };
  }

  return entries;
};

const FaqSection = (props: BoxProps) => {
  const { t } = useTranslation();
  const [expandedAccordions, setExpandedAccordions] = React.useState<boolean[]>(
    [],
  );
  const whitelabel = useContext(WhitelabelContext);
  const workspaceKey = useWorkspaceKey();
  const form = useSelector(workspaceFormSelector(workspaceKey));
  const voicemail = useSelector(workspaceVoicemailSelector(workspaceKey));
  const emailInbox = useSelector(workspaceEmailSelector(workspaceKey));
  const { resourcePassword: voicemailPassword } = useResourcePassword(
    voicemail?.hasPassword ? voicemail.pk : null,
  );
  const entries = getEntries(
    t,
    form,
    voicemail,
    emailInbox,
    voicemailPassword?.password,
    whitelabel,
  );

  const handleChange =
    (index: number) => (event: React.SyntheticEvent, expanded: boolean) => {
      setExpandedAccordions((prev) => {
        const newValue = [...prev];
        newValue[index] = expanded;
        return newValue;
      });
    };

  return (
    <RootBox {...props}>
      <Typography paragraph variant="h5">
        {t('faq')}
      </Typography>
      <Divider />
      {entries.map((entry, index) => (
        <MuiAccordion
          disableGutters
          square
          key={entry.question}
          elevation={0}
          expanded={expandedAccordions[index] ?? false}
          onChange={handleChange(index)}
          sx={{
            py: 1,
            borderBottom: 1,
            borderColor: 'divider',
            '&::before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: 0,
              '& .MuiAccordionSummary-content': { my: 0, py: 1 },
            }}
          >
            <Typography variant="subtitle1">{entry.question}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ pb: 1 }}>
            <Typography component="div" sx={{ whiteSpace: 'pre-line' }}>
              {entry.answer}
            </Typography>
          </AccordionDetails>
        </MuiAccordion>
      ))}
    </RootBox>
  );
};

export default FaqSection;
