export function isDevelopmentEnvironment() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export function isProductionEnvironment() {
  return process.env.NODE_ENV === 'production';
}

export function isWhitelabelOrigin() {
  return (
    !window.location.origin.endsWith('app.safetalk.io') &&
    !window.location.origin.endsWith('stage.safetalk.io') &&
    !window.location.origin.startsWith('http://localhost') &&
    !window.location.origin.startsWith('https://www.safetalk.io')
  );
}

export function getWindowUrl() {
  // Handle references from www.safetalk.io by replacing www with app.
  return window.location.href.replace('www', 'app').replace('_internal', '');
}
