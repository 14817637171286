import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { validateEmail } from '../../utils/validationUtils';
import HelperTextErrorIcon from '../../components/HelperTextErrorIcon';
import { ApiContext } from '../../contexts/ApiContext';
import { isProductionEnvironment } from '../../utils/appUtils';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { TRIAL_DAYS } from '../../constants';
import { checkUserExists } from '../../graphql/queries';

const forbiddenDomains: string[] = [
  //'gmx.com',
];

interface Props {
  onSubmit: (email: string) => void;
}

const RootBox = styled(Box, { name: 'SignupPage1' })(({ theme }) => ({}));

const SignupPage1 = ({ onSubmit }: Props) => {
  const { queryApi, mutationApi, authApi } = useContext(ApiContext);
  const { t } = useTranslation();
  const whitelabel = useContext(WhitelabelContext);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setEmail(value);

    if (
      isProductionEnvironment() &&
      forbiddenDomains.some((domain) => value.endsWith(domain))
    ) {
      setEmailError('emailDomainNotAllowed');
      return;
    }

    setEmailError(!Boolean(value) ? 'emailIsRequired' : null);
    setSubmitError(null);
  };

  const handleTermsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event.currentTarget.checked;
    setTermsChecked(checked);
    setTermsError(!checked);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (emailError !== null) {
      return;
    }

    const emailValidationResult = validateEmail(email);

    if (emailValidationResult) {
      setEmailError(emailValidationResult);
      return;
    }

    if (!termsChecked) {
      setTermsError(!termsChecked);
      return;
    }

    try {
      setLoading(true);
      const exists = (await queryApi.checkUserExists(email))?.exists;
      if (exists) {
        setLoading(false);
        setEmailError('UsernameExistsException');
        return;
      }

      // Send email notification about signup
      await mutationApi.sendSignupNotification(email);

      setLoading(false);
      onSubmit(email);
    } catch (e) {
      console.log(e);
      setSubmitError('unknownSignupError');
      setLoading(false);
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs" sx={{ py: 3 }}>
        <Box sx={{ textAlign: 'center' }} mb={5}>
          <Typography variant="h4" component="h1">
            {t('signupPage1Title')}
          </Typography>
          <Typography variant="bodyLarge" color="textSecondary">
            {t('signupPage1Subtitle', { count: TRIAL_DAYS })}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <TextField
              id="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              autoComplete="email"
              type="email"
              label={t('yourWorkEmail')}
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              helperText={t(emailError!)}
            />
            <FormControl
              error={termsError}
              sx={{ mt: 2, position: 'relative' }}
            >
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mb: 0, mr: 0 }}
                control={
                  <Checkbox
                    size="small"
                    sx={{ mt: -1 }}
                    checked={termsChecked}
                    onChange={handleTermsCheckboxChange}
                  />
                }
                label={
                  <Typography variant="body1">
                    <Trans i18nKey="signupLegal">
                      Don't delete - this random text increases component count
                      in the Trans component
                      <Link
                        href={
                          whitelabel?.termsOfServiceUrl ??
                          'https://www.safetalk.io/en/terms-and-conditions'
                        }
                        color="link.main"
                        underline="always"
                        target="_blank"
                      >
                        x
                      </Link>
                      <Link
                        href={
                          whitelabel?.privacyPolicyUrl ??
                          'https://www.safetalk.io/en/privacy-policy'
                        }
                        color="link.main"
                        underline="always"
                        target="_blank"
                      >
                        x
                      </Link>
                    </Trans>
                  </Typography>
                }
              />
              {termsError && (
                <FormHelperText sx={{ ml: 0 }}>
                  <HelperTextErrorIcon />
                  &nbsp;{t('termsNotAcceptedError')}
                </FormHelperText>
              )}
            </FormControl>
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              loading={loading}
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default SignupPage1;
