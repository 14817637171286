import * as types from "./actionTypes";
import {toId} from "../../utils/apiUtils";

export const loadStatuses = (statuses) => async (dispatch, getState) => {
    dispatch({ type: types.STATUSES_LOADED, payload: {statuses} });
}

export const addOrReplaceStatuses = (workspaceKey, statuses) => async (dispatch, getState) => {
    dispatch({ type: types.STATUSES_ADDED, payload: {workspaceKey, statuses} });
}

export const addOrUpdateStatus = (pk, sk, data) => async (dispatch, getState) => {
    dispatch({ type: types.STATUS_UPDATED, payload: {pk, sk, data} });
}

export const deleteStatus = (pk, sk) => async (dispatch, getState) => {
    dispatch({ type: types.STATUS_DELETED, payload: { pk, sk } });
}

export const deleteWorkspaceStatuses = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.STATUSES_DELETED, payload: { workspaceKey }});
}