import React from "react";
import PropTypes from "prop-types";
import FormEditorAttachmentsBlock from "./FormEditorAttachmentsBlock";
import FormViewAttachmentsBlock from "./FormViewAttachmentsBlock";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const RootBox = styled(Box, { name: "FormAttachmentsBlock" })(({ theme }) => ({}));

const FormAttachmentsBlock = ({ blockIndex, language, mode, ...rest }) => {
    return (
        <RootBox {...rest}>
            {mode === "view" ? (
                <FormViewAttachmentsBlock blockIndex={blockIndex} language={language} />
            ) : (
                <FormEditorAttachmentsBlock blockIndex={blockIndex} language={language} />
            )}
        </RootBox>
    );
};

FormAttachmentsBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired,
};

FormAttachmentsBlock.defaultProps = {
    mode: "view",
};

export default FormAttachmentsBlock;
