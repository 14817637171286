import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
// import WorkspaceAvatar from "../../components/WorkspaceAvatar";
import { toId } from '../../utils/apiUtils';
import { Link } from 'react-router-dom';
import {
  LargePictureSuffix,
  SmallPictureSuffix,
} from '../../constants/storage';
import ResourceAvatar from '../../components/ResourceAvatar';
import { useOrganization } from '../../hooks/useOrganization';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';

const useStyles = makeStyles((theme) => ({
  workspaceTile: {
    width: theme.spacing(14),
    minWidth: theme.spacing(14),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: theme.spacing(3, 0)
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    borderRadius: 6,
    padding: theme.spacing(2),
  },
  link: {
    textDecoration: 'none!important',
  },
  avatarContainer: {
    // padding: theme.spacing(0.75),
    borderRadius: 4,
    border: '2px solid transparent',
    // '&:hover': {
    //     borderColor: theme.palette.grey[300]
    // }
  },
  tileHeader: {
    //maxHeight: `${theme.typography.body1.lineHeight * 2}em`,
    //marginTop: theme.spacing(1),
    width: '100%',
    height: theme.spacing(5),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 1),
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const WorkspaceTile = ({ workspace, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const organizationKey = useOrganizationKey();
  const href = `/app/${toId(organizationKey)}/${toId(workspace.pk)}/overview`;

  return (
    <Link className={classes.link} to={href}>
      <div className={classes.workspaceTile} {...rest}>
        <div className={classes.avatarContainer}>
          <ResourceAvatar
            component="span"
            variant="rounded"
            pictureSize="large"
            name={workspace.name}
            picture={workspace.picture}
            size={80}
            sx={{
              display: 'inline-block',
              borderRadius: 1.5,
            }}
          />
        </div>
        <Typography
          className={classes.tileHeader}
          variant="body2"
          color="textPrimary"
          align="center"
        >
          {workspace.name}
        </Typography>
      </div>
    </Link>
  );
};

export default WorkspaceTile;
