import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Box, Container, Stack, Typography } from "@mui/material";
import { CARD_SHADOW3 } from "../../constants";
import { LargePictureSuffix, SmallPictureSuffix } from "../../constants/storage";
import ResourceAvatar from "../ResourceAvatar";
import { styled } from "@mui/material/styles";
import FormPaperLogo from '../FormPaperLogo/FormPaperLogo';

const RootBox = styled(Box, { name: "FormPaper" })(({ theme }) => ({
    padding: theme.spacing(4, 6),
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.background.paper,
    ...CARD_SHADOW3,
}));

const FormPaper = forwardRef((props, ref) => {
    const { children, workspace, ...rest } = props;

    return (
        <RootBox ref={ref} {...rest}>
            {workspace && (
                <FormPaperLogo workspace={workspace}/>
            )}
            <Box sx={{ "&& > *": { mt: 4 } }}>{children}</Box>
        </RootBox>
    );
});

FormPaper.propTypes = {
    workspace: PropTypes.shape({
        name: PropTypes.string.isRequired,
        picture: PropTypes.string,
    }),
};

FormPaper.defaultProps = {};

export default FormPaper;
