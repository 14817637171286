import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_FORMATTERS,
  deserializeEditorState,
  emptyEditorState,
  serializeEditorState,
} from '../../utils/richTextUtils';
import OutlinedDecorator from '../../components/OutlinedDecorator';
import FormattingToolbar from '../../components/FormattingToolbar';
import { styled, Theme } from '@mui/material/styles';
import UrlPopover from '../../components/UrlPopover';
import RichTextInput from '../../components/RichTextInput';
import { EditorState, RawDraftContentState } from 'draft-js';
import { FormattingToolbarClassName } from './richTextFieldClasses';
import { SxProps } from '@mui/material';

const RootOutlinedDecorator = styled(OutlinedDecorator, {
  name: 'RichTextField',
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ theme, fullWidth }) => ({ ...(fullWidth && { width: '100%' }) }));

export type ToolbarControlType =
  | 'heading1'
  | 'heading2'
  | 'divider'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'unorderedList'
  | 'orderedList'
  | 'link';

interface Props {
  value: RawDraftContentState;
  placeholder?: string;
  fullWidth?: boolean;
  toolbarControls: ToolbarControlType[];
  onChange: (newValue: RawDraftContentState) => void;
  sx?: SxProps<Theme>;
}

const RichTextField = ({
  value,
  placeholder,
  fullWidth,
  onChange,
  toolbarControls = [
    'bold',
    'italic',
    'underline',
    'unorderedList',
    'orderedList',
    'link',
  ],
  ...rest
}: Props) => {
  const [focused, setFocused] = useState(false);
  const [urlPopoverOpen, setUrlPopoverOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const editorRootRef = useRef();
  const contentChangedRef = useRef(false);
  const isInternalChange = useRef(false);
  const linkDecoratorProps = {
    showPopperOnHover: true,
    editorRootRef,
    onEditorStateChange: (
      newEditorState: EditorState,
      contentChanged: boolean,
      editingLink: boolean,
    ) => {
      setReadOnly(editingLink);
      handleEditorStateChange(newEditorState, contentChanged);
    },
  };
  const [editorState, setEditorState] = useState(() =>
    // @ts-ignore
    emptyEditorState(linkDecoratorProps),
  );

  useEffect(() => {
    if (!isInternalChange.current) {
      if (focused || urlPopoverOpen) {
        // Don't update state when the editor is focused as it can lead to unpredictable behavior like caret
        // jumping around or text being added to random places.
        return;
      }

      if (!value) {
        // @ts-ignore
        setEditorState(emptyEditorState(linkDecoratorProps));
        return;
      }

      // @ts-ignore
      let deserializedState = deserializeEditorState(value, linkDecoratorProps);
      setEditorState(deserializedState);
    }

    isInternalChange.current = false; // Reset after processing
  }, [value]);

  const handleEditorStateChange = (
    newEditorState: EditorState,
    contentChanged: boolean,
  ) => {
    if (contentChanged) {
      contentChangedRef.current = true;
    }
    setEditorState(newEditorState);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);

    if (readOnly || urlPopoverOpen || !contentChangedRef.current) {
      // Don't save changes when a link is being edited. Editing link within the RichTextLink popper causes
      // the RichInput to lose focus.
      return;
    }

    contentChangedRef.current = false;

    const serializedState = serializeEditorState(editorState);
    isInternalChange.current = true; // Mark change as internal
    onChange(serializedState);
  };

  const handleFormatterCallback = (actionType: string) => {
    if (editorState.getSelection().isCollapsed()) {
      return;
    }

    if (actionType === 'link') {
      setUrlPopoverOpen(true);
    }
  };

  const handleUrlPopoverClose = () => {
    setUrlPopoverOpen(false);
  };

  return (
    <RootOutlinedDecorator
      fullWidth={fullWidth}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...rest}
    >
      <RichTextInput
        ref={editorRootRef}
        // @ts-ignore
        editorState={editorState}
        readOnly={readOnly}
        formatters={DEFAULT_FORMATTERS}
        placeholder={placeholder}
        onChange={handleEditorStateChange}
        onCallbackFormatterSelected={handleFormatterCallback}
        sx={{
          backgroundColor: 'background.paper',
        }}
        plugins={[
          <UrlPopover
            key={'RichTextField-UrlPopover'}
            open={urlPopoverOpen}
            editorRootRef={editorRootRef}
            editorState={editorState}
            onEditorStateChange={handleEditorStateChange}
            onClose={handleUrlPopoverClose}
          />,
        ]}
      />
      <FormattingToolbar
        className={FormattingToolbarClassName}
        editorRootRef={editorRootRef}
        editorState={editorState}
        formatters={DEFAULT_FORMATTERS}
        layout={toolbarControls}
        onChange={handleEditorStateChange}
        onCallbackFormatterSelected={handleFormatterCallback}
      />
    </RootOutlinedDecorator>
  );
};

export default RichTextField;
