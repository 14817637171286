import React, { useState } from "react";
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormBuilderPage from "./FormBuilderPage";
import { useSelector } from "react-redux";
import { useWorkspaceKey } from "../../hooks/useWorkspaceKey";
import { workspaceSelector } from "../../state/workspaces/selectors";
import PropTypes from "prop-types";
import FormPage from '../FormView/FormPage';

const RootContainer = styled(Container, { name: "FormBuilder" })(({ theme }) => ({
    padding: theme.spacing(4),
}));

const FormBuilder = ({ language, pageIndex, mode, pageCount }) => {
    const workspaceKey = useWorkspaceKey();
    const workspace = useSelector(workspaceSelector(workspaceKey));

    return (
        <RootContainer maxWidth="sm">
            {mode === "edit" ? (
                <FormBuilderPage pageIndex={pageIndex} pageCount={pageCount} language={language} workspace={workspace} />
            ) : (
                <FormPage pageIndex={pageIndex} pageCount={pageCount} language={language} workspace={workspace} />
            )}
        </RootContainer>
    );
};

FormBuilder.propTypes = {
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired,
};

FormBuilder.defaultProps = {};

export default FormBuilder;
