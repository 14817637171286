import {
  blue,
  brown,
  cyan,
  green,
  grey,
  indigo,
  lightBlue,
  lime,
  pink,
  purple,
  red,
} from '@mui/material/colors';
import { LargePictureSuffix, SmallPictureSuffix } from '../constants/storage';

export const FONT_SIZES = {
  xSmall: '0.75rem',
  small: '0.875rem',
  medium: '1.1rem', // 1.25rem
  large: '1.25rem',
  xLarge: '1.4rem',
};

// TODO: Replace
export const AVATAR_SIZES = {
  xSmall: 3,
  small: 4,
  medium: 4.5,
  large: 5,
  xLarge: 6,
};

export const getInitials = (name = '', maxLength = 2) => {
  if (!Boolean(name)) {
    return '';
  }

  const words = name
    .replace(' - ', ' ')
    .replace(/\s+/, ' ')
    .split(' ')
    .filter((word) => word.length > 0);

  if (words.length > 1) {
    return words
      .slice(0, Math.min(maxLength, words.length))
      .map((word) => word[0].toUpperCase())
      .join('');
  } else {
    return words[0].slice(0, Math.min(maxLength, words[0].length));
  }
};

export const getAvatarColor = (name) => {
  const baseColors = [
    blue[700],
    pink[600],
    indigo[700],
    lightBlue[700],
    cyan[800],
    green[800],
    brown[400],
    red[700],
  ];

  if (!Boolean(name)) {
    return baseColors[0];
  }

  const pos = name
    ? Math.min(
        Math.max(Math.floor((name.toLowerCase().charCodeAt(0) - 97) / 3.25), 0),
        7,
      )
    : 0;
  return baseColors[pos];
};

export const getSmallPictureVariant = (picture) => {
  return picture?.replace(LargePictureSuffix, SmallPictureSuffix);
};

export const getLargePictureVariant = (picture) => {
  return picture?.replace(SmallPictureSuffix, LargePictureSuffix);
};

export function isIconPicture(picture) {
  return picture?.startsWith('/icons');
}
