import React, { useContext, useEffect, useState } from 'react';
import { Box, Collapse, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useDispatch, useSelector } from 'react-redux';
import { useResourcePassword } from '../../hooks/useResourcePassword';
import FixedLabelTextField from '../../components/FixedLabelTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApiContext } from '../../contexts/ApiContext';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { workspaceFormSelector } from '../../state/forms/selectors';
import SwitchSection from '../../components/SwitchSection/SwitchSection';
import { addOrUpdateForm } from '../../state/forms/actions';
import { addOrUpdateResourcePassword } from '../../state/resourcePasswords/actions';

const FormPasswordSection = () => {
    const { mutationApi } = useContext(ApiContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const organizationKey = useOrganizationKey();
    const workspaceKey = useWorkspaceKey();
    const form = useSelector(workspaceFormSelector(workspaceKey));
    const { resourcePassword } = useResourcePassword(form.pk);
    const [hasPassword, setHasPassword] = useState(form?.hasPassword ?? false)
    const [password, setPassword] = useState(resourcePassword?.password ?? "");
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [passwordSaving, setPasswordSaving] = useState(false);

    // TODO: Getting error when users leaves the page before the password is fetched
    useEffect(() => {
        if (resourcePassword && password !== resourcePassword.password) {
            setPassword(resourcePassword.password);
        }
    }, [resourcePassword?.pk])

    const handleFormHasPasswordChange = async (event) => {
        const checked = event.target.checked;
        setHasPassword(checked);
        dispatch(addOrUpdateForm(form.pk, {hasPassword: checked}));
        if (checked && resourcePassword) {
            await mutationApi.updatePublicResource(form.pk, form.sk, {hasPassword: true});
        } else if (!checked) {
            await mutationApi.updatePublicResource(form.pk, form.sk, {hasPassword: false});
        }
    }

    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPasswordError(value.length === 0 ? t('passwordIsRequired') : null);
        setPassword(value);
        setPasswordChanged(true);
    }

    const handleCancelPasswordButtonClick = () => {
        setPassword(resourcePassword?.password);
        setPasswordError(null);
        setPasswordChanged(false);
        if (!form.hasPassword) {
            setHasPassword(false);
        }
    }

    const handleSavePasswordButtonClick = async () => {
        setPasswordChanged(false);
        setPasswordSaving(true);
        dispatch(addOrUpdateResourcePassword(form.pk, password));
        if (!resourcePassword?.password) {
            await mutationApi.updatePublicResource(form.pk, form.sk, {hasPassword: true});
            await mutationApi.createResourcePassword(organizationKey, workspaceKey, form.pk, password);
        } else {
            await mutationApi.updateResourcePassword(form.pk, password);
        }
        setPasswordSaving(false);
    }

    return (
        <Box>
            <SwitchSection header={t('passwordProtection')} subheader={t('passwordProtectionSubtitle')} checked={hasPassword} onChange={handleFormHasPasswordChange}/>
            <Collapse in={hasPassword}>
                <Box mt={4}>
                    <FixedLabelTextField
                        required
                        fullWidth
                        label={t("password")}
                        variant="outlined"
                        value={password}
                        error={Boolean(passwordError)}
                        helperText={passwordError ?? " "}
                        onChange={handlePasswordChange}
                    />
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2} sx={{ mt: 0.5 }}>
                        <Button
                            color="default"
                            size="medium"
                            variant="outlined"
                            disabled={!passwordChanged}
                            onClick={handleCancelPasswordButtonClick}
                        >
                            {t("cancel")}
                        </Button>
                        <LoadingButton
                            color="primary"
                            size="medium"
                            variant="contained"
                            disabled={!passwordChanged || Boolean(passwordError) || passwordSaving}
                            loading={passwordSaving}
                            onClick={handleSavePasswordButtonClick}
                        >
                            {t("save")}
                        </LoadingButton>
                    </Stack>
                </Box>
            </Collapse>
        </Box>
    );
};

export default FormPasswordSection;
