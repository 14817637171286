import React from 'react';
import CodeBlock from '../../components/CodeBlock';
import { CodeBlockProps } from '../../components/CodeBlock/CodeBlock';

interface Props extends Omit<CodeBlockProps, 'text'> {
  children?: string[];
}

const FaqCodeBlock = ({ children, ...rest }: Props) => {
  return <CodeBlock fullWidth text={children?.[0] || ''} {...rest} />;
};

export default FaqCodeBlock;
