import * as types from './actionTypes';
import { PolicyActionTypes } from './actionTypes';
import { groupBy, updateGroupItem } from '../../utils/arrayUtils';
import { AppState } from '../../reducer';
import { DeserializedPrivateResource } from '../../types/api';

const initialState: AppState['policies'] = {};

export default function policiesReducer(
  state = initialState,
  action: PolicyActionTypes,
) {
  switch (action.type) {
    case types.LOAD_POLICIES: {
      const policies = action.payload.policies;
      return groupBy(policies, 'pk');
    }
    case types.UPDATE_POLICY: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      return updateGroupItem(
        state,
        pk,
        (policy: DeserializedPrivateResource) => policy.sk === sk,
        { ...action.payload.data, pk: pk, sk: sk },
      );
    }
    case types.DELETE_POLICY: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      const newState = { ...state };
      newState[pk] = state[pk].filter((t) => t.sk !== sk);
      return newState;
    }
    default:
      return state;
  }
}
