import { AppState } from '../../reducer';

export const workspaceInternalPolicySelector =
  (workspaceKey: string | null) => (state: AppState) => {
    if (!Boolean(workspaceKey)) {
      return null;
    }

    return state?.policies[workspaceKey!]?.[0];
  };
