import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { memberSelector } from '../../state/members/selectors';
import { useSelector } from 'react-redux';
import { tagsSelector } from '../../state/tags/selectors';
import Tag from '../Tag';
import Status from '../Status';
import { statusSelector } from '../../state/statuses/selectors';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import Timestamp from '../Timestamp';

const isTagEvent = (event) => event.startsWith('tag');
const isAssigneeEvent = (event) => event.startsWith('assignee');
const isStatusEvent = (event) => event.startsWith('status');
const isDueDateEvent = (event) => event.startsWith('dueDate');
const isReportCreatedEvent = (event) => event === 'reportCreated';

const EventLogMessage = ({
  event,
  createdAt,
  workspaceKey,
  senderKey,
  value,
  sx,
}) => {
  const { t } = useTranslation();
  const organizationKey = useOrganizationKey();
  const sender = useSelector(memberSelector(organizationKey, senderKey));
  const tag = useSelector(
    tagsSelector(workspaceKey, isTagEvent(event) ? value : null),
  );
  const assignee = useSelector(
    memberSelector(organizationKey, isAssigneeEvent(event) ? value : null),
  );
  const status = useSelector(
    statusSelector(workspaceKey, isStatusEvent(event) ? value : null),
  );
  const localizationKey =
    isDueDateEvent(event) && !value ? 'dueDateClearedEvent' : `${event}Event`;

  let translationValue;
  if (isDueDateEvent(event) && value) {
    translationValue = new Date(value);
  } else if (isAssigneeEvent(event)) {
    translationValue = assignee?.name;
  }
  const text = t(localizationKey, {
    senderName: sender?.name,
    value: translationValue,
  });

  return (
    <Stack direction="row" spacing={1} sx={sx}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{ flex: '1 1 auto' }}
        component="div"
      >
        {text}
        {isTagEvent(event) && (
          <Tag value={tag} sx={{ scale: '0.8', mt: '-0.125rem' }} />
        )}
        {isStatusEvent(event) && (
          <Status
            workspaceKey={status.pk}
            statusKey={status.sk}
            sx={{ scale: '0.8', mt: '-0.125rem' }}
          />
        )}
      </Typography>
      <Timestamp value={createdAt} align="right" />
    </Stack>
  );
};

EventLogMessage.propTypes = {
  event: PropTypes.oneOf([
    'reportCreated',
    'dueDateChanged',
    'assigneeAdded',
    'assigneeRemoved',
    'statusChanged',
    'tagAdded',
    'tagRemoved',
    'descriptionChanged',
    'nameChanged',
  ]).isRequired,
  createdAt: PropTypes.string.isRequired,
  workspaceKey: PropTypes.string.isRequired,
  senderKey: PropTypes.string,
  value: PropTypes.string,
};

EventLogMessage.defaultProps = {};

export default EventLogMessage;
