import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { LANGUAGE_LABELS } from '../../constants/languages';
import { ApiContext } from '../../contexts/ApiContext';
import {
    getAllLanguagePacksLanguages,
    getLanguagePackByLanguage,
    getLanguagePackLanguage
} from '../../utils/formUtils';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import DialogHeader from '../../components/DialogHeader/DialogHeader';
import LoadingButton from '@mui/lab/LoadingButton';
import { allLanguagePacksSelector } from '../../state/languagePacks/selectors';
import { useSelector } from 'react-redux';
import { getTranslations } from '../../components/FormBlock/utils';
import { useFormContext } from 'react-hook-form';

const AddFormLanguageDialog = ({ formKey, open, usedLanguages, sourceLanguage, onClose, onTranslationCompleted }) => {
    const theme = useTheme();
    const { queryApi } = useContext(ApiContext);
    const { t } = useTranslation();
    const organizationKey = useOrganizationKey();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [loading, setLoading] = useState(false);
    const availableLanguages = Object.keys(LANGUAGE_LABELS).filter((lang) => !usedLanguages.includes(lang));
    const { setValue, getValues, watch } = useFormContext();

    useEffect(() => {
        if (open) {
            setSelectedLanguages([]);
        }
    }, [open])


    const addSelectedLanguage = (value) => {
        setSelectedLanguages((prev) => [...prev, value]);
    };

    const removeSelectedLanguage = (value) => {
        setSelectedLanguages((prev) => {
            const index = prev.findIndex((lang) => lang === value);
            const newState = [...prev];
            newState.splice(index, 1);
            return newState;
        });
    };

    const handleLanguageSelected = (value) => {
        if (selectedLanguages.includes(value)) {
            removeSelectedLanguage(value);
        } else {
            addSelectedLanguage(value);
        }
    };

    const handleDialogClose = (event, reason) => {
        // Don't close the dialog if it is waiting for a translation to complete
        if (!loading) {
            onClose();
        }
    };

    const handleConfirmButtonClick = async () => {
        if (selectedLanguages.length === 0) {
            onClose();
            return;
        }

        setLoading(true);

        const sourceTranslations = getTranslations(getValues);
        // TODO: Cannot do all translations in a single request because it can timeout if too many languages were selected.
        const translated = [];
        let failedLanguages = [];
        for (const targetLanguage of selectedLanguages) {
            const translatedLanguagePacks = await queryApi.translateLanguagePack(
                organizationKey,
                formKey,
                sourceTranslations,
                sourceLanguage,
                [targetLanguage]
            );

            if (translatedLanguagePacks.length === 0) {
                failedLanguages.push(targetLanguage);
                continue;
            }

            translated.push(translatedLanguagePacks[0]);
        }

        setLoading(false);
        onTranslationCompleted({ translated, failedLanguages });
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="add-form-language-title"
            sx={{
                "& .MuiDialog-paper": {
                    maxHeight: 480,
                    width: {
                        sm: 600, // theme.breakpoints.up('sm')
                    },
                },
            }}
        >
            <DialogHeader
                id="add-form-language-title"
                showExitButton
                showDivider
                onExitClick={handleDialogClose}
                ExitButtonProps={{ disabled: loading }}
            >
                {t("addFormLanguageDialogTitle")}
            </DialogHeader>
            <DialogContent sx={{px: 4}}>
                <List
                    dense
                    sx={{
                        py: 0,
                        overflow: "auto",
                        maxHeight: "100%",
                    }}
                >
                    {availableLanguages.map((lang) => (
                        <ListItem
                            key={`availableLanguageItem-${lang}`}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={() => handleLanguageSelected(lang)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedLanguages.includes(lang)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `language-item-${lang}` }}
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    <Avatar alt={lang} src={`/static/icons/flags/${lang}.svg`} sx={{ width: 24, height: 24, border: 1, borderColor: 'grey.300' }}/>
                                </ListItemAvatar>
                                <ListItemText id={`language-item-${lang}`} primary={t(lang)} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button size="medium" color="default" variant="outlined" disabled={loading} onClick={onClose}>
                    {t("cancel")}
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    size="medium"
                    loading={loading}
                    onClick={handleConfirmButtonClick}
                >
                    {t("confirm")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

AddFormLanguageDialog.propTypes = {
    open: PropTypes.bool,
    formKey: PropTypes.string.isRequired,
    usedLanguages: PropTypes.array.isRequired,
    sourceLanguage: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onTranslationCompleted: PropTypes.func,
};

AddFormLanguageDialog.defaultProps = {
    onClose: () => {},
    onTranslationCompleted: () => {},
};

export default AddFormLanguageDialog;
