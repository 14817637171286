import React, { useEffect, useState } from 'react';
import { Box, useTheme, Button, FormControl, Typography, Tooltip, IconButton, Stack } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import PropTypes from "prop-types";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearButton from "../ClearButton";
import csLocale from "dayjs/locale/cs";
import enLocale from "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import TodayIcon from "@mui/icons-material/Today";
import { styled } from "@mui/material/styles";
import ReportProgress from "../ReportProgress/ReportProgress";
import { daysDiff } from "../../utils/time";
import { currentUserSelector } from "../../state/currentUser/selectors";
import { useSelector } from "react-redux";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const RootDiv = styled("div", { name: "DueDatePicker" })(({ theme }) => ({
}));

const StyledButton = styled(Button, { name: "DueDatePicker", slot: "button" })(({ theme }) => ({
    color: theme.palette.text.secondary,
    textTransform: "none",
    ...theme.typography.body1,
    marginLeft: '-3px'
}));

const StyledTodayIcon = styled(TodayIcon)(({ theme }) => ({
    borderRadius: "50%",
    border: "1px dashed rgba(0, 0, 0, 0.23)",
    padding: theme.spacing(0.75),
    width: theme.spacing(4),
    height: theme.spacing(4),
}));

// TODO: Use dynamic import emailTemplate
const DueDatePicker = ({ startDate, value, onChange, ...rest }) => {
    const { t} = useTranslation();
    const currentUser = useSelector(currentUserSelector());
    const [pendingValue, setPendingValue] = useState(new Date(value));

    useEffect(() => {
        if (value !== pendingValue) {
            setPendingValue(new Date(value));
        }
    }, [value])

    let elapsedHint = undefined;
    if (startDate && value) {
        const startDateParsed = new Date(startDate);
        const valueParsed = new Date(value);
        const termLength = daysDiff(valueParsed, startDateParsed);
        const elapsed = daysDiff(new Date(), startDateParsed);
        const isOverdue = new Date() > valueParsed;
        const days = Math.abs(termLength - elapsed);
        elapsedHint = isOverdue ? t("daysOverdue", { count: days }) : t("daysRemain", { count: days });
    }

    const handleAccept = (newValue) => {
        onChange(newValue);
    }


    const renderInput = (params) => {
        return (
            <Tooltip title={t("dueDateFieldTooltip")}>
                <StyledButton
                    size="small"
                    component="div"
                    edge='start'
                    onClick={params.InputProps.endAdornment.props.children.props.onClick}
                    ref={params.inputRef}
                    startIcon={!params.inputProps.value ? <StyledTodayIcon /> : undefined}
                >
                    {params.inputProps.value ? (
                        <Stack direction='column'>
                            <Box sx={{ display: "inline-flex", flexWrap: "wrap" }}>
                                <Typography component="span" mr="2px">
                                    {params.inputProps.value}{" "}
                                </Typography>
                                {elapsedHint && (
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        sx={{ color: "text.disabled" }}
                                    >{`(${elapsedHint})`}</Typography>
                                )}
                            </Box>
                            {startDate && (
                                <ReportProgress
                                    disableTooltip
                                    createdAt={startDate}
                                    dueDate={value}
                                    sx={{ width: "100%", mt: 0.5 }}
                                />
                            )}
                        </Stack>
                    ) : (
                        <Typography>{t("dueDateFieldPlaceholder")}</Typography>
                    )}
                </StyledButton>
            </Tooltip>
        );
    };

    // TODO: Fix localization -> crashes when browser locale is set to en-US
    return (
        <RootDiv {...rest}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentUser.language ?? "en"}>
                <DatePicker disablePast value={pendingValue} onChange={setPendingValue} onAccept={handleAccept} renderInput={renderInput} />
            </LocalizationProvider>
            {value && <ClearButton onClick={() => onChange(null)} />}
        </RootDiv>
    );
};

DueDatePicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

DueDatePicker.defaultProps = {
    value: null,
    onChange: () => {},
};

export default DueDatePicker;
