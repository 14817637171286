import React, { useContext, useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { activityLogEntriesSelector } from "../../state/activityLog/selectors";
import { useOrganizationKey } from "../../hooks/useOrganizationKey";
import { addActivityLogEntries } from "../../state/activityLog/actions";
import { ApiContext } from "../../contexts/ApiContext";
import { allReportsSelector } from "../../state/reports/selectors";
import { allCommentsSelector, reportCommentsSelector } from "../../state/comments/selectors";
import { toObject } from "../../utils/arrayUtils";
import { allStatusesSelector } from "../../state/statuses/selectors";
import { allTagsSelector } from "../../state/tags/selectors";
import { allMessagesSelector } from "../../state/messages/selectors";
import { isComment, isMessage } from "../../utils/apiUtils";
import { addOrUpdateForm } from "../../state/forms/actions";
import { addOrReplaceLanguagePacks } from "../../state/languagePacks/actions";
import { addMessages } from "../../state/messages/actions";
import { addComments } from "../../state/comments/actions";
import { filterActivityLog } from './utils';

const itemLimit = 100;

export const useFetchActivityLog = () => {
    const { queryApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const organizationKey = useOrganizationKey();
    const comments = useSelector(allCommentsSelector());
    const messages = useSelector(allMessagesSelector());
    const entries = useSelector(activityLogEntriesSelector(organizationKey));
    const nextTokenRef = useRef();
    const [canLoadMore, setCanLoadMore] = useState(true);

    const fetchMissingResources = async (logEntries) => {
        const messagesByKey = toObject(messages, "sk");
        const commentsByKey = toObject(comments, "sk");
        const missingReports = logEntries.filter(
            (entry) =>
                entry.reportKey &&
                ((entry.messageKey && !Boolean(messagesByKey[entry.messageKey])) ||
                    (entry.commentKey && !Boolean(commentsByKey[entry.commentKey])))
        ).map(entry => entry.reportKey);

        if (missingReports.length === 0) {
            return;
        }

        const tasks = [];
        for (const reportKey of missingReports) {
            tasks.push(queryApi.listPublicResources(reportKey));
            tasks.push(queryApi.listPrivateResources(reportKey));
        }

        const fetchedResources = (await Promise.all(tasks)).flat();
        const fetchedMessages = fetchedResources.filter(isMessage);
        const fetchedComments = fetchedResources.filter(isComment);

        batch(() => {
            dispatch(addMessages(fetchedMessages));
            dispatch(addComments(fetchedComments));
        });
    };

    // Fetches log entries newer than the newest one in redux. Used to load missing entries from log head.
    const loadNew = async () => {
        const sk = { gt: entries[0].sk };
        let nextToken = undefined;
        const items = [];
        let isLoaded = false;
        do {
            const response = await queryApi.listActivityLogEntries(organizationKey, sk, undefined, nextToken);
            if (response.items.length === 0) {
                return;
            }

            nextToken = response.nextToken;
            isLoaded = response.items.some((item) => item.sk === entries[0].sk);
            items.push(
                isLoaded
                    ? response.items.filter((item) => !entries.some((entry) => entry.sk === item.sk))
                    : response.items
            );
        } while (nextToken && !isLoaded);
        await fetchMissingResources(items);
        dispatch(addActivityLogEntries(organizationKey, items));
    };

    // Loads more entries from the log tail.
    const loadMore = async (filter = undefined) => {
        if (!canLoadMore) {
            return;
        }

        const sk = !nextTokenRef.current && entries ? { lt: entries[entries.length - 1].sk } : undefined;
        let items = [];
        do {
            const response = await queryApi.listActivityLogEntries(organizationKey, sk, itemLimit, nextTokenRef.current);
            nextTokenRef.current = response.nextToken;
            items = [...items, ...response.items];

            const filteredItems = filterActivityLog(response.items, filter);
            if (filteredItems.length > 0 || !response.nextToken) {
                break;
            }
        } while (true);

        await fetchMissingResources(items);
        dispatch(addActivityLogEntries(organizationKey, items));
        if (!nextTokenRef.current) {
            setCanLoadMore(false);
        }
    };

    const loadAll = async () => {
        if (!canLoadMore) {
            return;
        }

        const sk = !nextTokenRef.current && entries ? { lt: entries[entries.length - 1].sk } : undefined;
        let items = [];
        do {
            const response = await queryApi.listActivityLogEntries(organizationKey, sk, itemLimit, nextTokenRef.current);
            nextTokenRef.current = response.nextToken;
            items = [...items, ...response.items];
        } while (nextTokenRef.current);

        await fetchMissingResources(items);
        dispatch(addActivityLogEntries(organizationKey, items));
        setCanLoadMore(false);
    };

    useEffect(() => {
        if (entries) {
            loadNew();
        } else {
            loadMore();
        }
    }, []);

    return { canLoadMore, entries, loadMore, loadAll };
};
