import * as types from './actionTypes';
import {
  filterGroupItems,
  groupBy,
  updateGroupItem,
} from '../../utils/arrayUtils';

const initialState = {};

//  Tags state is a collection of reports grouped by team ID, e.g. {
//     miton: [{id:"tag1"},{id:"tag2"}],
//     cola: [{id:"tag3"}]
// }
export default function tagsReducer(state = initialState, action) {
  switch (action.type) {
    case types.TAGS_LOADED: {
      const tags = action.payload.tags;
      return groupBy(tags, 'pk');
    }
    case types.TAGS_UPDATED: {
      const workspaceKey = action.payload.workspaceKey;
      const tags = action.payload.tags;

      const updatedTags = [...(state[workspaceKey] ?? [])];
      for (const tag of tags) {
        // Add new state to the state if it doesn't contain the item with the same SK
        // already. If it does, the state item will be replaced by the new state.
        const index = updatedTags.findIndex((item) => item.sk === tag.sk);
        if (index < 0) {
          updatedTags.push(tag);
        } else {
          updatedTags[index] = tag;
        }
      }

      const newState = { ...state };
      newState[workspaceKey] = updatedTags;
      return newState;
    }
    case types.TAG_UPDATED: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      return updateGroupItem(state, pk, (tag) => tag.sk === sk, {
        ...action.payload.data,
        pk: pk,
        sk: sk,
      });
    }
    case types.TAG_DELETED: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      return filterGroupItems(state, pk, (status) => status.sk !== sk);
    }
    default:
      return state;
  }
}
