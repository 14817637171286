import React, { useEffect, useRef, useState } from 'react';
import { Popover, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import { addPrefixIfMissing, isNullOrEmpty } from '../../utils/textUtils';
import { findEntityRange } from '../../utils/richTextUtils';
import SelectionState from 'draft-js/lib/SelectionState';

const popoverHeight = 46;
const popoverWidth = 296;

const RootPopover = styled(Popover, { name: 'UrlPopover' })(({ theme }) => ({
  //position: "absolute",
  '& .MuiPopover-paper': {
    width: popoverWidth,
    height: popoverHeight,
    padding: theme.spacing(0.5),
  },
}));

const UrlPopover = ({
  editorRootRef,
  editorState,
  open,
  onEditorStateChange,
  onClose,
}) => {
  const { t } = useTranslation();
  const [position, setPosition] = useState();
  const [url, setUrl] = useState('');
  const [entityKey, setEntityKey] = useState();
  const editor = editorRootRef.current?.editor;
  const selectionStateRef = useRef(null);

  useEffect(() => {
    if (open) {
      const selection = editorState.getSelection();
      selectionStateRef.current = selection;

      // Get url and entity key of the current link if it exists
      const startOffset = selection.getStartOffset();
      const currentContent = editorState.getCurrentContent();
      const contentBlock = currentContent.getBlockForKey(
        selection.getStartKey(),
      );
      const linkKey = contentBlock.getEntityAt(startOffset);
      if (linkKey) {
        const linkInstance = currentContent.getEntity(linkKey);
        const data = linkInstance.getData();
        setUrl(data.url);
        setEntityKey(linkKey);
      } else {
        setUrl('');
        setEntityKey(null);
      }

      const newPosition = getSelectionPosition();
      setPosition(newPosition);
      const newEditorState = applyHighlightStyle();
      onEditorStateChange(newEditorState, false);
    }
  }, [open]);

  function getSelectionPosition() {
    const selection = window.getSelection();

    if (selection.rangeCount === 0) return null; // No selection

    const range = selection.getRangeAt(0);
    const selectionRect = range.getBoundingClientRect();

    // Ensure the selection is within the editor
    if (!editor || !editor.contains(selection.anchorNode)) {
      return null;
    }

    return {
      top: selectionRect.top - popoverHeight - 4,
      left:
        selectionRect.left +
        (selectionRect.right - selectionRect.left) / 2 -
        popoverWidth / 2,
    };
  }

  const applyHighlightStyle = () => {
    const currentContent = editorState.getCurrentContent();
    const newContentState = Modifier.applyInlineStyle(
      currentContent,
      selectionStateRef.current,
      'HIGHLIGHT',
    );
    return EditorState.push(editorState, newContentState, null);
  };

  const removeHighlightStyle = () => {
    const currentContent = editorState.getCurrentContent();
    const newContentState = Modifier.removeInlineStyle(
      currentContent,
      selectionStateRef.current,
      'HIGHLIGHT',
    );
    return EditorState.push(editorState, newContentState, null);
  };

  const restoreSelection = (newEditorState) => {
    return EditorState.forceSelection(
      newEditorState,
      selectionStateRef.current,
    );
  };

  const handleClose = () => {
    onClose();

    let newEditorState = restoreSelection(removeHighlightStyle());
    // Set selection and focus the editor
    onEditorStateChange(newEditorState, false);
    setTimeout(() => {
      editorRootRef.current?.focus();
    }, 1);
  };

  const confirmLink = () => {
    onClose();

    let newEditorState = restoreSelection(removeHighlightStyle());

    if (isNullOrEmpty(url)) {
      // Select the whole text in the entity range
      const entityRange = findEntityRange(newEditorState, entityKey);
      const { blockKey, start, end } = entityRange;
      const urlTextSelection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: end,
      });
      newEditorState = EditorState.forceSelection(
        newEditorState,
        urlTextSelection,
      );
      // Remove link
      newEditorState = RichUtils.toggleLink(
        newEditorState,
        urlTextSelection,
        null,
      );
    } else {
      // Create new link
      const contentState = newEditorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        'LINK',
        'MUTABLE',
        { url: addPrefixIfMissing(url) },
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      newEditorState = EditorState.set(newEditorState, {
        currentContent: contentStateWithEntity,
      });
      newEditorState = RichUtils.toggleLink(
        newEditorState,
        selectionStateRef.current,
        entityKey,
      );
    }

    // Set selection and focus the editor
    onEditorStateChange(newEditorState, true);
    setTimeout(() => {
      editorRootRef.current?.focus();
    }, 1);
  };

  return (
    <RootPopover
      disableRestoreFocus
      disableScrollLock
      open={open}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: position?.top ?? 0, left: position?.left ?? 0 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <TextField
          autoFocus
          hiddenLabel
          fullWidth
          type="url"
          size="small"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          sx={{
            flex: '1 1 auto',
            input: { color: 'text.link', textDecoration: 'underline' },
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              confirmLink();
            }
          }}
        />
      </Stack>
    </RootPopover>
  );
};

UrlPopover.propTypes = {
  onEditorStateChange: PropTypes.func,
  onClose: PropTypes.func,
};

UrlPopover.defaultProps = {
  onEditorStateChange: () => {},
  onClose: () => {},
};

export default UrlPopover;
