import { flatttenGroups } from '../../utils/objectUtils';
import { groupBy, toObject } from '../../utils/arrayUtils';

export const allStatusesSelector = () => state => flatttenGroups(state.statuses);

export const workspaceStatusesSelector = (workspaceKey) => state => workspaceKey ? state.statuses[workspaceKey] : null;

export const activeWorkspaceStatusesSelector = (workspaceKey) => state => workspaceKey ? state.statuses[workspaceKey]?.filter(status => !status.disabled) : null;

export const statusSelector = (workspaceKey, statusKey) => state => {
    if (!Boolean(workspaceKey) || !Boolean(statusKey)) {
        return null;
    }

    return state.statuses[workspaceKey]?.find(t => t.sk === statusKey);
};