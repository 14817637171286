import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { ListItemAvatar, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from './LanguageIcon';
import { LANGUAGE_NAMES, Language } from '../../i18n/config';

interface LanguageButtonProps {
  value: Language;
  supportedLanguages: Language[];
  onLocaleChange: (locale: Language) => void;
}

const LanguageButton = (props: LanguageButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleMenuItemClick = (language: Language) => {
    setAnchorEl(null);
    props.onLocaleChange(language);
  };

  return (
    <Box>
      <Button
        aria-label="language"
        aria-describedby="language-menu"
        endIcon={<ExpandMoreIcon />}
        onClick={(e) => setAnchorEl(e.target as Element)}
        sx={{
          typography: 'body1',
          marginLeft: 'auto',
          minWidth: 120,
          textTransform: 'none',
          color: 'text.primary',
        }}
      >
        <LanguageIcon component="span" locale={props.value} />
        {LANGUAGE_NAMES[props.value]}
      </Button>
      <Menu
        keepMounted
        id="language-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        {props.supportedLanguages.map((language) => (
          <MenuItem
            key={`language-${language}`}
            onClick={() => handleMenuItemClick(language)}
          >
            <ListItemAvatar>
              <LanguageIcon locale={language} />
            </ListItemAvatar>
            <ListItemText primary={LANGUAGE_NAMES[language]} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageButton;
