import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../../utils/validationUtils';
import PasswordTextField from '../../components/PasswordTextField/PasswordTextField';
import { ApiContext } from '../../contexts/ApiContext';
import { styled } from '@mui/material/styles';
import { getActiveLanguage } from '../../utils/localizationUtils';

interface Props {
  email: string;
}

const RootBox = styled(Box, { name: 'SignupPage2' })(({ theme }) => ({}));

const SignupPage2 = ({ email }: Props) => {
  const { t, i18n } = useTranslation();
  const { authApi } = useContext(ApiContext);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setName(value);
    setNameError(Boolean(value) ? null : 'nameIsRequired');
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPassword(value);
    setPasswordError(!Boolean(value) ? 'passwordIsRequired' : null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (nameError !== null || passwordError !== null) {
      return;
    }

    if (!Boolean(name)) {
      setNameError('nameIsRequired');
      return;
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    try {
      setLoading(true);
      const language = getActiveLanguage(i18n);
      await authApi.signUp(email, password, name, language);
    } catch (e: any) {
      console.error(e);
      setSubmitError(e?.name ?? 'unknownSignupError');
      setLoading(false);
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs" sx={{ py: 3 }}>
        <LinearProgress
          variant={'determinate'}
          value={33}
          color="primary"
          sx={{
            height: 4,
            mb: 3,
            borderRadius: 2,
            '& .MuiLinearProgress-bar': { borderRadius: 2 },
          }}
        />
        <Box sx={{ textAlign: 'center' }} mb={5}>
          <Typography variant="h4" component="h1">
            {t('signupPage2Title')}
          </Typography>
          <Typography variant="bodyLarge" color="textSecondary">
            {t('signupPage2Subtitle')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <TextField
              id="name"
              autoFocus
              required
              fullWidth
              variant="outlined"
              autoComplete="name"
              label={t('yourName')}
              value={name}
              onChange={handleNameChange}
              error={Boolean(nameError)}
              helperText={t(nameError!)}
            />
            <PasswordTextField
              id="password"
              required
              fullWidth
              variant="outlined"
              autoComplete="new-password"
              label={t('password')}
              value={password}
              onChange={handlePasswordChange}
              error={Boolean(passwordError)}
              helperText={t(passwordError!)}
            />
            <LoadingButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default SignupPage2;
