import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../components/Loader";
import { useOrganizationKey } from "../hooks/useOrganizationKey";
import { isOrganization, toId } from '../utils/apiUtils';
import { getCognitoGroups, isAdmin } from "../utils/user";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { currentUserRoleSelector } from "../state/members/selectors";
import { WhitelabelContext } from "../contexts/WhitelabelContext";
import NotAuthorizedView from "../views/NotAuthorizedView";
import { isInvitationRoute } from "../utils/routeUtils";
import NavigateToEntryPage from "./NavigateToEntryPage";
import { isWhitelabelOrigin } from "../utils/appUtils";
import NotFoundView from "../views/errors/NotFoundView";
import { useOrganization } from "../hooks/useOrganization";
import { organizationSelector } from "../state/organizations/selectors";
import { useStoredOrganizationId } from "../hooks/useStoredOrganizationId";
import { useAuthContext } from "../contexts/AuthContext";
import { isEnterpriseOrganization } from '../utils/modelUtils';

const PrivateRouter = () => {
    //const { pathname } = useLocation();
    const { authenticated, user } = useAuthContext();
    const whitelabel = useContext(WhitelabelContext);
    const location = useLocation();
    const { pathname } = location;
    const organizationKey = useOrganizationKey();
    const currentUserRole = useSelector(currentUserRoleSelector(organizationKey));
    const groups = getCognitoGroups(user);
    const organization = useSelector(organizationSelector(organizationKey));
    const whitelabelOrigin = isWhitelabelOrigin();

    console.log("PrivateRouter, pathname: %o, authenticated: %o",  pathname, authenticated);

    if (whitelabelOrigin && !whitelabel) {
        return <NotAuthorizedView />;
    }

    // Handle case when a user in a whitelabel app manually types ID of a different organization in the url bar.
    if (isOrganization(whitelabel) && organization && organization.path !== window.location.origin) {
        return <NotAuthorizedView />;
    }

    if (isInvitationRoute(pathname)) {
        return <Outlet />;
    }

    if (!authenticated) {
        return <Navigate replace to="/login" state={{ from: window.location.pathname }} />;
    }

    // if (!loaded) {
    //     return <Loader/>
    // }

    if (pathname !== "/app/onboarding" && (!groups || groups.length === 0)) {
        return <Navigate replace to={"/app/onboarding"} />;
    }

    if (organizationKey && !groups?.includes(toId(organizationKey))) {
        return <NotAuthorizedView />;
    }

    // If the route is only /app redirect to active organization home page
    if (pathname.replace(/\//g, "") === "app") {
        return <NavigateToEntryPage cognitoUser={user} />;
    }

    if (!isAdmin(currentUserRole) && pathname.match(/\/settings/)) {
        return <Navigate replace to={`/app/${toId(organizationKey)}/home`} />;
    }

    if (!isEnterpriseOrganization(organization) &&  pathname.match(/\/activity/)) {
        return (<Navigate replace to='/404' />)
    }

    return <Outlet />;
};

export default PrivateRouter;
