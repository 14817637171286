import React, { useEffect, useMemo } from 'react';
import { ApiContext } from './ApiContext';
import { API, Auth, Hub } from 'aws-amplify';
import { Outlet } from 'react-router-dom';
import QueryApi from '../api/QueryApi';
import MutationApi from '../api/MutationApi';
import SubscriptionApi from '../api/SubscriptionApi';
import StorageApi from '../api/StorageApi';
import AuthApi from '../api/AuthApi';
import { ActivityLogContextProvider } from './ActivityLogContext';
import { AuthProvider } from './AuthContext';

const AuthenticatedRoute = () => {
  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        // Include user identity claims like email or phone number in the JWT token.
        API.configure({
          graphql_headers: async () => {
            const session = await Auth.currentSession();
            return {
              Authorization: session.getIdToken().getJwtToken(),
            };
          },
        });
      }
    });
  }, []);

  return (
    <AuthProvider>
      <ActivityLogContextProvider>
        <Outlet />
      </ActivityLogContextProvider>
    </AuthProvider>
  );
};

export default AuthenticatedRoute;
