import * as types from "./actionTypes";

const initialState = {};

export default function resourcePasswordsReducer(state = initialState, action) {
    switch (action.type) {
        case types.RESOURCE_PASSWORD_UPDATED: {
            const {resourceKey, data} = action.payload;
            const newState = {...state};
            newState[resourceKey] = {...newState[resourceKey], ...data};
            return newState;
        }
        case types.RESOURCE_PASSWORD_DELETED: {
            const newState = {...state};
            newState[action.payload.resourceKey] = undefined;
            return newState;
        }
        default:
            return state
    }
}
