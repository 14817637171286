import * as types from "./actionTypes";
import { EMAILS_UPDATED, EMAILS_LOADED } from './actionTypes';

export const loadEmails = (emails) => async (dispatch, getState) => {
    dispatch({ type: types.EMAILS_LOADED, payload: {emails} });
}

export const addOrUpdateEmail = (pk, data) => async (dispatch, getState) => {
    if (!Boolean(pk)) {
        throw new Error("pk cannot be null");
    }
    dispatch({ type: types.EMAILS_UPDATED, payload: {pk, data} });
}