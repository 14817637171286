import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Stack, Toolbar } from '@mui/material';
import AuthTopbar from './AuthTopbar';
import AuthFooter from './AuthFooter';
import { Hub } from 'aws-amplify';
import { ApiContext } from '../../contexts/ApiContext';
import { useLocation } from 'react-router';

const RootBox = styled(Box, { name: 'AuthLayout' })(({ theme }) => ({
  //backgroundColor: theme.palette.grey[50],
  //height: '100%'
  minHeight: '100vh',
}));

const AuthLayout = () => {
  const { authApi } = useContext(ApiContext);
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const cognitoUser = await authApi.currentAuthenticatedUser();
      if (Boolean(cognitoUser)) {
        navigate('/app', { replace: true });
      }
    })();

    listenToAutoSignInEvent();
  }, []);

  const listenToAutoSignInEvent = () => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        navigate(state?.from ?? '/app');
      } else if (event === 'autoSignIn_failure') {
        navigate('/login', { replace: true });
      }
    });
  };

  return (
    <RootBox>
      <AuthTopbar />
      <Stack component="main" minHeight={'100vh'} direction="column">
        <Toolbar />
        <Outlet />
        <AuthFooter />
      </Stack>
    </RootBox>
  );
};

export default AuthLayout;
