import { Avatar, AvatarProps } from '@mui/material';
import React, { ElementType } from 'react';
import { styled } from '@mui/material/styles';
import { Language, LANGUAGE_NAMES, LANGUAGES } from '../../i18n/config';

interface StyledAvatarProps extends AvatarProps {
  component?: ElementType;
}

const StyledAvatar = styled(Avatar)<StyledAvatarProps>(({ theme }) => ({
  width: 28,
  height: 21,
  marginRight: theme.spacing(2),
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[300]}`,
}));

interface Props extends StyledAvatarProps {
  locale: (typeof LANGUAGES)[number];
}

const LanguageIcon = ({ locale, ...rest }: Props): JSX.Element => {
  const language = locale.split(/[-_]/)[0] as Language;
  return (
    <StyledAvatar
      alt={LANGUAGE_NAMES[language]}
      src={`/static/icons/flags/${locale}.svg`}
      {...rest}
    />
  );
};

export default LanguageIcon;
