export const newUuid = () => {
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}

export const numericId = () => {
    return Math.floor(Math.random() * (Date.now() - (new Date(2021, 10, 1))));
}

export const s4 = () => {
    return (((1+Math.random())*0x19A0FF)|0).toString(36).substring(1);
}

export const n3 = () => {
    return (((1+Math.random())*0x100)|0);
};

export const n9 = () => {
    return `${n3()}${n3()}${n3()}`;
}

export const s16 = () => {
    return `${s4()}${s4()}${s4()}${s4()}`
}

export const s8 = () => {
    return `${s4()}${s4()}`;
}

export const s5 = () => {
    return `${s4()}${s4()[0]}`;
}