import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  FilledInput,
  FormControl,
  FormControlProps,
  InputAdornment,
  InputBase,
  Link,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { CARD_SHADOW3 } from '../../constants';
import { styled } from '@mui/material/styles';
import FieldLabel from '../FieldLabel';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import ClipboardNotification from '../ClipboardNotification';
import { copyTextToClipboard } from '../../utils/clipboardUtils';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const RootFormControl = styled(FormControl, { name: 'CodeBlock' })(
  ({ theme }) => ({
    '& .MuiInputBase-root': {
      fontFamily: 'monospace, monospace',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[200], //800
      //color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.grey[200], //800
      },
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5, 2),
    },
    '& .MuiInputAdornment-positionEnd': {
      paddingRight: theme.spacing(2),
    },
    '& .MuiButtonBase-root': {
      //color: theme.palette.primary.contrastText,
    },
  }),
);

export interface CodeBlockProps extends FormControlProps {
  label?: string;
  description?: string;
  text: string;
}

const CodeBlock = ({ label, description, text, ...rest }: CodeBlockProps) => {
  const { t } = useTranslation();
  const [showCopiedNotification, setShowCopiedNotification] = useState(false);

  const handleCopyButtonClick = async () => {
    await copyTextToClipboard(text);
    setShowCopiedNotification(true);
  };

  return (
    <RootFormControl {...rest}>
      {label && (
        <FieldLabel
          label={label}
          sx={{
            mb: description ? 0 : 0.5,
            '&.Mui-focused': {
              color: 'inherit',
            },
          }}
        />
      )}
      {description && (
        <Typography color="textSecondary" sx={{ mb: 0.75 }}>
          {description}
        </Typography>
      )}
      <InputBase
        readOnly
        defaultValue={text}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title={t('copy')}>
              <IconButton
                aria-label="copy"
                onClick={handleCopyButtonClick}
                edge="end"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
      <ClipboardNotification
        open={showCopiedNotification}
        onClose={() => setShowCopiedNotification(false)}
      />
    </RootFormControl>
  );
};

export default CodeBlock;
