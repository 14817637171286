import * as React from 'react';
import Slider from '@mui/material/Slider';
import { alpha, styled } from '@mui/material/styles';
import ReactAudioPlayer from "react-audio-player";
import PropTypes from "prop-types";
import AttachmentPreview from "../AttachmentPreview/AttachmentPreview";

const AudioPlayerRoot = styled(ReactAudioPlayer)(({ theme }) => ({
    borderRadius: 4
}));

export default function AudioPlayer(props) {
    return <AudioPlayerRoot controls {...props} />;
}

AudioPlayer.propTypes = {
    src: PropTypes.string.isRequired
}

AudioPlayer.defaultProps = {
};