import StorageApi from '../api/StorageApi';
import { ENCRYPTED_PREFIX } from '../constants/storage';
import EncryptionClient from './EncryptionClient';

const cache = {};

export default class BlobCache {
    static async cacheObjectsFromS3(s3Keys){
        const urls = [];
        for (const s3Key of s3Keys) {
            if (cache[s3Key]) {
                urls.push(cache[s3Key]);
                continue;
            }

            const downloaded = await StorageApi.downloadObject(s3Key);
            let blob = downloaded.Body;

            // Decrypt the downloaded blob if necessary
            if (s3Key.startsWith(ENCRYPTED_PREFIX)) {
                const fileBuffer = await downloaded.Body.arrayBuffer();
                const bytes = new Uint8Array(fileBuffer);
                const decrypted = await EncryptionClient.decryptBytes(bytes);
                blob = new Blob([decrypted], { type: downloaded.Body.type });
            }

            const blobUrl = URL.createObjectURL(blob);
            cache[s3Key] = blobUrl;
            urls.push(blobUrl);
        }

        return urls;
    }

    static async cacheBlobUrl(s3Key, blobUrl){
        cache[s3Key] = blobUrl;
    }

    static getBlobUrl(s3Key) {
        return cache[s3Key];
    }

    static destroyBlob(s3Key) {
        if (!cache[s3Key]) {
            return
        }

        URL.revokeObjectURL(cache[s3Key]);
        cache[s3Key] = null;
    }

    static destroyBlobByUrl(url) {
        const s3Key = Object.keys(cache).find(key => cache[key] === url);
        if (!cache[s3Key]) {
            return
        }

        URL.revokeObjectURL(url);
        cache[s3Key] = null;
    }
}