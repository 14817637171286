import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { answerRegistrationName, getBlockTemplate, getTranslations, translationsRegistrationName } from './utils';
import { Controller, useFormContext } from 'react-hook-form';
import DropdownOptionsEditor from './DropdownOptionsEditor';

const RootBox = styled(Box, { name: "FormDropdownBlock" })(({ theme }) => ({}));

const FormDropdownBlock = ({ blockIndex, mode, language, ...rest }) => {
    const { t } = useTranslation();
    const { clearErrors, getValues } = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);
    const translations = getTranslations(getValues);

    return (
        <RootBox {...rest}>
            <Controller
                name={answerRegistrationName(blockTemplate.key)}
                rules={{
                    required: mode === "view" && blockTemplate.required && "requiredFieldError",
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                        select
                        fullWidth
                        disabled={mode === "edit"}
                        variant="outlined"
                        defaultValue=""
                        hiddenLabel
                        error={Boolean(error?.message)}
                        helperText={Boolean(error?.message) ? t(error.message, {lng: language}) : ""}
                        onChange={(e) => {
                            if (Boolean(error?.message)) {
                                clearErrors(answerRegistrationName(blockTemplate.key));
                            }
                            onChange(e.target.value);
                        }}
                        SelectProps={{
                            MenuProps: {
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" },
                            },
                        }}
                        sx={{
                            "& .MuiFormHelperText-root": {
                                position: "absolute",
                                top: "100%",
                            },
                        }}
                    >
                        {mode === "view" && !blockTemplate.required && (
                            <MenuItem value="">
                                <em>{t("none", { lng: language })}</em>
                            </MenuItem>
                        )}
                        {mode === "view" && (
                            blockTemplate.options.map((optionKey) => (
                                <MenuItem key={optionKey} value={optionKey}>
                                    {translations[optionKey] ?? ""}
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                )}
            />
            {mode === "edit" && (
                <DropdownOptionsEditor blockIndex={blockIndex} language={language} />
            )}
        </RootBox>
    );
};

FormDropdownBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired,
};

FormDropdownBlock.defaultProps = {
    mode: "view",
};

export default FormDropdownBlock;
