import React from "react";
import PropTypes from "prop-types";
import FormViewPrivacyPolicyBlock from "./FormViewPrivacyPolicyBlock";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FormEditorPrivacyPolicyBlock from './FormEditorPrivacyPolicyBlock';

const RootBox = styled(Box, { name: "FormEditorPrivacyPolicyBlock" })(({ theme }) => ({
    marginTop: theme.spacing(3)
}));

const FormPrivacyPolicyBlock = ({ mode, ...rest }) => {
    return (
        <RootBox {...rest}>
            {mode === "view" ? <FormViewPrivacyPolicyBlock {...rest} /> : <FormEditorPrivacyPolicyBlock {...rest} />}
        </RootBox>
    );
};

FormPrivacyPolicyBlock.propTypes = {
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]),
};

FormPrivacyPolicyBlock.defaultProps = {
    mode: "view",
};

export default FormPrivacyPolicyBlock;
