import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { workspaceInternalPolicySelector } from '../../state/policies/selectors';
import { convertToRaw, RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { deserializeEditorState } from '../../utils/richTextUtils';
import ArrowDownTrayIcon from '../../icons/ArrowDownTrayIcon';
import html2pdf from 'html-to-pdf-js';
import RichTextField from '../../components/RichTextField';
import { ApiContext } from '../../contexts/ApiContext';
import { addOrUpdatePolicy } from '../../state/policies/actions';

interface Props {
  open: boolean;
  onClose: () => void;
}

const InternalPolicyDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const workspaceKey = useWorkspaceKey();
  const policy = useSelector(workspaceInternalPolicySelector(workspaceKey));
  const [loading, setLoading] = useState(false);

  const downloadPdf = async (htmlContent: string): Promise<void> => {
    const opt = {
      margin: 1,
      filename: `${t('whistleblowingInternalPolicy')}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    await html2pdf().set(opt).from(htmlContent).save();
  };

  const handleDownload = async () => {
    setLoading(true);

    const editorState = deserializeEditorState(policy?.content);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    await downloadPdf(htmlContent);

    setLoading(false);
    onClose();
  };

  const handlePolicyChange = async (newValue: RawDraftContentState) => {
    dispatch(addOrUpdatePolicy(policy!.pk, policy!.sk, { content: newValue }));
    await mutationApi.updatePrivateResource(policy!.pk, policy!.sk, {
      content: newValue,
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="internal-policy-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle id="internal-policy-dialog-title">
        {t('whistleblowingInternalPolicy')}
      </DialogTitle>
      <DialogContent sx={{ pb: 0, mb: 3 }}>
        <RichTextField
          value={policy!.content as RawDraftContentState}
          onChange={handlePolicyChange}
          toolbarControls={[
            'heading1',
            'heading2',
            'divider',
            'bold',
            'italic',
            'underline',
            'unorderedList',
            'orderedList',
            'link',
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="outlined"
          size="medium"
          disabled={loading}
          onClick={onClose}
        >
          {t('close')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          disabled={loading}
          onClick={handleDownload}
          startIcon={<ArrowDownTrayIcon />}
        >
          {t('downloadAsPdf')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InternalPolicyDialog;
