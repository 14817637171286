import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form';
import { answerRegistrationName, getBlockTemplate, getTranslations, translationsRegistrationName } from './utils';
import { removeItem } from '../../utils/arrayUtils';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import DropdownOptionsEditor from './DropdownOptionsEditor';

const RootFormControl = styled(FormControl, { name: "FormCheckboxListBlock" })(({ theme }) => ({}));

const FormCheckboxListBlock = ({ blockIndex, language, mode, ...rest }) => {
    const { t } = useTranslation();
    const { clearErrors, getValues} = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);
    const translations = getTranslations(getValues);

    const handleCheckboxChange = (event, optionKey, formValue, onFormValueChange) => {
        const checked = event.target.checked;
        const newFormValue = checked ? [...(formValue ?? []), optionKey] : removeItem(formValue ?? [], optionKey);
        onFormValueChange(newFormValue);
    };

    return mode === "view" ? (
        <Controller
            name={answerRegistrationName(blockTemplate.key)}
            rules={{
                required: blockTemplate.required && "requiredFieldError",
            }}
            render={({ field: { onChange, value: answer }, fieldState: { error } }) => (
                <RootFormControl fullWidth required={blockTemplate.required} error={Boolean(error?.message)} {...rest}>
                    <FormGroup>
                        {blockTemplate.options?.map((optionKey) => (
                            <FormControlLabel
                                key={optionKey}
                                label={translations[optionKey]}
                                sx={{ mb: 0 }}
                                control={
                                    <Checkbox
                                        checked={answer?.includes(optionKey) ?? false}
                                        onChange={(e) => {
                                            if (Boolean(error?.message)) {
                                                clearErrors(answerRegistrationName(blockTemplate.key));
                                            }
                                            handleCheckboxChange(e, optionKey, answer, onChange)
                                        }}
                                        color="primary"
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                    <FormHelperText>{Boolean(error?.message) ? t(error.message, {lng: language}) : undefined}</FormHelperText>
                </RootFormControl>
            )}/>
    ) : (
        <RootFormControl fullWidth>
            <DropdownOptionsEditor blockIndex={blockIndex} language={language} ItemIcon={CheckBoxOutlineBlank} />
        </RootFormControl>
    );
};

FormCheckboxListBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired,
};

FormCheckboxListBlock.defaultProps = {
    mode: "view",
};

export default FormCheckboxListBlock;
