import * as types from './actionTypes';
import {
  AddMembersAction,
  DeleteMemberAction,
  DeleteMembersAction,
  LoadMembersAction,
  UpdateMemberAction,
} from './actionTypes';
import { Dispatch } from 'redux';
import { DeserializedPrivateResource } from '../../types/api';

export const loadMembers =
  (members: DeserializedPrivateResource[]) =>
  async (dispatch: Dispatch<LoadMembersAction>) => {
    dispatch({ type: types.MEMBERS_LOADED, payload: { members } });
  };

export const addOrReplaceMembers =
  (organizationKey: string, members: DeserializedPrivateResource[]) =>
  async (dispatch: Dispatch<AddMembersAction>) => {
    if (!members || members.length === 0) {
      return;
    }
    dispatch({
      type: types.MEMBERS_ADDED,
      payload: { organizationKey, members },
    });
  };

export const addOrUpdateMember =
  (pk: string, sk: string, data: DeserializedPrivateResource) =>
  async (dispatch: Dispatch<UpdateMemberAction>) => {
    dispatch({ type: types.MEMBER_UPDATED, payload: { pk, sk, data } });
  };

export const deleteMember =
  (pk: string, sk: string) =>
  async (dispatch: Dispatch<DeleteMemberAction>) => {
    dispatch({ type: types.MEMBER_DELETED, payload: { pk, sk } });
  };

export const deactivateMember =
  (pk: string, sk: string) =>
  async (dispatch: Dispatch<UpdateMemberAction>) => {
    dispatch({
      type: types.MEMBER_UPDATED,
      payload: {
        pk,
        sk,
        data: { disabled: true, pk: pk, sk: sk, __typename: 'PrivateResource' },
      },
    });
  };

export const deleteWorkspaceMembers =
  (organizationKey: string) =>
  async (dispatch: Dispatch<DeleteMembersAction>) => {
    dispatch({ type: types.MEMBERS_DELETED, payload: { organizationKey } });
  };
