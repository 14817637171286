import React, {useState} from "react";
import { Button, Divider, ListItemIcon, ListItemText } from '@mui/material';
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ContentSeparator from "../../components/ContentSeparator/ContentSeparator";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ShortTextOutlinedIcon from "@mui/icons-material/ShortTextOutlined";
import SubjectIcon from "@mui/icons-material/Subject";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const BlockSeparator = ({onClick}) => {
    const { t } = useTranslation();

    return (
        <Divider sx={{px: 6}}>
            <Button
                variant="text"
                size="medium"
                color="primary"
                startIcon={<AddIcon />}
                onClick={onClick}
            >
                {t("addBlock")}
            </Button>
        </Divider>
    )
}

BlockSeparator.propTypes = {
    onClick: PropTypes.func.isRequired
};

BlockSeparator.defaultProps = {
};

export default BlockSeparator;
