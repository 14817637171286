import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Tooltip,
  Typography,
  AvatarGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getReportName } from '../../utils/textUtils';
import { formatReportExcerpt, isClosed } from '../../utils/reportUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { memberSelector, membersSelector } from '../../state/members/selectors';
import UnreadIndicator from '../../components/UnreadIndicator';
import { ApiContext } from '../../contexts/ApiContext';
import { statusSelector } from '../../state/statuses/selectors';
import ReportProgress from '../../components/ReportProgress/ReportProgress';
import { styled } from '@mui/material/styles';
import Status from '../../components/Status';
import {
  activeWorkspaceTagsSelector,
  workspaceTagsSelector,
} from '../../state/tags/selectors';
import Tag from '../../components/Tag';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import Timestamp from '../../components/Timestamp';
import ResourceAvatar from '../../components/ResourceAvatar';
import { reportSelector } from '../../state/reports/selectors';
import { workspaceSelector } from '../../state/workspaces/selectors';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    transition: `opacity 75ms ease-in-out`,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    //  border: `1px solid ${theme.palette.divider}`,
    //  borderRadius: "4px",
    //  marginBottom: theme.spacing(1),
    //  marginLeft: theme.spacing(1),
    //  marginRight: theme.spacing(1),
    // ...CardShadow
  },
  listItem: {
    alignItems: 'flex-start',
    padding: theme.spacing(2, 4, 2, 4),
  },
  selected: {
    background: theme.palette.background.paper,
  },
  leftGutter: {
    flex: '0 0 auto',
    //paddingTop: theme.spacing(0.25),
    width: theme.spacing(4),
    position: 'relative',
    '& > *': {
      margin: 0,
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  },
  cardContent: {
    flex: '1 1 auto',
    minWidth: 0,
  },
  progress: {
    maxWidth: '66%',
    //marginBottom: "0.5em"
  },
  progressBar: {
    height: '4px',
  },
  message: {
    flex: '1 1 auto',
    overflow: 'hidden',
    minWidth: 0,
    marginRight: theme.spacing(1.75),
  },
  timestamp: {
    flex: '0 0 auto',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  progressLabel: {
    color: theme.palette.text.disabled,
    lineHeight: '135%',
  },
}));

const RootListItem = styled(ListItem, { name: 'ReportCard' })(
  ({ theme, color }) => ({
    transition: `opacity 75ms ease-in-out`,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  }),
);

const ReportCard = React.memo(
  ({
    reportKey,
    showWorkspaceAvatar,
    selected,
    onClick,
    onDelete,
    ...rest
  }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { mutationApi } = useContext(ApiContext);
    const organizationKey = useOrganizationKey();
    const report = useSelector(reportSelector(reportKey));
    const workspace = useSelector(
      workspaceSelector(showWorkspaceAvatar ? report.workspaceKey : null),
    );
    const assignedMembers = useSelector(
      membersSelector(organizationKey, report.assigned),
    );
    const currentUser = useSelector(currentUserSelector());
    const status = useSelector(
      statusSelector(report.workspaceKey, report.status),
    );
    const workspaceTags =
      useSelector(activeWorkspaceTagsSelector(report.workspaceKey)) ?? [];
    const tags = report.tags
      ?.map((tagKey) => workspaceTags.find((tag) => tag.sk === tagKey))
      .filter(Boolean);
    const [unread, setUnread] = useState(false);

    useEffect(() => {
      if (selected && unread) {
        setUnread(false);
        mutationApi.updatePrivateResource(report.pk, report.sk, {
          openedAt: new Date(Date.now()).toISOString(),
        });
      }
    }, [selected]);

    useEffect(() => {
      if (!selected) {
        const isUnread =
          (!Boolean(report.openedAt) ||
            report.openedAt <= report.excerptUpdatedAt) &&
          report.senderKey !== currentUser.pk;
        setUnread(isUnread);
      }
    }, [report.excerptUpdatedAt]);

    return (
      <RootListItem component="div" disablePadding className={classes.cardRoot}>
        <ListItemButton
          //className={classes.listItem}
          sx={{ px: 3, pt: 1.75, pb: 3, alignItems: 'flex-start' }}
          onClick={() => onClick(reportKey)}
          //classes={{selected: classes.selected}}
          selected={selected}
          {...rest}
        >
          {showWorkspaceAvatar && (
            <ListItemIcon sx={{ minWidth: 0, pr: 2, pt: '32px' }}>
              <ResourceAvatar
                variant="rounded"
                name={workspace.name}
                picture={workspace.picture}
                size={32}
              />
            </ListItemIcon>
          )}
          <Stack width="100%" overflow="hidden">
            <Stack justifyContent="center" height="1.75rem">
              {!isClosed(report) && report.dueDate && (
                <ReportProgress
                  createdAt={report.createdAt}
                  dueDate={report.dueDate}
                />
              )}
              {isClosed(report) && (
                <Typography variant="overline">{t('closed')}</Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1" sx={{ flex: '1 1 auto' }}>
                {getReportName(report, t)}
              </Typography>
              {!isClosed(report) && unread && <UnreadIndicator />}
            </Stack>
            <div className={classes.content}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.message}
                noWrap
              >
                {report.excerpt === '__voicemail'
                  ? t('phoneReportExcerpt')
                  : report.excerpt}
              </Typography>
              <Timestamp
                component="span"
                variant="caption"
                className={classes.timestamp}
                value={report.excerptUpdatedAt}
              />
            </div>
            <Stack direction="row" spacing={1}>
              {status && !status.disabled && (
                <Status workspaceKey={status.pk} statusKey={status.sk} />
              )}
              {tags?.map((tag) => (
                <Tag key={tag.sk} value={tag} />
              ))}
              <Box flex="1 1 auto" />
              {assignedMembers && (
                <AvatarGroup max={4}>
                  {assignedMembers
                    .filter((member) => !member.disabled)
                    .map((member) => (
                      <Tooltip key={member.sk} title={member.name}>
                        <ResourceAvatar
                          variant="circular"
                          name={member.name}
                          picture={member.picture}
                          size={24}
                        />
                      </Tooltip>
                    ))}
                </AvatarGroup>
              )}
            </Stack>
          </Stack>
        </ListItemButton>
      </RootListItem>
    );
  },
  arePropsEqual,
);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.reportKey === nextProps.reportKey &&
    prevProps.selected === nextProps.selected &&
    prevProps.showWorkspaceAvatar === nextProps.showWorkspaceAvatar
  );
}

ReportCard.propTypes = {
  reportKey: PropTypes.string.isRequired,
  showWorkspaceAvatar: PropTypes.bool,
};

export default React.memo(ReportCard);
