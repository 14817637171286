import * as subscriptions from '../graphql/subscriptions';
import { API } from 'aws-amplify';
import { isProductionEnvironment } from '../utils/appUtils';
import {
  OnCreatePrivateResourceSubscription,
  OnCreatePublicResourceSubscription,
  OnDeletePrivateResourceSubscription,
  OnDeletePublicResourceSubscription,
  OnUpdatePrivateResourceSubscription,
  OnUpdatePublicResourceSubscription,
  PrivateResource,
  PublicResource,
} from '../API';
import { deserializeObject } from './serializationUtils';
import {
  DeserializedPrivateResource,
  DeserializedPublicResource,
} from '../types/api';

const privateAuthMode = 'AMAZON_COGNITO_USER_POOLS';
const publicAuthMode = 'AWS_IAM';

type SubscriptionResponse<T> = { value: { data: T } };
type SubscriptionCallback<T> = (value: T) => void;

export default class SubscriptionApi {
  static onCreatePrivateResource(
    callback: SubscriptionCallback<DeserializedPrivateResource>,
    filter?: string,
  ) {
    return (
      API.graphql({
        query: subscriptions.onCreatePrivateResource,
        variables: { filter },
        authMode: privateAuthMode,
      }) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: async (
        response: SubscriptionResponse<OnCreatePrivateResourceSubscription>,
      ) => {
        const resource = response.value.data.onCreatePrivateResource;
        if (!isProductionEnvironment()) {
          console.log(
            'onCreatePrivateResource, response data: %o',
            response.value.data.onCreatePrivateResource,
          );
        }
        const deserialized = await deserializeObject(
          resource as PrivateResource,
        );
        callback(deserialized);
      },
    });
  }

  static onUpdatePrivateResource(
    callback: SubscriptionCallback<DeserializedPrivateResource>,
    filter = undefined,
  ) {
    return (
      API.graphql({
        query: subscriptions.onUpdatePrivateResource,
        variables: { filter },
        authMode: privateAuthMode,
      }) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: async (
        response: SubscriptionResponse<OnUpdatePrivateResourceSubscription>,
      ) => {
        if (!isProductionEnvironment()) {
          console.log(
            'onUpdatePrivateResource, response data: %o',
            response.value.data.onUpdatePrivateResource,
          );
        }
        const resource = response.value.data.onUpdatePrivateResource;
        const deserialized = await deserializeObject(
          resource as PrivateResource,
        );
        callback(deserialized);
      },
    });
  }

  static onDeletePrivateResource(
    callback: SubscriptionCallback<DeserializedPrivateResource>,
    filter = undefined,
  ) {
    return (
      API.graphql({
        query: subscriptions.onDeletePrivateResource,
        variables: { filter },
        authMode: privateAuthMode,
      }) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: async (
        response: SubscriptionResponse<OnDeletePrivateResourceSubscription>,
      ) => {
        if (!isProductionEnvironment()) {
          console.log(
            'onDeletePrivateResource, response data: %o',
            response.value.data.onDeletePrivateResource,
          );
        }
        const resource = response.value.data.onDeletePrivateResource;
        const deserialized = await deserializeObject(
          resource as PrivateResource,
        );
        callback(deserialized);
      },
    });
  }

  static onCreatePublicResource(
    callback: SubscriptionCallback<DeserializedPublicResource>,
    filter = undefined,
  ) {
    return (
      API.graphql({
        query: subscriptions.onCreatePublicResource,
        variables: { filter },
        authMode: publicAuthMode,
      }) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: async (
        response: SubscriptionResponse<OnCreatePublicResourceSubscription>,
      ) => {
        if (!isProductionEnvironment()) {
          console.log(
            'onCreatePublicResource, response data: %o',
            response.value.data.onCreatePublicResource,
          );
        }
        const resource = response.value.data.onCreatePublicResource;
        const deserialized = await deserializeObject(
          resource as PublicResource,
        );
        callback(deserialized);
      },
    });
  }

  static onUpdatePublicResource(
    callback: SubscriptionCallback<DeserializedPublicResource>,
    filter = undefined,
  ) {
    return (
      API.graphql({
        query: subscriptions.onUpdatePublicResource,
        variables: { filter },
        authMode: publicAuthMode,
      }) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: async (
        response: SubscriptionResponse<OnUpdatePublicResourceSubscription>,
      ) => {
        if (!isProductionEnvironment()) {
          console.log(
            'onUpdatePublicResource, response data: %o',
            response.value.data.onUpdatePublicResource,
          );
        }
        const resource = response.value.data.onUpdatePublicResource;
        const deserialized = await deserializeObject(
          resource as PublicResource,
        );
        callback(deserialized);
      },
    });
  }

  static onDeletePublicResource(
    callback: SubscriptionCallback<DeserializedPublicResource>,
    filter = undefined,
  ) {
    return (
      API.graphql({
        query: subscriptions.onDeletePublicResource,
        variables: { filter },
        authMode: publicAuthMode,
      }) as Exclude<ReturnType<typeof API.graphql>, Promise<any>>
    ).subscribe({
      next: async (
        response: SubscriptionResponse<OnDeletePublicResourceSubscription>,
      ) => {
        if (!isProductionEnvironment()) {
          console.log(
            'onDeletePublicResource, response data: %o',
            response.value.data.onDeletePublicResource,
          );
        }
        const resource = response.value.data.onDeletePublicResource;
        const deserialized = await deserializeObject(
          resource as PublicResource,
        );
        callback(deserialized);
      },
    });
  }

  // TODO: Delete, remember observer pattern
  // onTeamResourceCreated(teamId, observer) {
  //     return API.graphql({
  //         query: subscriptions.onTeamResourceCreatedByTeamId,
  //         variables: {teamId}
  //     }).subscribe({
  //         next: response => {
  //             console.log("onTeamResourceCreated callback: %o", response);
  //             const resource = response.data.onTeamResourceCreatedByTeamId;
  //
  //             // Subscription filtering doesnt work on local mocked api, the application
  //             // would receive notifications if any report item is updated, including messages.
  //             if (resource.teamId !== teamId) {
  //                 return;
  //             }
  //
  //             switch(resource.type) {
  //                 case teamResourceTypes.Report:
  //                     if (observer.onReportCreated) observer.onReportCreated(parseReportResource(resource));
  //                     break;
  //                 case teamResourceTypes.Comment:
  //                     if (observer.onCommentCreated) observer.onCommentCreated(parseCommentResource(resource));
  //                     break;
  //                 case teamResourceTypes.Tag:
  //                     if (observer.onTagCreated) observer.onTagCreated(parseTagResource(resource));
  //                     break;
  //                 case teamResourceTypes.TagAssignment:
  //                     if (observer.onTagAssignmentCreated) observer.onTagAssignmentCreated(parseTagAssignmentResource(resource));
  //                     break;
  //                 default:
  //                     console.warn("A resource with unsupported resource type has been created: %o", resource);
  //             }
  //         },
  //         error: error => {
  //             console.warn(error);
  //         }
  //     })
  // }
}
