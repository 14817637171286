import { isRichTextFieldSection } from '../utils/formBlockUtils';
import {
  deminifyDraftEditorState,
  minifyDraftEditorState,
} from '../utils/draftJsUtils';
import EncryptionClient from '../utils/EncryptionClient';
import { isJSONString } from '../utils/textUtils';
import {
  ActivityLogEntry,
  Partner,
  PrivateResource,
  PublicResource,
} from '../API';
import {
  DeserializedActivityLogEntry,
  DeserializedPartner,
  DeserializedPrivateResource,
  DeserializedPublicResource,
} from '../types/api';

export async function deserializeObject(
  object: PrivateResource,
): Promise<DeserializedPrivateResource>;
export async function deserializeObject(
  object: PublicResource,
): Promise<DeserializedPublicResource>;
export async function deserializeObject(
  object: ActivityLogEntry,
): Promise<DeserializedActivityLogEntry>;
export async function deserializeObject(
  object: Partner,
): Promise<DeserializedPartner>;

export async function deserializeObject(object?: any): Promise<any> {
  if (!object) {
    return object;
  }

  const hasEncryptedContent = Boolean(object.encryptedContent);
  const hasEncryptedExcerpt = Boolean(object.encryptedExcerpt);

  const deserialized: any = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const value: any = object[key];
      if (!value) {
        deserialized[key] = value;
        continue;
      }

      if (
        (key === 'content' && hasEncryptedContent) ||
        (key === 'excerpt' && hasEncryptedExcerpt)
      ) {
        continue;
      }

      if (key === 'encryptedContent') {
        const content = await EncryptionClient.decryptContent(value);
        deserialized.content = deserializeRichContent(content);
      } else if (key === 'encryptedExcerpt') {
        deserialized.excerpt = await EncryptionClient.decryptText(value);
      } else if (key === 'content' || key === 'description') {
        deserialized[key] = deserializeRichContent(JSON.parse(value as string));
      } else if (typeof value === 'string' && isJSONString(value)) {
        deserialized[key] = JSON.parse(value);
      } else {
        deserialized[key] = value;
      }
    }
  }

  return deserialized;
}

export async function serializeObject<T extends Record<string, any>>(
  object?: T | null,
  encrypt = false,
): Promise<T | null | undefined> {
  if (!object) {
    return object;
  }

  const serialized: any = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const value: any = object[key];

      if (!value) {
        serialized[key] = value;
        continue;
      }

      if (key === 'content' && encrypt) {
        serialized['encryptedContent'] = await EncryptionClient.encryptContent(
          minifyRichContent(value),
        );
      } else if (key === 'excerpt' && encrypt) {
        serialized['encryptedExcerpt'] =
          await EncryptionClient.encryptText(value);
      } else if (key === 'content' || key === 'description') {
        serialized[key] = JSON.stringify(minifyRichContent(value));
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        serialized[key] = JSON.stringify(value);
      } else {
        serialized[key] = value;
      }
    }
  }

  return serialized as T;
}

function deserializeRichContent(content: {}) {
  // Deep clone content object
  const json = JSON.stringify(content);
  let deserialized = JSON.parse(json);

  if (Array.isArray(deserialized)) {
    for (const section of deserialized) {
      if (isRichTextFieldSection(section)) {
        section.value = deminifyDraftEditorState(section.value);
      }
    }
  } else if (deserialized.blocks) {
    deserialized = deminifyDraftEditorState(deserialized);
  } else {
    for (const key in deserialized) {
      if (typeof deserialized[key] === 'object' && deserialized[key]?.blocks) {
        deserialized[key] = deminifyDraftEditorState(deserialized[key]);
      }
    }
  }

  return deserialized;
}

function minifyRichContent(content: {}) {
  // Deep clone content object
  const json = JSON.stringify(content);
  let serialized = JSON.parse(json);

  if (Array.isArray(serialized)) {
    for (const section of serialized) {
      if (isRichTextFieldSection(section)) {
        section.value = minifyDraftEditorState(section.value);
      }
    }
  } else if (serialized.blocks) {
    serialized = minifyDraftEditorState(serialized);
  } else {
    for (const key in serialized) {
      if (typeof serialized[key] === 'object' && serialized[key]?.blocks) {
        serialized[key] = minifyDraftEditorState(serialized[key]);
      }
    }
  }

  return serialized;
}
