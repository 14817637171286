import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isAudio, isImage } from "../../utils/fileUtils";
import { Skeleton } from "@mui/material";
import AttachmentPreview from "../AttachmentPreview/AttachmentPreview";
import { downloadFile } from "../../utils/storageUtils";
import { ApiContext } from "../../contexts/ApiContext";
import { ThumbnailPictureSuffix } from "../../constants/storage";
import { styled } from "@mui/material/styles";
import { useOrganizationKey } from "../../hooks/useOrganizationKey";
import { useSelector } from "react-redux";
import { organizationSelector } from "../../state/organizations/selectors";
import { isEnterpriseOrganization } from "../../utils/modelUtils";
import BlobCache from "../../utils/BlobCache";

const RootDiv = styled("div", { name: "S3Attachment", shouldForwardProp: (prop) => prop !== "size" })(
    ({ theme, size }) => ({
        borderRadius: theme.shape.borderRadius,
    })
);

const getName = (s3Key) => s3Key.split("/")?.pop();

// TODO: Merge with LocalAttachment and AttachmentPreview
const S3Attachment = ({ s3Key, canDelete, onDelete, ...rest }) => {
    const { storageApi } = useContext(ApiContext);
    const organizationKey = useOrganizationKey();
    const organization = useSelector(organizationSelector(organizationKey));
    const [url, setUrl] = useState();
    const isImageKey = isImage(s3Key);
    const isAudioKey = isAudio(s3Key);
    const name = isImageKey ? getName(s3Key).replace(ThumbnailPictureSuffix, "") : getName(s3Key);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            if (isImageKey || isAudioKey) {
                const blobUrl = (await BlobCache.cacheObjectsFromS3([s3Key]))[0];
                if (isMounted) {
                    setUrl(blobUrl);
                }
            } else {
                // Show only simple public url for file attachment, signed url will be generated
                // when user will click on the link
                const attachmentUrl = storageApi.getPublicUrl(s3Key);
                setUrl(attachmentUrl);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [s3Key]);

    const handlePreviewClick = async (event) => {
        event.preventDefault();
        const attachmentKey = isImageKey ? s3Key.replace(ThumbnailPictureSuffix, "") : s3Key;

        if (isEnterpriseOrganization(organization)) {
            const blobUrl = (await BlobCache.cacheObjectsFromS3([attachmentKey]))[0];
            downloadFile(blobUrl, name, true);
        } else {
            const attachmentUrl = await storageApi.getSignedUrl(attachmentKey);
            downloadFile(attachmentUrl, name, true);
        }
    };

    const handleDelete = () => {
        storageApi.deleteAttachment(s3Key);
        onDelete();
    };

    return (
        <RootDiv>
            {Boolean(url) ? (
                <AttachmentPreview
                    url={url}
                    name={name}
                    canDelete={canDelete}
                    onDownload={handlePreviewClick}
                    onDelete={handleDelete}
                    {...rest}
                />
            ) : (
                <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: 1, height: 64, width: isAudioKey ? 316 : 116  }}
                />
            )}
        </RootDiv>
    );
};

S3Attachment.propTypes = {
    s3Key: PropTypes.string.isRequired,
    canDelete: PropTypes.bool,
    onDelete: PropTypes.func,
};

S3Attachment.defaultProps = {
    onDelete: () => {},
};

export default S3Attachment;
