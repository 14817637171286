import React, { useContext, useEffect, useRef, useState } from 'react';
import { LinearProgress, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import AttachmentPreview from '../AttachmentPreview/AttachmentPreview';
import DescriptionIcon from '@mui/icons-material/Description';
import { ApiContext } from '../../contexts/ApiContext';
import { newUuid } from '../../utils/idGenerator';
import { appendToFileName, isImage } from '../../utils/fileUtils';
import { Storage } from 'aws-amplify';
import { CURRENT_STORAGE_VERSION, ENCRYPTED_PREFIX, ThumbnailPictureSuffix } from '../../constants/storage';
import { ImageTools } from '../../utils/ImageTools';
import { ThumbnailPictureDimensions } from '../../constants';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { organizationSelector } from '../../state/organizations/selectors';
import { useSelector } from 'react-redux';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import BlobCache from '../../utils/BlobCache';
import EncryptionClient from '../../utils/EncryptionClient';

const LocalAttachment = ({ language, file, onUploaded, onDeleted }) => {
    const { storageApi } = useContext(ApiContext);
    const organizationKey = useOrganizationKey();
    const organization = useSelector(organizationSelector(organizationKey));
    const [uploadProgress, setUploadProgress] = useState(0);
    const [url, setUrl] = useState();
    const [uploaded, setUploaded] = useState();
    const s3KeysRef = useRef();

    useEffect(() => {
        let isMounted = true;
        const blobUrl= URL.createObjectURL(file);
        setUrl(blobUrl);

        (async () => {
            const rootBucketName = isEnterpriseOrganization(organization) ? ENCRYPTED_PREFIX : CURRENT_STORAGE_VERSION;
            const s3Key = `${rootBucketName}/attachments/${newUuid()}/${file.name}`;

            try {
                if (isImage(file.name)) {
                    // Downsize image to create image thumbnail and upload it to S3
                    const thumbnailKey = appendToFileName(s3Key, ThumbnailPictureSuffix);
                    let thumbnailBlob = await ImageTools.scaleToFit(file, ThumbnailPictureDimensions);
                    await uploadToS3(thumbnailKey, thumbnailBlob, {
                        contentType: file.type,
                        progressCallback: (progress) => {
                            isMounted && setUploadProgress((progress.loaded / progress.total) * 50);
                        },
                    });
                    // Upload original image to S3
                    await uploadToS3(s3Key, file, {
                        contentType: file.type,
                        progressCallback: (progress) => {
                            isMounted && setUploadProgress(50 + (progress.loaded / progress.total) * 50);
                        },
                    });

                    BlobCache.cacheBlobUrl(thumbnailKey, URL.createObjectURL(thumbnailBlob));
                    BlobCache.cacheBlobUrl(s3Key, blobUrl);
                    s3KeysRef.current = [s3Key, thumbnailKey];
                } else {
                    await uploadToS3(s3Key, file, {
                        contentType: file.type,
                        progressCallback: (progress) => {
                            isMounted && setUploadProgress((progress.loaded / progress.total) * 100);
                        },
                    })

                    BlobCache.cacheBlobUrl(s3Key, blobUrl);
                    s3KeysRef.current = [s3Key];
                }
            } catch (err) {
                console.warn("error: ", err);
            }

            setUploaded(true);
            onUploaded(s3Key);
        })();

        return () => {
            isMounted = false;
            BlobCache.destroyBlobByUrl(blobUrl);
        };
    }, []);

    const uploadToS3 = async (key, blob, config) => {
        let dataToUpload = blob;
        if (isEnterpriseOrganization(organization)) {
            const fileBuffer = await blob.arrayBuffer();
            const bytes = new Uint8Array(fileBuffer);
            dataToUpload = await EncryptionClient.encryptBytes(bytes);
        }

        await Storage.put(key, dataToUpload, config);
    }

    const handleDelete = () => {
        for (const key of s3KeysRef.current) {
            BlobCache.destroyBlob(key);
            storageApi.deleteAttachment(key);
        }
        onDeleted();
    };

    return uploaded ? (
        <AttachmentPreview
            name={file.name}
            url={url}
            language={language}
            canDelete
            onDelete={handleDelete}
        />
    ) : (
        <Stack
            direction="row"
            alignItems="center"
            sx={{
                width: 160,
                height: 64,
                borderRadius: 1,
                borderWidth: "thin",
                borderStyle: "solid",
                borderColor: "divider",
                padding: 1,
            }}
        >
            <DescriptionIcon
                sx={{
                    width: 40,
                    height: 40,
                    color: "grey.400",
                    marginRight: 1,
                }}
            />
            <LinearProgress
                value={uploadProgress}
                variant="determinate"
                sx={{
                    height: 6,
                    flex: "1 1 auto",
                    borderRadius: 6,
                    backgroundColor: "grey.300",
                }}
            />
        </Stack>
    );
};

LocalAttachment.propTypes = {
    className: PropTypes.string,
    file: PropTypes.object.isRequired,
    onUploaded: PropTypes.func,
    onDeleted: PropTypes.func,
    language: PropTypes.string,
};

LocalAttachment.defaultProps = {
    onUploaded: () => {},
    onDeleted: () => {},
};

export default LocalAttachment;
