import React from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    ...theme.typography.buttonMedium,
    color: theme.palette.text.secondary,
    "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
    },
}));

const FormEditorToolbar = ({ lastSavedAt, isSaving, mode, onModeChange, onSettingsButtonClick }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

    const handleModeChange = (event, newValue) => {
        if (!newValue) {
            return;
        }

        onModeChange(newValue);
    };

    return (
        <Box sx={{position: 'sticky', top: 0, left: 'auto', right: 0, zIndex: 'appBar'}}>
            <Stack direction='row' alignItems='center' px={3} py={2} sx={{bgcolor: 'background.paper'}}>
                <Box flex="1 1 0" />
                <Box flex="1 1 0" textAlign="center">
                    <ToggleButtonGroup
                        value={mode}
                        exclusive
                        onChange={handleModeChange}
                        size="small"
                        aria-label="mode"
                    >
                        <StyledToggleButton value="edit" aria-label="edit mode">
                            <EditOutlinedIcon fontSize="medium" />
                            <Typography variant="button">{t("editing")}</Typography>
                        </StyledToggleButton>
                        <StyledToggleButton value="view" aria-label="preview mode">
                            <PlayArrowOutlinedIcon fontSize="small" />
                            <Typography variant="button">{t("preview")}</Typography>
                        </StyledToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box flex="1 1 0">
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                        sx={{ color: "text.secondary" }}
                    >
                        <Tooltip title={t("savedAt", {value: lastSavedAt})}>
                            <Box sx={{"&>*": {verticalAlign: 'middle'}}}>
                                {isSaving ? <CircularProgress color="inherit" size={20} /> : <CheckCircleOutlineIcon />}
                                <Typography display="inline" color="inherit" sx={{ ml: 0.5 }}>
                                    {isSaving ? t("saving") : t("saved")}
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 1 }} />
                        <Tooltip title={t("expandSettings")}>
                            <IconButton edge="end" onClick={onSettingsButtonClick}>
                                <SettingsIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
            </Stack>
            {/*<AppBar position="sticky" elevation={0} sx={{ zIndex: "appBar" }}>*/}
            {/*    <Toolbar sx={{ backgroundColor: "background.paper" }}>*/}
            {/*        */}
            {/*    </Toolbar>*/}
            {/*</AppBar>*/}
            <Divider />
        </Box>
    );
};

FormEditorToolbar.propTypes = {
    mode: PropTypes.oneOf(["edit", "view"]),
    lastSavedAt: PropTypes.object,
    isSaving: PropTypes.bool,
    onModeChange: PropTypes.func,
    onSettingsButtonClick: PropTypes.func,
};

FormEditorToolbar.defaultProps = {
    mode: "edit",
    onModeChange: () => {},
    onSettingsButtonClick: () => {},
};

export default FormEditorToolbar;
