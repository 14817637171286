import React from 'react';
import { useLocalStorage } from './useLocalStorage';
import { useAuthContext } from '../contexts/AuthContext';
import { toUserKey } from '../utils/apiUtils';

export const useStoredOrganizationId = () => {
    const {user} = useAuthContext();
    if (!user) {
        throw new Error("User cannot be null")
    }

    let storageKey = `${toUserKey(user.username)}-activeOrg`;
    return useLocalStorage(storageKey);
};
