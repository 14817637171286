import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const RootDiv = styled('div', { name: 'TabPanel', shouldForwardProp: (prop) => prop !== "fullHeight" && prop !== 'active' })(({ theme, color, fullHeight, active }) => ({
    overflow: "auto",
    ...(!active && {
        display: 'none'
    }),
    ...(fullHeight && {
        height: '100%'
    }),
}));


const TabPanel = ({ children, value, index, fullHeight, ...rest }) => {
    return (
        <RootDiv
            role="tabpanel"
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            active={value === index}
            fullHeight={fullHeight}
            {...rest}
        >
            {children}
        </RootDiv>
    );
}

export default TabPanel;

TabPanel.propTypes = {
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    fullHeight: PropTypes.bool
}