import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    LinearProgress,
    Tooltip, Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {addDays, daysDiff, millisecondsToDays} from "../../utils/time";
import { styled } from '@mui/material/styles';
import TodayIcon from '@mui/icons-material/Today';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { workspaceSelector } from '../../state/workspaces/selectors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    barColorDefault: {
        backgroundColor: theme.palette.secondary.main
    },
    barColorOverdue : {
        backgroundColor: theme.palette.error.main
    }
}));

const StyledLinearProgress = styled(LinearProgress, {name: "TermProgress"})(({ theme }) => ({
    height: "4px",
    width: "50%",
    borderRadius: "4px",
    backgroundColor: theme.palette.grey[300],
    position: "relative",
    '&:after': {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.divider}`
    },
}));

const ReportProgress = ({classes: propClasses, createdAt, disableTooltip, dueDate, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let isOverdue = false;
    let value = 0;
    let tooltip = '';
    if (dueDate) {
        const createdAtParsed = new Date(createdAt);
        const termLength = daysDiff(new Date(dueDate), createdAtParsed);
        const elapsed = daysDiff(new Date(), createdAtParsed);
        const daysLeft = Math.abs(termLength - elapsed);

        isOverdue = new Date() > dueDate;
        tooltip = isOverdue ? (t("daysOverdue", {count: daysLeft})) : (t("daysRemain", {count: daysLeft}));
        value = !dueDate ? 0 : Math.min(elapsed / termLength, 1) * 100;
    }

    return (
        <Tooltip title={disableTooltip ? undefined : tooltip}>
            <StyledLinearProgress
                color="secondary"
                variant="determinate"
                classes={{
                    barColorSecondary: clsx({
                        [classes.barColorDefault] : !isOverdue,
                        [classes.barColorOverdue] : isOverdue,
                    })
                }}
                value={value}
                {...rest}
            />
        </Tooltip>
    )
};

ReportProgress.propTypes = {
    createdAt: PropTypes.string.isRequired,
    dueDate: PropTypes.string,
    disableTooltip: PropTypes.bool,
}

ReportProgress.defaultProps = {
};


export default ReportProgress;