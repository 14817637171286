import React, { useContext, useState } from 'react';
import { Box, Container, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../contexts/ApiContext';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import FixedLabelTextField from '../../components/FixedLabelTextField';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { organizationSelector } from '../../state/organizations/selectors';
import { addOrUpdateOrganization } from '../../state/organizations/actions';
import LoadingButton from '@mui/lab/LoadingButton';

const StyledContainer = styled(Container, { name: "GeneralSettings" })(({ theme, color }) => ({}));

const GeneralOrganizationSettingsView = ({}) => {
    const { mutationApi } = useContext(ApiContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const organizationKey = useOrganizationKey();
    const organization = useSelector(organizationSelector(organizationKey));
    const [name, setName] = React.useState(organization.name);
    const [nameError, setNameError] = useState();
    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleNameChange = (event) => {
        const value = event.target.value;
        setNameError(value.length === 0 ? t("nameIsRequired") : null);
        setName(value);
        setChanged(true);
    };

    const handleSaveButtonClick = async () => {
        const data = {};
        if (name !== organization.name) {
            data.name = name;
        }

        // Data can have zero keys, when for example name is changed and then changed
        // back to the original value. The value won't be added to the data object but
        // changed state will be true, which caused the save button to be enabled
        if (Object.keys(data) === 0) {
            return;
        }

        setSaving(true);
        dispatch(addOrUpdateOrganization(organizationKey, data));
        // TODO: Move to MutationAPI class
        await mutationApi.updatePrivateResource(organizationKey, organizationKey, data);
        await mutationApi.updatePublicResource(organizationKey, organizationKey, data);
        setChanged(false);
        setSaving(false);
    };

    return (
        <Stack spacing={4}>
            <FixedLabelTextField
                required
                fullWidth
                label={t("name")}
                variant="outlined"
                value={name || ""}
                error={Boolean(nameError)}
                helperText={nameError}
                onChange={handleNameChange}
            />
            <Box sx={{'&&': {ml: 'auto'}}}>
                <LoadingButton
                    autoFocus
                    color="primary"
                    variant="contained"
                    size="large"
                    loading={saving}
                    disabled={!changed || nameError}
                    onClick={handleSaveButtonClick}
                >
                    {t("Save changes")}
                </LoadingButton>
            </Box>
        </Stack>
    );
};

export default GeneralOrganizationSettingsView;
