import React, { useContext, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../contexts/ApiContext';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import ViewContainer from '../../components/ViewContainer';

const FormCaptcha = ({onSuccess}) => {
    const { t, i18n } = useTranslation();
    const { queryApi } = useContext(ApiContext);
    const [failed, setFailed] = useState(false);

    const handleCaptchaChange = async (token) => {
        try {
            const result = await queryApi.verifyCaptchaToken(token);
            console.log("handleCaptchaChange, result: %o", result);
            if (result.success) {
                onSuccess();
            } else {
                setFailed(true);
            }
        } catch {
            setFailed(true);
        }
    }

    return (
        <ViewContainer sx={{"div": {margin: 'auto'}}}>
            <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={handleCaptchaChange}/>
            {failed && (
                <Typography align='center' variant='h5' sx={{mt: 4}}>
                    {t('captchaVerificationFailed')}
                </Typography>
            )}
        </ViewContainer>
    )
};

FormCaptcha.propTypes = {
    onSuccess: PropTypes.func,
};

FormCaptcha.defaultProps = {
    onSuccess: () => {},
};

export default FormCaptcha;
