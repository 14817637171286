import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';

export default styled(IconButton)(({ theme }) => ({
    padding: 0,
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.contrastText,
    "&:hover": {
        color: theme.palette.error.dark,
        backgroundColor: theme.palette.error.contrastText,
    },
}));