import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isAttachmentBlock, isDateSection, isRichTextFieldSection } from "../../utils/formBlockUtils";
import MessageAttachments from "./MessageAttachments";
import { alpha, styled } from "@mui/material/styles";
import Timestamp from "../Timestamp";
import { split } from "../../utils/arrayUtils";
import RichText from "../RichText/RichText";

const classNames = {
    senderLabel: "ChatMessage-senderLabel",
    timestamp: "ChatMessage-timestamp",
    sectionLabel: "ChatMessage-sectionLabel",
    text: "ChatMessage-text",
};

const RootStack = styled(Stack, {
    name: "ChatMessage",
    shouldForwardProp: (prop) => prop !== "position" && prop !== "variant",
})(({ theme, position, variant }) => ({
    marginLeft: position === 'right' ? 'auto' : 'initial',
    [theme.breakpoints.up("xs")]: {
        maxWidth: "100%",
    },
    [theme.breakpoints.up("sm")]: {
        maxWidth: "40ch",
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: "50ch",
    },
    [theme.breakpoints.up("xl")]: {
        maxWidth: "60ch",
    },
}));

const ContentStack = styled(Stack, { shouldForwardProp: (prop) => prop !== "position" && prop !== "variant" })(
    ({ theme, position, variant }) => ({
        alignItems: "flex-start",
        ...(variant === "bubble" && {
            padding: theme.spacing(1.25, 2, 2, 2),
            borderRadius: 12,
            border: `1px solid ${theme.palette.grey[300]}`, //300,
        }),
        ...(variant === "bubble" &&
            position === "left" && {
                backgroundColor: theme.palette.background.paper, //200
                [`.${classNames.senderLabel}, .${classNames.text}`]: {
                    color: theme.palette.text.primary,
                },
                [`.${classNames.sectionLabel},.${classNames.timestamp}`]: {
                    color: theme.palette.text.secondary,
                },
            }),
        ...(variant === "bubble" &&
            position === "right" && {
                backgroundColor: theme.palette.primary.main,
                //backgroundColor: alpha(theme.palette.primary.main, 0.95),
                [`.${classNames.senderLabel},.${classNames.text}`]: {
                    color: theme.palette.primary.contrastText,
                    //color: theme.palette.grey[300]
                    //color: alpha(theme.palette.primary.contrastText, 0.9)
                },
                [`.${classNames.sectionLabel},.${classNames.timestamp}`]: {
                    color: alpha(theme.palette.primary.contrastText, 0.64)
                    //color: theme.palette.grey[400]
                },
            }),
    })
);

const MessageTextDiv = styled("div", { shouldForwardProp: (prop) => prop !== "position" && prop !== "variant" })(
    ({ theme, position, variant }) => ({
        wordWrap: "break-word",
        "&>*:not(:last-child)": {
            paddingBottom: "0.5em",
        },
    })
);

const ChatMessage = ({ message, avatar, variant, position, hideHeader, senderName, onRendered, ...rest }) => {
    const { t, i18n } = useTranslation();
    const nonNullSections = message.content.filter((section) => Boolean(section.value));
    const { true: attachmentSections, false: nonAttachmentSections } = split(nonNullSections, isAttachmentBlock);
    const attachments = attachmentSections.flatMap((section) => section.value);

    return (
        <RootStack direction={position === "right" ? "row-reverse" : "row"} spacing={1} position={position} {...rest}>
            {avatar && <Box>{avatar}</Box>}
            <Box minWidth={0} sx={{...(avatar && variant !== 'bubble' && {'&&': {mt: -0.5}})}}>
                <ContentStack direction='column' variant={variant} position={position}>
                    {!hideHeader && (
                        <Box width="100%" pb={0.5}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                {senderName && (
                                    <Typography
                                        className={classNames.senderLabel}
                                        variant="subtitle1"
                                        component="span"
                                        mr={1}
                                    >
                                        {senderName}
                                    </Typography>
                                )}
                                <Timestamp
                                    className={classNames.timestamp}
                                    color="textSecondary"
                                    variant="caption"
                                    value={message.createdAt}
                                    component="span"
                                />
                            </Stack>
                            {message.ccEmailAddresses && message.ccEmailAddresses.length > 0 && (
                                <Typography className={classNames.text} variant="body2">
                                    {`${t("copies")}: ${message.ccEmailAddresses.join(", ")}`}
                                </Typography>
                            )}
                        </Box>
                    )}
                    {nonAttachmentSections.length > 0 && (
                        <MessageTextDiv>
                            {nonAttachmentSections.map((section) => (
                                <Box key={section.key}>
                                    {section.label && (
                                        <Typography
                                            className={classNames.sectionLabel}
                                            variant="subtitle2"
                                            color="textSecondary"
                                        >
                                            {section.label}
                                        </Typography>
                                    )}
                                    {isRichTextFieldSection(section) ? (
                                        <RichText className={classNames.text} content={section.value} />
                                    ) : (
                                        <Typography
                                            className={classNames.text}
                                            variant="body1"
                                            sx={{ whiteSpace: "pre-wrap" }}
                                        >
                                            {Array.isArray(section.value)
                                                ? section.value.map((item) => (
                                                    <React.Fragment key={item}>
                                                        {item}
                                                        <br />
                                                    </React.Fragment>
                                                ))
                                                : isDateSection(section)
                                                    ? new Date(section.value).toLocaleString(i18n.language, {
                                                        dateStyle: "long",
                                                    })
                                                    : section.value}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </MessageTextDiv>
                    )}
                </ContentStack>
                {attachments.length > 0 && <MessageAttachments attachments={attachments} />}
            </Box>
        </RootStack>
    );
};

ChatMessage.propTypes = {
    message: PropTypes.object,
    avatar: PropTypes.element,
    hideHeader: PropTypes.bool,
    senderName: PropTypes.string,
    variant: PropTypes.oneOf(["plain", "bubble"]),
    position: PropTypes.oneOf(["right", "left"]),
    onRendered: PropTypes.func
};

ChatMessage.defaultProps = {
    variant: "plain",
    position: "left",
    onRendered: () => {}
};

export default ChatMessage;
