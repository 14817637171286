import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { getLocale } from "../../constants/languages";
import { Controller, useFormContext } from "react-hook-form";
import deLocale from "dayjs/locale/de";
import skLocale from "dayjs/locale/sk";
import plLocale from "dayjs/locale/pl";
import huLocale from "dayjs/locale/hu";
import frLocale from "dayjs/locale/fr";
import esLocale from "dayjs/locale/es";
import ptLocale from "dayjs/locale/pt";
import itLocale from "dayjs/locale/it";
import nlLocale from "dayjs/locale/nl";
import daLocale from "dayjs/locale/da";
import noLocale from "dayjs/locale/nb";
import fiLocale from "dayjs/locale/fi";
import svLocale from "dayjs/locale/sv";
import roLocale from "dayjs/locale/ro";
import slLocale from "dayjs/locale/sl";
import hrLocale from "dayjs/locale/hr";
import lvLocale from "dayjs/locale/lv";
import etLocale from "dayjs/locale/et";
import ruLocale from "dayjs/locale/ru";
import ukLocale from "dayjs/locale/uk";
import bgLocale from "dayjs/locale/bg";
import elLocale from "dayjs/locale/el";
import viLocale from "dayjs/locale/vi";
import zhLocale from "dayjs/locale/zh";
import { answerRegistrationName, getBlockTemplate, getTranslations } from "./utils";
import { isValueObject } from "immutable";

dayjs.extend(localeData);

const RootBox = styled(Box, { name: "FormDateFieldBlock" })(({ theme }) => ({}));

const isValidDate = (value) => {
    if (value instanceof Date) {
        return !isNaN(value);
    }

    return dayjs(value).isValid();
};

// TODO: Use dynamic imports
const FormDateFieldBlock = ({ blockIndex, language, mode, ...rest }) => {
    const { t } = useTranslation();
    const { clearErrors, getValues } = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);
    const [hint, setHint] = useState();

    useEffect(() => {
        // Hint text is created from the date format by replacing '/' and '.' format dividers with ','
        dayjs.locale(getLocale(language));
        const localeData = dayjs.localeData();
        const localeFormat = localeData.longDateFormat("L");
        let dateHint = localeFormat.replace(/\//g, ", ").replace(/\./g, ", ");
        dateHint = dateHint.replace("DD", t("day", { lng: language }));
        dateHint = dateHint.replace("MM", t("month", { lng: language }));
        dateHint = dateHint.replace("YYYY", t("year", { lng: language }));
        setHint(dateHint);
    }, [language]);

    return (
        <RootBox {...rest}>
            <Controller
                name={answerRegistrationName(blockTemplate.key)}
                rules={{
                    required: mode === "view" && blockTemplate.required && "requiredFieldError",
                    validate: (value, formValues) => {
                        return mode === "edit" && !value || isValidDate(value) || "invalidDateError";
                    }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLocale(language)}>
                        <DatePicker
                            onChange={(date) => {
                                if (Boolean(error?.message)) {
                                    clearErrors(answerRegistrationName(blockTemplate.key));
                                }
                                if (date && !isNaN(date)) {
                                    onChange(date.toISOString());
                                } else {
                                    onChange(date);
                                }
                            }}
                            value={!value || isValidDate(value) ? value ?? null : new Date(value)}
                            openTo="year"
                            views={["year", "month", "day"]}
                            disabled={mode === "edit"}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    error={Boolean(error?.message)}
                                    helperText={
                                        mode === "view" ? (Boolean(error?.message) ? t(error.message, { lng: language }) : hint) : undefined
                                    }
                                    sx={{
                                        "& .MuiFormHelperText-root": {
                                            position: "absolute",
                                            top: "100%",
                                        },
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: mode === "view" ? params.inputProps.placeholder : undefined,
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                )}
            />
        </RootBox>
    );
};

FormDateFieldBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]).isRequired,
};

FormDateFieldBlock.defaultProps = {
    mode: "view",
};

export default FormDateFieldBlock;
