import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page/Page';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useAuthContext } from '../../contexts/AuthContext';
import { useStoredOrganizationId } from '../../hooks/useStoredOrganizationId';

const NotAuthorizedView = () => {
  const { t } = useTranslation();
  const { signOut } = useAuthContext();
  const [_, setStoredOrganizationId] = useStoredOrganizationId();

  const handleSignOutClick = async (event) => {
    event.preventDefault();

    setStoredOrganizationId(null);
    await signOut();
  };

  return (
    <Page component={'main'} sx={{ height: '100vh' }}>
      <Container maxWidth="sm" sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%', px: 4 }}
        >
          <Typography align="center" variant="h5">
            {t('organizationNotFound')}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            onClick={handleSignOutClick}
            sx={{ mt: 3 }}
          >
            {t('signOut')}
          </Button>
        </Stack>
      </Container>
    </Page>
  );
};

export default NotAuthorizedView;
