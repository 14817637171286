import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import ResourceAvatar from '../../components/ResourceAvatar';
import { useSelector } from 'react-redux';
import { workspaceSelector } from '../../state/workspaces/selectors';
import { getNonWorkspaceRouteTitle } from '../../utils/routeUtils';
import { useLocation } from 'react-router';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import ProfileButton from './ProfileButton';
import { NavLink } from 'react-router-dom';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import StyledTabs from '../../components/StyledTabs/StyledTabs';
import CustomTab from '../../components/StyledTabs/CustomTab';
import { toId } from '../../utils/apiUtils';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ShareIcon from '../../icons/ShareIcon';
import SquaresPlusIcon from '../../icons/SquaresPlusIcon';
import ChatBubbleLeftRightIcon from '../../icons/ChatBubbleLeftRightIcon';
import LifebuoyIcon from '../../icons/LifebuoyIcon';

const RootAppBar = styled(AppBar, { name: 'TopBar' })(({ theme }) => ({
  flex: 0,
  backgroundColor: theme.palette.background.default,
}));

const HeaderTab = styled(CustomTab)(({ theme }) => ({
  paddingTop: theme.spacing(0.25),
  // paddingBottom: theme.spacing(1),
  '&:hover': {
    textDecoration: 'none',
  },
  '&:hover:not(.Mui-selected)': {
    color: theme.palette.text.secondary,
  },
}));

const routePatterns = [
  /\/overview[\/]{0,1}$/,
  /\/reports[\/]{0,1}/,
  /\/form[\/]{0,1}$/,
  /\/publishing[\/]{0,1}$/,
];

const getActiveTabValue = (route) => {
  // If the route points only to workspace with no subroute specified return 0
  return Math.max(
    routePatterns.findIndex((pattern) => route.match(pattern)),
    0,
  );
};

const TopBar = ({ ...rest }) => {
  const { t, i18n } = useTranslation();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const workspace = useSelector(workspaceSelector(workspaceKey));
  const { pathname } = useLocation();
  const title =
    workspace?.name ?? getNonWorkspaceRouteTitle(pathname, t, i18n.language);
  const activeTab = getActiveTabValue(pathname);
  const isSettingsPath = workspaceKey && Boolean(pathname.match(/\/settings/));

  const getFullPath = (page) => {
    if (!workspaceKey) {
      return null;
    }

    return `/app/${toId(organizationKey)}/${toId(workspaceKey)}/${page}`;
  };

  return (
    <RootAppBar position="sticky" color="transparent" elevation={0} {...rest}>
      <Toolbar>
        {workspace && (
          <Box sx={{ mr: 2 }}>
            <ResourceAvatar
              name={workspace.name}
              picture={workspace.picture}
              variant="rounded"
              sx={{ width: 48, height: 48 }}
            />
          </Box>
        )}
        <Stack flexGrow={1} alignSelf="stretch">
          <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
            <Typography variant="h4">{title}</Typography>
          </Stack>
          {workspace && !isSettingsPath && (
            <Tabs value={activeTab}>
              <HeaderTab
                disableRipple
                label={t('overview')}
                icon={<LifebuoyIcon fontSize="small" />}
                iconPosition="start"
                component={NavLink}
                to={getFullPath('overview')}
              />
              <HeaderTab
                disableRipple
                label={t('reports')}
                icon={<ChatBubbleLeftRightIcon fontSize="small" />}
                iconPosition="start"
                component={NavLink}
                to={getFullPath('reports')}
              />
              <HeaderTab
                disableRipple
                label={t('form')}
                icon={<SquaresPlusIcon fontSize="small" />}
                iconPosition="start"
                component={NavLink}
                to={getFullPath('form')}
              />
              <HeaderTab
                disableRipple
                label={t('publishing')}
                icon={<ShareIcon fontSize="small" />}
                iconPosition="start"
                component={NavLink}
                to={getFullPath('publishing')}
              />
            </Tabs>
          )}
        </Stack>
        <ProfileButton />
      </Toolbar>
      <Divider />
    </RootAppBar>
  );
};

TopBar.propTypes = {
  onClick: PropTypes.func,
};

TopBar.defaultProps = {
  onClick: () => {},
};

export default TopBar;
