import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineCode = styled((props) => <Typography component="code" {...props} />, { name: "InlineCode" })(
    ({ theme }) => ({
        fontFamily: "monospace, monospace",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.grey[100],
        borderRadius: theme.shape.borderRadius * 0.75,
        border: `thin solid ${theme.palette.grey[400]}`,
        padding: theme.spacing(0.25, 0.75),
    })
);

InlineCode.propTypes = {};

InlineCode.defaultProps = {};

export default InlineCode;
