import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const HoverOutlineDecorator = styled(Box, { name: 'HoverOutlineDecorator' })(
  ({ theme }) => ({
    '& fieldset': {
      borderStyle: 'none',
    },
    '&:hover fieldset': {
      borderStyle: 'solid',
      '& .MuiSvgIcon-root': {
        visibility: 'visible',
      },
    },
    '&:focus-within fieldset': {
      borderStyle: 'solid',
      '& .MuiSvgIcon-root': {
        visibility: 'visible',
      },
    },
    '& .MuiInputBase-multiline': {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);

export default HoverOutlineDecorator;
