import React from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import { useSelector } from "react-redux";
import { tagsSelector } from "../../state/tags/selectors";
import PropTypes from "prop-types";
import { statusSelector } from "../../state/statuses/selectors";

const RootChip = styled(Chip, { name: "Status", shouldForwardProp: (prop) => prop !== "color" })(
    ({ theme, color }) => ({
        backgroundColor: color,
        color: "#FFFFFF",
        width: 100,
        padding: 0,
        minWidth: "32px",
    })
);

const Status = ({ workspaceKey, statusKey, ...rest }) => {
    const status = useSelector(statusSelector(workspaceKey, statusKey));
    return <RootChip size="small" label={status.name} color={status.color} {...rest} />;
};

Status.propTypes = {
    workspaceKey: PropTypes.string.isRequired,
    statusKey: PropTypes.string.isRequired,
};

Status.defaultProps = {};

export default Status;
