import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toOrganizationKey } from '../utils/apiUtils';
import { OrganizationKeyContext } from '../contexts/OrganizationKeyContext';

export const useOrganizationKey = () => {
  const { organizationId } = useParams();
  const organizationKey = useContext(OrganizationKeyContext);
  return Boolean(organizationId)
    ? toOrganizationKey(organizationId)
    : organizationKey;
};
