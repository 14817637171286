import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import DialogHeader from '../DialogHeader/DialogHeader';
import { Controller, useFormContext } from 'react-hook-form';
import {
  getPrivacyPolicy,
  translationRegistrationName,
} from '../FormBlock/utils';
import RichText from '../RichText/RichText';

const RootStack = styled(Stack, { name: 'FormViewPrivacyPolicyBlock' })(
  ({ theme }) => ({}),
);

const FormViewPrivacyPolicyBlock = ({ language, ...rest }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const { getValues } = useFormContext();
  const privacyPolicyTemplate = getPrivacyPolicy(getValues);

  const handleLinkClick = (event) => {
    event.preventDefault();
    setDialogOpen(true);
  };

  return (
    <RootStack alignItems="center" {...rest}>
      <Controller
        name={translationRegistrationName(privacyPolicyTemplate.label)}
        render={({ field: { value } }) => (
          <Link
            href="#"
            color="link.main"
            variant="body2"
            underline="hover"
            onClick={handleLinkClick}
          >
            {value}
          </Link>
        )}
      />
      <Dialog
        id="delete-member-confirmation-dialog"
        maxWidth="sm"
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="privacy-policy-dialog-title"
      >
        <DialogHeader
          id="privacy-policy-dialog-title"
          showExitButton
          disableTypography
          onExitClick={() => setDialogOpen(false)}
        ></DialogHeader>
        <DialogContent sx={{ pt: 0 }}>
          <Controller
            name={translationRegistrationName(privacyPolicyTemplate.content)}
            render={({ field: { value } }) => <RichText content={value} />}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            color="default"
            variant="outlined"
            onClick={() => setDialogOpen(false)}
          >
            {t('cancel', { lng: language })}
          </Button>
        </DialogActions>
      </Dialog>
    </RootStack>
  );
};

FormViewPrivacyPolicyBlock.propTypes = {
  language: PropTypes.string.isRequired,
};

FormViewPrivacyPolicyBlock.defaultProps = {};

export default FormViewPrivacyPolicyBlock;
