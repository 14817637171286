import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { scrollToView } from '../../utils/ui';
import MessageSkeleton from '../MessageSkeleton/MessageSkeleton';
import {
  getExcerptFromMessageContent,
  isSystemMessage,
} from '../../utils/reportUtils';
import { useDispatch, useSelector } from 'react-redux';
import ChatMessage from '../ChatMessage';
import { styled } from '@mui/material/styles';
import { alpha, Avatar, Box, Divider } from '@mui/material';
import {
  AVATAR_SIZES,
  FONT_SIZES,
  getSmallPictureVariant,
} from '../../utils/avatarUtils';
import PersonIcon from '@mui/icons-material/Person';
import ResourceAvatar from '../ResourceAvatar';
import { newMessageKey } from '../../utils/apiUtils';
import { newAwsDateTime } from '../../utils/time';
import { addOrUpdateMessage } from '../../state/messages/actions';
import { ApiContext } from '../../contexts/ApiContext';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import { organizationSelector } from '../../state/organizations/selectors';
import { reportMessagesSelector } from '../../state/messages/selectors';
import { allMembersSelector } from '../../state/members/selectors';
import { workspaceSelector } from '../../state/workspaces/selectors';
import { useTranslation } from 'react-i18next';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { Virtuoso } from 'react-virtuoso';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';
import ChatInput from '../ChatInput';

export const ReportChatClassNames = {
  root: 'ReportChat-root',
  messageContainer: 'ReportChat-messageContainer',
  input: 'ReportChat-input',
  message: 'ReportChat-message',
};

const RootBox = styled(Box, { name: 'ReportChat' })(({ theme, position }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const AnonymousAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(AVATAR_SIZES.small),
  width: theme.spacing(AVATAR_SIZES.small),
  fontSize: FONT_SIZES.small,
  //backgroundColor: `linear-gradient(${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.75)})`,
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[500],
  border: `1px solid ${theme.palette.grey[300]}`,
}));

const ReportChat = ({
  reportKey,
  workspaceKey,
  organizationKey,
  loading,
  ChatInputProps,
  variant,
  onSubmitted,
  ...rest
}) => {
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const messages = useSelector(reportMessagesSelector(reportKey));
  const members = useSelector(allMembersSelector(organizationKey));
  const organization = useSelector(organizationSelector(organizationKey));
  const workspace = useSelector(workspaceSelector(workspaceKey));
  const currentUser = useSelector(currentUserSelector());

  const handleChatSubmit = async ({ messageContent }) => {
    const messageKey = newMessageKey();
    const apiVariables = {
      content: messageContent,
      messageKey: messageKey,
      excerpt: getExcerptFromMessageContent(messageContent),
    };
    const isEnterprise = isEnterpriseOrganization(organization);
    if (currentUser) {
      mutationApi.createMessageAsMember(
        organizationKey,
        reportKey,
        apiVariables,
        isEnterprise,
      );
    } else {
      mutationApi.createMessageAsGuest(
        organizationKey,
        reportKey,
        apiVariables,
        isEnterprise,
      );
    }

    const createdAt = newAwsDateTime();
    const reduxVariables = {
      content: messageContent,
      createdAt: createdAt,
      updatedAt: createdAt,
      senderKey: currentUser?.pk,
    };
    dispatch(addOrUpdateMessage(reportKey, messageKey, reduxVariables));
    onSubmitted?.(messageKey);
  };

  const getMessageSender = (message) => {
    if (!message.senderKey) {
      return null;
    }

    return members?.find((user) => user.sk === message.senderKey);
  };

  const messageAvatar = (message, sender) => {
    if (isSystemMessage(message)) {
      return (
        <ResourceAvatar
          variant="rounded"
          name={workspace.name}
          picture={workspace.picture}
          size={32}
        />
      );
    }
    if (!isSystemMessage(message) && !message.senderKey) {
      return (
        <AnonymousAvatar>
          <PersonIcon />
        </AnonymousAvatar>
      );
    }

    return (
      <ResourceAvatar
        variant="circular"
        name={sender.name}
        picture={sender.picture}
        size={32}
      />
    );
  };

  //
  // const prependItems = useCallback(() => {
  //     const usersToPrepend = 20
  //     const nextFirstItemIndex = firstItemIndex - usersToPrepend
  //
  //     setTimeout(() => {
  //         setFirstItemIndex(() => nextFirstItemIndex)
  //         setUsers(() => [...generateUsers(usersToPrepend, nextFirstItemIndex), ...users])
  //     }, 500)
  //
  //     return false
  // }, [firstItemIndex, messages, setUsers])

  return (
    <RootBox className={ReportChatClassNames.root} {...rest}>
      <Box flex="1 1 auto">
        {loading ? (
          <Box px={4} sx={{ '&& > *': { py: 1.5 } }}>
            <MessageSkeleton />
            <MessageSkeleton />
            <MessageSkeleton position="right" />
          </Box>
        ) : (
          <Virtuoso
            style={{ height: '100%' }}
            followOutput={'auto'}
            //firstItemIndex={firstItemIndex}
            data={messages}
            defaultItemHeight={100}
            totalCount={messages.length}
            initialTopMostItemIndex={messages.length - 1}
            //atBottomThreshold={16}
            // startReached={prependItems}
            components={{
              Header: () => <Box pt={1.5} />,
            }}
            itemContent={(index, message) => {
              const sender = isSystemMessage(message)
                ? undefined
                : getMessageSender(message);
              return (
                <ChatMessage
                  className={ReportChatClassNames.message}
                  message={message}
                  avatar={messageAvatar(message, sender)}
                  senderName={
                    isSystemMessage(message)
                      ? t('autoMessage')
                      : sender?.name ?? t('reportingPerson')
                  }
                  variant={variant}
                  position={
                    !isSystemMessage(message) && sender?.sk === currentUser?.pk
                      ? 'right'
                      : 'left'
                  }
                  sx={{
                    py: 1.5,
                    px: 4,
                  }}
                />
              );
            }}
          />
        )}
      </Box>
      <Box
        className={ReportChatClassNames.input}
        flex="0 0 auto"
        pt={1.5}
        pb={3}
        px={4}
      >
        <ChatInput
          {...ChatInputProps}
          disableAtMention
          disableEmail
          onSubmit={handleChatSubmit}
        />
      </Box>
    </RootBox>
  );
};

ReportChat.propTypes = {
  reportKey: PropTypes.string.isRequired,
  workspaceKey: PropTypes.string.isRequired,
  organizationKey: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  ChatInputProps: PropTypes.shape({
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['plain', 'bubble']),
  position: PropTypes.oneOf(['right', 'left']),
};

ReportChat.defaultProps = {};

export default ReportChat;
