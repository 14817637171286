import * as types from "./actionTypes";
import { stableSort } from '../../utils/arrayUtils';
import { toId } from '../../utils/apiUtils';

const initialState = {};

export default function activityLogReducer(state = initialState, action) {
    switch (action.type) {
        case types.ACTIVITY_LOG_LOADED: {
            const {organizationKey, items} = action.payload;
            const newState = {...state};
            newState[organizationKey] = stableSort(items, 'desc', "createdAt");
            return newState;
        }
        case types.ACTIVITY_LOG_ENTRIES_ADDED: {
            const {organizationKey, items} = action.payload;
            const newState = {...state};
            newState[organizationKey] = [...(newState[organizationKey] ?? []), ...stableSort(items, 'desc', "createdAt")];
            return newState;
        }
        case types.ACTIVITY_LOG_ENTRY_ADDED: {
            const {organizationKey, item} = action.payload;

            const newState = {...state};
            const newItems = [...(newState[organizationKey] ?? []), item];
            newState[organizationKey] = stableSort(newItems, 'desc', "createdAt");
            return newState;
        }
        default:
            return state
    }
}
