import React from 'react';
import { Box, Stack, Switch, Typography } from '@mui/material';
import FieldLabel from '../../components/FieldLabel';
import PropTypes from 'prop-types';

const SwitchSection = ({ header, subheader, checked, onChange, SwitchProps, LabelProps, TypographyProps, ...rest }) => {
    return (
        <Stack direction="row" spacing={8} alignItems="center" {...rest}>
            <Box flex="1 1 auto">
                <FieldLabel label={header} {...LabelProps} />
                {subheader && (
                    <Typography variant="body1" color="textSecondary" {...TypographyProps}>
                        {subheader}
                    </Typography>
                )}
            </Box>
            <Switch checked={checked} color="primary" onChange={onChange} {...SwitchProps} />
        </Stack>
    );
};

SwitchSection.propTypes = {
    header: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    checked: PropTypes.bool,
    SwitchProps: PropTypes.object,
    LabelProps: PropTypes.object,
    TypographyProps: PropTypes.object,
    onChange: PropTypes.func,
};

SwitchSection.defaultProps = {
    onChange: () => {},
};

export default SwitchSection;
