export function filterActivityLog(activityLog, filter) {
    if (!filter || (filter.workspaceKey === "all" && filter.senderKey === "all" && filter.reportKey === "all")) {
        return activityLog;
    }

    return activityLog.filter(
        (entry) =>
            (filter.workspaceKey === "all" || entry.workspaceKey === filter.workspaceKey) &&
            (filter.senderKey === "all" || entry.senderKey === filter.senderKey) &&
            (filter.reportKey === "all" || entry.reportKey === filter.reportKey)
    );
}