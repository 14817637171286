import { buildClient, CommitmentPolicy, getClient, KMS, KmsKeyringBrowser } from '@aws-crypto/client-browser';
import { base64ToBytes, bytesToBase64 } from './textUtils';

const clientProvider = getClient(KMS, {
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    },
});
const generatorKeyId = process.env.REACT_APP_GENERATOR_KEY_ID;
const keyring = new KmsKeyringBrowser({ clientProvider, generatorKeyId });
const { encrypt, decrypt } = buildClient(CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT);

const utf8Encode = new TextEncoder();
const utf8Decode = new TextDecoder();

export default class EncryptionClient {
    static async encryptContent(content) {
        const json = JSON.stringify(content);
        const bytes = utf8Encode.encode(json);
        const encrypted = await encrypt(keyring, bytes);
        return bytesToBase64(encrypted.result);
    }

    static async decryptContent(encryptedBytes) {
        const bytes = base64ToBytes(encryptedBytes);
        const decrypted = await decrypt(keyring, bytes);
        const decoded = utf8Decode.decode(decrypted.plaintext);
        return JSON.parse(decoded);
    }

    static async encryptText(text) {
        const bytes = utf8Encode.encode(text);
        const encrypted = await encrypt(keyring, bytes);
        return bytesToBase64(encrypted.result);
    }

    static async decryptText(encryptedText) {
        const bytes = base64ToBytes(encryptedText);
        const decrypted = await decrypt(keyring, bytes);
        return utf8Decode.decode(decrypted.plaintext);
    }

    static async encryptBytes(bytes) {
        const encrypted = await encrypt(keyring, bytes);
        return encrypted.result;
    }

    static async decryptBytes(encryptedBytes) {
        const decrypted = await decrypt(keyring, encryptedBytes);
        return decrypted.plaintext;
    }
}
