import React from 'react';
import PropTypes from 'prop-types';
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { SIDEBAR_ITEM_HEIGHT } from '../../constants/ui';
import { styled } from '@mui/material/styles';

const RootListItem = styled(ListItem, { name: "NavbarItem", shouldForwardProp: (prop) => prop !== "highlighted" })(({ theme, highlighted }) => ({
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    "&.Mui-selected": {
        color: theme.palette.primary.main,
        "& .MuiListItemIcon-root": {
            color: "inherit",
        },
        "& .MuiListItemSecondaryAction-root": {
            display: 'block'
        }
    },
    "& .MuiListItemSecondaryAction-root": {
        display: 'none',
        right: '10px'
    },
    "&:hover": {
        "& .MuiListItemButton-root": {
            // Important to ensure that list item button will be highlighted even when user hovers over
            // the secondary action button
            backgroundColor: theme.palette.action.hover,
        },
        "& .MuiListItemSecondaryAction-root": {
            display: 'block'
        }
    },
    ...(highlighted && {
        "& .MuiListItemButton-root": {
            // Important to ensure that list item button will be highlighted even when user hovers over
            // the secondary action button
            backgroundColor: theme.palette.action.hover,
        },
        "& .MuiListItemSecondaryAction-root": {
            display: 'block',
            right: '10px'
        }
    })
}));

const NavbarItem = ({ title, icon, highlighted, ButtonProps, ...rest }) => {
    return (
        <RootListItem disablePadding highlighted={highlighted} {...rest}>
            <ListItemButton
                disableGutters
                sx={(theme) => ({
                    color: "inherit",
                    "&:hover": {
                        color: "unset"
                    },
                    py: 0.5,
                    pl: 1.5,
                    pr: 1,
                    minHeight: SIDEBAR_ITEM_HEIGHT,
                    borderRadius: 1,
                })}
                {...ButtonProps}
            >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText
                    primary={title}
                    primaryTypographyProps={{ sx: { overflow: "hidden", textOverflow: "ellipsis", fontWeight: 500 } }}
                />
            </ListItemButton>
        </RootListItem>
    );
};

NavbarItem.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.object,
    ButtonProps: PropTypes.object,
};

NavbarItem.defaultProps = {};

export default NavbarItem;
