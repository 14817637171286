import React, { useContext, useMemo } from 'react';
import { Container, Divider, Stack, Tooltip, Typography } from '@mui/material';
import ReportSidePanel from "./ReportSidePanel";
import ReportContentPanel from "./ReportContentPanel";
import PropTypes from "prop-types";
import { Navigate, useParams } from 'react-router-dom';
import { toId, toReportKey } from '../../utils/apiUtils';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { currentUserRoleSelector } from '../../state/members/selectors';
import { isAdmin, isObserver, isSolver } from '../../utils/user';
import { reportSelector } from '../../state/reports/selectors';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';

const RootDiv = styled("div", { name: "ReportView" })(({ theme }) => ({
    display: "flex",
    height: "100%",
}));

const ReportView = (props) => {
    const { reportId } = useParams();
    const reportKey = toReportKey(reportId);
    const report = useSelector(reportSelector(reportKey));
    const organizationKey = useOrganizationKey();
    const workspaceKey = useWorkspaceKey();
    const currentUser = useSelector(currentUserSelector());
    const currentUserRole = useSelector(currentUserRoleSelector(organizationKey));
    const {logReportOpened} = useContext(ActivityLogContext);

    useMemo(() => {
        logReportOpened(reportKey);
    }, [reportKey])

    if ((isSolver(currentUserRole) || isObserver(currentUserRole)) && !report?.assigned?.includes(currentUser.pk)) {
        return <Navigate to={`/portal/${toId(workspaceKey)}`} />
    }

    // TODO: Refactor
    return (
        <RootDiv {...props}>
            {!isObserver(currentUserRole) ? (
                <>
                    <ReportContentPanel report={report} sx={{ flex: "1 1 auto", minHeight: 0 }} />
                    <Divider orientation="vertical" />
                    <ReportSidePanel
                        report={report}
                        sx={{
                            mx: 'auto',
                            flex: '0 0 auto',
                            width: {
                                md: 360,
                                lg: 400, // theme.breakpoints.up('lg')
                                xl: 440, // theme.breakpoints.up('xl')
                            }
                        }}
                    />
                </>
            ) : (
                <Container maxWidth="md" sx={{ px: 6 }}>
                    <ReportSidePanel
                        report={report}
                        sx={{minHeight: '100%'}}
                    />
                </Container>

            )}
        </RootDiv>
    );
};

ReportView.propTypes = {
};

ReportView.defaultProps = {
};

export default ReportView;
