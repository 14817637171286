import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isLanguagePack } from '../utils/apiUtils';
import { addOrReplaceLanguagePacks } from '../state/languagePacks/actions';
import { formSelector, selectFormByPath } from '../state/forms/selectors';
import { allLanguagePacksSelector } from '../state/languagePacks/selectors';

export const useFetchLanguagePacks = (formKey) => {
  const { queryApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const form = useSelector(formSelector(formKey));
  const languagePacks = useSelector(allLanguagePacksSelector(formKey));
  const loaded = Boolean(languagePacks) && languagePacks.length > 0;

  useEffect(() => {
    if (!form || loaded) {
      return;
    }

    const fetchResources = async () => {
      const resources = await queryApi.listPublicResources(form.pk);
      if (resources.length === 0) {
        return;
      }

      const languagePacks = resources.filter((res) => isLanguagePack(res));

      batch(() => {
        dispatch(addOrReplaceLanguagePacks(form.pk, languagePacks));
      });
    };

    fetchResources();
  }, [form?.pk]);

  return { loaded, languagePacks };
};
