import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import DialogHeader from '../../components/DialogHeader/DialogHeader';
import Button from '@mui/material/Button';
import {
  insertItem,
  removeMatchingItem,
  replaceMatchingItem,
} from '../../utils/arrayUtils';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuWithArrow from '../../components/MenuWithArrow/MenuWithArrow';
import AddIcon from '@mui/icons-material/Add';
import { newAwsDateTime } from '../../utils/time';
import { isOpeningStatus, newTagKey, toId } from '../../utils/apiUtils';
import StatusTableRow from './StatusTableRow';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../contexts/ApiContext';
import { activeWorkspaceTagsSelector } from '../../state/tags/selectors';
import { addOrUpdateTag } from '../../state/tags/actions';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  padding: 0,
}));

const TagCustomizationDialog = ({ open, workspaceKey, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const tags = useSelector(activeWorkspaceTagsSelector(workspaceKey)) ?? [];
  const autoFocusedKeyRef = useRef();
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [selectedTagKey, setSelectedTagKey] = useState();
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [pendingTags, setPendingTags] = useState(tags);

  useEffect(() => {
    // Reset the autofocus key on first render, otherwise the status row would be autofocused on each rerender.
    autoFocusedKeyRef.current = null;
  }, [autoFocusedKeyRef.current]);

  const handleMoreButtonClick = useCallback((event, statusKey) => {
    setSelectedTagKey(statusKey);
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleContextMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedTagKey(null);
  };

  const handleStatusDeleteClick = () => {
    setPendingTags((prev) =>
      removeMatchingItem(prev, (tag) => tag.sk === selectedTagKey),
    );
    setChanged(true);
    handleContextMenuClose();
  };

  const handleAddNewTag = () => {
    const tagKey = newTagKey();
    const createdAt = newAwsDateTime();
    // TODO: Move api models to a common utils class
    const data = {
      pk: workspaceKey,
      sk: tagKey,
      group: toId(organizationKey),
      organizationKey: organizationKey,
      workspaceKey: workspaceKey,
      createdAt: createdAt,
      updatedAt: createdAt,
      color: '#2F80ED',
    };

    // Insert new active status just behind the last active statusS
    setPendingTags((prev) => insertItem(prev, pendingTags.length, data));
    setChanged(true);
    autoFocusedKeyRef.current = tagKey;
  };

  const handleTagChange = useCallback((updatedStatus) => {
    setPendingTags((prev) =>
      replaceMatchingItem(
        prev,
        (status) => status.sk === updatedStatus.sk,
        updatedStatus,
      ),
    );
    setChanged(true);
  }, []);

  const handleSaveButtonClick = async () => {
    setSaving(true);

    // Delete removed tags
    const currentTagKeys = pendingTags.map((tag) => tag.sk);
    const removedTags = tags.filter((tag) => !currentTagKeys.includes(tag.sk));
    for (const tag of removedTags) {
      dispatch(addOrUpdateTag(tag.pk, tag.sk, { disabled: true }));
      await mutationApi.deactivatePrivateResource(tag.pk, tag.sk);
    }

    // Update existing or create new statuses
    for (const newTag of pendingTags) {
      const originalTag = tags.find((tag) => tag.sk === newTag.sk);

      if (!Boolean(originalTag)) {
        // Create a new tag resource if it doesn't exist
        dispatch(addOrUpdateTag(newTag.pk, newTag.sk, newTag));
        await mutationApi.createPrivateResource(newTag.pk, newTag.sk, newTag);
      } else if (
        newTag.name !== originalTag.name ||
        newTag.color !== originalTag.color
      ) {
        // Update attributes of the existing tag resource
        const data = { name: newTag.name, color: newTag.color };
        dispatch(addOrUpdateTag(newTag.pk, newTag.sk, data));
        await mutationApi.updatePrivateResource(newTag.pk, newTag.sk, data);
      }
    }

    setSaving(false);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="tag-customization-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
        },
      }}
    >
      <DialogHeader
        id="tag-customization-dialog-title"
        showExitButton
        showDivider
        onExitClick={onClose}
        ExitButtonProps={{ disabled: saving }}
      >
        {t('tagDialogHeader')}
      </DialogHeader>
      <DialogContent>
        <Table size="small" sx={{ tableLayout: 'fixed', overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <StyledHeaderCell sx={{ width: 32 }} />
              <StyledHeaderCell sx={{ width: 32 }} />
              <StyledHeaderCell sx={{ width: '100%' }} />
              <StyledHeaderCell sx={{ width: 56 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingTags.map((tag, i) => (
              <StatusTableRow
                key={tag.sk}
                status={tag}
                autoFocus={autoFocusedKeyRef.current === tag.sk}
                showMoreButton={selectedTagKey === tag.sk}
                onChange={handleTagChange}
                onMoreButtonClick={handleMoreButtonClick}
                ColorPickerProps={{
                  IndicatorIcon: BookmarkIcon,
                }}
              />
            ))}
            {pendingTags.length === 0 && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={5}
                  sx={{
                    color: 'text.secondary',
                    py: '14px',
                    border: '1px dashed',
                    borderColor: 'grey.300',
                  }}
                >
                  {t('noTagsPlaceholder')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Button
          size="small"
          variant="text"
          startIcon={<AddIcon />}
          onClick={() => handleAddNewTag()}
          sx={{ ml: '4px' }}
        >
          {t('add')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          color="default"
          variant="outlined"
          disabled={saving}
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          size="medium"
          loading={saving}
          disabled={!changed}
          onClick={handleSaveButtonClick}
        >
          {t('Save changes')}
        </LoadingButton>
      </DialogActions>
      <MenuWithArrow
        keepMounted
        autoFocus={false}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleContextMenuClose}
      >
        <MenuItem
          disabled={isOpeningStatus(selectedTagKey)}
          onClick={handleStatusDeleteClick}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('delete')}</ListItemText>
        </MenuItem>
      </MenuWithArrow>
    </Dialog>
  );
};

TagCustomizationDialog.propTypes = {
  open: PropTypes.bool,
  workspaceKey: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

TagCustomizationDialog.defaultProps = {
  onClose: () => {},
};

export default TagCustomizationDialog;
