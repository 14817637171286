import React from 'react';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import ProfileSettingsSection from './ProfileSettingsSection';
import SecuritySettingsSection from './SecuritySettingsSection';

const RootBox = styled(Box, { name: 'AccountSettings' })(
  ({ theme, color }) => ({}),
);

const AccountSettingsView = ({}) => {
  const { t } = useTranslation();

  return (
    <RootBox sx={{ overflow: 'auto' }}>
      <Container maxWidth="md" sx={{ px: 6, py: 8 }}>
        <Stack spacing={4}>
          <Typography variant="h3">{t('myAccount')}</Typography>
          <Divider />
          <ProfileSettingsSection />
          <Divider />
          <SecuritySettingsSection />
        </Stack>
      </Container>
    </RootBox>
  );
};

export default AccountSettingsView;
