import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { isValidEmail, isValidPhoneNumber } from '../../utils/textUtils';

interface Props extends Omit<LinkProps, 'href'> {
  children?: string[];
}

const FaqLink = ({ children, ...rest }: Props) => {
  const url = children?.[0] || '';
  let href = url;
  if (isValidPhoneNumber(url)) {
    href = `callto:${url}`;
  } else if (isValidEmail(url)) {
    href = `mailto:${url}`;
  }

  return (
    <Link href={href} target="_blank" rel="noopener" {...rest}>
      {url}
    </Link>
  );
};

export default FaqLink;
