import * as mutations from '../graphql/mutations';
import { s4 } from '../utils/idGenerator';
import { toId } from '../utils/apiUtils';
import { API } from 'aws-amplify';
import {
  AcceptInvitationMutation,
  ActivityLogEntry,
  CreateActivityLogEntryMutation,
  CreateCommentMutation,
  CreateEmailConversationMutation,
  CreateInvitationMutation,
  CreateMessageMutation,
  CreateOrganizationMutation,
  CreatePrivateResourceInput,
  CreatePrivateResourceMutation,
  CreatePublicResourceInput,
  CreatePublicResourceMutation,
  CreateReportMutation,
  CreateResourcePasswordMutation,
  CreateWorkspaceMutation,
  DeletePrivateResourceMutation,
  DeletePublicResourceMutation,
  DeleteReportMutation,
  DeleteResourcePasswordMutation,
  DeleteWorkspaceMutation,
  EmailConversation,
  PrivateResource,
  PublicResource,
  ResourcePassword,
  SendSignupNotificationMutation,
  UpdatePrivateResourceInput,
  UpdatePrivateResourceMutation,
  UpdatePublicResourceInput,
  UpdatePublicResourceMutation,
  UpdateReportInput,
  UpdateReportMutation,
  UpdateResourcePasswordMutation,
  UpdateUserMutation,
  UpdateWorkspaceInput,
  UpdateWorkspaceMutation,
} from '../API';
import { deserializeObject, serializeObject } from './serializationUtils';
import { WorkspaceCountry } from '../constants/workspace';
import { Language } from '../i18n/config';
import AuthApi from './AuthApi';
import {
  DeserializedPrivateResource,
  UpdateDeserializedPrivateResourceInput,
  UpdateDeserializedPublicResourceInput,
} from '../types/api';

const privateAuthMode = 'AMAZON_COGNITO_USER_POOLS';
const publicAuthMode = 'AWS_IAM';

export default class MutationApi {
  static async createActivityLogEntry(
    organizationKey: string,
    senderKey: string,
    event: string,
    data?: Record<string, any>,
  ) {
    const sk = `${Date.now()}_${s4()}`; // has to be executed before the graphql() is called.
    const input = await serializeObject({
      pk: organizationKey,
      sk: sk,
      group: toId(organizationKey),
      senderKey,
      event,
      ...data,
    });
    const result = (await API.graphql({
      query: mutations.createActivityLogEntry,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateActivityLogEntryMutation };

    return deserializeObject(
      result.data.createActivityLogEntry as ActivityLogEntry,
    );
  }

  static async createEmailConversation(
    pk: string,
    sk: string,
    organizationKey: string,
    workspaceKey: string,
    emailAddress: string,
    type: string,
    data?: {},
  ) {
    const input = {
      pk,
      sk,
      organizationKey,
      workspaceKey,
      emailAddress,
      type,
      ...data,
    };
    const result = (await API.graphql({
      query: mutations.createEmailConversation,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: CreateEmailConversationMutation };

    return result.data.createEmailConversation as EmailConversation;
  }

  static async createPrivateResource(
    pk: string,
    sk: string,
    data?: UpdateDeserializedPrivateResourceInput,
  ) {
    const username = (await AuthApi.currentAuthenticatedUser())?.username;
    const input = await serializeObject({
      pk: pk,
      sk: sk,
      ...data,
      updatedBy: username,
    });
    const result = (await API.graphql({
      query: mutations.createPrivateResource,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreatePrivateResourceMutation };

    return deserializeObject(
      result.data.createPrivateResource as PrivateResource,
    );
  }

  static async updatePrivateResource(
    pk: string,
    sk: string,
    data?: UpdateDeserializedPrivateResourceInput,
  ) {
    const username = (await AuthApi.currentAuthenticatedUser())?.username;
    const input = await serializeObject({
      pk: pk,
      sk: sk,
      ...data,
      updatedBy: username,
    });
    const result = (await API.graphql({
      query: mutations.updatePrivateResource,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: UpdatePrivateResourceMutation };

    return deserializeObject(
      result.data.updatePrivateResource as PrivateResource,
    );
  }

  static async deactivatePrivateResource(pk: string, sk: string) {
    return MutationApi.updatePrivateResource(pk, sk, { disabled: true });
  }

  static async deletePrivateResource(pk: string, sk: string) {
    const input = { pk: pk, sk: sk };
    const result = (await API.graphql({
      query: mutations.deletePrivateResource,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: DeletePrivateResourceMutation };

    return deserializeObject(
      result.data.deletePrivateResource as PrivateResource,
    );
  }

  static async createPublicResource(
    pk: string,
    sk: string,
    data?: UpdateDeserializedPublicResourceInput,
  ) {
    const username = (await AuthApi.currentAuthenticatedUser())?.username;
    const input = await serializeObject({
      pk: pk,
      sk: sk,
      ...data,
      updatedBy: username,
    });
    const result = (await API.graphql({
      query: mutations.createPublicResource,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: CreatePublicResourceMutation };

    return deserializeObject(
      result.data.createPublicResource as PublicResource,
    );
  }

  static async updatePublicResource(
    pk: string,
    sk: string,
    data?: UpdateDeserializedPublicResourceInput,
  ) {
    const username = (await AuthApi.currentAuthenticatedUser())?.username;
    const input = await serializeObject({
      pk: pk,
      sk: sk,
      ...data,
      updatedBy: username,
    });
    const result = (await API.graphql({
      query: mutations.updatePublicResource,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: UpdatePublicResourceMutation };

    return deserializeObject(
      result.data.updatePublicResource as PublicResource,
    );
  }

  static async deletePublicResource(pk: string, sk: string) {
    const input = { pk: pk, sk: sk };
    const result = (await API.graphql({
      query: mutations.deletePublicResource,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: DeletePublicResourceMutation };

    return await deserializeObject(
      result.data.deletePublicResource as PublicResource,
    );
  }

  static async createResourcePassword(
    organizationKey: string,
    workspaceKey: string,
    resourceKey: string,
    password: string,
  ) {
    const input = await serializeObject({
      pk: resourceKey,
      sk: resourceKey,
      group: toId(organizationKey),
      organizationKey: organizationKey,
      workspaceKey: workspaceKey,
      password: password,
    });
    const result = (await API.graphql({
      query: mutations.createResourcePassword,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateResourcePasswordMutation };

    return result.data.createResourcePassword as ResourcePassword;
  }

  static async updateResourcePassword(resourceKey: string, password: string) {
    const input = await serializeObject({
      pk: resourceKey,
      sk: resourceKey,
      password,
    });
    const result = (await API.graphql({
      query: mutations.updateResourcePassword,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: UpdateResourcePasswordMutation };

    return result.data.updateResourcePassword as ResourcePassword;
  }

  static async deleteReport(reportKey: string) {
    const input = { reportKey: reportKey };
    const result = (await API.graphql({
      query: mutations.deleteReport,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: DeleteReportMutation };

    return deserializeObject(result.data.deleteReport as PrivateResource);
  }

  static async deleteWorkspace(workspaceKey: string) {
    const input = { workspaceKey: workspaceKey };
    const result = (await API.graphql({
      query: mutations.deleteWorkspace,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: DeleteWorkspaceMutation };

    return deserializeObject(result.data.deleteWorkspace as PrivateResource);
  }

  static async deleteResourcePassword(resourceKey: string) {
    const input = { pk: resourceKey, sk: resourceKey };
    const result = (await API.graphql({
      query: mutations.deleteResourcePassword,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: DeleteResourcePasswordMutation };

    return result.data.deleteResourcePassword as ResourcePassword;
  }

  static async createOrganization(
    organizationName: string,
    workspaceName: string,
    language: Language,
    country: WorkspaceCountry,
    workspacePicture?: string,
    partnerKey?: string,
  ) {
    const input = {
      organizationName,
      workspaceName,
      workspacePicture,
      language,
      country,
      partnerKey,
    };
    const result = (await API.graphql({
      query: mutations.createOrganization,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateOrganizationMutation };

    return {
      organization: await deserializeObject(
        result.data.createOrganization?.organization as PrivateResource,
      ),
      workspace: await deserializeObject(
        result.data.createOrganization?.workspace as PrivateResource,
      ),
    };
  }

  static async createWorkspace(
    organizationKey: string,
    name: string,
    language: string,
    country: WorkspaceCountry,
    picture?: string,
  ) {
    const input = { organizationKey, name, country, language, picture };
    const result = (await API.graphql({
      query: mutations.createWorkspace,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateWorkspaceMutation };

    const reportDeserializationTasks: Promise<DeserializedPrivateResource>[] =
      result.data.createWorkspace?.reports?.map((report) => {
        return deserializeObject(report as PrivateResource);
      }) ?? [];

    return {
      workspace: await deserializeObject(
        result.data.createWorkspace?.workspace as PrivateResource,
      ),
      form: await deserializeObject(
        result.data.createWorkspace?.form as PublicResource,
      ),
      email: await deserializeObject(
        result.data.createWorkspace?.email as PublicResource,
      ),
      voicemail: await deserializeObject(
        result.data.createWorkspace?.voicemail as PublicResource,
      ),
      reports: await Promise.all(reportDeserializationTasks),
      statuses: result.data.createWorkspace?.statuses as PrivateResource[],
      tags: result.data.createWorkspace?.tags as PrivateResource[],
      policy: await deserializeObject(
        result.data.createWorkspace?.policy as PrivateResource,
      ),
    };
  }

  static async updateWorkspace(
    workspaceKey: string,
    data?: Omit<UpdateWorkspaceInput, 'workspaceKey'>,
  ) {
    const input = {
      workspaceKey: workspaceKey,
      ...data,
    };
    const result = (await API.graphql({
      query: mutations.updateWorkspace,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: UpdateWorkspaceMutation };

    return await deserializeObject(
      result.data.updateWorkspace as PrivateResource,
    );
  }

  /// CreateReport and CreateMessage resolvers use both private-iam and public-iam authorization mode in graphql
  // scheme because of a "bug" in amplify appsync client. If the application is already opened on some tab and uses
  // "private-cognito" auth mode then all other tabs in the same browser session will use "authRole" The client uses
  // "authRole", even when IAM auth mode is specified in API calls.
  static async createReport(
    organizationKey: string,
    workspaceKey: string,
    channel: string,
    content: {},
    excerpt: string,
    language: string,
    encrypt = false,
  ) {
    const input = await serializeObject(
      {
        organizationKey,
        workspaceKey,
        channel,
        content,
        excerpt,
        language,
      },
      encrypt,
    );
    const result = (await API.graphql({
      query: mutations.createReport,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: CreateReportMutation };

    return result.data.createReport as ResourcePassword;
  }

  static async createMessageAsGuest(
    organizationKey: string,
    reportKey: string,
    data?: {},
    encrypt = false,
  ) {
    const input = await serializeObject(
      {
        organizationKey: organizationKey,
        reportKey: reportKey,
        ...data,
      },
      encrypt,
    );
    const result = (await API.graphql({
      query: mutations.createMessage,
      variables: { input },
      authMode: publicAuthMode,
    })) as { data: CreateMessageMutation };

    return deserializeObject(result.data.createMessage as PublicResource);
  }

  static async createMessageAsMember(
    organizationKey: string,
    reportKey: string,
    data?: {},
    encrypt = false,
  ) {
    const input = await serializeObject(
      {
        organizationKey: organizationKey,
        reportKey: reportKey,
        ...data,
      },
      encrypt,
    );
    const result = (await API.graphql({
      query: mutations.createMessage,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateMessageMutation };

    return deserializeObject(result.data.createMessage as PublicResource);
  }

  static async createComment(
    organizationKey: string,
    reportKey: string,
    commentKey: string,
    content: {},
    ccEmailAddresses?: string[],
    encrypt = false,
  ) {
    const input = await serializeObject(
      {
        organizationKey: organizationKey,
        reportKey: reportKey,
        commentKey: commentKey,
        content: content,
        ccEmailAddresses: ccEmailAddresses,
      },
      encrypt,
    );
    const result = (await API.graphql({
      query: mutations.createComment,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateCommentMutation };

    return deserializeObject(result.data.createComment as PrivateResource);
  }

  static async updateCurrentUser(data?: {}) {
    const input = await serializeObject(data);
    const result = (await API.graphql({
      query: mutations.updateUser,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: UpdateUserMutation };

    return deserializeObject(result.data.updateUser as PublicResource);
  }

  static async createInvitation(
    organizationKey: string,
    email: string,
    role: string,
    access: string[],
    language = 'en',
    invitationKey = undefined,
  ) {
    const input = {
      organizationKey,
      email,
      role,
      access,
      language,
      invitationKey,
    };
    const result = (await API.graphql({
      query: mutations.createInvitation,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: CreateInvitationMutation };

    return deserializeObject(result.data.createInvitation as PrivateResource);
  }

  static async updateReport(
    reportKey: string,
    data?: Omit<UpdateReportInput, 'reportKey'>,
  ) {
    const input = await serializeObject({
      reportKey: reportKey,
      ...data,
    });
    const result = (await API.graphql({
      query: mutations.updateReport,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: UpdateReportMutation };

    return deserializeObject(result.data.updateReport as PrivateResource);
  }

  static async acceptInvitation(
    organizationKey: string,
    invitationKey: string,
  ) {
    const input = { organizationKey, invitationKey };
    const result = (await API.graphql({
      query: mutations.acceptInvitation,
      variables: { input },
      authMode: privateAuthMode,
    })) as { data: AcceptInvitationMutation };

    return await deserializeObject(
      result.data.acceptInvitation as PrivateResource,
    );
  }

  static async sendSignupNotification(email: string) {
    const result = (await API.graphql({
      query: mutations.sendSignupNotification,
      variables: { input: { email, website: window.location.origin } },
      authMode: publicAuthMode,
    })) as { data: SendSignupNotificationMutation };

    return result.data.sendSignupNotification;
  }
}
