import { toId } from './apiUtils';
import { TFunction } from 'i18next';

const nonWorkspaceRoutes = [
  {
    pattern: /\/home/,
    titleKey: 'home',
  },
  {
    pattern: /\/reports/,
    titleKey: 'myReports',
  },
  {
    pattern: /\/onboarding/,
    titleKey: 'introduction',
  },
  {
    pattern: /\/settings/,
    titleKey: 'organizationSettings',
  },
  {
    pattern: /\/activity/,
    titleKey: 'activityLog',
  },
  {
    pattern: /\/invitation/,
    titleKey: 'invitation',
  },
  {
    pattern: /\/followup/,
    titleKey: 'yourReport',
  },
  {
    pattern: /\/login/,
    titleKey: 'login',
  },
  {
    pattern: /\/signup/,
    titleKey: 'signup',
  },
];

export function getNonWorkspaceRouteTitle(
  path: string,
  t: TFunction,
  language?: string,
) {
  const titleKey = nonWorkspaceRoutes.find((item) =>
    path.match(item.pattern),
  )?.titleKey;
  return titleKey ? t(titleKey, { lng: language }) : null;
}

export function getOrganizationRoute(organizationKey: string) {
  return `/app/${toId(organizationKey)}`;
}

export function getWorkspaceRoute(
  organizationKey: string,
  workspaceKey: string,
) {
  return `/app/${toId(organizationKey)}/${toId(workspaceKey)}`;
}

export function getReportRoute(
  organizationKey: string,
  workspaceKey: string,
  reportKey: string,
) {
  return `${getWorkspaceRoute(organizationKey, workspaceKey)}/reports/${toId(reportKey)}`;
}

export function isInvitationRoute(route?: string) {
  return Boolean(route?.match(/\/invitation/));
}

export function getDefaultAppUrl() {
  return process.env.REACT_APP_URL ?? 'https://app.safetalk.io';
}
