/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreatePrivateResource =
  /* GraphQL */ `subscription OnCreatePrivateResource(
  $filter: ModelSubscriptionPrivateResourceFilterInput
  $owner: String
) {
  onCreatePrivateResource(filter: $filter, owner: $owner) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreatePrivateResourceSubscriptionVariables,
    APITypes.OnCreatePrivateResourceSubscription
  >;
export const onUpdatePrivateResource =
  /* GraphQL */ `subscription OnUpdatePrivateResource(
  $filter: ModelSubscriptionPrivateResourceFilterInput
  $owner: String
) {
  onUpdatePrivateResource(filter: $filter, owner: $owner) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdatePrivateResourceSubscriptionVariables,
    APITypes.OnUpdatePrivateResourceSubscription
  >;
export const onDeletePrivateResource =
  /* GraphQL */ `subscription OnDeletePrivateResource(
  $filter: ModelSubscriptionPrivateResourceFilterInput
  $owner: String
) {
  onDeletePrivateResource(filter: $filter, owner: $owner) {
    pk
    sk
    organizationKey
    userKey
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    email
    plan
    address
    phone
    country
    language
    role
    description
    excerpt
    excerptUpdatedAt
    text
    content
    senderKey
    openedAt
    channel
    signature
    assigned
    dueDate
    color
    type
    ccEmailAddresses
    status
    tags
    event
    value
    disabled
    access
    encryptedContent
    encryptedExcerpt
    statuses
    termLength
    partnerKey
    templateName
    closed
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeletePrivateResourceSubscriptionVariables,
    APITypes.OnDeletePrivateResourceSubscription
  >;
export const onCreatePublicResource =
  /* GraphQL */ `subscription OnCreatePublicResource(
  $filter: ModelSubscriptionPublicResourceFilterInput
  $owner: String
) {
  onCreatePublicResource(filter: $filter, owner: $owner) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreatePublicResourceSubscriptionVariables,
    APITypes.OnCreatePublicResourceSubscription
  >;
export const onUpdatePublicResource =
  /* GraphQL */ `subscription OnUpdatePublicResource(
  $filter: ModelSubscriptionPublicResourceFilterInput
  $owner: String
) {
  onUpdatePublicResource(filter: $filter, owner: $owner) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdatePublicResourceSubscriptionVariables,
    APITypes.OnUpdatePublicResourceSubscription
  >;
export const onDeletePublicResource =
  /* GraphQL */ `subscription OnDeletePublicResource(
  $filter: ModelSubscriptionPublicResourceFilterInput
  $owner: String
) {
  onDeletePublicResource(filter: $filter, owner: $owner) {
    pk
    sk
    organizationKey
    path
    workspaceKey
    owner
    group
    createdAt
    updatedAt
    updatedBy
    name
    picture
    text
    content
    senderKey
    signature
    disabled
    template
    hasPassword
    encryptedContent
    plan
    colors
    whitelabelEnabled
    systemMessage
    partnerKey
    captchaEnabled
    isDeleted
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeletePublicResourceSubscriptionVariables,
    APITypes.OnDeletePublicResourceSubscription
  >;
export const onCreatePartner =
  /* GraphQL */ `subscription OnCreatePartner($filter: ModelSubscriptionPartnerFilterInput) {
  onCreatePartner(filter: $filter) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreatePartnerSubscriptionVariables,
    APITypes.OnCreatePartnerSubscription
  >;
export const onUpdatePartner =
  /* GraphQL */ `subscription OnUpdatePartner($filter: ModelSubscriptionPartnerFilterInput) {
  onUpdatePartner(filter: $filter) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdatePartnerSubscriptionVariables,
    APITypes.OnUpdatePartnerSubscription
  >;
export const onDeletePartner =
  /* GraphQL */ `subscription OnDeletePartner($filter: ModelSubscriptionPartnerFilterInput) {
  onDeletePartner(filter: $filter) {
    pk
    appUrl
    name
    picture
    emailDomain
    phoneNumbers
    translations
    colors
    statuses
    faviconUrl
    privacyPolicyUrl
    termsOfServiceUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeletePartnerSubscriptionVariables,
    APITypes.OnDeletePartnerSubscription
  >;
export const onCreateActivityLogEntry =
  /* GraphQL */ `subscription OnCreateActivityLogEntry(
  $filter: ModelSubscriptionActivityLogEntryFilterInput
) {
  onCreateActivityLogEntry(filter: $filter) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateActivityLogEntrySubscriptionVariables,
    APITypes.OnCreateActivityLogEntrySubscription
  >;
export const onUpdateActivityLogEntry =
  /* GraphQL */ `subscription OnUpdateActivityLogEntry(
  $filter: ModelSubscriptionActivityLogEntryFilterInput
) {
  onUpdateActivityLogEntry(filter: $filter) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateActivityLogEntrySubscriptionVariables,
    APITypes.OnUpdateActivityLogEntrySubscription
  >;
export const onDeleteActivityLogEntry =
  /* GraphQL */ `subscription OnDeleteActivityLogEntry(
  $filter: ModelSubscriptionActivityLogEntryFilterInput
) {
  onDeleteActivityLogEntry(filter: $filter) {
    pk
    sk
    group
    createdAt
    workspaceKey
    senderKey
    event
    tagKey
    statusKey
    messageKey
    commentKey
    reportKey
    userKey
    email
    role
    dueDate
    description
    name
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteActivityLogEntrySubscriptionVariables,
    APITypes.OnDeleteActivityLogEntrySubscription
  >;
export const onCreateResourcePassword =
  /* GraphQL */ `subscription OnCreateResourcePassword(
  $filter: ModelSubscriptionResourcePasswordFilterInput
) {
  onCreateResourcePassword(filter: $filter) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateResourcePasswordSubscriptionVariables,
    APITypes.OnCreateResourcePasswordSubscription
  >;
export const onUpdateResourcePassword =
  /* GraphQL */ `subscription OnUpdateResourcePassword(
  $filter: ModelSubscriptionResourcePasswordFilterInput
) {
  onUpdateResourcePassword(filter: $filter) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateResourcePasswordSubscriptionVariables,
    APITypes.OnUpdateResourcePasswordSubscription
  >;
export const onDeleteResourcePassword =
  /* GraphQL */ `subscription OnDeleteResourcePassword(
  $filter: ModelSubscriptionResourcePasswordFilterInput
) {
  onDeleteResourcePassword(filter: $filter) {
    pk
    sk
    password
    group
    organizationKey
    workspaceKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteResourcePasswordSubscriptionVariables,
    APITypes.OnDeleteResourcePasswordSubscription
  >;
export const onCreatePublicResourceAlias =
  /* GraphQL */ `subscription OnCreatePublicResourceAlias(
  $filter: ModelSubscriptionPublicResourceAliasFilterInput
) {
  onCreatePublicResourceAlias(filter: $filter) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreatePublicResourceAliasSubscriptionVariables,
    APITypes.OnCreatePublicResourceAliasSubscription
  >;
export const onUpdatePublicResourceAlias =
  /* GraphQL */ `subscription OnUpdatePublicResourceAlias(
  $filter: ModelSubscriptionPublicResourceAliasFilterInput
) {
  onUpdatePublicResourceAlias(filter: $filter) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdatePublicResourceAliasSubscriptionVariables,
    APITypes.OnUpdatePublicResourceAliasSubscription
  >;
export const onDeletePublicResourceAlias =
  /* GraphQL */ `subscription OnDeletePublicResourceAlias(
  $filter: ModelSubscriptionPublicResourceAliasFilterInput
) {
  onDeletePublicResourceAlias(filter: $filter) {
    pk
    sk
    aliasId
    group
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeletePublicResourceAliasSubscriptionVariables,
    APITypes.OnDeletePublicResourceAliasSubscription
  >;
export const onCreateEmailConversation =
  /* GraphQL */ `subscription OnCreateEmailConversation(
  $filter: ModelSubscriptionEmailConversationFilterInput
) {
  onCreateEmailConversation(filter: $filter) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateEmailConversationSubscriptionVariables,
    APITypes.OnCreateEmailConversationSubscription
  >;
export const onUpdateEmailConversation =
  /* GraphQL */ `subscription OnUpdateEmailConversation(
  $filter: ModelSubscriptionEmailConversationFilterInput
) {
  onUpdateEmailConversation(filter: $filter) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateEmailConversationSubscriptionVariables,
    APITypes.OnUpdateEmailConversationSubscription
  >;
export const onDeleteEmailConversation =
  /* GraphQL */ `subscription OnDeleteEmailConversation(
  $filter: ModelSubscriptionEmailConversationFilterInput
) {
  onDeleteEmailConversation(filter: $filter) {
    pk
    sk
    emailAddress
    rootEmailId
    organizationKey
    workspaceKey
    subject
    emailIDs
    type
    language
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteEmailConversationSubscriptionVariables,
    APITypes.OnDeleteEmailConversationSubscription
  >;
export const onCreateVoicemailRecording =
  /* GraphQL */ `subscription OnCreateVoicemailRecording(
  $filter: ModelSubscriptionVoicemailRecordingFilterInput
) {
  onCreateVoicemailRecording(filter: $filter) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateVoicemailRecordingSubscriptionVariables,
    APITypes.OnCreateVoicemailRecordingSubscription
  >;
export const onUpdateVoicemailRecording =
  /* GraphQL */ `subscription OnUpdateVoicemailRecording(
  $filter: ModelSubscriptionVoicemailRecordingFilterInput
) {
  onUpdateVoicemailRecording(filter: $filter) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateVoicemailRecordingSubscriptionVariables,
    APITypes.OnUpdateVoicemailRecordingSubscription
  >;
export const onDeleteVoicemailRecording =
  /* GraphQL */ `subscription OnDeleteVoicemailRecording(
  $filter: ModelSubscriptionVoicemailRecordingFilterInput
) {
  onDeleteVoicemailRecording(filter: $filter) {
    contactId
    organizationKey
    workspaceKey
    password
    phoneNumber
    completed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteVoicemailRecordingSubscriptionVariables,
    APITypes.OnDeleteVoicemailRecordingSubscription
  >;
