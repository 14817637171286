import React from 'react';
import { Box, Divider } from '@mui/material';
import { Outlet } from 'react-router';
import Page from '../../components/Page/Page';
import ReportList from './ReportList';
import { Navigate, useParams } from 'react-router-dom';
import {
  toOrganizationKey,
  toReportKey,
  toWorkspaceKey,
} from '../../utils/apiUtils';
import { useSelector } from 'react-redux';
import {
  activeReportsSelector,
  activeWorkspaceReportsSelector,
} from '../../state/reports/selectors';
import { getWorkspaceRoute } from '../../utils/routeUtils';

const ReportsLayout = () => {
  const { organizationId, workspaceId, reportId } = useParams();
  const organizationKey = toOrganizationKey(organizationId);
  const workspaceKey = toWorkspaceKey(workspaceId);
  const reportKey = toReportKey(reportId);
  const reports = useSelector(
    workspaceKey
      ? activeWorkspaceReportsSelector(organizationKey, workspaceKey)
      : activeReportsSelector(),
  );

  if (reportId && !reports.find((report) => report.pk === reportKey)) {
    return (
      <Navigate
        replace
        to={`${getWorkspaceRoute(organizationKey, workspaceKey)}/reports`}
      />
    );
  }

  return (
    <Page
      sx={{
        flex: '1 1 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <ReportList
        sx={{
          flex: '0 0 auto',
          width: {
            md: 360,
            xl: 400, // theme.breakpoints.up('xl')
          },
        }}
      />
      <Divider orientation="vertical" />
      <Box flex="1 1 auto" minWidth={0} overflow="auto">
        <Outlet />
      </Box>
    </Page>
  );
};

export default ReportsLayout;
