import React, { useContext } from "react";
import { Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { memberSelector } from "../../state/members/selectors";
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';

const RootLink = styled(Link, { name: "MentionLink", shouldForwardProp: (prop) => prop !== "clickable" })(
    ({ theme, clickable }) => ({
        cursor: clickable ? "pointer" : "inherit",
    })
);

const MentionLink = ({ contentState, entityKey, children, sx, offsetKey, ...rest }) => {
    const entity = contentState.getEntity(entityKey);
    const data = entity.getData();
    const member = useSelector(memberSelector(data.organizationKey, data.userKey));

    const handleClick = (e) => {
        e.preventDefault();
        /*if (url !== "#") {
            window.open(url, "_blank");
        }*/
    };

    // Use actual user name if the user is still member of the current workspace. If the user is no longer a member,
    // use the children property, that contains <span> element with the original text.
    // !! Carefull, displaying different text than what is stored in the editor state may break the DraftJS editor, e.g.
    // displaying a text with a different length will break removing characters by a backspace.!!
    return (
        <RootLink href="#" rel="noreferrer" color='link.main' onClick={handleClick} underline="hover" sx={sx}>
            {!member ? (
                children
            ) : (
                <span data-offset-key={offsetKey}>
                    <span data-text="true">{member.name}</span>
                </span>
            )}
        </RootLink>
    );
};

MentionLink.propTypes = {};

export default MentionLink;
