import { combineReducers } from 'redux';
import reportsReducer from './state/reports/reducer';
import currentUserReducer from './state/currentUser/reducer';
import workspacesReducer from './state/workspaces/reducer';
import messagesReducer from './state/messages/reducer';
import commentsReducer from './state/comments/reducer';
import membersReducer from './state/members/reducer';
import invitationsReducer from './state/invitations/reducer';
import formsReducer from './state/forms/reducer';
import resourcePasswordsReducer from './state/resourcePasswords/reducer';
import languagePacksReducer from './state/languagePacks/reducer';
import statusesReducer from './state/statuses/reducer';
import tagsReducer from './state/tags/reducer';
import organizationsReducer from './state/organizations/reducer';
import emailsReducer from './state/emails/reducer';
import voicemailsReducer from './state/voicemails/reducer';
import { CLEAR_REDUX_STATE, clearState } from './state/rootActions';
import activityLogReducer from './state/activityLog/reducer';
import { ActivityLogEntry, ResourcePassword } from './API';
import policiesReducer from './state/policies/reducer';
import {
  DeserializedPrivateResource,
  DeserializedPublicResource,
} from './types/api';

/*const combineReducersWithRoot = (rootReducer, reducers) => {
    return (state, action) => {
        // Ensure the root state object is a new object; otherwise
        // React may not re-render.
        let newState = {...rootReducer(state, action)};
        Object.keys(reducers).forEach(domain => {
            let obj = state ? state[domain] : undefined;
            newState[domain] = reducers[domain](obj, action);
        });
        return newState;
    };
}

function rootReducer(state = {loading: false, loaded: false}, action) {
    switch(action.type) {
        case STARTED_LOADING:
            return {...state, loading: true, loaded: false};
        case FINISHED_LOADING:
            return {...state, loading: false, loaded: true};
        default:
            return state;
    }
}

export default combineReducersWithRoot(rootReducer, {data: dataReducer});*/

interface WithPrimaryKey {
  pk: string;
  [key: string]: any;
}

type GroupedByPrimaryKey<T extends WithPrimaryKey> = {
  [K in T['pk']]: T[];
};

export interface AppState {
  currentUser: DeserializedPrivateResource;
  workspaces: DeserializedPrivateResource[];
  reports: DeserializedPrivateResource[];
  invitations: GroupedByPrimaryKey<DeserializedPrivateResource>;
  members: GroupedByPrimaryKey<DeserializedPrivateResource>;
  messages: GroupedByPrimaryKey<DeserializedPublicResource>;
  comments: GroupedByPrimaryKey<DeserializedPrivateResource>;
  forms: DeserializedPublicResource[];
  languagePacks: GroupedByPrimaryKey<DeserializedPublicResource>;
  statuses: GroupedByPrimaryKey<DeserializedPrivateResource>;
  tags: GroupedByPrimaryKey<DeserializedPrivateResource>;
  organizations: DeserializedPrivateResource[];
  emails: DeserializedPublicResource[];
  voicemails: DeserializedPublicResource[];
  resourcePasswords: ResourcePassword[];
  activityLog: ActivityLogEntry[];
  policies: GroupedByPrimaryKey<DeserializedPrivateResource>;
  api: (state: any) => any;
}

const appReducer = combineReducers({
  currentUser: currentUserReducer,
  workspaces: workspacesReducer,
  reports: reportsReducer,
  invitations: invitationsReducer,
  members: membersReducer,
  messages: messagesReducer,
  comments: commentsReducer,
  forms: formsReducer,
  languagePacks: languagePacksReducer,
  statuses: statusesReducer,
  tags: tagsReducer,
  organizations: organizationsReducer,
  emails: emailsReducer,
  voicemails: voicemailsReducer,
  resourcePasswords: resourcePasswordsReducer,
  activityLog: activityLogReducer,
  policies: policiesReducer,
  api: (state = {}) => state,
});

const rootReducer = (state: AppState, action: { type: string }) => {
  if (action.type === CLEAR_REDUX_STATE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
