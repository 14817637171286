import * as types from './actionTypes';
import { toId } from '../../utils/apiUtils';
import { MemberActionTypes } from './actionTypes';
import { AppState } from '../../reducer';

const initialState: AppState['members'] = {};

export default function membersReducer(
  state = initialState,
  action: MemberActionTypes,
) {
  switch (action.type) {
    case types.MEMBERS_LOADED: {
      const members = action.payload.members;
      const uniquePartitionKeys = [...new Set(members.map((item) => item.pk))];
      const newState = { ...state };
      for (const partitionKey of uniquePartitionKeys) {
        newState[partitionKey] = members.filter(
          (item) => item.pk === partitionKey,
        );
      }

      return newState;
    }
    case types.MEMBERS_ADDED: {
      const organizationKey = action.payload.organizationKey;
      const members = action.payload.members;
      const updatedMembers = [...(state[organizationKey] ?? [])];
      for (const member of members) {
        // Add new member to the sub state if it doesn't contain the item with the same SK
        // already. If it does, the state item will be replaced by the new member.
        const index = updatedMembers.findIndex((item) => item.sk === member.sk);
        if (index < 0) {
          updatedMembers.push(member);
        } else {
          updatedMembers[index] = member;
        }
      }

      const newState = { ...state };
      newState[organizationKey] = updatedMembers;
      return newState;
    }
    case types.MEMBER_UPDATED: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      const newOrganizationMembers = [...(state[pk] ?? [])];
      const index = newOrganizationMembers.findIndex((item) => item.sk === sk);
      if (index < 0) {
        newOrganizationMembers.push({ ...action.payload.data, pk: pk, sk: sk });
      } else {
        newOrganizationMembers[index] = {
          ...newOrganizationMembers[index],
          ...action.payload.data,
        };
      }

      const newState = { ...state };
      newState[pk] = newOrganizationMembers;
      return newState;
    }
    case types.MEMBER_DELETED: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      const newState = { ...state };
      newState[pk] = state[pk].filter((t) => t.sk !== sk);
      return newState;
    }
    case types.MEMBERS_DELETED: {
      const newState = { ...state };
      delete newState[action.payload.organizationKey];
      return newState;
    }
    default:
      return state;
  }
}
