import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  FormControl,
  Stack,
  Tab,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import FieldLabel from '../../components/FieldLabel';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../contexts/ApiContext';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { organizationSelector } from '../../state/organizations/selectors';
import { normalizeId } from '../../utils/textFormatter';
import { toId } from '../../utils/apiUtils';
import { workspaceFormSelector } from '../../state/forms/selectors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { workspaceSelector } from '../../state/workspaces/selectors';
import SwitchSection from '../../components/SwitchSection/SwitchSection';
import { addOrUpdateForm } from '../../state/forms/actions';
import FormPasswordSection from './FormPasswordSection';
import { QRCode } from 'react-qrcode-logo';
import CodeBlock from '../../components/CodeBlock';
import { downloadFile } from '../../utils/storageUtils';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import PublishingSectionHeader from './PublishingSectionHeader';
import { getFormUrl } from '../../utils/formUtils';

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
}));

const FormPublishingInfo = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const whitelabel = useContext(WhitelabelContext);
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const organization = useSelector(organizationSelector(organizationKey));
  const workspace = useSelector(workspaceSelector(workspaceKey));
  const form = useSelector(workspaceFormSelector(workspaceKey));
  const formUrl = getFormUrl(form, whitelabel);
  const [tabIndex, setTabIndex] = useState('0');
  const active = !form.disabled ?? false;

  const handleQrButtonClick = () => {
    const canvas = document.querySelector('#form-qr-code');
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = 336;
    resizedCanvas.height = 336;
    const context = resizedCanvas.getContext('2d');
    context.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

    downloadFile(
      resizedCanvas.toDataURL(),
      `${organization.name}-${workspace.name}-QR.png`,
    );
  };

  const handleFormActiveChange = (event) => {
    const disabled = !event.target.checked;
    if (disabled !== form.disabled) {
      dispatch(addOrUpdateForm(form.pk, { disabled }));
      mutationApi.updatePublicResource(form.pk, form.sk, { disabled });
    }
  };

  return (
    <Box>
      <PublishingSectionHeader
        title={t('reportingForm')}
        subtitle={t('formPublishingOptionsSubtitle')}
        active={active}
      />
      <Stack spacing={4} mt={2}>
        <Box>
          <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={(e, newValue) => setTabIndex(newValue)}
                aria-label="form publishing options"
              >
                <Tab label={t('link')} value="0" />
                <Tab label={t('qrCode')} value="1" />
              </TabList>
            </Box>
            <StyledTabPanel value="0">
              <Box>
                <Typography color="textSecondary">
                  {t('formLinkDescription')}
                </Typography>
                <CodeBlock fullWidth text={formUrl} sx={{ mt: 1 }} />
              </Box>
            </StyledTabPanel>
            <StyledTabPanel value="1">
              <Stack direction="row" spacing={3}>
                <Box lineHeight={0}>
                  <QRCode
                    value={formUrl}
                    size={88}
                    qrStyle="dots"
                    eyeRadius={5}
                    quietZone={0}
                    fgColor={theme.palette.text.primary}
                    // logoImage={qrLogoUrl}
                    // removeQrCodeBehindLogo={true}
                  />
                  <Box hidden>
                    <QRCode
                      id="form-qr-code"
                      value={formUrl}
                      size={160}
                      qrStyle="dots"
                      eyeRadius={5}
                      quietZone={8}
                      fgColor={theme.palette.text.primary}
                      // logoImage={qrLogoUrl}
                      // removeQrCodeBehindLogo={true}
                    />
                  </Box>
                </Box>
                <Stack spacing={1} alignItems="flex-start">
                  <Typography color="textSecondary" sx={{ flexGrow: 1 }}>
                    {t('qrCodeDescription')}
                  </Typography>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleQrButtonClick}
                  >
                    {t('downloadQrCode')}
                  </Button>
                </Stack>
              </Stack>
            </StyledTabPanel>
          </TabContext>
        </Box>
        <SwitchSection
          header={t('active')}
          subheader={t('formActiveDescription')}
          checked={active}
          onChange={handleFormActiveChange}
        />
        <FormPasswordSection />
      </Stack>
    </Box>
  );
};

export default FormPublishingInfo;
