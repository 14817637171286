import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Box,
  Container,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  COUNTRY_LOCALE_MAP,
  WORKSPACE_COUNTRIES,
  WorkspaceCountry,
} from '../../constants/workspace';
import {
  getActiveLanguage,
  getLocalizedCountryName,
} from '../../utils/localizationUtils';
import { ApiContext } from '../../contexts/ApiContext';
import LoadingButton from '@mui/lab/LoadingButton';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import LanguageIcon from '../../components/LanguageButton/LanguageIcon';
import { getWorkspaceRoute } from '../../utils/routeUtils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearState } from '../../state/rootActions';

const RootFormControl = styled(FormControl, { name: 'WorkspaceCountrySelect' })(
  ({ theme }) => ({}),
);

interface Props extends Omit<FormControlProps, 'onChange'> {
  value?: WorkspaceCountry | string;
  label?: string | null;
  helperText?: string | null;
  onChange: (value: WorkspaceCountry) => void;
}

const WorkspaceCountrySelect = ({
  value,
  label,
  helperText,
  onChange,
  ...rest
}: Props) => {
  const { t, i18n } = useTranslation();
  const language = getActiveLanguage(i18n);

  return (
    <RootFormControl {...rest}>
      <InputLabel id="company-country-select-label">{label}</InputLabel>
      <Select
        id="company-country-select"
        labelId="company-country-select-label"
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value as WorkspaceCountry)}
        SelectDisplayProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
            height: '1.4375em',
          },
        }}
      >
        {WORKSPACE_COUNTRIES.map((country) => (
          <MenuItem key={country} value={country}>
            {country !== 'other' ? (
              <>
                <ListItemAvatar>
                  <LanguageIcon locale={COUNTRY_LOCALE_MAP[country]} />
                </ListItemAvatar>
                <ListItemText
                  primary={getLocalizedCountryName(country, language)}
                  sx={{ my: 0 }}
                />
              </>
            ) : (
              t('other')
            )}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </RootFormControl>
  );
};

export default WorkspaceCountrySelect;
