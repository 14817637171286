import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toWorkspaceKey } from '../../utils/apiUtils';
import { useSelector } from 'react-redux';
import { workspaceNameSelector } from '../../state/workspaces/selectors';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import { getNonWorkspaceRouteTitle } from '../../utils/routeUtils';
import { BoxProps } from '@mui/material/Box/Box';
import { TFunction } from 'i18next';
import { styled } from '@mui/material/styles';
import { getActiveLanguage } from '../../utils/localizationUtils';

interface Props extends BoxProps {
  className?: string;
  language?: string;
}

const RootBox = styled(Box, { name: 'Page' })(({ theme }) => ({}));

export function getPageTitle(
  path: string,
  t: TFunction,
  whitelabelName?: string | null | undefined,
  language?: string,
) {
  const routeTitle = getNonWorkspaceRouteTitle(path, t, language);
  const appName = whitelabelName ?? 'SafeTalk';
  return routeTitle ? `${routeTitle} - ${appName}` : appName;
}

const Page = forwardRef(
  ({ children, className, language, ...rest }: Props, ref) => {
    const { t, i18n } = useTranslation();
    const { workspaceId } = useParams();
    const workspaceKey = workspaceId ? toWorkspaceKey(workspaceId) : null;
    const workspaceName = useSelector(workspaceNameSelector(workspaceKey));
    const whitelabel = useContext(WhitelabelContext);
    let location = useLocation();
    const [title, setTitle] = useState(
      () =>
        workspaceName ??
        getPageTitle(
          location.pathname,
          t,
          whitelabel?.name,
          language ?? getActiveLanguage(i18n),
        ),
    );

    useEffect(() => {
      if (whitelabel?.name && !workspaceName) {
        setTitle(getNonWorkspaceRouteTitle(location.pathname, t, language));
      }
    }, [whitelabel?.name]);

    return (
      <RootBox ref={ref} className={className} {...rest}>
        <HelmetProvider>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {children}
        </HelmetProvider>
      </RootBox>
    );
  },
);

export default Page;
