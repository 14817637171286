import React, { useContext, useRef } from 'react';
import { ApiContext } from './ApiContext';
import { useOrganizationKey } from '../hooks/useOrganizationKey';
import { useWorkspaceKey } from '../hooks/useWorkspaceKey';
import { currentUserSelector } from '../state/currentUser/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { organizationSelector } from '../state/organizations/selectors';
import { addActivityLogEntry } from '../state/activityLog/actions';
import { isEnterpriseOrganization } from '../utils/modelUtils';
import { allReportsSelector } from '../state/reports/selectors';

interface ActivityLogger {
  logNewMessage: (reportKey: string, messageKey: string) => void;
  logNewComment: (reportKey: string, commentKey: string) => void;
  logStatusChanged: (reportKey: string, statusKey: string) => void;
  logTagAdded: (reportKey: string, tagKey: string) => void;
  logTagRemoved: (reportKey: string, tagKey: string) => void;
  logDueDateChanged: (reportKey: string, dueDate: string) => void;
  logAssigneeAdded: (reportKey: string, userKey: string) => void;
  logAssigneeRemoved: (reportKey: string, userKey: string) => void;
  logDescriptionChanged: (reportKey: string, description: string) => void;
  logNameChanged: (reportKey: string, description: string) => void;
  logReportOpened: (reportKey: string) => void;
  logReportExported: (reportKey: string) => void;
  logReportDeleted: (reportKey: string) => void;
  logUserRoleChanged: (userKey: string, role: string) => void;
  logInvitationCreated: (email: string, role: string) => void;
  logInvitationAccepted: (email: string) => void;
  logInvitationDeleted: (email: string) => void;
  logUserRemoved: (userKey: string) => void;
  logWorkspaceCreated: (workspaceKey: string) => void;
  logWorkspaceDeleted: (workspaceKey: string) => void;
  logOrganizationCreated: (organizationKey: string) => void;
}

const DefaultActivityLogger: ActivityLogger = {
  logNewMessage: async () => {},
  logNewComment: async () => {},
  logStatusChanged: async () => {},
  logTagAdded: async () => {},
  logTagRemoved: async () => {},
  logDueDateChanged: async () => {},
  logAssigneeAdded: async () => {},
  logAssigneeRemoved: async () => {},
  logDescriptionChanged: async () => {},
  logNameChanged: async () => {},
  logReportOpened: async () => {},
  logReportExported: async () => {},
  logReportDeleted: async () => {},
  logUserRoleChanged: async () => {},
  logInvitationCreated: async () => {},
  logInvitationAccepted: async () => {},
  logInvitationDeleted: async () => {},
  logUserRemoved: async () => {},
  logWorkspaceCreated: async () => {},
  logWorkspaceDeleted: async () => {},
  logOrganizationCreated: async () => {},
};

export const ActivityLogContext = React.createContext<ActivityLogger>(
  DefaultActivityLogger,
);

export const ActivityLogContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const { mutationApi } = useContext(ApiContext);
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const currentUser = useSelector(currentUserSelector());
  const organization = useSelector(organizationSelector(organizationKey));
  const reports = useSelector(allReportsSelector());
  const reportKeysBatchRef = useRef(new Set());
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  // ReportOpened events are batched and created in 1s intervals. This is because fast clicking between reports
  // can cause AppSync to send multiple createActivityLogEvent mutations with the same timestamp as the sort key.
  const queueReportOpenedEvent = (reportKey: string) => {
    reportKeysBatchRef.current.add(reportKey);
    if (!timerRef.current) {
      timerRef.current = setTimeout(
        () => batchCreateReportOpenedEntries(),
        1000,
      );
    }
  };

  const batchCreateReportOpenedEntries = async () => {
    const keys = reportKeysBatchRef.current;
    reportKeysBatchRef.current = new Set();
    clearTimeout(timerRef.current);
    timerRef.current = undefined;
    for (const reportKey of keys) {
      await createActivityLogEntry('reportOpened', { workspaceKey, reportKey });
    }
  };

  const createActivityLogEntry = async (
    event: string,
    data: Record<string, any>,
  ) => {
    // TODO: Refactor - workspaceKey is null when status is changed from MyReports
    if (data.reportKey && !data.workspaceKey) {
      data.workspaceKey = reports.find(
        (report) => report.pk === data.reportKey,
      ).workspaceKey;
    }
    const logEntry = await mutationApi.createActivityLogEntry(
      data.organizationKey ?? organizationKey,
      currentUser.pk,
      event,
      data,
    );
    dispatch(addActivityLogEntry(organizationKey, logEntry));
  };

  // Organization is null when creating an organization
  const contextValue =
    !organization || isEnterpriseOrganization(organization)
      ? {
          logNewMessage: async (reportKey: string, messageKey: string) =>
            await createActivityLogEntry('newMessage', {
              workspaceKey,
              reportKey,
              messageKey,
            }),
          logNewComment: async (reportKey: string, commentKey: string) =>
            await createActivityLogEntry('newComment', {
              workspaceKey,
              reportKey,
              commentKey,
            }),
          logStatusChanged: async (reportKey: string, statusKey: string) =>
            await createActivityLogEntry('statusChanged', {
              workspaceKey,
              reportKey,
              statusKey,
            }),
          logTagAdded: async (reportKey: string, tagKey: string) =>
            await createActivityLogEntry('tagAdded', {
              workspaceKey,
              reportKey,
              tagKey,
            }),
          logTagRemoved: async (reportKey: string, tagKey: string) =>
            await createActivityLogEntry('tagRemoved', {
              workspaceKey,
              reportKey,
              tagKey,
            }),
          logDueDateChanged: async (reportKey: string, dueDate: string) =>
            await createActivityLogEntry('dueDateChanged', {
              workspaceKey,
              reportKey,
              dueDate,
            }),
          logAssigneeAdded: async (reportKey: string, userKey: string) =>
            await createActivityLogEntry('assigneeAdded', {
              workspaceKey,
              reportKey,
              userKey,
            }),
          logAssigneeRemoved: async (reportKey: string, userKey: string) =>
            await createActivityLogEntry('assigneeRemoved', {
              workspaceKey,
              reportKey,
              userKey,
            }),
          logDescriptionChanged: async (
            reportKey: string,
            description: string,
          ) =>
            await createActivityLogEntry('descriptionChanged', {
              workspaceKey,
              reportKey,
              description,
            }),
          logNameChanged: async (reportKey: string, name: string) =>
            await createActivityLogEntry('nameChanged', {
              workspaceKey,
              reportKey,
              name,
            }),
          logReportOpened: async (reportKey: string) =>
            await queueReportOpenedEvent(reportKey),
          logReportExported: async (reportKey: string) =>
            await createActivityLogEntry('reportExported', {
              workspaceKey,
              reportKey,
            }),
          logReportDeleted: async (reportKey: string) =>
            await createActivityLogEntry('reportDeleted', {
              workspaceKey,
              reportKey,
            }),
          logUserRoleChanged: async (userKey: string, role: string) =>
            await createActivityLogEntry('userRoleChanged', { userKey, role }),
          logInvitationCreated: async (email: string, role: string) =>
            await createActivityLogEntry('invitationCreated', { email, role }),
          logInvitationAccepted: async (email: string) =>
            await createActivityLogEntry('invitationAccepted', { email }),
          logInvitationDeleted: async (email: string) =>
            await createActivityLogEntry('invitationDeleted', { email }),
          logUserRemoved: async (userKey: string) =>
            await createActivityLogEntry('userRemoved', { userKey }),
          logWorkspaceCreated: async (workspaceKey: string) =>
            await createActivityLogEntry('workspaceCreated', { workspaceKey }),
          logWorkspaceDeleted: async (workspaceKey: string) =>
            await createActivityLogEntry('workspaceDeleted', { workspaceKey }),
          logOrganizationCreated: async (organizationKey: string) =>
            await createActivityLogEntry('organizationCreated', {
              organizationKey,
            }),
        }
      : DefaultActivityLogger;

  return (
    <ActivityLogContext.Provider value={contextValue}>
      {children}
    </ActivityLogContext.Provider>
  );
};
