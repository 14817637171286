import React, { useContext, useState } from 'react';
import { Box, Container, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { toFormKey } from '../../utils/apiUtils';
import Page from '../../components/Page';
import FormPaper from '../../components/FormPaper';
import { getFormPageVariant } from '../../utils/formUtils';
import FormBlockContainer from '../../components/FormBlockContainer/FormBlockContainer';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApiContext } from '../../contexts/ApiContext';
import PropTypes from 'prop-types';
import FormActions from '../../components/FormActions';

const FormPasswordPage = ({
  formKey,
  workspace,
  language,
  onLoginAccepted,
}) => {
  const { t, i18n } = useTranslation();
  const { queryApi } = useContext(ApiContext);
  const [error, setError] = useState();
  const [password, setPassword] = useState();
  const [working, setWorking] = useState(false);

  const handleAccessCodeChange = (event) => {
    setPassword(event.target.value);
    setError(null);
  };

  const handleContinueButtonClick = async () => {
    setWorking(true);
    const resourcePassword = await queryApi.getResourcePassword(formKey);
    setWorking(false);

    // trim() is called because the acceess code is " " instead of an empty string
    if (
      (resourcePassword.password === ' ' && !Boolean(password)) ||
      resourcePassword.password === password
    ) {
      onLoginAccepted();
    } else {
      setError(t('incorrectPasswordError', { lng: language }));
    }
  };

  return (
    <FormPaper workspace={workspace}>
      <Box>
        <Typography variant="h4" align="center">
          {t('formPasswordDialogHeader', { lng: language })}
        </Typography>
      </Box>
      <TextField
        required
        fullWidth
        label={t('password', { lng: language })}
        placeholder={t('enterPassword', { lng: language })}
        variant="outlined"
        error={Boolean(error)}
        helperText={error}
        onChange={handleAccessCodeChange}
      />
      <FormActions>
        <LoadingButton
          fullWidth
          loading={working}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleContinueButtonClick}
        >
          {t('continue')}
        </LoadingButton>
      </FormActions>
    </FormPaper>
  );
};

FormPasswordPage.propTypes = {
  formKey: PropTypes.string,
  language: PropTypes.string,
  onLoginAccepted: PropTypes.func,
};

FormPasswordPage.defaultProps = {
  language: 'en',
  onLoginAccepted: () => {},
};

export default FormPasswordPage;
