export const scrollToView = (endRef) => {
    if (endRef.current) {
        endRef.current.scrollIntoView({ behavior: "auto" });
    }
}

export const scrollToElement = (element) => {
    if (element) {
        element.scrollIntoView({ behavior: "auto", block: "center" });
    }
}

export function moveCursorToEnd(element) {
    if (typeof element.selectionStart == "number") {
        element.selectionStart = element.selectionEnd = element.value.length;
    } else if (typeof element.createTextRange != "undefined") {
        element.focus();
        const range = element.createTextRange();
        range.collapse(false);
        range.select();
    }
}

export function focusElement(element, callback = null) {
    //setTimeout(() => editorRef.current.blur(), 0);
    setTimeout(() => {element.focus(); callback?.()}, 1);
}