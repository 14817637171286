import React, { useRef, useState } from 'react';
import {
  ClickAwayListener,
  Fade,
  Link,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { addPrefixIfMissing, isNullOrEmpty } from '../../utils/textUtils';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import SelectionState from 'draft-js/lib/SelectionState';

const RootLink = styled(Link, { name: 'RichTextLink' })(({ theme }) => ({}));

const RichTextLink = ({
  contentState,
  entityKey,
  children,
  sx,
  blockKey,
  offsetKey,
  showPopperOnHover,
  editorRootRef,
  onEditorStateChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const entity = contentState.getEntity(entityKey);
  const data = entity.getData();
  const [url, setUrl] = useState(data.url);
  const decoratedTextSelectionRef = useRef();
  const originalSelectionRef = useRef(null);
  const fieldRef = useRef();

  if (showPopperOnHover && !editorRootRef) {
    throw new Error(
      'editorRootRef cannot be null when in showPopperOnHover is true',
    );
  }

  const applyHighlight = () => {
    return Modifier.applyInlineStyle(
      contentState,
      decoratedTextSelectionRef.current,
      'HIGHLIGHT',
    );
  };

  const removeHighlight = () => {
    return Modifier.removeInlineStyle(
      contentState,
      decoratedTextSelectionRef.current,
      'HIGHLIGHT',
    );
  };

  const focusEditorAndUpdateState = (
    newEditorState,
    contentChanged,
    readOnlyEditor,
  ) => {
    onEditorStateChange(newEditorState, contentChanged, readOnlyEditor);
    setTimeout(() => {
      editorRootRef.current?.focus();
    }, 1);
  };

  const closePopper = () => {
    setAnchorEl(null);

    if (editing) {
      // Restore previous selection
      const editorState = editorRootRef?.current.props.editorState;
      let newEditorState = EditorState.forceSelection(
        editorState,
        originalSelectionRef.current,
      );
      let newContentState = removeHighlight();
      newEditorState = EditorState.push(newEditorState, newContentState, null);

      focusEditorAndUpdateState(newEditorState, false, false);
    }
  };

  const handleLinkMouseEnter = (event) => {
    if (showPopperOnHover && !anchorEl) {
      const editorState = editorRootRef?.current.props.editorState;
      originalSelectionRef.current = editorState.getSelection();

      setEditing(false);
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverPaperMouseLeave = () => {
    if (showPopperOnHover && anchorEl && !editing) {
      closePopper();
    }
  };

  const handleEditClick = () => {
    // Apply highlight style
    decoratedTextSelectionRef.current = SelectionState.createEmpty(
      blockKey,
    ).merge({
      anchorOffset: rest.start,
      focusOffset: rest.end,
    });
    const editorState = editorRootRef?.current.props.editorState;
    const newContentState = applyHighlight();
    const newEditorState = EditorState.push(editorState, newContentState, null);
    onEditorStateChange(newEditorState, false, true);

    setEditing(true);
  };

  const handleUrlFieldKeyDown = (event) => {
    if (event.key !== 'Enter') {
      return;
    }

    // Important to prevent the event bubbling all the way up to the editor
    event.stopPropagation();
    event.preventDefault();

    setAnchorEl(null);

    // Remove highlight style
    // Editor state must only be retrieved when needed, it cannot be retrieved globally in component, because it
    // would be retrieved only when the component is rendered and by the time the function is called it could
    // have changed.
    const editorState = editorRootRef?.current.props.editorState;
    const newContentState = removeHighlight();
    let newEditorState = EditorState.push(editorState, newContentState, null);
    // Select decorated text
    newEditorState = EditorState.forceSelection(
      newEditorState,
      decoratedTextSelectionRef.current,
    );
    if (isNullOrEmpty(url)) {
      // Remove link
      newEditorState = RichUtils.toggleLink(
        newEditorState,
        decoratedTextSelectionRef.current,
        null,
      );
    } else {
      // Replace link url
      newContentState.replaceEntityData(entityKey, {
        url: addPrefixIfMissing(url),
      });
      newEditorState = EditorState.push(
        newEditorState,
        newContentState,
        'apply-entity',
      );
    }

    focusEditorAndUpdateState(newEditorState, true, false);
  };

  return (
    <RootLink
      href={url}
      target="_blank"
      rel="noreferrer"
      color="link.main"
      underline="hover"
      sx={sx}
      onMouseEnter={handleLinkMouseEnter}
      onMouseLeave={handlePopoverPaperMouseLeave}
    >
      {children}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
        transition
        sx={{ zIndex: 'modal' }}
      >
        {({ TransitionProps }) => (
          <Fade timeout={350} {...TransitionProps}>
            <Paper
              elevation={8}
              sx={{
                p: 0.5,
                borderRadius: 1.5,
                boxShadow:
                  'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px',
              }}
            >
              <ClickAwayListener onClickAway={closePopper}>
                {editing ? (
                  <TextField
                    ref={fieldRef}
                    autoFocus
                    hiddenLabel
                    fullWidth
                    type="url"
                    size="small"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    sx={{
                      input: {
                        color: 'text.link',
                        textDecoration: 'underline',
                      },
                    }}
                    onKeyDown={handleUrlFieldKeyDown}
                  />
                ) : (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.5}
                    sx={{ p: 0.5 }}
                  >
                    <Typography>{t('openLink')}:</Typography>
                    <Link
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      color="link.main"
                      variant="body1"
                    >
                      {url}
                    </Link>
                    <Tooltip title={t('edit')}>
                      <IconButton
                        size="small"
                        onClick={handleEditClick}
                        sx={{ ml: 1 }}
                      >
                        <EditIcon sx={{ width: 16, height: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </RootLink>
  );
};

RichTextLink.propTypes = {
  editorRootRef: PropTypes.object,
  showPopover: PropTypes.bool,
  onEditorStateChange: PropTypes.func,
};

RichTextLink.defaultProps = {
  showPopover: false,
  onEditorStateChange: () => {},
};

export default RichTextLink;
