import { styled } from "@mui/material/styles";

const AttachmentsWell = styled("div", {name: 'AttachmentsWell'})(({ theme, size, readOnly }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    border: `2px dashed ${theme.palette.grey[300]}`,
    textAlign: "center",
    padding: theme.spacing(3),
    position: "relative",
    "&>*:not(:last-child)": {
        marginBottom: theme.spacing(2),
    }
}));

export default AttachmentsWell;
