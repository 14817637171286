import { AppState } from '../../reducer';

export const allMembersSelector =
  (organizationKey: string | null) => (state: AppState) =>
    organizationKey ? state.members[organizationKey] : null;

export const activeMembersSelector =
  (organizationKey: string | null) => (state: AppState) =>
    organizationKey
      ? state.members[organizationKey].filter((member) => !member.disabled)
      : null;

export const memberSelector =
  (organizationKey: string | null, memberKey: string | null) =>
  (state: AppState) => {
    if (!Boolean(organizationKey) || !Boolean(memberKey)) {
      return null;
    }

    return state.members[organizationKey!]?.find((t) => t.sk === memberKey);
  };

export const membersSelector =
  (organizationKey: string | null, memberKeys: string[] | null) =>
  (state: AppState) => {
    if (!Boolean(organizationKey) || !Boolean(memberKeys)) {
      return null;
    }

    return state.members[organizationKey!]?.filter((t) =>
      memberKeys!.includes(t.sk),
    );
  };

// export const adminMembersSelector = () => (state: AppState) => state.members?.filter(t => t.role === "admin");
// export const solverMembersSelector = () => (state: AppState) => state.members?.filter(t => t.role === "solver");
// export const observerMembersSelector = () => (state: AppState) => state.members?.filter(t => t.role === "observer");

export const currentUserRoleSelector =
  (organizationKey: string) => (state: AppState) => {
    if (!state.currentUser || !state.members[organizationKey]) {
      return null;
    }
    return state.members[organizationKey].find(
      (t) => t.sk === state.currentUser.sk,
    )?.role;
  };

export const currentUserRolesInAllOrganizationsSelector =
  () => (state: AppState) => {
    if (!state.currentUser || !state.members) {
      return null;
    }

    return Object.keys(state.members).reduce(
      (accumulator: Record<string, any>, orgKey) => {
        accumulator[orgKey] = state.members[orgKey].find(
          (t) => t.sk === state.currentUser.sk,
        )?.role;
        return accumulator;
      },
      {},
    );
  };

export const currentUserOrganizationKeysSelector = () => (state: AppState) => {
  if (!state.currentUser || !state.members) {
    return null;
  }

  return Object.keys(state.members);
};
