import React from "react";
import { ToggleButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

const TooltipToggleButton = ({ title, ...rest }) => {
    return (
        <Tooltip title={title}>
            <span>
                <ToggleButton {...rest} />
            </span>
        </Tooltip>
    );
};

TooltipToggleButton.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
};

TooltipToggleButton.defaultProps = {};

export default TooltipToggleButton;
