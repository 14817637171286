import * as types from "./actionTypes";
const initialState = {};

export default function organizationsReducer(state = initialState, action) {
    switch (action.type) {
        case types.ORGANIZATIONS_LOADED: {
            const newState = {...state};
            for (const workspace of action.payload.organizations) {
                newState[workspace.pk] = workspace;
            }
            return newState;
        }
        case types.ORGANIZATION_UPDATED: {
            const pk = action.payload.pk;
            const newState = {...state};
            newState[pk] = {...newState[pk], ...action.payload.data, pk: pk, sk: pk};
            return newState;
        }
        default:
            return state
    }
}
