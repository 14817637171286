import React, { useContext, useEffect, useRef } from "react";
import { ApiContext } from "../contexts/ApiContext";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { addOrReplaceMembers, addOrUpdateMember } from "../state/members/actions";
import { arraysEqual } from "../utils/arrayUtils";
import { allMembersSelector } from "../state/members/selectors";
import { toOrganizationKey } from "../utils/apiUtils";

export const useMessageSenders = (messages) => {
    const { queryApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const organizationKey = Boolean(messages?.[0]) ? toOrganizationKey(messages[0].group) : null;
    const members = useSelector(allMembersSelector(organizationKey)) ?? [];
    const prevSenderKeys = useRef([]);
    const senderKeys = messages?.filter((msg) => Boolean(msg.senderKey)).map((msg) => msg.senderKey);
    const isLoaded =
        arraysEqual(prevSenderKeys.current, senderKeys) ||
        senderKeys?.every((key) => Boolean(members.find((member) => member.sk === key)));

    const fetchMembers = async () => {
        prevSenderKeys.current = senderKeys;

        const uniqueSenderKeys = [...new Set(senderKeys)];
        const tasks = [];
        for (const senderKey of uniqueSenderKeys) {
            const loaded = members?.some((member) => member.sk === senderKey);
            if (!loaded) {
                tasks.push(queryApi.getPublicResource(senderKey, senderKey));
            }
        }

        const results = await Promise.all(tasks);
        const fetchedMembers = results.flat();
        dispatch(addOrReplaceMembers(organizationKey, fetchedMembers));
    };

    useEffect(() => {
        if (isLoaded || !messages || messages.length === 0) {
            return;
        }

        fetchMembers();
    }, [isLoaded]);

    return { loaded: isLoaded, members: members };
};
