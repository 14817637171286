import { Language } from '../i18n/config';

export const WorkspaceTemplates = {
  WHISTLEBLOWING: 'whistleblowing',
  OPEN_DOOR: 'open_door',
  INCIDENT_REPORTING: 'incident_reporting',
  EMPLOYEE_FEEDBACK: 'employee_feedback',
};

// TODO: Delete
export const WorkspaceCountries = {
  CZECHIA: 'czechia',
  POLAND: 'poland',
  SLOVAKIA: 'slovakia',
  EU: 'otherEuCountry',
  OTHER: 'otherCountry',
};

export const WORKSPACE_COUNTRIES = [
  'cz',
  'pl',
  // 'sk',
  // 'hu',
  'ee',
  // 'de',
  'other',
] as const;

export type WorkspaceCountry = (typeof WORKSPACE_COUNTRIES)[number];

export const WORKSPACE_COLORS = ['#219653'] as const;

export type WorkspaceColor = (typeof WORKSPACE_COLORS)[number];

export const COUNTRY_LOCALE_MAP: Record<WorkspaceCountry, Language> = {
  cz: 'cs', // Czechia -> Czech
  pl: 'pl', // Poland -> Polish
  //sk: 'sk', // Slovakia -> Slovak
  //hu: 'hu', // Hungary -> Hungarian
  ee: 'et', // Estonia -> Estonian
  //de: 'de', // Germany -> German
  other: 'en',
} as const;
