import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    unreadIndicator: {
        width: theme.spacing(1),
        height: theme.spacing(1),
        color: theme.palette.primary.main,
    }
}));

const UnreadIndicator = () => {
    const classes = useStyles();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes.unreadIndicator}>
            <circle cx="50%" cy="50%" r="50%" fill="currentColor" />
        </svg>
    )
};

UnreadIndicator.propTypes = {
}

UnreadIndicator.defaultProps = {
};


export default UnreadIndicator;