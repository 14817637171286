import { colors, PaletteMode } from '@mui/material';
import { unstable_createMuiStrictModeTheme as createTheme } from '@mui/material/styles';

export const primaryColor = '#714EFF';
//const primaryTextColor = "rgb(48, 43, 54)";
export const primaryTextColor = '#212B36';

const rawTheme = createTheme();

export const light = {
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light' as PaletteMode,
  default: rawTheme.palette.augmentColor({ color: { main: primaryTextColor } }),
  primary: {
    main: primaryColor,
    light: '#AA7DFF',
    dark: '#2E20CB',
  },
  secondary: {
    main: colors.deepOrange[400],
    contrastText: '#FFFFFF',
  },
  link: {
    main: primaryColor,
  },
  // secondary: {
  //     main: "#FF9800",
  //     light: "#FFC947",
  //     dark: "#C66900",
  // },
  border: {
    default: 'rgba(0, 0, 0, 0.23)',
    hover: 'rgba(0, 0, 0, 0.87)',
  },
  success: {
    main: '#4CAF50',
    dark: '#3B873E',
    light: '#7BC67E',
    contrastText: '#FFFFFF',
    textDark:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4CAF50',
    lightBg:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50',
    border: 'rgba(76, 175, 80, 0.5)',
  },
  warning: {
    main: '#FF9800',
    dark: '#C77700',
    light: '#FFB547',
    textDark:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #FF9800',
    lightBg:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800',
    border: 'rgba(255, 152, 0, 0.5)',
  },
  error: {
    main: '#F44336',
    dark: '#E31B0C',
    light: '#F88078',
    textDark:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #F44336',
    lightBg:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
    hoverBg:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #F44336',
    border: 'rgba(244, 67, 54, 0.5)',
  },
  text: {
    primary: primaryTextColor,
    //secondary: "#637381",
    secondary: '#4b5563',
    disabled: 'rgba(0, 0, 0, 0.38)',
    link: primaryColor,
  },
  action: {
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#ffffff',
    level2: '#f5f5f5',
    level1: '#ffffff',
  },
};
