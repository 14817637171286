export const COUNTRIES = [
    {
        "callingCode":"376",
        "countryCode":"ad",
        "name":"Andorra",
        "timezones":[
            "Andorra"
        ]
    },
    {
        "callingCode":"971",
        "isoCode2":"AE",
        "name":"United Arab Emirates",
        "timezones":[
            "Dubai"
        ]
    },
    {
        "callingCode":"93",
        "isoCode2":"AF",
        "name":"Afghanistan",
        "timezones":[
            "Kabul"
        ]
    },
    {
        "callingCode":"355",
        "isoCode2":"AL",
        "name":"Albania",
        "timezones":[
            "Tirana"
        ]
    },
    {
        "callingCode":"374",
        "isoCode2":"AM",
        "name":"Armenia",
        "timezones":[
            "Yerevan"
        ]
    },
    {
        "callingCode":"54",
        "isoCode2":"AR",
        "name":"Argentina",
        "timezones":[
            "Buenos_Aires",
            "Cordoba",
            "Salta",
            "Jujuy",
            "Tucuman",
            "Catamarca",
            "La_Rioja",
            "San_Juan",
            "Mendoza",
            "San_Luis",
            "Rio_Gallegos",
            "Ushuaia"
        ]
    },
    {
        "callingCode":"43",
        "isoCode2":"AT",
        "name":"Austria",
        "timezones":[
            "Vienna"
        ]
    },
    {
        "callingCode":"61",
        "isoCode2":"AU",
        "name":"Australia",
        "timezones":[
            "Lord_Howe",
            "Macquarie",
            "Hobart",
            "Melbourne",
            "Sydney",
            "Broken_Hill",
            "Brisbane",
            "Lindeman",
            "Adelaide",
            "Darwin",
            "Perth",
            "Eucla"
        ]
    },
    {
        "callingCode":"994",
        "isoCode2":"AZ",
        "name":"Azerbaijan",
        "timezones":[
            "Baku"
        ]
    },
    {
        "callingCode":"387",
        "isoCode2":"BA",
        "name":"Bosnia and Herzegovina",
        "timezones":[
            "Sarajevo"
        ]
    },
    {
        "callingCode":"880",
        "isoCode2":"BD",
        "name":"Bangladesh",
        "timezones":[
            "Dhaka"
        ]
    },
    {
        "callingCode":"32",
        "isoCode2":"BE",
        "name":"Belgium",
        "timezones":[
            "Brussels"
        ]
    },
    {
        "callingCode":"359",
        "isoCode2":"BG",
        "name":"Bulgaria",
        "timezones":[
            "Sofia"
        ]
    },
    {
        "callingCode":"973",
        "isoCode2":"BH",
        "name":"Bahrain",
        "timezones":[
            "Bahrain"
        ]
    },
    {
        "callingCode":"1-441",
        "isoCode2":"BM",
        "name":"Bermuda",
        "timezones":[
            "Bermuda"
        ]
    },
    {
        "callingCode":"591",
        "isoCode2":"BO",
        "name":"Bolivia",
        "timezones":[
            "La_Paz"
        ]
    },
    {
        "callingCode":"55",
        "isoCode2":"BR",
        "name":"Brazil",
        "timezones":[
            "Noronha",
            "Belem",
            "Fortaleza",
            "Recife",
            "Araguaina",
            "Maceio",
            "Bahia",
            "Sao_Paulo",
            "Campo_Grande",
            "Cuiaba",
            "Santarem",
            "Porto_Velho",
            "Boa_Vista",
            "Manaus",
            "Eirunepe",
            "Rio_Branco"
        ]
    },
    {
        "callingCode":"975",
        "isoCode2":"BT",
        "name":"Bhutan",
        "timezones":[
            "Thimphu"
        ]
    },
    {
        "callingCode":"375",
        "isoCode2":"BY",
        "name":"Belarus",
        "timezones":[
            "Minsk"
        ]
    },
    {
        "callingCode":"501",
        "isoCode2":"BZ",
        "name":"Belize",
        "timezones":[
            "Belize"
        ]
    },
    {
        "callingCode":"1",
        "isoCode2":"CA",
        "name":"Canada",
        "timezones":[
            "St_Johns",
            "Halifax",
            "Glace_Bay",
            "Moncton",
            "Goose_Bay",
            "Toronto",
            "Nipigon",
            "Thunder_Bay",
            "Iqaluit",
            "Pangnirtung",
            "Winnipeg",
            "Rainy_River",
            "Resolute",
            "Rankin_Inlet",
            "Regina",
            "Swift_Current",
            "Edmonton",
            "Cambridge_Bay",
            "Yellowknife",
            "Inuvik",
            "Dawson_Creek",
            "Fort_Nelson",
            "Whitehorse",
            "Dawson",
            "Vancouver",
            "Blanc-Sablon",
            "Atikokan",
            "Creston"
        ]
    },
    {
        "callingCode":"41",
        "isoCode2":"CH",
        "name":"Switzerland",
        "timezones":[
            "Zurich"
        ]
    },
    {
        "callingCode":"56",
        "isoCode2":"CL",
        "name":"Chile",
        "timezones":[
            "Santiago",
            "Punta_Arenas",
            "Easter"
        ]
    },
    {
        "callingCode":"86",
        "isoCode2":"CN",
        "name":"China",
        "timezones":[
            "Shanghai",
            "Urumqi"
        ]
    },
    {
        "callingCode":"57",
        "isoCode2":"CO",
        "name":"Colombia",
        "timezones":[
            "Bogota"
        ]
    },
    {
        "callingCode":"53",
        "isoCode2":"CU",
        "name":"Cuba",
        "timezones":[
            "Havana"
        ]
    },
    {
        "callingCode":"357",
        "isoCode2":"CY",
        "name":"Cyprus",
        "timezones":[
            "Nicosia",
            "Famagusta"
        ]
    },
    {
        "callingCode":"420",
        "isoCode2":"CZ",
        "name":"Czech Republic",
        "timezones":[
            "Prague"
        ]
    },
    {
        "callingCode":"49",
        "isoCode2":"DE",
        "name":"Germany",
        "timezones":[
            "Berlin",
            "Busingen"
        ]
    },
    {
        "callingCode":"45",
        "isoCode2":"DK",
        "name":"Denmark",
        "timezones":[
            "Copenhagen"
        ]
    },
    {
        "callingCode":"593",
        "isoCode2":"EC",
        "name":"Ecuador",
        "timezones":[
            "Guayaquil",
            "Galapagos"
        ]
    },
    {
        "callingCode":"372",
        "isoCode2":"EE",
        "name":"Estonia",
        "timezones":[
            "Tallinn"
        ]
    },
    {
        "callingCode":"20",
        "isoCode2":"EG",
        "name":"Egypt",
        "timezones":[
            "Cairo"
        ]
    },
    {
        "callingCode":"34",
        "isoCode2":"ES",
        "name":"Spain",
        "timezones":[
            "Madrid",
            "Ceuta",
            "Canary"
        ]
    },
    {
        "callingCode":"358",
        "isoCode2":"FI",
        "name":"Finland",
        "timezones":[
            "Helsinki"
        ]
    },
    {
        "callingCode":"33",
        "isoCode2":"FR",
        "name":"France",
        "timezones":[
            "Paris"
        ]
    },
    {
        "callingCode":"44",
        "isoCode2":"GB",
        "name":"United Kingdom",
        "timezones":[
            "London"
        ]
    },
    {
        "callingCode":"995",
        "isoCode2":"GE",
        "name":"Georgia",
        "timezones":[
            "Tbilisi"
        ]
    },
    {
        "callingCode":"30",
        "isoCode2":"GR",
        "name":"Greece",
        "timezones":[
            "Athens"
        ]
    },
    {
        "callingCode":"852",
        "isoCode2":"HK",
        "name":"Hong Kong",
        "timezones":[
            "Hong_Kong"
        ]
    },
    {
        "callingCode":"504",
        "isoCode2":"HN",
        "name":"Honduras",
        "timezones":[
            "Tegucigalpa"
        ]
    },
    {
        "callingCode":"385",
        "isoCode2":"HR",
        "name":"Croatia",
        "timezones":[
            "Zagreb"
        ]
    },
    {
        "callingCode":"36",
        "isoCode2":"HU",
        "name":"Hungary",
        "timezones":[
            "Budapest"
        ]
    },
    {
        "callingCode":"62",
        "isoCode2":"ID",
        "name":"Indonesia",
        "timezones":[
            "Jakarta",
            "Pontianak",
            "Makassar",
            "Jayapura"
        ]
    },
    {
        "callingCode":"353",
        "isoCode2":"IE",
        "name":"Ireland",
        "timezones":[
            "Dublin"
        ]
    },
    {
        "callingCode":"972",
        "isoCode2":"IL",
        "name":"Israel",
        "timezones":[
            "Jerusalem"
        ]
    },
    {
        "callingCode":"91",
        "isoCode2":"IN",
        "name":"India",
        "timezones":[
            "Kolkata"
        ]
    },
    {
        "callingCode":"964",
        "isoCode2":"IQ",
        "name":"Iraq",
        "timezones":[
            "Baghdad"
        ]
    },
    {
        "callingCode":"354",
        "isoCode2":"IS",
        "name":"Iceland",
        "timezones":[
            "Reykjavik"
        ]
    },
    {
        "callingCode":"39",
        "isoCode2":"IT",
        "name":"Italy",
        "timezones":[
            "Rome"
        ]
    },
    {
        "callingCode":"1-876",
        "isoCode2":"JM",
        "name":"Jamaica",
        "timezones":[
            "Jamaica"
        ]
    },
    {
        "callingCode":"962",
        "isoCode2":"JO",
        "name":"Jordan",
        "timezones":[
            "Amman"
        ]
    },
    {
        "callingCode":"81",
        "isoCode2":"JP",
        "name":"Japan",
        "timezones":[
            "Tokyo"
        ]
    },
    {
        "callingCode":"996",
        "isoCode2":"KG",
        "name":"Kyrgyzstan",
        "timezones":[
            "Bishkek"
        ]
    },
    {
        "callingCode":"855",
        "isoCode2":"KH",
        "name":"Cambodia",
        "timezones":[
            "Phnom_Penh"
        ]
    },
    {
        "callingCode":"82",
        "isoCode2":"KR",
        "name":"Republic of Korea",
        "timezones":[
            "Seoul"
        ]
    },
    {
        "callingCode":"965",
        "isoCode2":"KW",
        "name":"Kuwait",
        "timezones":[
            "Kuwait"
        ]
    },
    {
        "callingCode":"1-345",
        "isoCode2":"KY",
        "name":"Cayman Islands",
        "timezones":[
            "Cayman"
        ]
    },
    {
        "callingCode":"7",
        "isoCode2":"KZ",
        "name":"Kazakhstan",
        "timezones":[
            "Almaty",
            "Qyzylorda",
            "Qostanay",
            "Aqtobe",
            "Aqtau",
            "Atyrau",
            "Oral"
        ]
    },
    {
        "callingCode":"961",
        "isoCode2":"LB",
        "name":"Lebanon",
        "timezones":[
            "Beirut"
        ]
    },
    {
        "callingCode":"423",
        "isoCode2":"LI",
        "name":"Liechtenstein",
        "timezones":[
            "Vaduz"
        ]
    },
    {
        "callingCode":"94",
        "isoCode2":"LK",
        "name":"Sri Lanka",
        "timezones":[
            "Colombo"
        ]
    },
    {
        "callingCode":"370",
        "isoCode2":"LT",
        "name":"Lithuania",
        "timezones":[
            "Vilnius"
        ]
    },
    {
        "callingCode":"352",
        "isoCode2":"LU",
        "name":"Luxembourg",
        "timezones":[
            "Luxembourg"
        ]
    },
    {
        "callingCode":"371",
        "isoCode2":"LV",
        "name":"Latvia",
        "timezones":[
            "Riga"
        ]
    },
    {
        "callingCode":"377",
        "isoCode2":"MC",
        "name":"Monaco",
        "timezones":[
            "Monaco"
        ]
    },
    {
        "callingCode":"373",
        "isoCode2":"MD",
        "name":"Republic of Moldova",
        "timezones":[
            "Chisinau"
        ]
    },
    {
        "callingCode":"382",
        "isoCode2":"ME",
        "name":"Montenegro",
        "timezones":[
            "Podgorica"
        ]
    },
    {
        "callingCode":"976",
        "isoCode2":"MN",
        "name":"Mongolia",
        "timezones":[
            "Ulaanbaatar",
            "Hovd",
            "Choibalsan"
        ]
    },
    {
        "callingCode":"356",
        "isoCode2":"MT",
        "name":"Malta",
        "timezones":[
            "Malta"
        ]
    },
    {
        "callingCode":"52",
        "isoCode2":"MX",
        "name":"Mexico",
        "timezones":[
            "Mexico_City",
            "Cancun",
            "Merida",
            "Monterrey",
            "Matamoros",
            "Mazatlan",
            "Chihuahua",
            "Ojinaga",
            "Hermosillo",
            "Tijuana",
            "Bahia_Banderas"
        ]
    },
    {
        "callingCode":"60",
        "isoCode2":"MY",
        "name":"Malaysia",
        "timezones":[
            "Kuala_Lumpur",
            "Kuching"
        ]
    },
    {
        "callingCode":"31",
        "isoCode2":"NL",
        "name":"Netherlands",
        "timezones":[
            "Amsterdam"
        ]
    },
    {
        "callingCode":"47",
        "isoCode2":"NO",
        "name":"Norway",
        "timezones":[
            "Oslo"
        ]
    },
    {
        "callingCode":"674",
        "isoCode2":"NR",
        "name":"Nauru",
        "timezones":[
            "Nauru"
        ]
    },
    {
        "callingCode":"64",
        "isoCode2":"NZ",
        "name":"New Zealand",
        "timezones":[
            "Auckland",
            "Chatham"
        ]
    },
    {
        "callingCode":"507",
        "isoCode2":"PA",
        "name":"Panama",
        "timezones":[
            "Panama"
        ]
    },
    {
        "callingCode":"51",
        "isoCode2":"PE",
        "name":"Peru",
        "timezones":[
            "Lima"
        ]
    },
    {
        "callingCode":"63",
        "isoCode2":"PH",
        "name":"Philippines",
        "timezones":[
            "Manila"
        ]
    },
    {
        "callingCode":"92",
        "isoCode2":"PK",
        "name":"Pakistan",
        "timezones":[
            "Karachi"
        ]
    },
    {
        "callingCode":"48",
        "isoCode2":"PL",
        "name":"Poland",
        "timezones":[
            "Warsaw"
        ]
    },
    {
        "callingCode":"1",
        "isoCode2":"PR",
        "name":"Puerto Rico",
        "timezones":[
            "Puerto_Rico"
        ]
    },
    {
        "callingCode":"351",
        "isoCode2":"PT",
        "name":"Portugal",
        "timezones":[
            "Lisbon",
            "Madeira",
            "Azores"
        ]
    },
    {
        "callingCode":"595",
        "isoCode2":"PY",
        "name":"Paraguay",
        "timezones":[
            "Asuncion"
        ]
    },
    {
        "callingCode":"974",
        "isoCode2":"QA",
        "name":"Qatar",
        "timezones":[
            "Qatar"
        ]
    },
    {
        "callingCode":"40",
        "isoCode2":"RO",
        "name":"Romania",
        "timezones":[
            "Bucharest"
        ]
    },
    {
        "callingCode":"381",
        "isoCode2":"RS",
        "name":"Serbia",
        "timezones":[
            "Belgrade"
        ]
    },
    {
        "callingCode":"7",
        "isoCode2":"RU",
        "name":"Russian Federation",
        "timezones":[
            "Kaliningrad",
            "Moscow",
            "Simferopol",
            "Kirov",
            "Volgograd",
            "Astrakhan",
            "Saratov",
            "Ulyanovsk",
            "Samara",
            "Yekaterinburg",
            "Omsk",
            "Novosibirsk",
            "Barnaul",
            "Tomsk",
            "Novokuznetsk",
            "Krasnoyarsk",
            "Irkutsk",
            "Chita",
            "Yakutsk",
            "Khandyga",
            "Vladivostok",
            "Ust-Nera",
            "Magadan",
            "Sakhalin",
            "Srednekolymsk",
            "Kamchatka",
            "Anadyr"
        ]
    },
    {
        "callingCode":"966",
        "isoCode2":"SA",
        "name":"Saudi Arabia",
        "timezones":[
            "Riyadh"
        ]
    },
    {
        "callingCode":"46",
        "isoCode2":"SE",
        "name":"Sweden",
        "timezones":[
            "Stockholm"
        ]
    },
    {
        "callingCode":"65",
        "isoCode2":"SG",
        "name":"Singapore",
        "timezones":[
            "Singapore"
        ]
    },
    {
        "callingCode":"386",
        "isoCode2":"SI",
        "name":"Slovenia",
        "timezones":[
            "Ljubljana"
        ]
    },
    {
        "callingCode":"421",
        "isoCode2":"SK",
        "name":"Slovakia",
        "timezones":[
            "Bratislava"
        ]
    },
    {
        "callingCode":"378",
        "isoCode2":"SM",
        "name":"San Marino",
        "timezones":[
            "San_Marino"
        ]
    },
    {
        "callingCode":"66",
        "isoCode2":"TH",
        "name":"Thailand",
        "timezones":[
            "Bangkok"
        ]
    },
    {
        "callingCode":"992",
        "isoCode2":"TJ",
        "name":"Tajikistan",
        "timezones":[
            "Dushanbe"
        ]
    },
    {
        "callingCode":"993",
        "isoCode2":"TM",
        "name":"Turkmenistan",
        "timezones":[
            "Ashgabat"
        ]
    },
    {
        "callingCode":"216",
        "isoCode2":"TN",
        "name":"Tunisia",
        "timezones":[
            "Tunis"
        ]
    },
    {
        "callingCode":"90",
        "isoCode2":"TR",
        "name":"Turkey",
        "timezones":[
            "Istanbul"
        ]
    },
    {
        "callingCode":"380",
        "isoCode2":"UA",
        "name":"Ukraine",
        "timezones":[
            "Kiev",
            "Uzhgorod",
            "Zaporozhye"
        ]
    },
    {
        "callingCode":"1",
        "isoCode2":"US",
        "name":"United States",
        "timezones":[
            "New_York",
            "Detroit",
            "Louisville",
            "Monticello",
            "Indianapolis",
            "Vincennes",
            "Winamac",
            "Marengo",
            "Petersburg",
            "Vevay",
            "Chicago",
            "Tell_City",
            "Knox",
            "Menominee",
            "Center",
            "New_Salem",
            "Beulah",
            "Denver",
            "Boise",
            "Phoenix",
            "Los_Angeles",
            "Anchorage",
            "Juneau",
            "Sitka",
            "Metlakatla",
            "Yakutat",
            "Nome",
            "Adak",
            "Honolulu"
        ]
    },
    {
        "callingCode":"598",
        "isoCode2":"UY",
        "name":"Uruguay",
        "timezones":[
            "Montevideo"
        ]
    },
    {
        "callingCode":"998",
        "isoCode2":"UZ",
        "name":"Uzbekistan",
        "timezones":[
            "Samarkand",
            "Tashkent"
        ]
    },
    {
        "callingCode":"379",
        "isoCode2":"VA",
        "name":"Vatican City",
        "timezones":[
            "Vatican"
        ]
    },
    {
        "callingCode":"58",
        "isoCode2":"VE",
        "name":"Venezuela",
        "timezones":[
            "Caracas"
        ]
    },
    {
        "callingCode":"84",
        "isoCode2":"VN",
        "name":"Vietnam",
        "timezones":[
            "Ho_Chi_Minh"
        ]
    },
    {
        "callingCode":"27",
        "isoCode2":"ZA",
        "name":"South Africa",
        "timezones":[
            "Johannesburg"
        ]
    }
]