import * as types from "./actionTypes";
import {stableSort} from "../../utils/arrayUtils";

export const loadMessages = (messages) => async (dispatch, getState) => {
    dispatch({ type: types.MESSAGES_LOADED, payload: {messages} });
}

export const addMessages = (messages) => async (dispatch, getState) => {
    dispatch({ type: types.MESSAGES_ADDED, payload: {messages} });
}

export const addOrUpdateMessage = (pk, sk, data) => async (dispatch, getState) => {
    dispatch({ type: types.MESSAGE_UPDATED, payload: {pk, sk, data} });
}

export const deleteWorkspaceMessages = (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.MESSAGES_DELETED, payload: { workspaceKey } });
}