import {isSvgFile} from "./fileUtils";

const hasBlobConstructor = typeof (Blob) !== 'undefined' && (function () {
    try {
        return Boolean(new Blob());
    } catch (e) {
        return false;
    }
}());

const hasArrayBufferViewSupport = hasBlobConstructor && typeof (Uint8Array) !== 'undefined' && (function () {
    try {
        return new Blob([new Uint8Array(100)]).size === 100;
    } catch (e) {
        return false;
    }
}());

const hasToBlobSupport = (typeof HTMLCanvasElement !== 'undefined' ? HTMLCanvasElement.prototype.toBlob : false);

const hasBlobSupport = (hasToBlobSupport ||
    (typeof Uint8Array !== 'undefined' && typeof ArrayBuffer !== 'undefined' && typeof atob !== 'undefined'));

const hasReaderSupport = (typeof FileReader !== 'undefined' || typeof URL !== 'undefined');

export class ImageTools {
    static cropToSquare(file, maxSize) {
        // Manipulation of svg files is not supported
        if (isSvgFile(file.name)) {
            return new Promise(resolve => {resolve(file)});
        }

        return new Promise(resolve => {
            this._runExecutor(file, (img) => this._cropToSquareExecutor(img, maxSize), (blob) => resolve(blob));
        });
    }

    static scaleToFit(file, maxDimensions) {
        // Manipulation of svg files is not supported
        if (isSvgFile(file.name)) {
            return new Promise(resolve => {resolve(file)});
        }

        return new Promise(resolve => {
            this._runExecutor(file, (img) => this._scaleToFitExecutor(img, maxDimensions), (blob) => resolve(blob));
        });
    }

    static _runExecutor(file, executor, callback) {
        if (!ImageTools.isSupported() || !file.type.match(/image.*/)) {
            callback(file, false);
            return;
        }

        if (file.type.match(/image\/gif/)) {
            // Not attempting, could be an animated gif
            callback(file, false);
            return;
        }

        const image = document.createElement('img');
        image.onload = (imgEvt) => {
            const canvas = executor(image);

            if (!canvas) {
                // Image was not changed, early exit
                callback(file, false);
                return;
            }

            if (hasToBlobSupport) {
                canvas.toBlob((blob) => {
                    callback(blob, true);
                }, file.type);
            } else {
                const blob = ImageTools._toBlob(canvas, file.type);
                callback(blob, true);
            }
        };
        ImageTools._loadImage(image, file);
    }

    static _scaleToFitExecutor(image, maxDimensions) {
        const isWidthTooLarge = maxDimensions.width && image.width > maxDimensions.width;
        const isHeightTooLarge = maxDimensions.height && image.height > maxDimensions.height;

        if (!isWidthTooLarge && !isHeightTooLarge) {
            return null;
        }

        let adjustedWidth = image.width;
        let adjustedHeight = image.height;
        if (isWidthTooLarge && !isHeightTooLarge) {
            // If image is too wide but height is ok, the image is resized to fit the
            // max width keeping the aspect ratio
            const scaleRatio = maxDimensions.width / image.width;
            adjustedWidth *= scaleRatio;
            adjustedHeight *= scaleRatio;
        } else if (!isWidthTooLarge && isHeightTooLarge) {
            // If image is too high but width is ok, the image is resized to fit the
            // max height keeping the aspect ratio
            const scaleRatio = maxDimensions.height / image.height;
            adjustedWidth *= scaleRatio;
            adjustedHeight *= scaleRatio;
        } else if (isWidthTooLarge && isHeightTooLarge) {
            // If both dimensions are larger than max dimensions, resize the image to
            // fit the max dimensions not regarding the aspect ratio.
            adjustedWidth *= maxDimensions.width / image.width;
            adjustedHeight *= maxDimensions.height / image.height;
        }

        const canvas = document.createElement('canvas');
        canvas.width = adjustedWidth;
        canvas.height = adjustedHeight;
        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'low';
        ctx.drawImage(image, 0, 0, adjustedWidth, adjustedHeight);

        return canvas;
    }

    static _cropToSquareExecutor(image, maxSize) {
        const smallestDimension = Math.min(image.width, image.height);
        const cropStartX = image.width / 2 - smallestDimension / 2;
        const cropStartY = image.height / 2 - smallestDimension / 2;

        const canvas = document.createElement('canvas');
        canvas.width = maxSize;
        canvas.height = maxSize;

        const ctx = canvas.getContext('2d');
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'medium'; // high
        ctx.drawImage(
            image,
            cropStartX, cropStartY, // Start at x/y pixels from the left and the top of the image (crop),
            smallestDimension, smallestDimension, // "Get" a `w * h` area from the source image (crop),
            0, 0, // Place the result at 0, 0 in the canvas,
            maxSize, maxSize // With as width / height: maxSize * maxSize (scale));
        );

        return canvas;
    }

    static _toBlob(canvas, type) {
        const dataURI = canvas.toDataURL(type);
        const dataURIParts = dataURI.split(',');
        let byteString;
        if (dataURIParts[0].indexOf('base64') >= 0) {
            // Convert base64 to raw binary data held in a string:
            byteString = atob(dataURIParts[1]);
        } else {
            // Convert base64/URLEncoded data component to raw binary data:
            byteString = decodeURIComponent(dataURIParts[1]);
        }
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i += 1) {
            intArray[i] = byteString.charCodeAt(i);
        }

        const mimeString = dataURIParts[0].split(':')[1].split(';')[0];
        let blob = null;

        if (hasBlobConstructor) {
            blob = new Blob(
                [hasArrayBufferViewSupport ? intArray : arrayBuffer],
                {type: mimeString}
            );
        } else {
            blob = new Blob([arrayBuffer]);
        }
        return blob;
    }

    static _loadImage(image, file, callback) {
        if (typeof (URL) === 'undefined') {
            const reader = new FileReader();
            reader.onload = function (evt) {
                image.src = evt.target.result;
                if (callback) {
                    callback();
                }
            };
            reader.readAsDataURL(file);
        } else {
            image.src = URL.createObjectURL(file);
            if (callback) {
                callback();
            }
        }
    }

    static _toFile = (theBlob, fileName) => {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return theBlob;
    }

    static isSupported() {
        return ((typeof (HTMLCanvasElement) !== 'undefined') && hasBlobSupport && hasReaderSupport);
    }
}

