import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useReportSubscription } from '../../hooks/useReportSubscription';
import { usePublicReportResources } from '../../hooks/usePublicReportResources';
import { useMessageSenders } from '../../hooks/useMessageSenders';
import { useFetchPublicWorkspaceResource } from '../../hooks/useFetchPublicWorkspaceResource';
import { styled } from '@mui/material/styles';
import { clearState } from '../../state/rootActions';
import ReportChat, {
  ReportChatClassNames,
} from '../../components/ReportChat/ReportChat';
import { useFetchPublicOrganizationResource } from '../../hooks/useFetchPublicOrganizationResource';
import Loader from '../../components/Loader';
import { CARD_SHADOW3 } from '../../constants';
import FormPaperLogo from '../../components/FormPaperLogo/FormPaperLogo';
import { OrganizationKeyContext } from '../../contexts/OrganizationKeyContext';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { isClosed } from '../../utils/reportUtils';

const RootContainer = styled(Container, { name: 'ReportingPersonChat' })(
  ({ theme }) => ({
    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 2),
    },
  }),
);

const PaperBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0), //4,6
  borderRadius: theme.shape.borderRadius * 2,
  background: theme.palette.background.paper,
  ...CARD_SHADOW3,
}));

const ImprintDiv = styled('div', {
  name: 'ReportingPersonChat',
  slot: 'imprint',
})(({ theme }) => ({
  display: 'inline-block',
  margin: 'auto',
  borderRadius: '8px',
  borderWidth: 'thin',
  borderStyle: 'solid',
  borderColor: theme.palette.divider,
  color: theme.palette.text.disabled,
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const ReportingPersonChat = ({
  className,
  password,
  reportKey,
  onChatClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    messages,
    report,
    loaded: messagesLoaded,
  } = usePublicReportResources(reportKey);
  const { workspace, loaded: workspaceLoaded } =
    useFetchPublicWorkspaceResource(report?.workspaceKey);
  const { organization, loaded: organizationLoaded } =
    useFetchPublicOrganizationResource(report?.organizationKey);
  const { members, loaded: sendersLoaded } = useMessageSenders(messages);
  const subscription = useReportSubscription(reportKey);

  const handleChatClose = () => {
    if (subscription) {
      subscription.unsubscribe();
    }
    dispatch(clearState());
    onChatClose();
  };

  if (report?.isDeleted || workspace?.isDeleted) {
    return (
      <RootContainer maxWidth="md">
        <Stack
          direction={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          height={1}
        >
          <Typography variant="h6">{t('reportHasBeenDeleted')}</Typography>
        </Stack>
      </RootContainer>
    );
  }

  return (
    <RootContainer maxWidth="md">
      {workspaceLoaded && organizationLoaded ? (
        <OrganizationKeyContext.Provider value={organization.pk}>
          <PaperBox sx={{ height: '100%' }}>
            <Stack direciton="columm" sx={{ height: '100%' }}>
              <Box
                sx={{
                  position: 'relative',
                  mx: 5,
                  '&>*:not(:last-child)': { mb: 4 },
                }}
              >
                <FormPaperLogo workspace={workspace} />
                <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
                  {/*<Tooltip title="More">*/}
                  {/*    <IconButton aria-label="export">*/}
                  {/*        <MoreVertOutlinedIcon fontSize="small" />*/}
                  {/*    </IconButton>*/}
                  {/*</Tooltip>*/}
                  <Tooltip title="Exit report">
                    <IconButton
                      aria-label="export"
                      onClick={handleChatClose}
                      edge="end"
                      sx={{ mt: '-2px' }}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider />
                <Typography variant="h4">{`Report ${password}`}</Typography>
              </Box>
              {/*{isEnterpriseOrganization(organization) && (*/}
              {/*    <ImprintDiv>*/}
              {/*        <LockOutlinedIcon />*/}
              {/*        <Typography variant="caption" color="inherit">*/}
              {/*            {t("anonymousChatNotification")}*/}
              {/*        </Typography>*/}
              {/*    </ImprintDiv>*/}
              {/*)}*/}
              <ReportChat
                loading={!messagesLoaded || !sendersLoaded}
                reportKey={reportKey}
                workspaceKey={workspace.pk}
                organizationKey={organization.pk}
                variant="bubble"
                ChatInputProps={{
                  disabled: isClosed(report),
                  placeholder: isClosed(report)
                    ? t('disabledChatInputPlaceholder')
                    : t('guestChatInputPlaceholder'),
                }}
                sx={{
                  flex: '1 1 auto',
                  minHeight: 0,
                  pt: 1.5,
                  [`.${ReportChatClassNames.message},.${ReportChatClassNames.input}`]:
                    {
                      px: 5,
                    },
                }}
              />
            </Stack>
          </PaperBox>
        </OrganizationKeyContext.Provider>
      ) : (
        <Loader />
      )}
    </RootContainer>
  );
};

ReportingPersonChat.propTypes = {
  reportKey: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
};

export default ReportingPersonChat;
