import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Avatar,
    Box, Collapse,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Snackbar, Stack,
    Tooltip
} from '@mui/material';
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import IconButton from "@mui/material/IconButton";
import FieldLabel from "../../components/FieldLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { styled } from "@mui/material/styles";
import AddFormLanguageDialog from "./AddFormLanguageDialog";
import Alert from "@mui/material/Alert";
import {
    getDictionary,
    dictionaryRegistrationName,
    privacyPolicyFieldRegistrationName,
    privacyPolicyRegistrationName,
    getTranslations,
    translationsRegistrationName,
    getFormTemplate,
    formTemplateRegistrationName,
} from "../../components/FormBlock/utils";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {
    getAllLanguagePacksLanguages,
    getLanguagePackByLanguage,
    getLanguagePackLanguage,
} from "../../utils/formUtils";
import { useDispatch, useSelector } from 'react-redux';
import { allLanguagePacksSelector } from "../../state/languagePacks/selectors";
import SwitchSection from "../../components/SwitchSection/SwitchSection";
import { formSelector } from "../../state/forms/selectors";
import { Controller, useFormContext } from "react-hook-form";
import EditorTextField from "../../components/FormBlock/EditorTextField";
import {
    getDefaultPrivacyPolicyTranslations,
    getDefaultTranslations,
    newFormBlock,
    newPrivacyPolicy
} from '../../utils/formBlockUtils';
import { s8 } from '../../utils/idGenerator';
import { removeObjectFields } from '../../utils/objectUtils';
import { addOrUpdateForm } from '../../state/forms/actions';
import { ApiContext } from '../../contexts/ApiContext';

const RootBox = styled(Paper, { name: "FormBuilderSettings" })(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(3, 4),
    overflow: "auto",
    borderRadius: 0,
    "&>*:not(:first-of-type)": {
        marginTop: theme.spacing(3),
    },
}));

const FormBuilderSettings = ({ form, formMethods, language, onLanguageChange, onLanguagePacksChange, onEditPrivacyPolicyClick, ...rest }) => {
    const { t } = useTranslation();
    const { mutationApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const [languageContextMenuAnchorEl, setLanguageContextMenuAnchorEl] = useState();
    const [translationErrorSnackbarOpen, setTranslationErrorSnackbarOpen] = useState(false);
    const [newLanguageDialogOpen, setNewLanguageDialogOpen] = useState(false);
    const [focusedLanguage, setFocusedLanguage] = useState();
    const languagePacks = useSelector(allLanguagePacksSelector(form.pk));
    const languages = getAllLanguagePacksLanguages(languagePacks);
    //const form = useSelector(formSelector(form.pk));
    const { setValue, getValues, watch, unregister } = useFormContext();
    const formTemplate = watch(formTemplateRegistrationName());

    const onLanguageSelected = (newLanguage) => {
        if (newLanguage !== language) {
            onLanguageChange(newLanguage);
        }
    };

    const handleLanguageMoreClick = (event, value) => {
        setLanguageContextMenuAnchorEl(event.currentTarget);
        setFocusedLanguage(value);
    };

    const handleDeleteLanguageClick = () => {
        setLanguageContextMenuAnchorEl(null);

        // Change active language pack if the language of the current active language pack was deleted.
        if (focusedLanguage === language) {
            const newLanguage = languages.find((lang) => lang !== focusedLanguage);
            onLanguageChange(newLanguage);
        }

        onLanguagePacksChange({ removed: [getLanguagePackByLanguage(languagePacks, focusedLanguage)] });
    };

    const handleTranslationsCompleted = (result) => {
        setNewLanguageDialogOpen(false);

        if (result.failedLanguages.length > 0) {
            // Some translations were not completed.
            setTranslationErrorSnackbarOpen(true);
        }
        if (result.translated.length === 0) {
            // Early exit, all translations have failed.
            return;
        }

        onLanguagePacksChange({ added: result.translated });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTranslationErrorSnackbarOpen(false);
    };

    const handlePrivacyPolicyEnabled = () => {
        const privacyPolicy = newPrivacyPolicy();
        const formTemplate = getFormTemplate(getValues);
        setValue(
            formTemplateRegistrationName(),
            { ...formTemplate, privacyPolicy: privacyPolicy },
            { shouldDirty: true }
        );

        const defaultTranslations = getDefaultPrivacyPolicyTranslations(privacyPolicy);
        const translations = getTranslations(getValues);
        setValue(translationsRegistrationName(), { ...translations, ...defaultTranslations }, { shouldDirty: true });

        // Update language packs
        const dictionary = getDictionary(getValues);
        const newDictionary = {};
        for (const lang in dictionary) {
            newDictionary[lang] = { ...dictionary[lang], ...defaultTranslations };
        }
        if (Object.keys(newDictionary).length > 0) {
            setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });
        }
    };

    const handlePrivacyPolicyDisabled = () => {
        const formTemplate = getFormTemplate(getValues);

        setValue(
            formTemplateRegistrationName(),
            removeObjectFields(formTemplate, ['privacyPolicy']),
            { shouldDirty: true }
        );

        const localizationKeys = Object.values(formTemplate.privacyPolicy);

        // Update active translations
        const translations = getTranslations(getValues);
        setValue(translationsRegistrationName(), removeObjectFields(translations, localizationKeys), { shouldDirty: true });

        // Update language packs
        const dictionary = getDictionary(getValues);
        const newDictionary = {};
        for (const lang in dictionary) {
            newDictionary[lang] = removeObjectFields(dictionary[lang], localizationKeys);
        }
        setValue(dictionaryRegistrationName(), newDictionary, { shouldDirty: true });
    };

    const handleCaptchaEnabledChanged = async (value) => {
        dispatch(addOrUpdateForm(form.pk, {captchaEnabled: value}));
        await mutationApi.updatePublicResource(form.pk, form.pk, {captchaEnabled: value});
        setValue(
            formTemplateRegistrationName(),
            getFormTemplate(getValues),
            { shouldDirty: true }
        );
    }

    return (
        <RootBox elevation={0} {...rest}>
            <Box>
                <SwitchSection
                    spacing={0}
                    header={t("privacyPolicySettingLabel")}
                    subheader={t("privacyPolicySettingDescription")}
                    checked={Boolean(formTemplate.privacyPolicy)}
                    onChange={(e) => e.target.checked ? handlePrivacyPolicyEnabled() : handlePrivacyPolicyDisabled()}
                    LabelProps={{ variant: "subtitle2" }}
                    TypographyProps={{ variant: "body2" }}
                />
                <Collapse in={Boolean(formTemplate.privacyPolicy)}>
                    <Button
                        size="small"
                        variant="text"
                        onClick={onEditPrivacyPolicyClick}
                        sx={{ ml: -0.5 }}
                    >
                        {t("editPrivacyPolicy")}
                    </Button>
                </Collapse>
            </Box>
            <SwitchSection
                spacing={0}
                header={t("enableCaptcha")}
                subheader={t("captchaDescription")}
                checked={Boolean(form.captchaEnabled)}
                onChange={(e) => handleCaptchaEnabledChanged(e.target.checked)}
                LabelProps={{ variant: "subtitle2" }}
                TypographyProps={{ variant: "body2" }}
            />
            <Box>
                <FieldLabel label={t("languages")} variant="subtitle2" />
                <List>
                    {languages.map((lang) => (
                        <ListItem
                            disablePadding
                            key={`formSettingsLanguage-${lang}`}
                            secondaryAction={
                                <Tooltip title={t("more")}>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={(event) => handleLanguageMoreClick(event, lang)}
                                    >
                                        <MoreHorizIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        >
                            <ListItemButton
                                selected={lang === language}
                                onClick={() => onLanguageSelected(lang)}
                                sx={{ borderRadius: 0.75, mx: -2 }}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        alt={lang}
                                        src={`/static/icons/flags/${lang}.svg`}
                                        sx={{ width: 24, height: 24, border: 1, borderColor: "grey.300" }}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={t(lang)} primaryTypographyProps={{ variant: "body2" }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Button
                    size="small"
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => setNewLanguageDialogOpen(true)}
                    sx={{ ml: -0.25, "& .MuiButton-startIcon": { mr: 1.75 } }}
                >
                    {t("add")}
                </Button>
                {/*<Button*/}
                {/*    size="small"*/}
                {/*    variant="text"*/}
                {/*    startIcon={<AddIcon />}*/}
                {/*    onClick={() => setNewLanguageDialogOpen(true)}*/}
                {/*>*/}
                {/*    {t("add")}*/}
                {/*</Button>*/}
            </Box>
            <Menu
                open={Boolean(languageContextMenuAnchorEl)}
                anchorEl={languageContextMenuAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setLanguageContextMenuAnchorEl(null)}
            >
                <MenuItem disabled={languages.length === 1} onClick={handleDeleteLanguageClick}>
                    <ListItemIcon>
                        <DeleteOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>{t("delete")}</ListItemText>
                </MenuItem>
            </Menu>
            <AddFormLanguageDialog
                open={newLanguageDialogOpen}
                formKey={form.pk}
                usedLanguages={languages}
                sourceLanguage={language}
                onClose={() => setNewLanguageDialogOpen(false)}
                onTranslationCompleted={handleTranslationsCompleted}
            />
            <Snackbar
                open={translationErrorSnackbarOpen}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={"warning"} variant="filled">
                    {t("notAllLanguagesTranslated")}
                </Alert>
            </Snackbar>
        </RootBox>
    );
};

FormBuilderSettings.propTypes = {
    form: PropTypes.object.isRequired,
    formMethods: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    onLanguageChange: PropTypes.func.isRequired,
    onLanguagePacksChange: PropTypes.func.isRequired,
    onEditPrivacyPolicyClick: PropTypes.func.isRequired,
};

FormBuilderSettings.defaultProps = {};

export default FormBuilderSettings;
