import { UI_LANGUAGES } from '../constants/languages';

export function getActiveLanguage(i18n) {
  const locale = i18n.language;
  const langauge = locale.slice(0, 2); // i18n.language can be a culture ID, e.g. en-GB
  return UI_LANGUAGES.includes(langauge) ? langauge : 'en';
}

export function getLocalizedCountryName(countryCode, locale = 'en') {
  const regionNames = new Intl.DisplayNames([locale], { type: 'region' });
  return regionNames.of(countryCode.toUpperCase());
}
