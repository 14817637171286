import React from 'react';
import { Typography } from '@mui/material';

const AttributeLabel = ({children}) => {
    return (
        <Typography variant='caption' color='textSecondary' sx={{minWidth: 120}}>
            {children}
        </Typography>
    )
}

AttributeLabel.propTypes = {
}

AttributeLabel.defaultProps = {
};

export default AttributeLabel;