import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { allMembersSelector } from '../../state/members/selectors';
import ResourceAvatar from '../../components/ResourceAvatar';
import Status from '../../components/Status';
import { useFetchActivityLog } from './useFetchActivityLog';
import Loader from '../../components/Loader';
import { toObject } from '../../utils/arrayUtils';
import { allReportsSelector } from '../../state/reports/selectors';
import { allTagsSelector } from '../../state/tags/selectors';
import Menu from '@mui/material/Menu';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import DenseListItemText from '../../components/DenseListItemText';
import LogTableFooter from './LogTableFooter';
import { getReportRoute } from '../../utils/routeUtils';
import { Link as RouterLink } from 'react-router-dom';
import Tag from '../../components/Tag';
import { filterActivityLog } from './utils';
import ChatMessage from '../../components/ChatMessage';
import { allMessagesSelector } from '../../state/messages/selectors';
import { allCommentsSelector } from '../../state/comments/selectors';
import RichText from '../../components/RichText/RichText';
import Page from '../../components/Page';
import ViewContainer from '../../components/ViewContainer';
import { allWorkspacesSelector } from '../../state/workspaces/selectors';

const RootStack = styled(Stack, { name: 'ActivityLogView' })(
  ({ theme, color }) => ({}),
);

const IgnoreChildrenProp = ({ children, component }) => component;

const ActivityLogView = () => {
  const { t } = useTranslation();
  const organizationKey = useOrganizationKey();
  const allWorkspaces = useSelector(allWorkspacesSelector(organizationKey));
  const allWorkspacesByKey = toObject(allWorkspaces, 'pk');
  const members = useSelector(allMembersSelector(organizationKey));
  const membersByKey = toObject(members, 'sk');
  const reports = useSelector(allReportsSelector());
  const messages = useSelector(allMessagesSelector());
  const comments = useSelector(allCommentsSelector());
  const tags = useSelector(allTagsSelector());
  const { entries, canLoadMore, loadMore, loadAll } = useFetchActivityLog();
  const [filter, setFilter] = useState({
    workspaceKey: 'all',
    senderKey: 'all',
    reportKey: 'all',
  });
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const filteredActivityLog = filterActivityLog(entries, filter);

  const eventCellContent = (entry) => {
    const components = {};
    const values = {};
    if (entry.reportKey) {
      values.reportName = reports.find(
        (report) => report.pk === entry.reportKey,
      ).name;
      components.reportLink = (
        <RouterLink
          to={getReportRoute(
            organizationKey,
            entry.workspaceKey,
            entry.reportKey,
          )}
        />
      );
    }
    if (entry.tagKey) {
      components.tag = (
        <IgnoreChildrenProp
          component={
            <Tag
              value={tags.find((tag) => tag.sk === entry.tagKey)}
              sx={{ scale: '0.8', mt: '-0.125rem' }}
            />
          }
        />
      );
    }
    if (entry.statusKey) {
      components.status = (
        <IgnoreChildrenProp
          component={
            <Status
              workspaceKey={entry.workspaceKey}
              statusKey={entry.statusKey}
              sx={{ scale: '0.8', mt: '-0.125rem' }}
            />
          }
        />
      );
    }
    if (entry.userKey) {
      const user = membersByKey[entry.userKey];
      values.userName = user.name;
      components.userAvatar = (
        <IgnoreChildrenProp
          component={
            <ResourceAvatar
              component="span"
              variant="circular"
              name={user.name}
              picture={user.picture}
              sx={{ width: 24, height: 24, display: 'inline-flex' }}
            />
          }
        />
      );
    }
    if (entry.messageKey) {
      // TODO: PERF: replace allMessagesSelector with a selector that returns messages grouped by reportKey
      const message = messages.find((msg) => msg.sk === entry.messageKey);
      components.content = (
        <IgnoreChildrenProp
          component={<ChatMessage hideHeader message={message} />}
        />
      );
    }
    if (entry.commentKey) {
      // TODO: PERF: replace allCommentsSelector with a selector that returns comments grouped by reportKey
      const comment = comments.find((msg) => msg.sk === entry.commentKey);
      components.content = (
        <IgnoreChildrenProp
          component={<ChatMessage hideHeader message={comment} />}
        />
      );
    }
    if (entry.description) {
      components.content = (
        <IgnoreChildrenProp
          component={<RichText content={entry.description} />}
        />
      );
    }
    if (entry.role) {
      values.role = entry.role;
    }
    if (entry.role) {
      values.role = entry.role;
    }
    if (entry.email) {
      values.email = entry.email;
    }
    if (entry.value) {
      values.value = entry.value;
    }

    return (
      <Trans
        i18nKey={`${entry.event}ActivityLogEvent`}
        values={values}
        components={components}
      />
    );
  };

  // TODO: Refactor
  const handleExportClick = async () => {
    // setContextMenuAnchorEl(null);
    // setExportLoading(true);
    //
    // await loadAll();
    // const rows = [[t("date"), t("workspace"), t("user"), t("event")]];
    // for (const entry of entries) {
    //     const row = [
    //         entry.createdAt,
    //         entry.workspaceKey ? workspacesByKey[entry.workspaceKey].name : "",
    //         membersByKey[entry.senderKey].name
    //     ]
    // }
    //
    // const headers = ["date", "workspace", "user", "event"];
    // const rows = [];
    // rows.push(headers.map((header) => t(header)).join(","));
    // const messagesAsRows = filteredActivityLog
    //     .map((entry) => ({
    //         date: entry.createdAt,
    //         workspace: workspacesByKey[entry.workspaceKey].name,
    //         user: membersByKey[entry.senderKey].name,
    //         event: eventCellContent(entry),
    //     }))
    //     .map((data) => Object.values(data).join(","));
    // rows.push(...messagesAsRows);
    // const csvContent = rows.join("\n");
    //
    // const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
    // const objUrl = URL.createObjectURL(blob);
    // downloadFile(objUrl, `${t("activityLog")}.csv`, false);
    // window.URL.revokeObjectURL(objUrl);
    // setExportLoading(false);
  };

  const handleFilterChanged = (data) => {
    const newFilter = { ...filter, ...data };
    setFilter(newFilter);
    loadMore(newFilter);
  };

  const handleEndReached = () => {
    loadMore();
  };

  return (
    <Page sx={{ flex: '1 1 auto', overflow: 'hidden' }}>
      <ViewContainer sx={{ minHeight: '100%', display: 'flex' }}>
        <RootStack direction="column" spacing={4} flex="1 1 auto">
          {/*<Typography>{t("activityLogSubtitle")}</Typography>*/}
          <Stack direction="row" alignItems="center" spacing={4}>
            <FormControl
              fullWidth
              variant="outlined"
              margin="none"
              size="small"
            >
              <Select
                value={filter.workspaceKey}
                onChange={(e) =>
                  handleFilterChanged({ workspaceKey: e.target.value })
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={'all'}>
                  <Stack direction="row" alignItems="center">
                    <ListItemText>{t('allWorkspaces')}</ListItemText>
                    <ListItemAvatar sx={{ visibility: 'hidden' }}>
                      <ResourceAvatar
                        variant="rounded"
                        name="placeholder"
                        size={32}
                      />
                    </ListItemAvatar>
                  </Stack>
                </MenuItem>
                {allWorkspaces.map((workspace) => (
                  <MenuItem key={workspace.sk} value={workspace.sk}>
                    <Stack direction="row" alignItems="center">
                      <ListItemAvatar>
                        <ResourceAvatar
                          variant="rounded"
                          name={workspace.name}
                          picture={workspace.picture}
                          size={32}
                        />
                      </ListItemAvatar>
                      <ListItemText>{workspace.name}</ListItemText>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              variant="outlined"
              margin="none"
              size="small"
            >
              <Select
                value={filter.senderKey}
                onChange={(e) =>
                  handleFilterChanged({ senderKey: e.target.value })
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={'all'}>
                  <Stack direction="row" alignItems="center">
                    <ListItemText>{t('allUsers')}</ListItemText>
                    <ListItemAvatar sx={{ visibility: 'hidden' }}>
                      <ResourceAvatar
                        variant="circular"
                        name="placeholder"
                        size={32}
                      />
                    </ListItemAvatar>
                  </Stack>
                </MenuItem>
                {members.map((member) => (
                  <MenuItem key={member.sk} value={member.sk}>
                    <Stack direction="row" alignItems="center">
                      <ListItemAvatar>
                        <ResourceAvatar
                          variant="circular"
                          name={member.name}
                          picture={member.picture}
                          size={32}
                        />
                      </ListItemAvatar>
                      <ListItemText>{member.name}</ListItemText>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="none">
              <Select
                value={filter.reportKey}
                onChange={(e) =>
                  handleFilterChanged({ reportKey: e.target.value })
                }
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                sx={{
                  '& .MuiSelect-select': {
                    pb: '15px',
                  },
                }}
              >
                <MenuItem value={'all'}>{t('allReports')}</MenuItem>
                {reports.map((report) => (
                  <MenuItem key={`menuItem-${report.pk}`} value={report.pk}>
                    {report.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/*<Box flex="1 1 auto" />*/}
            {/*<Tooltip title={t("More")}>*/}
            {/*    <IconButton*/}
            {/*        size="small"*/}
            {/*        edge="end"*/}
            {/*        onClick={(event) => setContextMenuAnchorEl(event.currentTarget)}*/}
            {/*    >*/}
            {/*        <MoreHorizIcon />*/}
            {/*    </IconButton>*/}
            {/*</Tooltip>*/}
          </Stack>
          {entries ? (
            <TableVirtuoso
              overscan={50}
              endReached={handleEndReached}
              data={filteredActivityLog}
              style={{ flex: '1 1 auto' }}
              components={{
                Table: ({ children, ...rest }) => (
                  <Table
                    {...rest}
                    sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
                  >
                    {children}
                    <LogTableFooter loading={canLoadMore} />
                  </Table>
                ),
                TableHead: TableHead,
                TableRow: TableRow,
                TableBody: React.forwardRef((props, ref) => (
                  <TableBody {...props} ref={ref} />
                )),
              }}
              fixedHeaderContent={() => (
                <TableRow>
                  <TableCell
                    variant="head"
                    sx={{ width: '20%', backgroundColor: 'background.paper' }}
                  >
                    {t('date')}
                  </TableCell>
                  <TableCell
                    variant="head"
                    sx={{ width: '20%', backgroundColor: 'background.paper' }}
                  >
                    {t('workspace')}
                  </TableCell>
                  <TableCell
                    variant="head"
                    sx={{ width: '20%', backgroundColor: 'background.paper' }}
                  >
                    {t('user')}
                  </TableCell>
                  <TableCell
                    variant="head"
                    sx={{ width: '40%', backgroundColor: 'background.paper' }}
                  >
                    {t('event')}
                  </TableCell>
                </TableRow>
              )}
              itemContent={(index, entry) => (
                <>
                  <TableCell>
                    {t('dateLong', { date: new Date(entry.createdAt) })}
                  </TableCell>
                  <TableCell>
                    {entry.workspaceKey && (
                      <>
                        <Stack direction="row" alignItems="center">
                          <ListItemAvatar>
                            <ResourceAvatar
                              name={allWorkspacesByKey[entry.workspaceKey].name}
                              picture={
                                allWorkspacesByKey[entry.workspaceKey].picture
                              }
                              variant="rounded"
                              size={24}
                            />
                          </ListItemAvatar>
                          <DenseListItemText
                            primary={
                              allWorkspacesByKey[entry.workspaceKey].name
                            }
                          />
                        </Stack>
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      <ListItemAvatar>
                        <ResourceAvatar
                          name={membersByKey[entry.senderKey].name}
                          picture={membersByKey[entry.senderKey].picture}
                          variant="circular"
                          size={24}
                        />
                      </ListItemAvatar>
                      <DenseListItemText
                        primary={membersByKey[entry.senderKey].name}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>{eventCellContent(entry)}</TableCell>
                </>
              )}
            />
          ) : (
            <Loader />
          )}
          <Menu
            open={Boolean(contextMenuAnchorEl)}
            anchorEl={contextMenuAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={() => setContextMenuAnchorEl(null)}
          >
            <MenuItem onClick={handleExportClick}>
              <ListItemIcon
                sx={{
                  ml: -0.25,
                  minWidth: 40,
                }}
              >
                <SaveAltOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t('export')} />
            </MenuItem>
          </Menu>
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              flexDirection: 'column',
            }}
            open={exportLoading}
          >
            <Box mb={1}>
              <CircularProgress color="inherit" />
            </Box>
            <Typography variant="body1">{t('exportLoadingMessage')}</Typography>
          </Backdrop>
        </RootStack>
      </ViewContainer>
    </Page>
  );
};

export default ActivityLogView;
