import { isRichTextFieldSection, isTextFieldBlock } from './formBlockUtils';

export const termDays = 90;

export function isSystemMessage(message) {
  return message.systemMessage;
}

export function isEventMessage(message) {
  return Boolean(message.event);
}

// TODO: Merge richTextField and multilineTextField
export function getExcerptFromMessageContent(content) {
  const richTextSection = content.find(isRichTextFieldSection);
  if (richTextSection && richTextSection.value?.blocks) {
    return richTextSection.value.blocks[0].text.substring(0, 100);
  }

  const textSection = content.find(
    (block) => isTextFieldBlock(block) && block.value,
  );
  if (textSection && textSection.value) {
    return textSection.value.substring(0, 100);
  }

  const attachmentsSection = content.find(
    (section) => section.type === 'attachments',
  );
  if (attachmentsSection && attachmentsSection.value?.length > 0) {
    return attachmentsSection.value[0].split('/').pop();
  }

  return undefined;
}

export function isClosedStatus(status) {
  return status?.type === 'closed' ?? false;
}

export function isClosed(report) {
  if (!report) {
    return false;
  }
  // disabled was used originally to denote closed report
  return report.disabled || report.closed;
}

export function isOverdue(report) {
  return (
    !isClosed(report) && report.dueDate && new Date() > new Date(report.dueDate)
  );
}

export function isAssignedToUser(report, userKey) {
  return report?.assigned?.includes(userKey);
}
