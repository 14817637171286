import React, { useContext, useState } from 'react';
import { Alert, Box, Container, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../../utils/validationUtils';
import { ApiContext } from '../../contexts/ApiContext';
import PasswordTextField from '../../components/PasswordTextField';
import { styled } from '@mui/material/styles';
import { CognitoUser } from 'amazon-cognito-identity-js';

const RootBox = styled(Box, { name: 'NewPasswordPage' })(({ theme }) => ({}));

interface Props {
  cognitoUser: CognitoUser;
  onSubmit: (user: CognitoUser) => void;
}

const NewPasswordPage = ({ cognitoUser, onSubmit }: Props) => {
  const { authApi } = useContext(ApiContext);
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPassword(value);
    setPasswordError(!Boolean(value) ? 'passwordIsRequired' : null);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (passwordError !== null) {
      return;
    }

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    try {
      setLoading(true);
      const user = await authApi.completeNewPassword(cognitoUser, password);
      onSubmit(user);
    } catch (e: any) {
      console.error(e);
      setSubmitError('unknownLoginError');
      setLoading(false);
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs">
        <Box sx={{ textAlign: 'center' }} mb={5}>
          <Typography variant="h4" component="h1">
            {t('newPasswordFormSubtitle')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <PasswordTextField
              required
              fullWidth
              variant="outlined"
              autoComplete="new-password"
              label={t('password')}
              value={password}
              onChange={handlePasswordChange}
              error={Boolean(passwordError)}
              helperText={t(passwordError!)}
            />
            <LoadingButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default NewPasswordPage;
