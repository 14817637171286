import * as types from "./actionTypes";

export const loadForms = (forms) => async (dispatch, getState) => {
    dispatch({ type: types.FORMS_LOADED, payload: { forms } });
}

export const addOrUpdateForm = (pk, data) => async (dispatch, getState) => {
    if (!Boolean(pk)) {
        throw new Error("pk cannot be null");
    }
    dispatch({ type: types.FORM_UPDATED, payload: {pk, data} });
}

export const deleteForm = (pk) => async (dispatch, getState) => {
    dispatch({ type: types.FORM_DELETED, payload: {pk} });
}