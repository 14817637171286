import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import Page from '../../components/Page/Page';
import LoginPage from './LoginPage';
import { CognitoUser } from 'amazon-cognito-identity-js';
import SMSAuthenticationPage from './SMSAuthenticationPage';
import NewPasswordPage from './NewPasswordPage';

const LoginView = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(
    state?.cognitoUser ?? null,
  );
  const [password, setPassword] = useState<string | null>(
    state?.password ?? null,
  );
  const [challenge, setChallenge] = useState<string | null>(
    state?.cognitoUser?.challengeName ?? null,
  );

  const handleLoginSubmit = (user: CognitoUser, password: string) => {
    setPassword(password);
    resolveCognitoChallenge(user);
  };

  const resolveCognitoChallenge = (user: CognitoUser) => {
    if (
      user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'NEW_PASSWORD_REQUIRED'
    ) {
      setCognitoUser(user);
      setChallenge(user.challengeName);
      return;
    }

    navigate(state?.from ?? '/app', { replace: true });
  };

  return (
    <Page sx={{ flex: 1, alignContent: 'center' }}>
      {!challenge && <LoginPage onSubmit={handleLoginSubmit} />}
      {challenge === 'SMS_MFA' && (
        <SMSAuthenticationPage
          cognitoUser={cognitoUser!}
          password={password!}
          onSubmit={resolveCognitoChallenge}
        />
      )}
      {challenge === 'NEW_PASSWORD_REQUIRED' && (
        <NewPasswordPage
          cognitoUser={cognitoUser!}
          onSubmit={resolveCognitoChallenge}
        />
      )}
    </Page>
  );
};

export default LoginView;
