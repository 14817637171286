import React from 'react';
import { TEXT_FIELD_BORDER_RADIUS } from '../../constants';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { Box } from '@mui/material';

const RootBox = styled(Box, { name: "OutlinedDecorator" })(({ theme }) => ({
    position: 'relative',
    '&:after': {
        pointerEvents: 'none',
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`
    },
    '&:hover': {
        '&:after': {
            borderColor: theme.palette.text.primary,
        }
    },
    '&:focus-within': {
        '&:after': {
            border: `2px solid ${theme.palette.primary.main}`
        }
    },
}));

const OutlinedDecorator = ({children, ...rest}) => {
    return (
        <RootBox {...rest}>
            {children}
        </RootBox>
    )
}

OutlinedDecorator.propTypes = {
};

OutlinedDecorator.defaultProps = {
};

export default OutlinedDecorator;
