import * as types from './actionTypes';
import { toId } from '../../utils/apiUtils';
import { REPORTS_ADDED } from './actionTypes';

export const loadReports = (reports) => async (dispatch, getState) => {
  dispatch({ type: types.REPORTS_LOADED, payload: { reports } });
};

export const addOrUpdateReports = (reports) => async (dispatch, getState) => {
  dispatch({ type: types.REPORTS_ADDED, payload: { reports } });
};

export const addOrUpdateReport = (pk, data) => async (dispatch, getState) => {
  if (!Boolean(pk)) {
    throw new Error('pk cannot be null');
  }

  dispatch({ type: types.REPORT_UPDATED, payload: { pk, data } });
};

export const deleteWorkspaceReports =
  (workspaceKey) => async (dispatch, getState) => {
    dispatch({ type: types.REPORTS_DELETED, payload: { workspaceKey } });
  };
