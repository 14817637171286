import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk'
import rootReducer from "./reducer";
import {composeWithDevTools} from "redux-devtools-extension";
import { batchedSubscribe } from 'redux-batched-subscribe';
import { debounce } from 'lodash';

// TODO: Migrate to redux toolkit
// TODO: Use batch subscription notifications for better performance (https://unsplash.com/blog/react-redux-performance-considerations-when-dispatching-multiple-actions/)
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const configureStore = (state) => {
    return createStore(rootReducer, state, composedEnhancer)
}

export default configureStore;