export const emailSelector = (emailKey) => state => {
    if (!Boolean(emailKey)) {
        return null;
    }

    return state.emails[emailKey];
}

export const workspaceEmailSelector = (workspaceKey) => state => {
    if (!Boolean(workspaceKey)) {
        return null;
    }

    const emailKey = Object.keys(state?.emails).find(key => state?.emails[key].workspaceKey === workspaceKey);
    return state?.emails[emailKey];
}