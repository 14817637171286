import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
// import ReactPhoneInput from 'react-phone-input-material-ui';
import { maskPhoneNumber } from '../../utils/textUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import { ApiContext } from '../../contexts/ApiContext';
import DialogHeader from '../../components/DialogHeader/DialogHeader';
import FixedLabelTextField from '../../components/FixedLabelTextField';

const styles = (theme) => ({
  paper: {
    // width: 640,
    // maxWidth: 640
  },
  scrollPaper: {
    alignItems: 'baseline',
  },
  header: {
    padding: theme.spacing(3, 4, 0, 4),
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flex: '1 1 auto',
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const PhoneVerificationDialog = ({ open, phoneNumber, onClose, onSuccess }) => {
  const { authApi } = useContext(ApiContext);
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [inputErrorText, setInputErrorText] = useState(' ');
  const [loading, setLoading] = useState();
  const [submitErrorText, setSubmitErrorText] = useState();

  const handleCodeInputChange = (event) => {
    const value = event.target.value;
    setVerificationCode(value);
    setInputErrorText(Boolean(value) ? ' ' : t('verificationCodeRequired'));
  };

  const handleSubmit = async (event) => {
    event.persist();
    event.preventDefault();

    if (inputErrorText !== ' ') {
      return;
    }

    if (!Boolean(verificationCode)) {
      setInputErrorText(t('verificationCodeRequired'));
      return;
    }

    try {
      setLoading(true);
      const response = await authApi.verifyCurrentUserAttributeSubmit(
        'phone_number',
        verificationCode,
      );
      onSuccess();
    } catch (e) {
      setSubmitErrorText(t(e.name));
      setLoading(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogHeader showExitButton TypographyProps={{ variant: 'h3' }}>
          {t('smsVerificationPageTitle')}
        </DialogHeader>
        <DialogContent>
          <Stack spacing={4}>
            <Typography variant="body2" color="text.primary">
              {t('smsVerificationPageSubtitle', {
                phoneNumber: maskPhoneNumber(phoneNumber),
              })}
            </Typography>
            <Alert hidden={!Boolean(submitErrorText)} severity="error">
              {submitErrorText}
            </Alert>
            <FixedLabelTextField
              autoFocus
              required
              fullWidth
              label={t('verificationCode')}
              size="small"
              value={verificationCode}
              onChange={handleCodeInputChange}
              error={inputErrorText !== ' '}
              helperText={inputErrorText}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            size="large"
            variant="outlined"
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <LoadingButton
            color="primary"
            loading={loading}
            variant="contained"
            size="large"
            type="submit"
            disabled={verificationCode.length < 6}
          >
            {t('confirm')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

PhoneVerificationDialog.propTypes = {
  open: PropTypes.bool,
  phoneNumber: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

PhoneVerificationDialog.defaultProps = {
  onClose: () => {},
  onSuccess: () => {},
  open: false,
};

export default PhoneVerificationDialog;
