import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { batch, useDispatch, useSelector } from 'react-redux';
import { addOrUpdateForm } from '../state/forms/actions';
import { selectFormByPath } from '../state/forms/selectors';

export const useFetchForm = (formPath) => {
  const { queryApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const [found, setFound] = useState(true);
  const form = useSelector(selectFormByPath(formPath));
  const loaded = Boolean(form);

  useEffect(() => {
    let isMounted = true;
    if (!formPath || loaded) {
      return;
    }

    const fetchResources = async () => {
      const form = (await queryApi.listPublicResourcesByPath(formPath))[0];
      if (!form) {
        isMounted && setFound(false);
        return;
      }

      console.log('form: %o', form);

      batch(() => {
        dispatch(addOrUpdateForm(form.pk, form));
      });

      isMounted && setFound(true);
    };

    fetchResources();

    return () => {
      isMounted = false;
    };
  }, [formPath]);

  return { loaded, form, notFound: !found };
};
