import React, {useContext, useEffect, useState} from 'react';
import {
    Box,
    Avatar,
    Typography, Backdrop, CircularProgress, Tooltip, Toolbar, Link, useTheme
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';
import DueDatePicker from "../DueDatePicker/DueDatePicker";
import {
    cyan,
    green,
    grey,
    indigo,
    lightBlue,
    lime,
    purple,
    red
} from '@mui/material/colors';
import clsx from "clsx";
import IconButton from "@mui/material/IconButton";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import ReactAudioPlayer from "react-audio-player";

const AttachmentDeleteButton = ({sx = [], visible, onClick, ...rest}) => {
    return (
        <Box sx={[
            {
                p: 0.5,
                zIndex: "drawer + 2",
                color: "grey[200]",
            },
            !visible && {
                'display': 'none'
            },
            ...(Array.isArray(sx) ? sx : [sx])
        ]}
        >
            <IconButton aria-label="delete" size="small" onClick={onClick} {...rest}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </Box>
    );
};

AttachmentDeleteButton.propTypes = {
    className: PropTypes.string,
    visible: PropTypes.bool,
    onClick: PropTypes.func
}

AttachmentDeleteButton.defaultProps = {
    onClick: () => {},
    visible: true
};

export default AttachmentDeleteButton;