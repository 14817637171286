import React from 'react';
import {urlB64ToUint8Array} from "../utils/textUtils";

export const NotificationContext = React.createContext({});

export const newNotificationService = () => {
    // Check if browser supports service worker and push notifications
    return 'serviceWorker' in navigator && 'showNotification' in ServiceWorkerRegistration.prototype ? {
        isEnabled: () => isEnabled(),
        getSubscription: (callback) => getSubscription(callback),
        subscribe: (callback) => subscribe(callback),
        unsubscribe: () => unsubscribe()
    } : {
        isEnabled: () => false,
        getSubscription: (callback) => callback(null),
        subscribe: (callback) => callback(null),
        unsubscribe: () => {}
    }
}

const pushKey = "BPU8g3VMMt4ELcnAl9K83-_oWkKiL2aVyUY1EiZAcIqDzMnpHOAhPt8YvulvTnvGIyJlmONxEwhjWw-r62z1hw8";

function isEnabled() {
    return Notification.permission !== 'denied';
}

function getSubscription(callback) {
    navigator.serviceWorker.getRegistration().then(function (registration) {
        if (!registration) {
            callback(null);
            return;
        }

        registration.pushManager.getSubscription().then(callback);
    });
}

function subscribe(callback) {
    navigator.serviceWorker.ready.then(function(reg) {
        reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlB64ToUint8Array(pushKey),
        }).then(function(sub) {
            callback(sub);
        }).catch(function(error) {
            console.warn("Push subscription ended with error: %o", error);
        });
    })
}

function unsubscribe() {
    if ('unsubscribe' in PushSubscription.prototype) {
        console.log("unsubscribing push subscription")
        navigator.serviceWorker.ready.then(function(reg) {
            reg.pushManager.getSubscription().then(function(subscription) {
                subscription?.unsubscribe();
            })
        });
    }
}