import React from 'react';
import { FormLabel, FormLabelOwnProps, TypographyVariant } from '@mui/material';
import { styled } from '@mui/material/styles';

type FieldLabelRootExtraProps = {
    variant: TypographyVariant;
    component: React.ElementType;
};

const FieldLabelRoot = styled(FormLabel, {
    name: 'FieldLabel',
    shouldForwardProp: (prop) => prop !== 'variant',
})<FieldLabelRootExtraProps>(({ theme, variant }) => ({
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
    ...theme.typography[variant],
}));

interface Props extends FormLabelOwnProps {
    label: string;
    variant?: TypographyVariant;
}
const FieldLabel = ({ label, variant = 'subtitle1', ...rest }: Props) => {
    return (
        <FieldLabelRoot variant={variant} component="p" {...rest}>
            {label}
        </FieldLabelRoot>
    );
};

export default FieldLabel;
