import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Button from '@mui/material/Button';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { getFileKey } from '../../utils/fileUtils';
import AttachmentsContainer from '../AttachmentsContainer';
import { EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import * as uiUtils from '../../utils/ui';
import {
  Autocomplete,
  Box,
  Chip,
  Collapse,
  InputAdornment,
  ListItemAvatar,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import AtMentionMenu from './AtMentionMenu';
import {
  addText,
  DEFAULT_FORMATTERS,
  emptyEditorState,
  getEditorBounds,
  getLineNumber,
  insertAutocompleteSuggestionAsText,
  isEmptyState,
  moveFocusToEnd,
  replaceSelectionWithText,
  resetEditorState,
  serializeEditorState,
} from '../../utils/richTextUtils';
import { useSelector } from 'react-redux';
import { allMembersSelector } from '../../state/members/selectors';
import { styled } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import TooltipToggleButton from '../TooltipToggleButton';
import AttachmentUploader from '../AttachmentUploader';
import IconButton from '@mui/material/IconButton';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import FormattingMenu from './FormattingMenu';
import { isValidEmail } from '../../utils/textUtils';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import ResourceAvatar from '../ResourceAvatar';
import DenseListItemText from '../DenseListItemText';
import {
  newAttachmentsSection,
  newRichTextSection,
} from '../../utils/formBlockUtils';
import LocalAttachment from '../LocalAttachment';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import UrlPopover from '../UrlPopover';
import RichTextInput from '../RichTextInput';

const RootDiv = styled('div', { name: 'ChatInput' })(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: '7px',
  borderRadius: theme.shape.borderRadius,
}));

const atMentionTriggerChar = '@';

const formatterNames = [
  'bold',
  'italic',
  'underline',
  'unorderedList',
  'orderedList',
  'link',
];
const formatters = DEFAULT_FORMATTERS.filter((formatter) =>
  formatterNames.includes(formatter.name),
);

const getMentionItems = (members, searchTerm, t, workspaceKey) => {
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const filteredMembers = members.filter(
    (member) =>
      !member.disabled &&
      (!workspaceKey || !member.access || member.access.includes(workspaceKey)),
  );
  const memberItems = filteredMembers
    .filter((member) => member.name.toLowerCase().includes(lowercaseSearchTerm))
    .map((member) => ({
      key: member.sk,
      text: member.name,
      entityData: {
        type: 'user',
        organizationKey: member.pk,
        userKey: member.sk,
      },
      group: t('members'),
      component: (
        <>
          <ListItemAvatar>
            <ResourceAvatar
              name={member.name}
              picture={member.picture}
              variant="circular"
            />
          </ListItemAvatar>
          <DenseListItemText
            primary={member.name}
            secondary={member.role ? t(member.role) : undefined}
          />
        </>
      ),
    }));

  return memberItems;
};

const getMenuOffset = (editorRef, editorState, lineHeight) => {
  const paddingTop = 14; // top padding of the editor
  const paddingLeft = 12; // left padding of the editor
  const editor = editorRef.current?.editor;
  const { editorRect, selectionRect } = getEditorBounds(editor);
  const line = getLineNumber(editorState);
  const absoluteTop = selectionRect
    ? selectionRect.top
    : editorRect.top + lineHeight * line + paddingTop;
  const absoluteLeft = selectionRect
    ? selectionRect.left
    : editorRect.left + paddingLeft;
  const relativeLeft = editor.offsetLeft + (absoluteLeft - editorRect.left);
  const relativeTop = editor.offsetTop + (absoluteTop - editorRect.top);

  // move popper 4px to the top
  return {
    left: relativeLeft,
    top: -relativeTop + 4,
  };
};

const ChatInput = ({
  placeholder,
  disabled,
  disableAtMention,
  disableEmail,
  mailingList,
  onSubmit,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const members = useSelector(allMembersSelector(organizationKey)) ?? [];
  const [emailChecked, setEmailChecked] = useState(false);
  const [ccEmailAddresses, setCcEmailAddresses] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [atMentionMenuOpen, setAtMentionMenuOpen] = useState(false);
  const [atMentionMenuSearchTerm, setAtMentionMenuSearchTerm] = useState('');
  const [formattingMenuOpen, setFormattingMenuOpen] = useState(false);
  const [menuOffset, setMenuOffset] = useState();
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState(0);
  const [selectedBlock, setSelectedBlock] = useState();
  const [urlPopoverOpen, setUrlPopoverOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const editorRootRef = useRef();
  const emailFieldRef = useRef();
  const pendingEmailFieldValue = useRef('');
  const lineHeight =
    parseFloat(theme.typography.body1.fontSize.replace('rem', '')) *
    16 *
    theme.typography.body1.lineHeight;
  const atMentionItems = !disableAtMention
    ? getMentionItems(members, atMentionMenuSearchTerm, t, workspaceKey)
    : [];
  const linkDecoratorProps = {
    showPopperOnHover: true,
    editorRootRef,
    onEditorStateChange: (newEditorState, contentChanged, editingLink) => {
      setReadOnly(editingLink);
      handleValueChange(newEditorState, contentChanged);
    },
  };
  const [editorState, setEditorState] = useState(() =>
    emptyEditorState(linkDecoratorProps),
  );

  const handleSendClick = async () => {
    if (isEmptyState(editorState) && attachments.length === 0) {
      return;
    }

    const messageContent = [];
    const serializedState = serializeEditorState(editorState);
    messageContent.push(newRichTextSection(serializedState));
    if (attachments && attachments.length > 0) {
      const keys = attachments.filter((a) => Boolean(a.key)).map((a) => a.key);
      messageContent.push(newAttachmentsSection(keys));
    }

    let mentionedUsers = [];
    for (const entityId in messageContent.entityMap) {
      const entity = messageContent.entityMap[entityId];
      if (entity.type === 'MENTION' && entity.data.userKey) {
        mentionedUsers.push(entity.data.key);
      }
    }

    // Parse specified email addresses
    const pendingAddresses = pendingEmailFieldValue.current
      .split(',')
      .map((address) => address.trim());
    const validEmailAddresses = [
      ...(ccEmailAddresses ?? []),
      ...pendingAddresses,
    ].filter((address) => isValidEmail(address));
    const parsedEmailAddresses =
      validEmailAddresses.length > 0 ? validEmailAddresses : undefined;

    setEditorState(resetEditorState(editorState));
    setAttachments([]);
    setCcEmailAddresses([]);
    pendingEmailFieldValue.current = '';

    // If user clicked on the Send button while the email field had focus then the focus would be restored
    // back to the email field. By calling blur we restore focus to any element that was focused before.
    emailFieldRef.current?.blur();

    onSubmit({
      messageContent: messageContent,
      mentionedUsers: mentionedUsers,
      ccEmailAddresses: parsedEmailAddresses,
    });
  };

  const keyBindingFn = (e) => {
    if (atMentionMenuOpen || formattingMenuOpen) {
      switch (e.key) {
        case 'ArrowDown':
          return 'menu-navigate-down';
        case 'ArrowUp':
          return 'menu-navigate-up';
        case 'Enter':
          return 'menu-insert';
        case 'Escape':
          return 'menu-close';
      }
    }

    const keyBinding = getDefaultKeyBinding(e);
    if (keyBinding === 'backspace' && atMentionMenuOpen) {
      const text = editorState.getCurrentContent().getLastBlock().getText();
      if (text.length === 1 && text[0] === atMentionTriggerChar) {
        setAtMentionMenuOpen(false);
      } else {
        const newSearchTerm =
          atMentionMenuSearchTerm.length > 0
            ? atMentionMenuSearchTerm.substring(
                0,
                atMentionMenuSearchTerm.length - 1,
              )
            : '';
        setAtMentionMenuSearchTerm(newSearchTerm);
      }
    }

    return keyBinding;
  };

  const handleKeyCommand = (command, currentEditorState) => {
    const menuItems = atMentionMenuOpen
      ? atMentionItems
      : formattingMenuOpen
        ? formatters
        : undefined;
    if (!menuItems) {
      return 'not-handled';
    }

    switch (command) {
      case 'menu-insert': {
        if (atMentionMenuOpen) {
          handleAtMentionMenuItemClick(menuItems[selectedMenuItemIndex]);
        } else if (formattingMenuOpen) {
          handleFormattingMenuItemClick(menuItems[selectedMenuItemIndex]);
        }
        return 'handled';
      }
      case 'menu-close': {
        setAtMentionMenuOpen(false);
        setFormattingMenuOpen(false);
        return 'handled';
      }
      case 'menu-navigate-up': {
        if (selectedMenuItemIndex - 1 < 0) {
          setSelectedMenuItemIndex(menuItems.length - 1);
        } else {
          setSelectedMenuItemIndex(selectedMenuItemIndex - 1);
        }
        return 'handled';
      }
      case 'menu-navigate-down': {
        if (selectedMenuItemIndex + 1 >= menuItems.length) {
          setSelectedMenuItemIndex(0);
        } else {
          setSelectedMenuItemIndex(selectedMenuItemIndex + 1);
        }
        return 'handled';
      }
      default:
        return 'not-handled';
    }
  };

  const handleAttachmentAdded = (file) => {
    const attachmentId = getFileKey(file);
    const isAttached = attachments?.some(
      (attachment) => attachment.id === attachmentId,
    );
    if (!isAttached) {
      const attachment = { file: file, id: attachmentId };
      setAttachments([...(attachments ?? []), attachment]);
    }
  };

  const handleAttachmentUploaded = (attachmentId, key) => {
    const newAttachments = [...attachments];
    const index = newAttachments.findIndex(
      (attachment) => attachment.id === attachmentId,
    );
    newAttachments[index].key = key;
    setAttachments(newAttachments);
  };

  const handleAttachmentDeleted = (attachmentId) => {
    const newAttachments = [...attachments];
    const index = newAttachments.findIndex(
      (attachment) => attachment.id === attachmentId,
    );
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const preventDefault = (e) => {
    // Prevents buttons from taking focus from the rich text editor.
    e.preventDefault();
  };

  const handleMentionButtonClick = () => {
    if (atMentionMenuOpen) {
      return;
    }

    editorRootRef.current.focus();
    const selection = editorState.getSelection();
    let newEditorState = editorState;
    if (!selection.getHasFocus()) {
      newEditorState = EditorState.moveFocusToEnd(newEditorState);
    }

    if (!selection.isCollapsed()) {
      // Replace selected text with the trigger char
      newEditorState = replaceSelectionWithText(
        newEditorState,
        atMentionTriggerChar,
      );
    } else {
      // Add trigger char at the end of the current selection
      newEditorState = addText(editorState, atMentionTriggerChar);
    }

    setEditorState(newEditorState);
    openAtMentionMenu();
  };

  const openAtMentionMenu = () => {
    const selection = editorState.getSelection();
    setSelectedBlock({
      blockKey: selection.getAnchorKey(),
      blockOffset: selection.getAnchorOffset() + atMentionTriggerChar.length,
    });
    setMenuOffset(getMenuOffset(editorRootRef, editorState, lineHeight));
    setAtMentionMenuOpen(true);
  };

  const closeAtMentionMenu = () => {
    setAtMentionMenuOpen(false);
    setAtMentionMenuSearchTerm('');
    setSelectedMenuItemIndex(0);
  };
  const handleAtMentionMenuItemClick = (item) => {
    const selection = editorState.getSelection();
    const newAnchorOffset =
      selection.getAnchorOffset() -
      atMentionMenuSearchTerm.length -
      atMentionTriggerChar.length;
    const newSelection = selection.merge({
      anchorOffset: newAnchorOffset,
    });

    const newEditorState = insertAutocompleteSuggestionAsText(
      editorState,
      newSelection,
      item.text,
      item.entityData,
      true,
    );

    setEditorState(newEditorState);
    closeAtMentionMenu();
  };

  const handleFormattingButtonClick = () => {
    if (formattingMenuOpen) {
      return;
    }

    editorRootRef.current.focus();

    const selection = editorState.getSelection();
    let newEditorState = editorState;
    if (!selection.getHasFocus()) {
      newEditorState = moveFocusToEnd(editorState);
      setEditorState(newEditorState);
    }

    setMenuOffset(getMenuOffset(editorRootRef, newEditorState, lineHeight));
    setFormattingMenuOpen(true);
  };

  const handleFormatterCallback = (actionType) => {
    if (actionType === 'link' && !editorState.getSelection().isCollapsed()) {
      setUrlPopoverOpen(true);
    }
  };

  const handleFormattingMenuItemClick = (formatter) => {
    closeFormattingMenu();

    if (
      formatter.name === 'link' &&
      !editorState.getSelection().isCollapsed()
    ) {
      setUrlPopoverOpen(true);
      return;
    }

    const newEditorState =
      formatter.type === 'inline'
        ? RichUtils.toggleInlineStyle(editorState, formatter.styleName)
        : RichUtils.toggleBlockType(editorState, formatter.blockType);
    setEditorState(newEditorState);
  };

  const closeFormattingMenu = () => {
    setFormattingMenuOpen(false);
    setSelectedMenuItemIndex(0);
  };

  const handleTextInsertion = (chars, currentEditorState) => {
    if (atMentionMenuOpen) {
      setAtMentionMenuSearchTerm((prev) => prev + chars);
    } else if (chars === atMentionTriggerChar) {
      // Open the autocomplete menu when a user types the trigger char
      openAtMentionMenu();
    }
  };

  const handleValueChange = (newEditorState) => {
    if (atMentionMenuOpen) {
      const selection = newEditorState.getSelection();
      const blockKey = selection.getAnchorKey();
      const blockOffset = selection.getAnchorOffset();
      // Close the autocomplete menu if the editor selection changes
      if (
        blockKey !== selectedBlock.blockKey ||
        blockOffset < selectedBlock.blockOffset ||
        blockOffset > selectedBlock.blockOffset + atMentionMenuSearchTerm.length
      ) {
        closeAtMentionMenu();
      }
    }

    setEditorState(newEditorState);
  };

  const handleEditorBlur = () => {
    closeAtMentionMenu();
    closeFormattingMenu();
  };

  const handleEmailToggleButtonChange = () => {
    if (!emailChecked) {
      uiUtils.focusElement(emailFieldRef.current);
    }
    setEmailChecked((prev) => !prev);
  };

  return (
    <RootDiv {...rest} sx={{ pointerEvents: disabled ? 'none' : 'default' }}>
      {!disableEmail && (
        <Collapse in={emailChecked}>
          <Autocomplete
            multiple
            freeSolo
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={mailingList}
            value={ccEmailAddresses}
            filterOptions={(options, params) => {
              const filtered = options.filter(
                (address) => !ccEmailAddresses.includes(address),
              );
              // Suggest the creation of a new value
              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === option.title,
              );
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `${t('add')} "${inputValue}"`,
                });
              }

              return filtered;
            }}
            onChange={(e, value) => {
              // Add 'xxx' option will have the inputValue prop
              const parsedValues = value.map(
                (option) => option?.inputValue ?? option,
              );
              setCcEmailAddresses(parsedValues);
            }}
            getOptionLabel={(option) => {
              return option?.inputValue ?? option;
            }}
            renderOption={(props, option) => (
              <li {...props}>{option.title ?? option}</li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip size="small" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={emailFieldRef}
                placeholder={t('addEmail')}
                variant="standard"
                size="small"
                margin="normal"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">{`${t('to')}:`}</InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                sx={{
                  px: 1.5,
                }}
              />
            )}
          />
        </Collapse>
      )}
      <RichTextInput
        ref={editorRootRef}
        disabled={disabled}
        readOnly={readOnly}
        editorState={editorState}
        placeholder={placeholder}
        formatters={formatters}
        keyBindingFn={keyBindingFn}
        onChange={handleValueChange}
        onTextInsertion={handleTextInsertion}
        onKeyCommand={handleKeyCommand}
        onBlur={handleEditorBlur}
        onCallbackFormatterSelected={handleFormatterCallback}
        plugins={[
          <UrlPopover
            key={'ChatInput-UrlPopover'}
            open={urlPopoverOpen}
            editorRootRef={editorRootRef}
            editorState={editorState}
            onEditorStateChange={handleValueChange}
            onClose={() => setUrlPopoverOpen(false)}
          />,
        ]}
      />
      {attachments && attachments.length > 0 && (
        <AttachmentsContainer sx={{ px: 1.75, mb: 1 }}>
          {attachments.map((attachment) => (
            <LocalAttachment
              key={attachment.id}
              file={attachment.file}
              onUploaded={(key) => handleAttachmentUploaded(attachment.id, key)}
              onDeleted={() => handleAttachmentDeleted(attachment.id)}
            />
          ))}
        </AttachmentsContainer>
      )}
      <Stack
        direction="row"
        alignItems="center"
        p={1}
        onMouseDown={preventDefault}
      >
        {!disableAtMention && (
          <Tooltip title={t('mention')}>
            <span>
              <StyledIconButton
                size="small"
                onClick={handleMentionButtonClick}
                disabled={disabled}
              >
                <AlternateEmailIcon fontSize="small" />
              </StyledIconButton>
            </span>
          </Tooltip>
        )}
        <AttachmentUploader onFileAdded={handleAttachmentAdded}>
          <Tooltip title={t('attachFile')} placement="top">
            <span>
              <StyledIconButton
                size="small"
                component="span"
                disabled={disabled}
              >
                <AttachFileOutlinedIcon fontSize="small" />
              </StyledIconButton>
            </span>
          </Tooltip>
        </AttachmentUploader>
        <Tooltip title={t('formatting')}>
          <span>
            <StyledIconButton
              size="small"
              disabled={disabled}
              onClick={handleFormattingButtonClick}
            >
              <TextFormatIcon fontSize="small" />
            </StyledIconButton>
          </span>
        </Tooltip>
        <Box flex="1" />
        {!disableEmail && (
          <TooltipToggleButton
            title={t('sendCopyToEmail')}
            size="small"
            value="check"
            selected={emailChecked}
            disabled={disabled}
            onChange={handleEmailToggleButtonChange}
            sx={{ mr: 1, p: '7.5px' }}
          >
            <EmailIcon fontSize="small" />
          </TooltipToggleButton>
        )}
        <Button
          size="medium"
          color="primary"
          variant="contained"
          disabled={disabled}
          onClick={handleSendClick}
        >
          {t('send')}
        </Button>
      </Stack>
      {!disableAtMention && (
        <AtMentionMenu
          open={atMentionMenuOpen}
          anchorEl={editorRootRef.current?.editorContainer}
          offset={menuOffset}
          items={atMentionItems}
          selectedKey={
            atMentionMenuOpen
              ? atMentionItems[selectedMenuItemIndex]?.key
              : undefined
          }
          onClick={handleAtMentionMenuItemClick}
          onClose={closeAtMentionMenu}
        />
      )}
      <FormattingMenu
        anchorEl={editorRootRef.current?.editorContainer}
        open={formattingMenuOpen}
        offset={menuOffset}
        formatters={formatters}
        selectedKey={
          formattingMenuOpen
            ? formatters[selectedMenuItemIndex]?.name
            : undefined
        }
        onClick={handleFormattingMenuItemClick}
        onClose={closeFormattingMenu}
      />
    </RootDiv>
  );
};

ChatInput.propTypes = {
  placeholder: PropTypes.string,
  disableAtMention: PropTypes.bool,
  disableEmail: PropTypes.bool,
  mailingList: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func,
};

ChatInput.defaultProps = {
  mailingList: [],
  onSubmit: () => {},
};

export default ChatInput;
