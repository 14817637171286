import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../contexts/ApiContext';
import { getActiveLanguage } from '../../utils/localizationUtils';
import { styled } from '@mui/material/styles';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const RootBox = styled(Box, { name: 'SMSAuthenticationPage' })(
  ({ theme }) => ({}),
);

interface Props {
  cognitoUser: CognitoUser;
  password: string;
  onSubmit: (user: CognitoUser) => void;
}

const SMSAuthenticationPage = ({ cognitoUser, password, onSubmit }: Props) => {
  const { authApi } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.currentTarget.value;
    setVerificationCode(value);

    setVerificationCodeError(
      !Boolean(value) ? 'verificationCodeRequired' : null,
    );
    setSubmitError(null);
  };

  const handleResendCodeClick = async () => {
    try {
      const language = getActiveLanguage(i18n);
      await authApi.signIn(cognitoUser.getUsername(), password, language);
    } catch (e: any) {
      console.error(e);
      const errorName =
        e?.name === 'NotAuthorizedException' ||
        e?.name === 'TooManyRequestsException'
          ? e.name
          : 'unknownLoginError';
      setSubmitError(errorName);
      setLoading(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (verificationCodeError !== null) {
      return;
    }

    if (!Boolean(verificationCode)) {
      setVerificationCodeError('verificationCodeRequired');
      return;
    }

    try {
      setLoading(true);
      const user = await authApi.confirmSignIn(
        cognitoUser,
        verificationCode,
        'SMS_MFA',
      );
      onSubmit(user);
    } catch (e: any) {
      console.error(e);
      setLoading(false);

      if (
        e.name === 'CodeMismatchException' ||
        e.name === 'ExpiredCodeException'
      ) {
        setVerificationCodeError(e.name);
        return;
      } else if (e.name === 'LimitExceededException') {
        setSubmitError(e.name);
      } else {
        setSubmitError('unknownLoginError');
      }
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs">
        <Box sx={{ textAlign: 'center' }} mb={5}>
          <Typography variant="h4" component="h1">
            {t('smsVerificationPageTitle')}
          </Typography>
          <Typography variant="bodyLarge" color="textSecondary">
            {t('smsVerificationPageSubtitle', {
              phoneNumber:
                cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION ?? '',
            })}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <Box>
              <TextField
                id="sms-verification-code"
                autoFocus
                required
                fullWidth
                type="text"
                autoComplete="one-time-code"
                variant="outlined"
                label={t('verificationCode')}
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                error={Boolean(verificationCodeError)}
                helperText={t(verificationCodeError!)}
              />
              <Box sx={{ textAlign: 'end' }}>
                <Button
                  color="default"
                  size="small"
                  startIcon={<RestartAltIcon />}
                  onClick={handleResendCodeClick}
                >
                  {t('resendCode')}
                </Button>
              </Box>
            </Box>
            <LoadingButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default SMSAuthenticationPage;
