export const downloadFile = (url, downloadName, openInNewWindow = false) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = downloadName;
    if (openInNewWindow) {
        link.target = "_blank";
    }

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );

    // Remove link from body
    document.body.removeChild(link);
}