import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, MenuItem, Select, Stack, Typography } from '@mui/material';
import AvatarPicker from "../../components/AvatarPicker";
import { useDispatch, useSelector } from "react-redux";
import { ApiContext } from "../../contexts/ApiContext";
import { toId } from "../../utils/apiUtils";
import { useTranslation } from "react-i18next";
import { useAvatarPicture } from "../../hooks/useAvatarPicture";
import { LargePictureSuffix, SmallPictureSuffix } from "../../constants/storage";
import FixedLabelTextField from "../../components/FixedLabelTextField";
import { currentUserSelector } from "../../state/currentUser/selectors";
import { LANGUAGE_LABELS, UI_LANGUAGES } from "../../constants/languages";
import { I18n } from "aws-amplify";
import { loadOrUpdateCurrentUser } from "../../state/currentUser/actions";
import { addOrUpdateMember } from "../../state/members/actions";
import { memberSelector } from "../../state/members/selectors";
import { useOrganizationKey } from "../../hooks/useOrganizationKey";
import LoadingButton from "@mui/lab/LoadingButton";
import { getLargePictureVariant } from "../../utils/avatarUtils";
import { getActiveLanguage } from "../../utils/localizationUtils";

const ProfileSettingsSection = ({}) => {
    const { authApi, mutationApi, storageApi } = useContext(ApiContext);
    const { t, i18n } = useTranslation();
    const organizationKey = useOrganizationKey();
    const dispatch = useDispatch();
    const currentUser = useSelector(currentUserSelector());
    const currentUserMembership = useSelector(memberSelector(organizationKey, currentUser.pk));
    const [name, setName] = useState(currentUser.name);
    const [nameError, setNameError] = useState();
    const [language, setLanguage] = React.useState(currentUser.language ?? getActiveLanguage(i18n));
    const [pictureUrl, setPictureUrl] = useAvatarPicture(getLargePictureVariant(currentUser?.picture));
    const [selectedPictureFile, setSelectedPictureFile] = useState();
    const [uploading, setUploading] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const [changed, setChanged] = React.useState(false);
    const [saving, setSaving] = useState(false);
    const isMountedRef = useRef(true);

    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        }
    }, [])

    const handleOnAvatarSelected = (file) => {
        // Release previously created object URL if any
        if (selectedPictureFile) {
            URL.revokeObjectURL(pictureUrl);
        }
        setPictureUrl(URL.createObjectURL(file));
        setSelectedPictureFile(file);
        setChanged(true);
    };

    const handleAvatarCleared = () => {
        // Release previously created object URL if any
        if (selectedPictureFile) {
            URL.revokeObjectURL(pictureUrl);
        }
        setPictureUrl(null);
        setSelectedPictureFile(null);
        setChanged(true);
    };

    const handleNameChange = (event) => {
        const value = event.target.value;
        setNameError(value.length === 0);
        setName(value);
        setChanged(true);
    };

    const handleLanguageChange = (value) => {
        setLanguage(value);
        setChanged(true);
    };

    const handleSaveButtonClick = async () => {
        setSaving(true);

        const data = {};
        if (name !== currentUser.name) {
            data.name = name;
        }
        if (language !== currentUser.language) {
            data.language = language;
            // Change app localization
            await i18n.changeLanguage(language);
            I18n.setLanguage(language);
            await authApi.updateCurrentUserAttributes({ locale: language });
        }

        // Remove currentUser's original avatar if currentUser clicked on Remove button
        if (!pictureUrl && currentUser.picture) {
            await storageApi.deleteAvatarPictures(currentUser.picture);
            data.picture = null;
        }

        if (selectedPictureFile) {
            setUploading(true);
            // Remove currentUser's original avatar if another image was selected
            if (currentUser.picture) {
                await storageApi.deleteAvatarPictures(currentUser.picture);
            }
            const key = await storageApi.uploadProfilePicture(
                selectedPictureFile,
                toId(currentUser.pk),
                setUploadProgress
            );
            data.picture = key;
            isMountedRef.current && setUploading(false);
        }

        // Data can have zero keys, when for example name is changed and then changed
        // back to the original value. The value won't be added to the data object but
        // changed state will be true, which caused the save button to be enabled
        if (Object.keys(data).length > 0) {
            dispatch(loadOrUpdateCurrentUser(data));
            dispatch(addOrUpdateMember(currentUserMembership.pk, currentUserMembership.sk, data));
            await mutationApi.updateCurrentUser(data);
        }

        if (isMountedRef.current) {
            console.log('turning off save')
            setSaving(false);
            setChanged(false);
        }
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom>
                {t("profileDialogHeader")}
            </Typography>
            <Stack direction="row" spacing={4} alignItems="flex-start">
                <AvatarPicker
                    name={name}
                    imageUrl={pictureUrl}
                    variant="circular"
                    isUploading={uploading}
                    uploadProgress={uploadProgress}
                    onFileSelected={handleOnAvatarSelected}
                    onClear={handleAvatarCleared}
                />
                <Stack flex="1 1 auto" spacing={4}>
                    <FixedLabelTextField
                        required
                        fullWidth
                        label={t("name")}
                        variant="outlined"
                        value={name || ""}
                        error={nameError}
                        helperText={nameError ? t("nameIsRequired") : null}
                        onChange={handleNameChange}
                    />
                    <FixedLabelTextField
                        disabled
                        fullWidth
                        label={t("email")}
                        variant="outlined"
                        defaultValue={currentUser.email}
                        onChange={handleNameChange}
                    />
                    <FixedLabelTextField
                        select
                        fullWidth
                        label={t("Language")}
                        value={language}
                        onChange={(event) => handleLanguageChange(event.target.value)}
                    >
                        {UI_LANGUAGES.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                                {LANGUAGE_LABELS[lang]}
                            </MenuItem>
                        ))}
                    </FixedLabelTextField>
                    <Box sx={{ "&&": { ml: "auto" } }}>
                        <LoadingButton
                            autoFocus
                            color="primary"
                            variant="contained"
                            size="large"
                            loading={saving}
                            disabled={!changed || nameError || uploading}
                            onClick={handleSaveButtonClick}
                        >
                            {t("Save changes")}
                        </LoadingButton>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ProfileSettingsSection;
