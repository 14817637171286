import React, { useContext, useEffect, useRef } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { batch, useDispatch, useSelector } from 'react-redux';
import { addOrUpdateReport, loadReports } from '../state/reports/actions';
import {
  addOrUpdateWorkspace,
  deleteWorkspace,
  loadWorkspaces,
} from '../state/workspaces/actions';
import { addOrUpdateMessage } from '../state/messages/actions';
import { addOrUpdateComment } from '../state/comments/actions';
import {
  isComment,
  isEmail,
  isForm,
  isInvitation,
  isLanguagePack,
  isMembership,
  isMessage,
  isPolicy,
  isReport,
  isStatus,
  isTag,
  isVoicemail,
  isWorkspace,
  toId,
} from '../utils/apiUtils';
import {
  addOrUpdateMember,
  deleteMember,
  loadMembers,
} from '../state/members/actions';
import {
  addOrUpdateInvitation,
  deleteInvitation,
  loadInvitations,
} from '../state/invitations/actions';
import { addOrUpdateForm, loadForms } from '../state/forms/actions';
import { addOrUpdateLanguagePack } from '../state/languagePacks/actions';
import {
  addOrUpdateStatus,
  deleteStatus,
  loadStatuses,
} from '../state/statuses/actions';
import { currentUserSelector } from '../state/currentUser/selectors';
import { Hub } from 'aws-amplify';
import { addOrUpdateTag, deleteTag, loadTags } from '../state/tags/actions';
import { organizationSelector } from '../state/organizations/selectors';
import {
  addOrUpdateVoicemail,
  loadVoicemails,
} from '../state/voicemails/actions';
import { addOrUpdateEmail, loadEmails } from '../state/emails/actions';
import { useNavigate } from 'react-router';
import { addOrUpdateOrganization } from '../state/organizations/actions';
import { clearState } from '../state/rootActions';
import BlobCache from '../utils/BlobCache';
import { getSmallPictureVariant, isIconPicture } from '../utils/avatarUtils';
import {
  addOrUpdatePolicy,
  deletePolicy,
  loadPolicies,
} from '../state/policies/actions';

export const useOrganization = (organizationKey) => {
  const { queryApi, authApi, subscriptionApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(currentUserSelector());
  const organization = useSelector(organizationSelector(organizationKey));
  const isLoaded = Boolean(organization?.loaded);
  const subscriptionsRef = useRef();
  const currentUserId = toId(currentUser.pk);

  const fetch = async () => {
    const privateResourcesFetchTask =
      queryApi.listPrivateResourcesByOrganizationKey(organizationKey);
    const publicResourcesFetchTask =
      queryApi.listPublicResourcesByOrganizationKey(organizationKey);
    const privateResources = await privateResourcesFetchTask;
    const publicResources = await publicResourcesFetchTask;

    const avatarPictures = privateResources
      .filter(
        (resource) => resource.picture && !isIconPicture(resource.picture),
      )
      .map((resource) => getSmallPictureVariant(resource.picture));
    await BlobCache.cacheObjectsFromS3(avatarPictures);

    batch(() => {
      dispatch(addOrUpdateOrganization(organizationKey, { loaded: true }));
      dispatch(loadWorkspaces(privateResources.filter(isWorkspace)));
      dispatch(loadReports(privateResources.filter(isReport)));
      dispatch(loadMembers(privateResources.filter(isMembership)));
      dispatch(loadStatuses(privateResources.filter(isStatus)));
      dispatch(loadTags(privateResources.filter(isTag)));
      dispatch(loadInvitations(privateResources.filter(isInvitation)));
      dispatch(loadPolicies(privateResources.filter(isPolicy)));

      dispatch(loadForms(publicResources.filter(isForm)));
      dispatch(loadEmails(publicResources.filter(isEmail)));
      dispatch(loadVoicemails(publicResources.filter(isVoicemail)));
    });
  };

  const subscribe = () => {
    if (subscriptionsRef.current) {
      cancelSubscriptions();
    }

    //const filter = {group: toId(organizationKey)};
    subscriptionsRef.current = {};
    subscriptionsRef.current.privateResourceCreatedSubscription =
      subscriptionApi.onCreatePrivateResource(
        handlePrivateResourceChanged,
        undefined,
      );
    subscriptionsRef.current.privateResourceUpdatedSubscription =
      subscriptionApi.onUpdatePrivateResource(
        handlePrivateResourceChanged,
        undefined,
      );
    subscriptionsRef.current.privateResourceDeletedSubscription =
      subscriptionApi.onDeletePrivateResource(handlePrivateResourceDeleted);
    subscriptionsRef.current.publicResourceCreatedSubscription =
      subscriptionApi.onCreatePublicResource(
        handlePublicResourceChanged,
        undefined,
      );
    subscriptionsRef.current.publicResourceUpdatedSubscription =
      subscriptionApi.onUpdatePublicResource(
        handlePublicResourceChanged,
        undefined,
      );
  };

  const cancelSubscriptions = () => {
    subscriptionsRef.current.privateResourceCreatedSubscription.unsubscribe();
    subscriptionsRef.current.privateResourceUpdatedSubscription.unsubscribe();
    subscriptionsRef.current.privateResourceDeletedSubscription.unsubscribe();
    subscriptionsRef.current.publicResourceCreatedSubscription.unsubscribe();
    subscriptionsRef.current.publicResourceUpdatedSubscription.unsubscribe();
    subscriptionsRef.current = null;
  };

  const handlePrivateResourceChanged = async (resource) => {
    if (resource.updatedBy === currentUserId) {
      return;
    }

    if (isWorkspace(resource)) {
      dispatch(addOrUpdateWorkspace(resource.pk, resource));
    } else if (isReport(resource)) {
      dispatch(addOrUpdateReport(resource.pk, resource));
    } else if (isMembership(resource)) {
      dispatch(addOrUpdateMember(resource.pk, resource.sk, resource));
      if (resource.disabled && resource.sk === currentUser.pk) {
        // If the current user was deleted from an organization
        cancelSubscriptions();
        await authApi.refreshAccessToken();
        dispatch(clearState());
        navigate('/app', { replace: true });
      }
    } else if (isComment(resource)) {
      dispatch(addOrUpdateComment(resource.pk, resource.sk, resource));
    } else if (isStatus(resource)) {
      dispatch(addOrUpdateStatus(resource.pk, resource.sk, resource));
    } else if (isTag(resource)) {
      dispatch(addOrUpdateTag(resource.pk, resource.sk, resource));
    } else if (isInvitation(resource)) {
      dispatch(addOrUpdateInvitation(resource.pk, resource.sk, resource));
    } else if (isPolicy(resource)) {
      dispatch(addOrUpdatePolicy(resource.pk, resource.sk, resource));
    }
  };

  const handlePrivateResourceDeleted = async (resource) => {
    if (isWorkspace(resource)) {
      dispatch(deleteWorkspace(resource.pk));
    } else if (isMembership(resource)) {
      dispatch(deleteMember(resource.pk, resource.sk));
    } else if (isStatus(resource)) {
      dispatch(deleteStatus(resource.pk, resource.sk));
    } else if (isTag(resource)) {
      dispatch(deleteTag(resource.pk, resource.sk));
    } else if (isInvitation(resource)) {
      dispatch(deleteInvitation(resource.pk, resource.sk));
    } else if (isPolicy(resource)) {
      dispatch(deletePolicy(resource.pk, resource.sk));
    }
  };

  const handlePublicResourceChanged = async (resource) => {
    if (resource.updatedBy === currentUserId) {
      return;
    }

    if (isMessage(resource)) {
      dispatch(addOrUpdateMessage(resource.pk, resource.sk, resource));
    } else if (isForm(resource)) {
      dispatch(addOrUpdateForm(resource.pk, resource));
    } else if (isLanguagePack(resource)) {
      dispatch(addOrUpdateLanguagePack(resource.pk, resource.sk, resource));
    } else if (isEmail(resource)) {
      dispatch(addOrUpdateEmail(resource.pk, resource));
    } else if (isVoicemail(resource)) {
      dispatch(addOrUpdateVoicemail(resource.pk, resource));
    }
  };

  useEffect(() => {
    if (isLoaded || !organizationKey) {
      return;
    }

    fetch();
    subscribe();
  }, [isLoaded]);

  // Cancel all subscriptions on sign out
  useEffect(() => {
    Hub.listen('auth', (data) => {
      // Sometimes Hub fires singOut event at signIn as well. In that case subscriptionsRef.current is already
      // null and unsubscribe calls in the cancelWorkspaceSubscriptions() function would throw and error.
      if (data.payload.event === 'signOut' && subscriptionsRef.current) {
        cancelSubscriptions();
      }
    });
  }, []);

  return {
    loaded: isLoaded,
    organization: organization,
    subscriptions: subscriptionsRef.current,
  };
};
