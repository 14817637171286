import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Stack } from '@mui/material';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { workspaceFormSelector } from '../../state/forms/selectors';
import { Navigate } from 'react-router-dom';
import { toLanguagePackKey } from '../../utils/apiUtils';
import FormEditorToolbar from './FormEditorToolbar';
import {
  getAllLanguagePacksLanguages,
  getBlockLocalizationKeys,
  getLanguagePackByLanguage,
  getLanguagePackLanguage,
  groupLanguagePackContentsByLanguage,
  newFormPageTemplate,
} from '../../utils/formUtils';
import { ApiContext } from '../../contexts/ApiContext';
import Loader from '../../components/Loader';
import FormBuilderSettings from './FormBuilderSettings';
import {
  addOrUpdateLanguagePack,
  deleteLanguagePack,
} from '../../state/languagePacks/actions';
import { addOrUpdateForm } from '../../state/forms/actions';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import FormBuilder from './FormBuilder';
import { FormProvider, useForm } from 'react-hook-form';
import { removeObjectFields } from '../../utils/objectUtils';
import {
  getDictionary,
  getFormTemplate,
  getPageTemplate,
  getTranslations,
} from '../../components/FormBlock/utils';
import FormPageList from './FormPageList';
import { insertItem, removeItemAtIndex } from '../../utils/arrayUtils';
import { useFetchLanguagePacks } from '../../hooks/useFetchLanguagePacks';
import { useLocation } from 'react-router';

const FormSettingsView = () => {
  // const theme = useTheme();
  // const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { mutationApi } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const workspaceKey = useWorkspaceKey();
  const form = useSelector(workspaceFormSelector(workspaceKey));
  const { languagePacks, loaded } = useFetchLanguagePacks(form?.pk); // Fetch language packs
  const [mode, setMode] = useState('edit');
  const [language, setLanguage] = useState();
  const languageRef = useRef();
  const [pageIndex, setPageIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const [settingsExpanded, setSettingsExpanded] = useState(true);
  const timerRef = useRef();
  const dirtyFieldsRef = useRef({});
  const watchSubscriptionRef = useRef();
  const savedAtRef = useRef();
  const [focusRelevantPersonsBlock, setFocusRelevantPersonsBlock] = useState(
    state?.focusRelevantPersons,
  );
  const [focusPrivacyPolicyBlock, setFocusPrivacyPolicyBlock] = useState(false);
  const [pageRendered, setPageRendered] = useState(false);
  const formResetRef = useRef();
  const formMethods = useForm({
    defaultValues: {
      pageTemplate: {},
      translations: {},
      dictionary: {},
      formTemplate: {},
    },
  });

  useEffect(() => {
    if (!pageRendered && loaded && language) {
      setPageRendered(true);
    }
  }, [pageRendered, loaded, language]);

  useEffect(() => {
    if (!focusRelevantPersonsBlock || !pageRendered) {
      return;
    }

    const elements = document.getElementsByClassName(
      'public-DraftEditor-content',
    );

    elements[0].focus();
    setFocusRelevantPersonsBlock(false);
  }, [focusRelevantPersonsBlock, pageRendered]);

  useEffect(() => {
    if (!focusPrivacyPolicyBlock || !pageRendered) {
      return;
    }

    const elements = document.getElementsByClassName(
      'public-DraftEditor-content',
    );
    elements[elements.length - 1].focus();
    setFocusPrivacyPolicyBlock(false);
  }, [focusPrivacyPolicyBlock, pageRendered]);

  useEffect(() => {
    if (loaded) {
      const languages = languagePacks.map((item) =>
        getLanguagePackLanguage(item),
      );
      const activeLanguage = languages.includes(i18n.language)
        ? i18n.language
        : languages[0];
      const activeLanguagePack = getLanguagePackByLanguage(
        languagePacks,
        activeLanguage,
      );

      resetFormWithoutTriggeringAutosave({
        pageTemplate: form.template.pages[pageIndex],
        translations: activeLanguagePack.content,
        dictionary: groupLanguagePackContentsByLanguage(
          languagePacks,
          activeLanguage,
        ),
        formTemplate: form.template,
      });

      savedAtRef.current = new Date(
        Math.max(
          new Date(form.updatedAt),
          new Date(activeLanguagePack.updatedAt),
        ),
      );
      languageRef.current = activeLanguage;
      setLanguage(activeLanguage);

      // Subscribe to form changes
      watchSubscriptionRef.current = formMethods.watch(handleFormValueChange);
      return () => watchSubscriptionRef.current.unsubscribe();
    }
  }, [loaded]);

  const resetFormWithoutTriggeringAutosave = (values, options = undefined) => {
    formResetRef.current = true;
    formMethods.reset(values, options);
  };

  const handleFormValueChange = (value, { name, type }) => {
    if (!name) {
      return;
    }

    if (
      formResetRef.current &&
      (name === 'formTemplate.pages' || name === 'pageTemplate.blocks')
    ) {
      // This function is invoked as a subscription handler every time a form reset function is called.
      // Changing the active page or language also uses form reset, which would then subsequently trigger
      // this subscription handler. In these cases we don't want to trigger the autosave since the content of the
      // form has not actually changed. We ignore 'formTemplate.pages' and 'pageTemplate.blocks' events that are
      // invoked after calling form reset due to language or page change. 'pageTemplate.blocks' is called after
      // the 'formTemplate.pages' event, so when its invoked we reset the formResetRef flag so that all further
      // changes will trigger autosave.
      if (name === 'pageTemplate.blocks') {
        formResetRef.current = false;
      }
      return;
    }

    const rootName = name.split('.')[0];
    //setObjectProperty(dirtyFieldsRef.current, name.split(".").slice(0, 2).join("."), true);
    dirtyFieldsRef.current[rootName] = true;

    if (!timerRef.current) {
      timerRef.current = setTimeout(() => submitChanges(), 2000);
    }
  };

  const submitChanges = async (
    forceFormSubmit = false,
    forceLanguagePacksSubmit = false,
  ) => {
    clearTimeout(timerRef.current);
    if (Object.keys(dirtyFieldsRef.current).length === 0 && !forceFormSubmit) {
      timerRef.current = null;
      return;
    }

    triggerSavingIndicator();
    const tasks = [];
    batch(() => {
      if (
        dirtyFieldsRef.current.pageTemplate ||
        dirtyFieldsRef.current.formTemplate ||
        forceFormSubmit
      ) {
        const newFormTemplate = { ...getFormTemplate(formMethods.getValues) };
        const pageTemplate = getPageTemplate(formMethods.getValues);
        const index = newFormTemplate.pages.findIndex(
          (page) => page.key === pageTemplate.key,
        );
        if (index >= 0) {
          // Index can be -1 if the page was deleted from the form template
          newFormTemplate.pages[index] = pageTemplate;
        }
        dispatch(addOrUpdateForm(form.pk, { template: newFormTemplate }));
        tasks.push(
          mutationApi.updatePublicResource(form.pk, form.sk, {
            template: newFormTemplate,
          }),
        );
      }

      if (dirtyFieldsRef.current.translations || forceLanguagePacksSubmit) {
        const translations = getTranslations(formMethods.getValues);
        const languagePackKey = toLanguagePackKey(languageRef.current);
        dispatch(
          addOrUpdateLanguagePack(form.pk, languagePackKey, {
            content: translations,
          }),
        );
        tasks.push(
          mutationApi.updatePublicResource(form.pk, languagePackKey, {
            content: translations,
          }),
        );
      }

      if (dirtyFieldsRef.current.dictionary || forceLanguagePacksSubmit) {
        // Update all language packs if a new block was added/deleted or an option was deleted
        const dictionary = getDictionary(formMethods.getValues);
        for (const lang in dictionary) {
          const translations = dictionary[lang];
          const languagePackKey = toLanguagePackKey(lang);
          dispatch(
            addOrUpdateLanguagePack(form.pk, languagePackKey, {
              content: translations,
            }),
          );
          tasks.push(
            mutationApi.updatePublicResource(form.pk, languagePackKey, {
              content: translations,
            }),
          );
        }
      }
    });

    //await Promise.all(tasks);
    savedAtRef.current = new Date(Date.now());
    dirtyFieldsRef.current = {};
    timerRef.current = null;
  };

  const triggerSavingIndicator = (value) => {
    if (value && saving) {
      return;
    }

    setSaving(true);
    setTimeout(() => setSaving(false), 1000);
  };

  const handleLanguageChange = async (newLanguage) => {
    submitChanges();

    const dictionary = getDictionary(formMethods.getValues);
    const newDictionary = removeObjectFields(dictionary, [newLanguage]);
    newDictionary[languageRef.current] = getTranslations(formMethods.getValues);
    resetFormWithoutTriggeringAutosave({
      pageTemplate: getPageTemplate(formMethods.getValues),
      translations: dictionary[newLanguage],
      dictionary: newDictionary,
      formTemplate: getFormTemplate(formMethods.getValues),
    });

    languageRef.current = newLanguage;
    setLanguage(newLanguage);
  };

  const handleLanguagePacksChange = ({ added, removed }) => {
    batch(() => {
      for (const languagePack of added ?? []) {
        dispatch(
          addOrUpdateLanguagePack(
            languagePack.pk,
            languagePack.sk,
            languagePack,
          ),
        );
      }
      for (const languagePack of removed ?? []) {
        dispatch(deleteLanguagePack(languagePack.pk, languagePack.sk));
        mutationApi.deletePublicResource(languagePack.pk, languagePack.sk);
      }
    });

    // Update form state
    const newFormDictionary = removeObjectFields(
      getDictionary(formMethods.getValues),
      getAllLanguagePacksLanguages(removed),
    );
    for (const languagePack of added ?? []) {
      newFormDictionary[getLanguagePackLanguage(languagePack)] =
        languagePack.content;
    }
    resetFormWithoutTriggeringAutosave(
      {
        pageTemplate: getPageTemplate(formMethods.getValues),
        translations: getTranslations(formMethods.getValues),
        dictionary: newFormDictionary,
        formTemplate: getFormTemplate(formMethods.getValues),
      },
      { keepDirtyValues: true },
    );
  };

  const handlePageChange = (newPageIndex) => {
    submitChanges();

    const formTemplate = getFormTemplate(formMethods.getValues);
    resetFormWithoutTriggeringAutosave({
      pageTemplate: formTemplate.pages[newPageIndex],
      translations: getTranslations(formMethods.getValues),
      dictionary: getDictionary(formMethods.getValues),
      formTemplate: getFormTemplate(formMethods.getValues),
    });

    setPageIndex(newPageIndex);
    setPageRendered(false);
  };

  const handlePageAdd = async () => {
    const newPage = newFormPageTemplate();
    const formTemplate = getFormTemplate(formMethods.getValues);
    const newFormTemplate = { ...formTemplate };
    newFormTemplate.pages[pageIndex] = getPageTemplate(formMethods.getValues);
    newFormTemplate.pages = insertItem(
      formTemplate.pages,
      formTemplate.pages.length - 1,
      newPage,
    );

    formMethods.reset({
      pageTemplate: newPage,
      translations: getTranslations(formMethods.getValues),
      dictionary: getDictionary(formMethods.getValues),
      formTemplate: newFormTemplate,
    });
    submitChanges(true);
    setPageIndex(newFormTemplate.pages.length - 2);
  };

  const handlePageDelete = async (index) => {
    // Update form template
    const formTemplate = getFormTemplate(formMethods.getValues);
    const newFormTemplate = { ...formTemplate };
    newFormTemplate.pages[pageIndex] = getPageTemplate(formMethods.getValues);
    newFormTemplate.pages = removeItemAtIndex(newFormTemplate.pages, index);

    const deletedPage =
      index === pageIndex
        ? getPageTemplate(formMethods.getValues)
        : formTemplate.pages[index];
    const deletedLocalizationKeys = deletedPage.blocks
      .map((block) => getBlockLocalizationKeys(block))
      .flat();
    const translations = getTranslations(formMethods.getValues);
    const newTranslations = removeObjectFields(
      translations,
      deletedLocalizationKeys,
    );

    const dictionary = getDictionary(formMethods.getValues);
    const newDictionary = {};
    for (const lang in dictionary) {
      newDictionary[lang] = removeObjectFields(
        dictionary[lang],
        deletedLocalizationKeys,
      );
    }

    // If the page removed was not the current page, keep the current page in the form state
    const newPageTemplate =
      index === pageIndex
        ? newFormTemplate.pages[Math.max(pageIndex - 1, 0)]
        : getPageTemplate(formMethods.getValues);
    formMethods.reset({
      pageTemplate: newPageTemplate,
      translations: newTranslations,
      dictionary: newDictionary,
      formTemplate: newFormTemplate,
    });

    submitChanges(true, true);

    if (index <= pageIndex) {
      setPageIndex((prev) => Math.max(prev - 1, 0));
    }
  };

  const handleEditPrivacyPolicyClick = () => {
    const formTemplate = getFormTemplate(formMethods.getValues);
    if (pageIndex !== formTemplate.pages.length - 2) {
      handlePageChange(formTemplate.pages.length - 2);
    }

    //setBlockFocusContextValue({focusPrivacyPolicy: true});

    setFocusPrivacyPolicyBlock(true);
  };

  if (!loaded || !language) {
    return <Loader />;
  }

  if (!form) {
    return <Navigate to="/404" />;
  }

  return (
    <FormProvider {...formMethods}>
      <Stack
        direction="row"
        alignItems="stretch"
        sx={{ flex: '1 1 auto', overflow: 'hidden' }}
      >
        <FormPageList
          pageIndex={pageIndex}
          onPageChange={handlePageChange}
          onPageAdd={handlePageAdd}
          onPageDelete={handlePageDelete}
          sx={{
            minWidth: 280,
            maxWidth: 320,
            borderRight: 1,
            borderColor: 'divider',
            flex: '1 1 auto',
          }}
        />
        <Box
          sx={{
            overflow: 'auto',
            height: '100%',
            flex: '1 0 auto',
            minWidth: 600,
            backgroundColor: 'grey.50',
            position: 'relative',
          }}
        >
          <FormEditorToolbar
            lastSavedAt={savedAtRef.current}
            isSaving={saving}
            mode={mode}
            onModeChange={setMode}
            onSettingsButtonClick={() => setSettingsExpanded((prev) => !prev)}
          />
          <FormBuilder
            language={language}
            pageIndex={pageIndex}
            pageCount={form.template.pages.length}
            mode={mode}
          />
        </Box>
        <Collapse in={settingsExpanded} orientation="horizontal">
          <FormBuilderSettings
            form={form}
            formMethods={formMethods}
            language={language}
            onLanguageChange={handleLanguageChange}
            onLanguagePacksChange={handleLanguagePacksChange}
            onEditPrivacyPolicyClick={handleEditPrivacyPolicyClick}
            sx={{
              maxWidth: 320,
              borderLeft: 1,
              borderColor: 'divider',
            }}
          />
        </Collapse>
      </Stack>
    </FormProvider>
  );
};

export default FormSettingsView;
