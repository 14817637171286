import * as handlebars from 'handlebars';
import { s8 } from './idGenerator';
import cloneDeep from 'lodash/cloneDeep';
import {
  deminifyDraftEditorState,
  minifyDraftEditorState,
} from './draftJsUtils';
import { removeItemAtIndex } from './arrayUtils';
import { toId, toLanguagePackKey } from './apiUtils';
import { isoTime } from './time';
import { normalizeId } from './textFormatter';

export function getFormUrl(form, whitelabel) {
  if (form.path) {
    return form.path;
  }

  const baseUrl = whitelabel?.appUrl || 'https://www.safetalk.io';
  return form ? `${baseUrl}/f/${normalizeId(toId(form.pk))}` : undefined;
}

export function hydrateLanguagePacks(languagePacks, variables = {}) {
  const hydrateFunc = handlebars.compile(JSON.stringify(languagePacks));
  const hydrated = hydrateFunc(variables);
  return JSON.parse(hydrated);
}

export const groupLanguagePackContentsByLanguage = (
  languagePacks,
  exceptLanguages = [],
) => {
  const grouped = {};
  for (const languagePack of languagePacks) {
    const lang = getLanguagePackLanguage(languagePack);
    if (exceptLanguages.includes(lang)) {
      continue;
    }
    grouped[lang] = languagePack.content;
  }
  return grouped;
};

export function getBlockLocalizationKeys(block) {
  let localizationKeys = [];
  for (const key in block) {
    if (key === 'key' || key === 'type' || key === 'required') {
      continue;
    }

    if (Array.isArray(block[key])) {
      localizationKeys = localizationKeys.concat(block[key]);
    } else if (block[key].length === 8) {
      // Skip any values that are not s8 IDs. This is just a safeguard, so that the function would return only
      // valid localization keys. For example someone may add a new field into a block template and
      // forgets to add the field name to the key check above.
      localizationKeys.push(block[key]);
    }
  }

  return localizationKeys;
}

export const getFormPageVariant = (pageIndex, pagesCount) => {
  if (pageIndex === 0) {
    return 'firstPage';
  } else if (pageIndex === pagesCount - 2) {
    return 'submitPage';
  } else if (pageIndex === pagesCount - 1) {
    return 'confirmationPage';
  } else {
    return 'other';
  }
};

export function newFormPageTemplate() {
  return {
    key: s8(),
    blocks: [],
  };
}

export function getLanguagePackLanguage(languagePack) {
  return toId(languagePack.sk);
}

export function getAllLanguagePacksLanguages(languagePacks) {
  if (!languagePacks) {
    return [];
  }

  return languagePacks.map((pack) => getLanguagePackLanguage(pack));
}

export function getLanguagePackByLanguage(languagePacks, language) {
  const secondaryKey = toLanguagePackKey(language);
  return languagePacks.find((pack) => pack.sk === secondaryKey);
}

export function findBlock(form, blockKey) {
  if (!form.template?.pages) {
    return { pageIndex: null, blockIndex: null };
  }

  for (let pageIndex = 0; pageIndex < form.template.pages.length; pageIndex++) {
    const page = form.template.pages[pageIndex];
    for (let blockIndex = 0; blockIndex < page.blocks.length; blockIndex++) {
      const block = page.blocks[blockIndex];
      if (block.key === blockKey) {
        return { pageIndex, blockIndex };
      }
    }
  }

  return { pageIndex: null, blockIndex: null };
}
