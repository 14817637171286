import React, { useContext, useEffect, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { Box, Skeleton } from '@mui/material';
import BlobCache from '../../utils/BlobCache';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import { BoxProps } from '@mui/material/Box/Box';

const defaultLogoUrl = '/static/images/logo.png';

type Size = 'small' | 'medium' | 'large';

const heights = {
    small: 16,
    medium: 24,
    large: 40,
};

const LogoRoot = styled(Box, { name: 'Logo' })(({ theme }) => ({
    marginRight: theme.spacing(0.75),
}));

const StyledImage = styled('img', { shouldForwardProp: (prop) => prop !== 'size' })(
    ({ theme, size }: { theme?: Theme; size: Size }) => ({
        height: heights[size],
    }),
);

const StyledSkeleton = styled(Skeleton, { shouldForwardProp: (prop) => prop !== 'size' })(
    ({ theme, size }: { theme?: Theme; size: Size }) => ({
        height: heights[size],
    }),
);

interface Props extends BoxProps {
    name?: string;
    avatarSrc?: string;
    size?: Size;
}
const Logo = ({
    name = 'SafeTalk',
    avatarSrc = '/static/images/logo_transparent.png',
    size = 'medium',
    ...rest
}: Props) => {
    const [logoUrl, setLogoUrl] = useState();
    const whitelabel = useContext(WhitelabelContext);

    useEffect(() => {
        if (!whitelabel?.picture) {
            return;
        }

        (async () => {
            const url = (await BlobCache.cacheObjectsFromS3([whitelabel.picture]))[0];
            setLogoUrl(url);
        })();
    }, [whitelabel]);

    return (
        <LogoRoot {...rest}>
            {!whitelabel?.picture ? (
                <StyledImage src={defaultLogoUrl} size={size} />
            ) : logoUrl ? (
                <StyledImage src={logoUrl} size={size} />
            ) : (
                <StyledSkeleton variant="rectangular" size={size} />
            )}
        </LogoRoot>
    );
};

export default Logo;
