import { toId } from '../../utils/apiUtils';

export const allReportsSelector = () => (state) => Object.values(state.reports);

export const reportSelector = (pk) => (state) =>
  pk ? state.reports[pk] : null;

export const activeReportsSelector = () => (state) =>
  Object.values(state.reports).filter((report) => !report.isDeleted);

export const activeWorkspaceReportsSelector =
  (organizationKey, workspaceKey) => (state) => {
    if (!state.currentUser || !state.members[organizationKey]) {
      return [];
    }

    const workspaceReports = [];
    for (const [key, value] of Object.entries(state.reports)) {
      if (value.workspaceKey === workspaceKey && !value.isDeleted) {
        workspaceReports.push(value);
      }
    }

    const membership = state.members[organizationKey].find(
      (t) => t.sk === state.currentUser.pk,
    );
    if (membership.role === 'admin' || membership.role === 'manager') {
      return workspaceReports;
    }

    // Return only approved reports or reports awaiting approval
    return workspaceReports.filter((t) =>
      t.assigned?.includes(state.currentUser.pk),
    );
  };
