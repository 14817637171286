import React, { useState } from "react";
import {
    Avatar,
    Box,
    Container,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FormBuilderPage from "./FormBuilderPage";
import { useSelector } from "react-redux";
import { useWorkspaceKey } from "../../hooks/useWorkspaceKey";
import { workspaceSelector } from "../../state/workspaces/selectors";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { formSelector } from "../../state/forms/selectors";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTranslation } from "react-i18next";
import FieldLabel from "../../components/FieldLabel";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
    blocksRegistrationName,
    formTemplateRegistrationName,
    getFormTemplate,
} from "../../components/FormBlock/utils";

const RootBox = styled(Box, { name: "FormPageList" })(({ theme }) => ({
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
}));

const FormPageList = ({ pageIndex, onPageChange, onPageAdd, onPageDelete, ...rest }) => {
    const { t } = useTranslation();
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState();
    const [contextMenuPageIndex, setContextMenuPageIndex] = useState();
    const { control, getValues } = useFormContext();
    const { fields } = useFieldArray({
        control,
        name: `${formTemplateRegistrationName()}.pages`,
    });
    const formTemplate = getFormTemplate(getValues);

    const handleDeleteClick = () => {
        setContextMenuAnchorEl(null);
        onPageDelete(contextMenuPageIndex);
    };

    return (
        <RootBox {...rest}>
            <FieldLabel label={t("pages")} />
            <List disablePadding>
                {fields.slice(0, -1).map((page, index) => (
                    <ListItem
                        disablePadding
                        key={`pageList-${page.key}`}
                        secondaryAction={
                            <Tooltip title={t("more")}>
                                <IconButton
                                    size="small"
                                    edge="end"
                                    onClick={(e) => {
                                        setContextMenuAnchorEl(e.currentTarget);
                                        setContextMenuPageIndex(index);
                                    }}
                                >
                                    <MoreHorizIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <ListItemButton
                            selected={index === pageIndex}
                            onClick={() => onPageChange(index)}
                            sx={{ borderRadius: 0.75, mx: -2 }}
                        >
                            <ListItemIcon>
                                <DescriptionOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={`${t("page")} ${index + 1}`}
                                secondary={
                                    Boolean(formTemplate.privacyPolicy) && index === fields.length - 2
                                        ? t("privacyPolicy")
                                        : undefined
                                }
                                secondaryTypographyProps={{sx: {mr: 1}}}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem disablePadding>
                    <Button
                        size="medium"
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={onPageAdd}
                        sx={{ my: 1, ml: -0.25, "& .MuiButton-startIcon": { mr: 1.75 } }}
                    >
                        {t("addPage")}
                    </Button>
                    {/*<ListItemButton sx={{ borderRadius: 0.75, mx: -2, color: "primary.main" }} onClick={onPageAdd}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <AddIcon color="primary" fontSize="small" />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText*/}
                    {/*        primary={t("addPage")}*/}
                    {/*        sx={{ "& .MuiListItemText-primary": { typography: "buttonSmall", color: "inherit" } }}*/}
                    {/*    />*/}
                    {/*</ListItemButton>*/}
                </ListItem>
                <Divider component="li" />
                <ListItem component="div" disablePadding>
                    <ListItemButton
                        selected={pageIndex === fields.length - 1}
                        onClick={() => onPageChange(fields.length - 1)}
                        sx={{ borderRadius: 0.75, mx: -2 }}
                    >
                        <ListItemIcon>
                            <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("confirmationPage")} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Menu
                open={Boolean(contextMenuAnchorEl)}
                anchorEl={contextMenuAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setContextMenuAnchorEl(null)}
            >
                <MenuItem onClick={handleDeleteClick} disabled={fields.length <= 2}>
                    <ListItemIcon>
                        <DeleteOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t("delete")}</ListItemText>
                </MenuItem>
            </Menu>
        </RootBox>
    );
};

FormPageList.propTypes = {
    pageIndex: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageAdd: PropTypes.func.isRequired,
    onPageDelete: PropTypes.func.isRequired,
};

FormPageList.defaultProps = {};

export default FormPageList;
