import {
    Divider,
    FormControl,
    InputLabel,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        whiteSpace: "normal",
        maxWidth: theme.spacing(75)
    },
}));

const UserRoleSelect = ({className, value, onChange, SelectProps, ...rest}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <FormControl variant="standard" className={className} {...rest}>
            <Select
                variant="standard"
                labelId="role-select-label"
                value={value}
                renderValue={(val) => t(val)}
                onChange={(event) => onChange(event.target.value)}
                MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" }
                }}
                {...SelectProps}
            >
                <MenuItem className={classes.menuItem} value="observer">
                    <ListItemText
                        primary={t("observer")}
                        secondary={t("observerHint")}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem className={classes.menuItem} value="solver">
                    <ListItemText
                        primary={t("solver")}
                        secondary={t("solverHint")}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem className={classes.menuItem} value="manager">
                    <ListItemText
                        primary={t("manager")}
                        secondary={t("managerHint")}
                    />
                </MenuItem>
                <Divider/>
                <MenuItem className={classes.menuItem} value="admin">
                    <ListItemText
                        primary={t("admin")}
                        secondary={t("adminHint")}
                    />
                </MenuItem>
            </Select>
        </FormControl>
    );
}

UserRoleSelect.propTypes = {
    className: PropTypes.string,
    value: PropTypes.oneOf(["admin", "manager", "solver", "observer"]).isRequired,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    hiddenLabel: PropTypes.bool,
    disableUnderline: PropTypes.bool
}

UserRoleSelect.defaultProps = {
    onChange: () => {}
};

export default UserRoleSelect;