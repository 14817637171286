import * as types from "./actionTypes";
import { groupBy, stableSort } from '../../utils/arrayUtils';
import { toId } from '../../utils/apiUtils';

const initialState = {};

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case types.MESSAGES_LOADED: {
            const messages = action.payload.messages;
            const uniquePartitionKeys = [...new Set(messages.map(item => item.pk))];
            const newState = {...state};
            for (const partitionKey of uniquePartitionKeys) {
                const filtered =  messages.filter(item => item.pk === partitionKey);
                const sorted = stableSort(filtered, 'asc', "createdAt");
                newState[partitionKey] = sorted;
            }

            return newState;
        }
        case types.MESSAGES_ADDED: {
            //const reportKey = action.payload.reportKey;
            const { messages } = action.payload;
            const groupedMessages = groupBy(messages, "pk");
            const newState = {...state};
            for (const reportKey in groupedMessages) {
                const existingMessages = (newState[reportKey] ?? []);
                const uniqueMessages = groupedMessages[reportKey].filter(message => !existingMessages.some(existing => existing.sk === message.sk));
                const newMessages = [...existingMessages, ...uniqueMessages];
                const sorted = stableSort(newMessages, 'asc', "createdAt");
                newState[reportKey] = sorted;
            }

            return newState;
        }
        case types.MESSAGE_UPDATED: {
            const pk = action.payload.pk;
            const sk = action.payload.sk;
            const newStateMessages = [...(state[pk] ?? [])];
            const index = newStateMessages.findIndex(item => item.sk === sk);
            if (index < 0) {
                newStateMessages.push({...action.payload.data, pk: pk, sk: sk});
            } else {
                newStateMessages[index] = {...newStateMessages[index], ...action.payload.data};
            }

            const newState = {...state};
            newState[pk] = newStateMessages;
            return newState;
        }
        case types.MESSAGES_DELETED: {
            const workspaceId = toId(action.payload.workspaceKey);
            const keysToDelete = [];
            for (const pk in state) {
                const message = state[pk][0];
                if (message.group === workspaceId) {
                    keysToDelete.push(pk);
                }
            }

            const newState = {...state};
            for (const pk of keysToDelete) {
                delete newState[pk];
            }
            return newState;
        }
        default:
            return state
    }
}
