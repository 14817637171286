import React from 'react';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import ArrowTopRightOnSquareIcon from '../../icons/ArrowTopRightOnSquareIcon';

interface Props {
  title: string;
  value: number;
  onClick: () => void;
}

const StatsCard = ({ title, value, onClick }: Props) => {
  return (
    <Card sx={{ width: 1 }}>
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ p: 3, textAlign: 'center', position: 'relative' }}>
          <ArrowTopRightOnSquareIcon
            fontSize="small"
            sx={{
              color: 'text.secondary',
              position: 'absolute',
              top: '24px',
              right: '24px',
            }}
          />
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="h3" component="p">
            {value}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StatsCard;
