import React from 'react';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';

const StyledButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const ClearButton = ({...rest}) => {
    return (
        <StyledButton size="small" {...rest}>
            <ClearIcon fontSize="inherit" colo='inherit'/>
        </StyledButton>
    )
}

ClearButton.propTypes = {
}

ClearButton.defaultProps = {
};

export default ClearButton;