import { Divider, FormControl, ListItemText, MenuItem, Select } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    whiteSpace: "normal",
    maxWidth: 400,
}));

const UserRolePicker = React.forwardRef(({ value, fullWidth, hiddenLabel, size, onChange, ...rest }, ref) => {
    const { t } = useTranslation();

    return (
        <FormControl variant="outlined" fullWidth={fullWidth} hiddenLabel={hiddenLabel} size={size}>
            <Select
                ref={ref}
                value={value}
                renderValue={(val) => t(val)}
                onChange={(event) => onChange(event.target.value)}
                MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                }}
                {...rest}
            >
                <StyledMenuItem value="admin">
                    <ListItemText primary={t("admin")} secondary={t("adminHint")} />
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem value="manager">
                    <ListItemText primary={t("manager")} secondary={t("managerHint")} />
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem value="solver">
                    <ListItemText primary={t("solver")} secondary={t("solverHint")} />
                </StyledMenuItem>
                <Divider />
                <StyledMenuItem value="observer">
                    <ListItemText primary={t("observer")} secondary={t("observerHint")} />
                </StyledMenuItem>
            </Select>
        </FormControl>
    );
});

UserRolePicker.propTypes = {
    value: PropTypes.oneOf(["admin", "manager", "solver", "observer"]).isRequired,
    size: PropTypes.oneOf(["small", "medium"]),
    fullWidth: PropTypes.bool,
    hiddenLabel: PropTypes.bool,
    onChange: PropTypes.func,
};

UserRolePicker.defaultProps = {
    onChange: () => {},
};

export default UserRolePicker;
