import React from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import { toId } from '../../utils/apiUtils';
import { useTranslation } from 'react-i18next';
import { Navigate, NavLink, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import StyledTabs from '../../components/StyledTabs/StyledTabs';
import CustomTab from '../../components/StyledTabs/CustomTab';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { useLocation } from 'react-router';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import { organizationSelector } from '../../state/organizations/selectors';
import { useSelector } from 'react-redux';

const RootBox = styled(Box, { name: 'OrganizationSettings' })(
  ({ theme, color }) => ({}),
);

const StyledCustomTab = styled(CustomTab)(({ theme }) => ({
  '&:hover': {
    textDecoration: 'none',
  },
  '&:hover:not(.Mui-selected)': {
    color: theme.palette.text.secondary,
  },
}));

const routePatterns = [/\/general[\/]{0,1}$/, /\/members[\/]{0,1}$/];

const getActiveTabValue = (route) => {
  return Math.max(
    routePatterns.findIndex((pattern) => route.match(pattern)),
    0,
  );
};

const OrganizationSettingsLayout = () => {
  const { t } = useTranslation();
  const organizationKey = useOrganizationKey();
  const { pathname } = useLocation();
  const activeTab = getActiveTabValue(pathname);

  const getFullPath = (page) => {
    return `/app/${toId(organizationKey)}/settings/${page}`;
  };

  return (
    <RootBox sx={{ overflow: 'auto' }}>
      <Container
        maxWidth="md"
        sx={{
          px: 6,
          py: 8,
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*<Typography paragraph variant="h3">{t("organizationSettings")}</Typography>*/}
        <StyledTabs value={activeTab}>
          <StyledCustomTab
            disableRipple
            label={t('general')}
            component={NavLink}
            to={getFullPath('general')}
          />
          <StyledCustomTab
            disableRipple
            label={t('members')}
            component={NavLink}
            to={getFullPath('members')}
          />
        </StyledTabs>
        <Divider sx={{ mb: 4 }} />
        <Outlet />
      </Container>
    </RootBox>
  );
};

export default OrganizationSettingsLayout;
