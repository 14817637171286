import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const RootStack = styled(Stack, { name: "PublishingSectionHeader" })(({ theme }) => ({
}));

const PublishingSectionHeader = ({ title, subtitle, active, ...rest }) => {
    const { t } = useTranslation();

    return (
        <RootStack direction='row' alignItems='flex-start' {...rest}>
            <Box flexGrow={1} mr={8}>
                <Typography variant="h3">
                    {title}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    {subtitle}
                </Typography>
            </Box>
            <Chip variant='filled' size='small' label={active ? t('active') : t('inactive')}  color={active ? "success" : "default"} sx={{mt: 2}}/>
        </RootStack>
    );
};

PublishingSectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    active: PropTypes.bool,
};

PublishingSectionHeader.defaultProps = {};

export default PublishingSectionHeader;
