import React from 'react';
import clsx from 'clsx';
import {
    Tabs,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    tabs: {
        minHeight: 0
    },
}));

const StyledTabs = ({ className, children, value, onChange, ...other }) => {
    const classes = useStyles();

    return (
        <Tabs
            className={clsx(classes.tabs, className)}
            value={value}
            onChange={(_, newValue) => onChange(newValue)}
            indicatorColor="primary"
            textColor="primary"
            {...other}
        >
            {children}
        </Tabs>
    );
}

export default StyledTabs;

StyledTabs.propTypes = {
    className: PropTypes.string,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func
}

StyledTabs.defaultProps = {
    onChange: () => {}
};