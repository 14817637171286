export const voicemailSelector = (voicemailKey) => state => {
    if (!Boolean(voicemailKey)) {
        return null;
    }

    return state.voicemails[voicemailKey];
}

export const workspaceVoicemailSelector = (workspaceKey) => state => {
    if (!Boolean(workspaceKey)) {
        return null;
    }

    const voicemailKey = Object.keys(state?.voicemails).find(key => state?.voicemails[key].workspaceKey === workspaceKey);
    return state?.voicemails[voicemailKey];
}