import React from 'react';
import Page from '../../components/Page/Page';
import { Container, Grid, Stack } from '@mui/material';
import StatsCard from './StatsCard';
import { useSelector } from 'react-redux';
import {
  allReportsSelector,
  activeWorkspaceReportsSelector,
} from '../../state/reports/selectors';
import { useTranslation } from 'react-i18next';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { isAssignedToUser, isOverdue } from '../../utils/reportUtils';
import { currentUserSelector } from '../../state/currentUser/selectors';
import { useNavigate } from 'react-router-dom';
import { getWorkspaceRoute } from '../../utils/routeUtils';
import ComplianceCard from './ComplianceCard';
import { styled } from '@mui/material/styles';
import FaqSection from './FaqSection';
import MembersCard from './MembersCard';

const OverviewView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const reports = useSelector(
    activeWorkspaceReportsSelector(organizationKey, workspaceKey),
  );
  const currentUser = useSelector(currentUserSelector());
  const overdueReports = reports.filter((report) => isOverdue(report));
  const assignedToMe = reports.filter((report) =>
    isAssignedToUser(report, currentUser.pk),
  );

  const handleCardClick = (filter: string) => {
    const url = getWorkspaceRoute(organizationKey!, workspaceKey!);
    navigate(`${url}/reports`, { state: { filter } });
  };

  return (
    <Page sx={{ overflow: 'auto' }}>
      <Container maxWidth={'lg'} sx={{ p: 4 }}>
        <Grid container spacing={3} sx={{ mb: 6 }}>
          <Grid item sm={12} md={4}>
            <StatsCard
              title={t('allReports')}
              value={reports.length}
              onClick={() => handleCardClick('all')}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <StatsCard
              title={t('overdueReports')}
              value={overdueReports.length}
              onClick={() => handleCardClick('overdue')}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <StatsCard
              title={t('reportsAssignedToMe')}
              value={assignedToMe.length}
              onClick={() => handleCardClick('assignedToMe')}
            />
          </Grid>
          <Grid item xs={12}>
            <ComplianceCard />
          </Grid>
          <Grid item xs={12}>
            <MembersCard />
          </Grid>
        </Grid>
        <FaqSection />
      </Container>
    </Page>
  );
};

export default OverviewView;
