import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../contexts/ApiContext';
import Loader from '../../components/Loader';
import { toId, toInvitationKey } from '../../utils/apiUtils';
import Page from '../../components/Page/Page';
import { TopBarHeight } from '../../constants';
import { useTranslation } from 'react-i18next';
import { currentUserOrganizationKeysSelector } from '../../state/members/selectors';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { clearState } from '../../state/rootActions';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';
import { useAuthContext } from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  invitationView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    minHeight: TopBarHeight,
    height: TopBarHeight,
  },
}));

const InvitationView = () => {
  const classes = useStyles();
  const { queryApi, mutationApi, authApi } = useContext(ApiContext);
  const { authenticated, user } = useAuthContext();
  const { logInvitationAccepted } = useContext(ActivityLogContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invitationId } = useParams();
  const [error, setError] = useState('');
  const organizationKey = useOrganizationKey();
  const organizationKeys = useSelector(currentUserOrganizationKeysSelector());

  const showErrorScreen = (exception) => {
    const message = exception.errors[0].message;
    console.log('Failed to accept the invitation due to an error: %o', message);
    if (message === 'notFound') {
      setError(t('invitationNotFound'));
    } else if (message === 'notAuthorized') {
      setError(t('invitationNotAuthorized'));
    } else {
      setError(t('invitationUnknownError'));
    }
  };

  const handleUnauthenticatedInvitation = async () => {
    console.log('handleUnauthenticatedInvitation');
    const invitationKey = toInvitationKey(invitationId);
    try {
      const { registered, email } =
        await queryApi.checkInviteeRegistrationStatus(
          organizationKey,
          invitationKey,
        );
      if (!registered) {
        navigate(`/signup`, {
          replace: true,
          state: { from: window.location.pathname, email: email },
        });
      } else {
        navigate(`/login`, {
          replace: true,
          state: { from: window.location.pathname, email: email },
        });
      }
    } catch (e) {
      showErrorScreen(e);
    }
  };

  const handleAuthenticatedInvitation = async () => {
    console.log('handleAuthenticatedInvitation');
    const invitationKey = toInvitationKey(invitationId);
    const organizationId = toId(organizationKey);

    if (organizationKeys.includes(organizationKey)) {
      // Early exit - user is already member of the organization
      navigate(`/app/${organizationId}/home`, { replace: true });
      return;
    }

    try {
      await mutationApi.acceptInvitation(organizationKey, invitationKey);
      await authApi.refreshAccessToken(organizationKey, user.email);
      await logInvitationAccepted(user.email); // must be executed after refreshToken call
      dispatch(clearState());
      navigate(`/app/${organizationId}/home`, { replace: true });
    } catch (e) {
      showErrorScreen(e);
    }
  };

  useEffect(() => {
    if (authenticated) {
      handleAuthenticatedInvitation();
    } else {
      handleUnauthenticatedInvitation();
    }
  }, [authenticated]);

  // TODO: Use suspense or loader component
  return (
    <Page component={'main'} className={classes.invitationView}>
      {!error ? (
        <Loader label="Processing Invitation" />
      ) : (
        <Typography variant="h5" color="textSecondary">
          {error}
        </Typography>
      )}
    </Page>
  );
};

export default InvitationView;
