import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "@mui/material";
import {makeStyles} from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import ErrorDialog from "../ErrorDialog";
import { useTranslation } from "react-i18next";
import { numericId } from "../../utils/idGenerator";
import { MAX_ATTACHMENT_SIZE, ThumbnailHeight, ThumbnailMaxWidth } from '../../constants';
import { styled } from '@mui/material/styles';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { organizationSelector } from '../../state/organizations/selectors';
import { isEnterpriseOrganization } from '../../utils/modelUtils';
import { useSelector } from 'react-redux';

const RootDiv = styled("div", { name: "AttachmentUploader"})(({ theme }) => ({

}));

const AttachmentUploader = ({
    className,
    language,
    variant,
    size,
    edge,
    component,
    children,
    onFileAdded,
    ...rest
}) => {
    const { t, i18n } = useTranslation();
    const organizationKey = useOrganizationKey();
    const organization = useSelector(organizationSelector(organizationKey));
    const activeLanguage = language ?? i18n.language;
    const [invalidFile, setInvalidFile] = useState(false);
    const inputId = useMemo(() => `file-upload-button-${numericId()}`, []);

    const handleFileInputChange = (event) => {
        const {
            target: { value, files },
        } = event;
        for (const file of files) {
            if (file.size > MAX_ATTACHMENT_SIZE && !isEnterpriseOrganization(organization)) {
                setInvalidFile(file.name);
            } else {
                onFileAdded(file);
            }
        }

        // setFileKeys(newFiles.map(file => getFileKey(file)));
        event.target.value = "";
    };

    const renderButton = () =>
        variant === "icon" ? (
            <Tooltip title={t("attachFile", { lng: activeLanguage })}>
                <label htmlFor={inputId} style={{ marginBottom: 0 }}>
                    <IconButton
                        className={className}
                        aria-label="attach-file"
                        size={size}
                        component={component}
                        edge={edge}
                        {...rest}
                    >
                        {children}
                    </IconButton>
                </label>
            </Tooltip>
        ) : (
            <label htmlFor={inputId} style={{ marginBottom: 0 }}>
                <Button
                    className={className}
                    variant="contained"
                    aria-label="attach-file"
                    size={size}
                    component={component}
                    edge={edge}
                    {...rest}
                >
                    {children}
                </Button>
            </label>
        );

    return (
        <RootDiv>
            <label htmlFor={inputId} style={{ marginBottom: 0, cursor: 'pointer' }}>
                {children}
            </label>
            <input id={inputId} type="file" style={{ display: "none" }} onChange={handleFileInputChange} multiple />
            <ErrorDialog
                open={Boolean(invalidFile)}
                title={t("cannotUploadAttachment", { fileName: invalidFile, lng: activeLanguage })}
                text={t("attachmentSizeError", { lng: activeLanguage })}
                onClose={() => setInvalidFile(null)}
            />
        </RootDiv>
    );
};

AttachmentUploader.propTypes = {
    className: PropTypes.string,
    language: PropTypes.string,
    variant: PropTypes.oneOf(["icon", "standard"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    edge: PropTypes.oneOf(["end", "start"]),
    component: PropTypes.string,
    onFileAdded: PropTypes.func,
};

AttachmentUploader.defaultProps = {
    variant: "standard",
    size: "medium",
    onFileAdded: () => {}
};

export default AttachmentUploader;
