import React from 'react';
import { Menu } from '@mui/material';

const getMenuMargin = (transformOrigin) => {
    return transformOrigin.vertical === 'bottom' ? {mb: 1.5} : {mt: 0.5};
}

const getArrowProps = (transformOrigin) => {
    switch(true) {
        case transformOrigin.vertical === "top" && transformOrigin.horizontal === "right":
            return {top: 0, right: 14, transform: 'translateY(-50%) rotate(45deg)' };
        case transformOrigin.vertical === "bottom" && transformOrigin.horizontal === "left":
            return {bottom: 0, left: 14, transform: 'translateY(50%) rotate(45deg)' };
        case transformOrigin.vertical === "bottom" && transformOrigin.horizontal === "right":
            return {bottom: 0, right: 14, transform: 'translateY(50%) rotate(45deg)' };
        default:
            return {top: 0, left: 14, transform: 'translateY(-50%) rotate(45deg)' };
    }
}

const MenuWithArrow = ({ children, transformOrigin, ...rest }) => {
    return (
        <Menu
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    ...getMenuMargin(transformOrigin),
                    // '& .MuiAvatar-root': {
                    //     width: 32,
                    //     height: 32,
                    //     ml: -0.5,
                    //     mr: 1,
                    // },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        zIndex: 0,
                        ...getArrowProps(transformOrigin)
                    },
                },
                ...rest.PaperProps
            }}
            transformOrigin={transformOrigin}
            {...rest}
        >
            {children}
        </Menu>
    );
};

MenuWithArrow.propTypes = {
};

MenuWithArrow.defaultProps = {
};

export default MenuWithArrow;
