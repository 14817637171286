import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FieldLabel from '../FieldLabel';
import { Controller, useFormContext } from 'react-hook-form';
import {
    blockFieldRegistrationName,
    blockTemplateRegistrationName,
    getBlockTemplate,
    getTranslations, translationRegistrationName,
    translationsRegistrationName
} from './utils';
import EditorTextField from './EditorTextField';

const BlockLabel = ({blockIndex, mode}) => {
    const { t } = useTranslation();
    const { register, getValues } = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);

    if (mode === 'view') {
        return (
            <Controller
                name={translationRegistrationName(blockTemplate.label)}
                render={({ field: { value } }) => (
                    <FieldLabel label={value} required={blockTemplate.required}/>
                )}/>
        )
    }

    return (
        <Stack direction='row' alignItems='center'>
            <EditorTextField
                fullWidth
                {...register(translationRegistrationName(blockTemplate.label))}
                placeholder={t("blockHeaderPlaceholder")}
                textVariant='subtitle1'
                sx={{
                    mr: 2
                }}
            />
            <FormControlLabel
                label={t(`required`)}
                control={
                    <Controller
                        name={blockFieldRegistrationName(blockIndex, 'required')}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox
                                color="primary"
                                size="small"
                                checked={value ?? false}
                                onChange={e => onChange(e.target.checked)}
                            />
                        )}/>
                }
                sx={{
                    margin: 0,
                    '& .MuiFormControlLabel-label': {
                        typography: 'body2',
                    }
                }}
            />
        </Stack>
    )
}

BlockLabel.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    mode: PropTypes.oneOf(['view', 'edit']),
};

BlockLabel.defaultProps = {
    mode: 'view'
};

export default BlockLabel;
