import React from 'react';
import { OutlinedTextFieldProps, SxProps, TextField } from '@mui/material';
import FieldLabel from '../FieldLabel';
import { styled, Theme } from '@mui/material/styles';
import HelperTextErrorIcon from '../HelperTextErrorIcon/HelperTextErrorIcon';
import { isNullOrEmpty } from '../../utils/textUtils';

const RootDiv = styled('div', { name: 'TextInput' })(({ theme }) => ({}));

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  disableLabelTypography?: boolean;
  label: string;
  LabelProps?: {};
  helperText?: string | null;
  disableBottomPadding?: boolean;
  sx?: SxProps<Theme>;
}

const FixedLabelTextField = ({
  label,
  LabelProps,
  disableLabelTypography,
  required,
  error,
  helperText,
  disableBottomPadding,
  sx,
  ...rest
}: Props) => {
  const helper = (
    <span style={{ display: 'inline-block' }}>
      <HelperTextErrorIcon
        sx={{ display: Boolean(error) ? 'inline-block' : 'none', mr: 0.5 }}
      />
      {isNullOrEmpty(helperText) ? ' ' : helperText}
    </span>
  );

  return (
    <RootDiv sx={sx}>
      {disableLabelTypography ? (
        label
      ) : (
        <FieldLabel label={label} required={required} {...LabelProps} />
      )}
      <TextField
        hiddenLabel
        variant="outlined"
        error={error}
        helperText={helperText}
        {...rest}
      />
    </RootDiv>
  );
};

export default FixedLabelTextField;
