import React, {useContext, useState} from 'react';
import {styled} from "@mui/material/styles";
import ReactAudioPlayer from "react-audio-player";
import {Box} from "@mui/material";

export default styled('div', {name: 'Well'})(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2, 2)
}));

// const Well = styled('div')(styles);
//
// const Well = ({children}) => {
//     return (
//         <Box sx={{ p: 1borderRadius: 1, bgcolor: 'grey.100'}}>
//             {children}
//         </Box>
//     )
// }
//
// export default Well;

