import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import {
  getPrivacyPolicy,
  translationRegistrationName,
} from '../FormBlock/utils';
import EditorTextField from '../FormBlock/EditorTextField';
import RichTextField from '../RichTextField';

const FormEditorPrivacyPolicyBlock = ({ ...rest }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const privacyPolicyTemplate = getPrivacyPolicy(getValues);

  return (
    <Stack direction="column" spacing={1} {...rest}>
      <Controller
        name={translationRegistrationName(privacyPolicyTemplate.label)}
        render={({ field: { onChange, value } }) => (
          <EditorTextField
            textAlign="center"
            placeholder={t('textEditorPlaceholder')}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            textVariant="body2"
            sx={{
              alignSelf: 'center',
              margin: 'auto',
              typography: 'body2',
              pointerEvents: 'all',
              px: 3.5,
              '& .MuiInput-root': {
                color: 'primary.main',
              },
            }}
          />
        )}
      />
      <Box>
        <Typography
          variant="caption"
          color="textSecondary"
          gutterBottom
          sx={{ p: 0.5, mb: 0.5 }}
        >
          {t('privacyPolicyText')}
        </Typography>
        <Controller
          name={translationRegistrationName(privacyPolicyTemplate.content)}
          render={({ field: { onChange, value } }) => {
            return (
              <RichTextField
                value={value}
                onChange={onChange}
                placeholder={t('textEditorPlaceholder')}
                toolbarControls={[
                  'heading1',
                  'heading2',
                  'divider',
                  'bold',
                  'italic',
                  'underline',
                  'unorderedList',
                  'orderedList',
                  'link',
                ]}
              />
            );
          }}
        />
      </Box>
    </Stack>
  );
};

FormEditorPrivacyPolicyBlock.propTypes = {
  language: PropTypes.string.isRequired,
};

FormEditorPrivacyPolicyBlock.defaultProps = {};

export default FormEditorPrivacyPolicyBlock;
