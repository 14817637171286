import * as types from "./actionTypes";
import { VOICEMAIL_UPDATED, VOICEMAILS_LOADED } from './actionTypes';

const initialState = { };

export default function emailsReducer(state = initialState, action) {
    switch (action.type) {
        case types.EMAILS_LOADED: {
            const newState = {...state};
            for (const email of action.payload.emails) {
                newState[email.pk] = email;
            }
            return newState;
        }
        case types.EMAILS_UPDATED: {
            const pk = action.payload.pk;
            const newState = {...state};
            newState[pk] = {...newState[pk], ...action.payload.data, pk: pk, sk: pk};
            return newState;
        }
        default:
            return state
    }
}