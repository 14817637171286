import React from "react";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import { Container } from 'reactstrap';

const RootDiv = styled('div', { name: "LandingPageView" })(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
}));

const LandingPageView = (props) => {
    const { domain } = useParams();

    return (
        <RootDiv {...props}>
            <Typography variant="h6">{domain}</Typography>
        </RootDiv>
    );
};

LandingPageView.propTypes = {
};

LandingPageView.defaultProps = {
};

export default LandingPageView;
