import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { validateEmail, validatePassword } from '../../utils/validationUtils';
import { ApiContext } from '../../contexts/ApiContext';
import { useLocation } from 'react-router';
import PasswordTextField from '../../components/PasswordTextField';
import { getActiveLanguage } from '../../utils/localizationUtils';
import { styled } from '@mui/material/styles';
import { CognitoUser } from 'amazon-cognito-identity-js';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const RootBox = styled(Box, { name: 'LoginPage' })(({ theme }) => ({}));

interface Props {
  onSubmit: (user: CognitoUser, password: string) => void;
}

const LoginPage = ({ onSubmit }: Props) => {
  const { authApi } = useContext(ApiContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [email, setEmail] = useState(state?.email ?? '');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const autoFocusEmailField = !Boolean(state?.email);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setEmail(value);

    setEmailError(!Boolean(value) ? 'emailIsRequired' : null);
    setSubmitError(null);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPassword(value);
    setPasswordError(!Boolean(value) ? 'passwordIsRequired' : null);
  };

  const handleForgottenPasswordClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    navigate('/forgotten-password');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.persist();
    event.preventDefault();

    if (emailError !== null || passwordError !== null) {
      return;
    }

    const emailValidationError = validateEmail(email);
    if (Boolean(emailValidationError)) {
      setEmailError(emailValidationError);
      return;
    }

    const passwordValidationError = validatePassword(password);
    if (Boolean(passwordValidationError)) {
      setPasswordError(passwordValidationError);
      return;
    }

    const language = getActiveLanguage(i18n);
    try {
      setLoading(true);
      const user = await authApi.signIn(email, password, language);
      onSubmit(user, password);
    } catch (e: any) {
      if (e.name === 'PasswordResetRequiredException') {
        await authApi.forgotPassword(email, language);
        navigate('/forgotten-password', {
          state: { email: email, confirmationRequired: true },
        });
        return;
      }

      console.error(e);
      const errorName =
        e?.name === 'UserNotFoundException' ||
        e?.name === 'NotAuthorizedException' ||
        e?.name === 'TooManyRequestsException'
          ? e.name
          : 'unknownLoginError';
      setSubmitError(errorName);
      setLoading(false);
    }
  };

  return (
    <RootBox>
      <Container maxWidth="xs">
        <Box sx={{ textAlign: 'center' }} mb={5}>
          <Typography variant="h4" component="h1">
            {t('signInToYourAccount')}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {Boolean(submitError) && (
              <Alert severity="error">{t(submitError!)}</Alert>
            )}
            <TextField
              id="email"
              autoFocus={autoFocusEmailField}
              required
              fullWidth
              variant="outlined"
              autoComplete="email"
              label={t('email')}
              value={email}
              onChange={handleEmailChange}
              error={Boolean(emailError)}
              helperText={t(emailError!)}
            />
            <Box>
              <PasswordTextField
                id="password"
                required
                fullWidth
                variant="outlined"
                autoComplete="current-password"
                label={t('password')}
                value={password}
                onChange={handlePasswordChange}
                error={Boolean(passwordError)}
                helperText={t(passwordError!)}
              />
              <Box sx={{ textAlign: 'end' }}>
                <Link
                  variant="body1"
                  color="link.main"
                  href={'/forgotten-password'}
                  onClick={handleForgottenPasswordClick}
                  sx={{ verticalAlign: 'baseline' }}
                >
                  {t('forgotPassword')}
                </Link>
              </Box>
            </Box>
            <LoadingButton
              fullWidth
              color="primary"
              loading={loading}
              variant="contained"
              size="large"
              type="submit"
            >
              {t('continue')}
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </RootBox>
  );
};

export default LoginPage;
