import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

const RootDiv = styled('div', { name: 'EmptyLayout' })(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  minHeight: '100vh',
  //overflow: 'hidden',
  // background: "linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)",
  // backgroundSize: "400% 400%",
  // animation: "$gradient 15s ease infinite"
  // "@keyframes gradient": {
  //     "0%": {backgroundPosition: "0% 50%"},
  //     "50%": {backgroundPosition: "100% 50%"},
  //     "100%": {backgroundPosition: "0% 50%"}
  // },
}));

const EmptyLayout = () => {
  return (
    <RootDiv>
      <Outlet />
    </RootDiv>
  );
};

export default EmptyLayout;
