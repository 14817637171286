import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  List,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import ReportCard from './ReportCard';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toId, toReportKey, toWorkspaceKey } from '../../utils/apiUtils';
import {
  allReportsSelector,
  activeWorkspaceReportsSelector,
  activeReportsSelector,
} from '../../state/reports/selectors';
import ResourceAvatar from '../../components/ResourceAvatar';
import { activeWorkspacesSelector } from '../../state/workspaces/selectors';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useResolvedPath } from 'react-router';
import { styled } from '@mui/material/styles';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { isAssignedToUser, isClosed, isOverdue } from '../../utils/reportUtils';
import { currentUserSelector } from '../../state/currentUser/selectors';

const RootStack = styled(Stack, { name: 'ReportsPanel' })(
  ({ theme, color }) => ({
    height: '100%',
  }),
);

const filterByStatus = (filter, report, currentUser) => {
  if (filter === 'all') {
    return true;
  }

  return (
    (filter === 'active' && !isClosed(report)) ||
    (filter === 'closed' && isClosed(report)) ||
    (filter === 'overdue' && isOverdue(report)) ||
    (filter === 'assignedToMe' && isAssignedToUser(report, currentUser.pk))
  );
};

const sortingFunction = () => {
  return (a, b) => {
    if (!a.excerptUpdatedAt && b.excerptUpdatedAt) {
      return 1;
    }
    if (a.excerptUpdatedAt && !b.excerptUpdatedAt) {
      return -1;
    }
    if (a.excerptUpdatedAt < b.excerptUpdatedAt) {
      return 1;
    }
    if (a.excerptUpdatedAt > b.excerptUpdatedAt) {
      return -1;
    }
    return 0;
  };
};

// TODO: Use border-bottom on cards instead of separator
// TODO: May have bad performance with too many reports due to filtering&sorting
const ReportList = ({ ...rest }) => {
  const { state } = useLocation();
  const [stateFilter, setStateFilter] = useState(state?.filter || 'all');
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const route = useResolvedPath('');
  const currentUser = useSelector(currentUserSelector());
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const { reportId } = useParams();
  const reportKey = reportId ? toReportKey(reportId) : null;
  const workspaces = useSelector(activeWorkspacesSelector(organizationKey));
  const reports = useSelector(
    workspaceKey
      ? activeWorkspaceReportsSelector(organizationKey, workspaceKey)
      : activeReportsSelector(),
  );
  // TODO: REmove workspace filtering->just temp solution until authorized resources are filtered on backend
  const filteredReports = reports
    .filter(
      (report) =>
        filterByStatus(stateFilter, report, currentUser) &&
        workspaces.some((workspace) => workspace.pk === report.workspaceKey),
    )
    .sort(sortingFunction());

  const handleListItemClick = (reportKey) => {
    navigate(`${route.pathname}/${toId(reportKey)}`);
  };

  return (
    <RootStack {...rest}>
      <Box flex={0} px={3} py={2} height={82}>
        <FormControl fullWidth variant="outlined" margin="none" size="small">
          <Select
            value={stateFilter}
            onChange={(event) => setStateFilter(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            sx={{
              '& .MuiSelect-select': {
                py: '14.5px',
              },
            }}
          >
            <MenuItem value={'all'}>{t('allReports')}</MenuItem>
            <MenuItem value={'active'}>{t('openReports')}</MenuItem>
            <MenuItem value={'closed'}>{t('closedReports')}</MenuItem>
            <MenuItem value={'overdue'}>{t('overdueReports')}</MenuItem>
            <MenuItem value={'assignedToMe'}>
              {t('reportsAssignedToMe')}
            </MenuItem>
          </Select>
        </FormControl>
        {/*<Box flex="1 1 auto"/>*/}
        {/*<Button className={classes.right} size="small" startIcon={<FilterListIcon />}>*/}
        {/*    Filter*/}
        {/*</Button>*/}
      </Box>
      <Divider />
      <List disablePadding component="div" sx={{ flex: 1, overflow: 'auto' }}>
        {filteredReports?.map((report) => (
          <ReportCard
            key={report.pk}
            showWorkspaceAvatar={!Boolean(workspaceKey)}
            selected={report.pk === reportKey}
            onClick={handleListItemClick}
            reportKey={report.pk}
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          />
        ))}
      </List>
    </RootStack>
  );
};

ReportList.propTypes = {};

ReportList.defaultProps = {};

export default ReportList;
