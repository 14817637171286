import React, {useContext, useEffect, useRef, useState} from 'react';
import {ApiContext} from "../contexts/ApiContext";
import {ReactReduxContext, useDispatch, useSelector} from "react-redux";
import { addOrUpdateWorkspace } from '../state/workspaces/actions';
import { workspaceSelector } from '../state/workspaces/selectors';

export const useFetchPublicWorkspaceResource = (workspaceKey, forceFetch = false) => {
    const {queryApi} = useContext(ApiContext);
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();
    const [notFound, setNotFound] = useState(false);
    const workspace = useSelector(workspaceSelector(workspaceKey));
    //const isLoaded = workspaceKey ? Boolean(store.getState().workspaces[workspaceKey]) : false;

    const fetchResources = async () => {
        const resource = await queryApi.getPublicResource(workspaceKey, workspaceKey);
        if (!resource) {
            setNotFound(true);
            return;
        }

        dispatch(addOrUpdateWorkspace(resource.pk, resource));
        setNotFound(false);
    }

    useEffect(() => {
        if (!workspaceKey) {
            return;
        }

        if (forceFetch || !Boolean(workspace)) {
            fetchResources();
        }
    }, [workspaceKey]);

    return {workspace, notFound, loaded: Boolean(workspace)};
}

