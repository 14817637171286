import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../../contexts/ApiContext';
import { useTranslation } from 'react-i18next';
import { isClosed } from '../../utils/reportUtils';
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { getReportName } from '../../utils/textUtils';
import TabPanel from '../../components/TabPanel/TabPanel';
import { styled } from '@mui/material/styles';
import { useMessageSenders } from '../../hooks/useMessageSenders';
import { workspaceStatusesSelector } from '../../state/statuses/selectors';
import { usePublicReportResources } from '../../hooks/usePublicReportResources';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { reportCommentsSelector } from '../../state/comments/selectors';
import { exportReportToCsv } from '../../utils/exportUtils';
import { ActivityLogContext } from '../../contexts/ActivityLogContext';
import { workspaceTagsSelector } from '../../state/tags/selectors';
import ReportChat from '../../components/ReportChat/ReportChat';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { addOrUpdateReport } from '../../state/reports/actions';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { getWorkspaceRoute } from '../../utils/routeUtils';
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog';
import HoverOutlineDecorator from '../../components/HoverOutlineDecorator';
import GrowingTextField from '../../components/GrowingTextField';

const RootDiv = styled('div', { name: 'ReportContentPanel' })(({ theme }) => ({
  height: '100%',
  minWidth: 0,
  display: 'flex',
  flexDirection: 'column',
}));

const ContentTabPanel = styled(TabPanel)(({ theme }) => ({
  height: '100%',
}));

const ReportContentPanel = ({ report, ...rest }) => {
  const { t } = useTranslation();
  const { mutationApi, storageApi } = useContext(ApiContext);
  const { logReportExported, logReportDeleted, logNewMessage, logNameChanged } =
    useContext(ActivityLogContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const comments = useSelector(reportCommentsSelector(report.pk));
  const statuses = useSelector(workspaceStatusesSelector(report.workspaceKey));
  const tags = useSelector(workspaceTagsSelector(report.workspaceKey));
  const { messages, loaded: messagesLoaded } = usePublicReportResources(
    report.pk,
  );
  const { members, loaded: membersLoaded } = useMessageSenders(messages);
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletingReport, setDeletingReport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [reportName, setReportName] = useState(() => getReportName(report, t));
  const [originalReportName, setOriginalReportName] = useState(() =>
    getReportName(report, t),
  );

  useEffect(() => {
    const name = getReportName(report, t);
    setReportName(name);
    setOriginalReportName(name);
  }, [report?.pk]);

  const handleExportReportClick = async () => {
    setExportLoading(true);
    setContextMenuAnchorEl(null);
    await exportReportToCsv(
      report,
      messages,
      comments,
      members,
      statuses,
      tags,
      t,
      storageApi,
    );
    setExportLoading(false);
    logReportExported(report.pk);
  };

  const handleMessageSubmitted = (messageKey) => {
    logNewMessage(report.pk, messageKey);
  };

  const handleDeleteReportClick = async () => {
    setDeletingReport(true);
    await logReportDeleted(report.pk);
    await mutationApi.deleteReport(report.pk);

    setDeletingReport(false);
    setDeleteDialogOpen(false);
    setContextMenuAnchorEl(null);
    dispatch(addOrUpdateReport(report.pk, { isDeleted: true }));

    navigate(`${getWorkspaceRoute(organizationKey, workspaceKey)}/reports`, {
      replace: true,
    });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setContextMenuAnchorEl(null);
  };

  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
  };

  const handleReportNameFieldBlur = async () => {
    if (reportName === originalReportName) {
      console.log("name didn't change, exit");
      return;
    }
    dispatch(addOrUpdateReport(report.pk, { name: reportName }));
    await logNameChanged(report.pk, reportName);
    await mutationApi.updateReport(report.pk, { name: reportName });
  };

  return (
    <RootDiv {...rest}>
      <Stack
        pl={2.25}
        pr={4}
        flexGrow={0}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Stack direction="row" alignItems="center" height={81}>
          <HoverOutlineDecorator>
            <GrowingTextField
              variant="outlined"
              size="small"
              value={reportName}
              onChange={handleReportNameChange}
              onBlur={handleReportNameFieldBlur}
              sx={{
                overflow: 'hidden',
                typography: 'h4',
                '& .MuiInputBase-input': {
                  typography: 'h4',
                },
              }}
            />
          </HoverOutlineDecorator>
          <Box flex="1 1 auto" />
          <Tooltip title={t('More')}>
            <IconButton
              size="small"
              edge="end"
              onClick={(event) => setContextMenuAnchorEl(event.currentTarget)}
            >
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      <ReportChat
        loading={!messagesLoaded || !membersLoaded}
        reportKey={report.pk}
        workspaceKey={report.workspaceKey}
        organizationKey={organizationKey}
        variant="bubble"
        onSubmitted={handleMessageSubmitted}
        ChatInputProps={{
          disabled: isClosed(report),
          placeholder: isClosed(report)
            ? t('disabledChatInputPlaceholder')
            : t('reportChatInputPlaceholder'),
        }}
        sx={{
          bgcolor: 'grey.50',
        }}
      />
      <Menu
        open={Boolean(contextMenuAnchorEl)}
        anchorEl={contextMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setContextMenuAnchorEl(null)}
      >
        <MenuItem onClick={handleExportReportClick}>
          <ListItemIcon
            sx={{
              ml: -0.25,
              minWidth: 40,
            }}
          >
            <SaveAltOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('export')} />
        </MenuItem>
        <MenuItem onClick={() => setDeleteDialogOpen(true)}>
          <ListItemIcon
            sx={{
              ml: -0.25,
              minWidth: 40,
            }}
          >
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('delete')} />
        </MenuItem>
      </Menu>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          flexDirection: 'column',
        }}
        open={exportLoading}
      >
        <Box mb={1}>
          <CircularProgress color="inherit" />
        </Box>
        <Typography variant="body1">{t('exportLoadingMessage')}</Typography>
      </Backdrop>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        loading={deletingReport}
        text={t('deleteReportConfirmationText')}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteReportClick}
      />
      {/*<ExportDialog reportKey={report.pk} open={exportDialogOpen} onClose={() => setExportDialogOpen(false)} />*/}
    </RootDiv>
  );
};

ReportContentPanel.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportContentPanel;
