import React, { useEffect, useRef, useState } from 'react';
import { BaseTextFieldProps, Box, TextField } from '@mui/material';

interface Props extends Omit<BaseTextFieldProps, 'defaultValue' | 'value'> {
  value: string;
}

const GrowingTextField = ({ value, fullWidth, sx = [], ...rest }: Props) => {
  const [inputWidth, setInputWidth] = useState<number>(0);
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!fullWidth && spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth);
    }
  }, [value]);

  return (
    <>
      <TextField
        hiddenLabel
        variant="outlined"
        value={value}
        fullWidth={fullWidth}
        sx={[
          !fullWidth ? { '& .MuiInputBase-input': { width: inputWidth } } : {},
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...rest}
      />
      {!fullWidth && (
        <Box
          component="span"
          ref={spanRef}
          sx={[
            {
              typography: 'body1',
              visibility: 'hidden',
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: 0,
              top: 0,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {value.split(' ').join('\u00a0') || '\u00a0'}
        </Box>
      )}
    </>
  );
};

export default GrowingTextField;
