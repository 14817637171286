import * as types from "./actionTypes";

const initialState = { };

export default function formsReducer(state = initialState, action) {
    switch (action.type) {
        case types.FORMS_LOADED: {
            const newState = {...state};
            for (const form of action.payload.forms) {
                newState[form.pk] = form;
            }
            return newState;
        }
        case types.FORM_UPDATED: {
            const pk = action.payload.pk;
            const newState = {...state};
            newState[pk] = {...newState[pk], ...action.payload.data, pk: pk, sk: pk};
            return newState;
        }
        case types.FORM_DELETED: {
            const newState = {...state};
            delete newState[action.payload.pk];
            return newState;
        }
        default:
            return state
    }
}