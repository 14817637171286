import React from "react";
import clsx from "clsx";
import { Dialog, Tab, Tabs } from "@mui/material";
import {makeStyles} from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    tab: {
        //textTransform: 'none',
        //marginRight: theme.spacing(4),
        marginRight: theme.spacing(3),
        minHeight: 0,
        minWidth: theme.spacing(6),
        paddingLeft: 0,
        paddingRight: 0,
        // paddingTop: theme.spacing(1),
        // paddingBottom: theme.spacing(1),
        /*...theme.typography.subtitle2,
        textTransform: "none",*/
        alignItems: "initial",
        flexDirection: "row",
        "&>*:first-child": {
            marginRight: theme.spacing(0.75),
        },
    }
}));

const CustomTab = ({ className, ...other }) => {
    const classes = useStyles();

    return (
        <Tab
            disableRipple
            className={clsx(classes.tab, className)}
            {...other}
        />
    );
};

export default CustomTab;
