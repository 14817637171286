import { newInvitationKey } from './apiUtils';

export function emptyInvitationRequest(currentUser) {
    return {
        invitationKey: newInvitationKey(),
        role: "admin",
        language: currentUser.language ?? "en",
    };
};

export function isEnterpriseOrganization(organization) {
    return organization?.plan === 'enterprise'
}

export function isWhitelabelEnabled(organization) {
    return isEnterpriseOrganization(organization) && organization?.whitelabelEnabled;

}
