import * as types from './actionTypes';
import { Dispatch } from 'redux';
import {
  DeletePolicyAction,
  LoadPoliciesAction,
  PolicyActionTypes,
  UpdatePolicyAction,
} from './actionTypes';
import {
  DeserializedPrivateResource,
  UpdateDeserializedPrivateResourceInput,
} from '../../types/api';

export const loadPolicies =
  (policies: [DeserializedPrivateResource]) =>
  async (dispatch: Dispatch<LoadPoliciesAction>) => {
    dispatch({ type: types.LOAD_POLICIES, payload: { policies } });
  };

export const addOrUpdatePolicy =
  (pk: string, sk: string, data: UpdateDeserializedPrivateResourceInput) =>
  async (dispatch: Dispatch<UpdatePolicyAction>) => {
    dispatch({ type: types.UPDATE_POLICY, payload: { pk, sk, data } });
  };

export const deletePolicy =
  (pk: string, sk: string) =>
  async (dispatch: Dispatch<DeletePolicyAction>) => {
    dispatch({ type: types.DELETE_POLICY, payload: { pk, sk } });
  };
