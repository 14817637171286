import * as types from './actionTypes';

export const loadOrganizations = (organizations) => async (dispatch, getState) => {
    dispatch({ type: types.ORGANIZATIONS_LOADED, payload: {organizations} });
}

export const addOrUpdateOrganization = (pk, data) => async (dispatch, getState) => {
    if (!Boolean(pk)) {
        throw new Error("pk cannot be null");
    }
    dispatch({ type: types.ORGANIZATION_UPDATED, payload: {pk, data } });
}