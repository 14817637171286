import React from 'react';
import { SvgIconProps } from '@mui/material';
import MegaphoneIcon from '../../icons/MegaphoneIcon';

const iconMapping = {
  MegaphoneIcon: MegaphoneIcon,
};

interface Props extends SvgIconProps {
  iconName: keyof typeof iconMapping;
}

const WorkspaceIcon = ({ iconName, ...rest }: Props) => {
  const Icon = iconMapping[iconName];

  return <Icon {...rest} />;
};

export default WorkspaceIcon;
