import React, { useState } from 'react';
import { useLocation } from 'react-router';
import Page from '../../components/Page/Page';
import SignupPage1 from './SignupPage1';
import SignupPage2 from './SignupPage2';
import { Box } from '@mui/material';

const SignupView = () => {
  const { state } = useLocation();
  const [email, setEmail] = useState<string | null>(state?.email ?? null);

  return (
    <Page sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Box flex={1} alignContent={'center'}>
        {!Boolean(email) ? (
          <SignupPage1 onSubmit={setEmail} />
        ) : (
          <SignupPage2 email={email!} />
        )}
      </Box>
    </Page>
  );
};

export default SignupView;
