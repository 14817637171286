import React from 'react';
import { Stack } from '@mui/material';

const AttributeField = ({children, ...rest}) => {
    return (
        <Stack direction='row' alignItems='center' {...rest}>{children}</Stack>
    )
}

AttributeField.propTypes = {
}

AttributeField.defaultProps = {
};

export default AttributeField;