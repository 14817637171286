import React from 'react';
import Page from '../../components/Page/Page';
import OnboardingTopbar from './OnboardingTopbar';
import OnboardingPage1 from './OnboardingPage1';
import { Box, Stack, Toolbar } from '@mui/material';

const OnboardingView = () => {
  return (
    <Page>
      <OnboardingTopbar />
      <Stack component="main" minHeight={'100vh'} direction="column">
        <Toolbar />
        <Box flex={1} alignContent={'center'} py={3}>
          <OnboardingPage1 />
        </Box>
      </Stack>
    </Page>
  );
};

export default OnboardingView;
