import React from 'react';
import { ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(ListItemText, { name: "DenseListItemText" })(({ theme }) => ({
    margin: 0,
    "& .MuiListItemText-primary": {
        fontSize: "0.8125rem",
    },
    "& .MuiListItemText-secondary": {
        fontSize: "0.75rem",
        lineHeight: 1.33
    }
}));