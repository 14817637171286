import { s5 } from "./idGenerator";

export function minifyDraftEditorState(serializedState) {
    if (!Boolean(serializedState)) {
        return serializedState;
    }

    const minifiedState = { blocks: [], updatedAt: serializedState.updatedAt };

    if (Object.keys(serializedState.entityMap).length !== 0) {
        minifiedState.entityMap = serializedState.entityMap;
    }

    for (let i = 0; i < serializedState.blocks.length; i++) {
        const block = serializedState.blocks[i];
        const minifiedBlock = { text: block.text.trim() }; // remove any whiteline characters from the beginning and end of the text
        if (block.type !== "unstyled") {
            minifiedBlock.type = block.type;
        }
        if (block.depth !== 0) {
            minifiedBlock.depth = block.depth;
        }
        if (block.inlineStyleRanges?.length > 0) {
            minifiedBlock.inlineStyleRanges = block.inlineStyleRanges;
        }
        if (block.entityRanges?.length > 0) {
            minifiedBlock.entityRanges = block.entityRanges;
        }
        if (Object.keys(block.data)?.length > 0) {
            minifiedBlock.data = block.data;
        }
        minifiedState.blocks[i] = minifiedBlock;
    }

    return minifiedState;
}

export function deminifyDraftEditorState(minifiedState) {
    if (!Boolean(minifiedState)) {
        return minifiedState;
    }

    const restoredEditorState = {
        blocks: [],
        updatedAt: minifiedState.updatedAt,
        entityMap: minifiedState.entityMap ?? {},
    };

    for (let i = 0; i < minifiedState.blocks.length; i++) {
        const restoredBlock = { ...minifiedState.blocks[i], key: s5() };
        if (restoredBlock.type === undefined) {
            restoredBlock.type = "unstyled";
        }
        if (restoredBlock.depth === undefined) {
            restoredBlock.depth = 0;
        }
        if (restoredBlock.inlineStyleRanges === undefined) {
            restoredBlock.inlineStyleRanges = [];
        }
        if (restoredBlock.entityRanges === undefined) {
            restoredBlock.entityRanges = [];
        }
        if (restoredBlock.data === undefined) {
            restoredBlock.data = {};
        }
        restoredEditorState.blocks[i] = restoredBlock;
    }

    return restoredEditorState;
}
