import React from 'react';
import { Link, Toolbar, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

const AuthFooter = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const translationKey =
    pathname === '/signup' ? 'alreadyHaveAccount' : 'dontHaveAccount';
  const buttonPath = pathname === '/signup' ? '/login' : '/signup';

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(buttonPath);
  };

  return (
    <Toolbar>
      <Typography variant="body1" sx={{ m: 'auto' }}>
        <Trans i18nKey={translationKey}>
          Don't delete - this random text increases component count in the Trans
          component
          <Link
            color="link.main"
            href={buttonPath}
            onClick={handleLinkClick}
            sx={{ verticalAlign: 'baseline' }}
          >
            x
          </Link>
        </Trans>
      </Typography>
    </Toolbar>
  );
};

export default AuthFooter;
