import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { ApiContext } from '../contexts/ApiContext';
import { useDispatch, useSelector } from 'react-redux';
import { addMessages } from '../state/messages/actions';
import { isMessage, isReport } from '../utils/apiUtils';
import { reportMessagesSelector } from '../state/messages/selectors';
import { reportSelector } from '../state/reports/selectors';
import { addOrUpdateReport } from '../state/reports/actions';

export const usePublicReportResources = (reportKey) => {
    const { queryApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const report = useSelector(reportSelector(reportKey));
    const messages = useSelector(reportMessagesSelector(reportKey));
    const notFoundRef = useRef(false);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const isLoaded = Boolean(report?.loaded);

    const fetch = async () => {
        const resources = await queryApi.listPublicResources(reportKey);
        const reportResource = resources.find((r) => isReport(r));
        const messageResources = resources.filter((r) => isMessage(r));

        if (messageResources.length === 0) {
            notFoundRef.current = true;
            forceUpdate();
            return;
        }

        notFoundRef.current = false;
        dispatch(addMessages(messageResources));
        if (!report) {
            dispatch(addOrUpdateReport(reportKey, { ...reportResource, loaded: true }));
        }
    };

    useEffect(() => {
        if (isLoaded) {
            return;
        }

        fetch();
    }, [reportKey]);

    return { messages, report, notFound: notFoundRef.current, loaded: isLoaded };
};
