import * as types from './actionTypes';

export const loadTags = (tags) => async (dispatch, getState) => {
  dispatch({ type: types.TAGS_LOADED, payload: { tags } });
};

export const addOrReplaceTags =
  (workspaceKey, tags) => async (dispatch, getState) => {
    dispatch({ type: types.TAGS_UPDATED, payload: { workspaceKey, tags } });
  };

export const addOrUpdateTag = (pk, sk, data) => async (dispatch, getState) => {
  dispatch({ type: types.TAG_UPDATED, payload: { pk, sk, data } });
};

export const deleteTag = (pk, sk) => async (dispatch, getState) => {
  dispatch({ type: types.TAG_DELETED, payload: { pk, sk } });
};
