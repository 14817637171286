import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useNavigate } from 'react-router-dom';
import { getOrganizationRoute } from '../../utils/routeUtils';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { useSelector } from 'react-redux';
import { activeMembersSelector } from '../../state/members/selectors';
import ResourceAvatar from '../../components/ResourceAvatar';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MembersCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const members = useSelector(activeMembersSelector(organizationKey));
  // @ts-ignore
  const filteredMembers = members.filter(
    (member) => !member.access || member.access.includes(workspaceKey!),
  );

  const handleEditButtonClick = () => {
    navigate(`${getOrganizationRoute(organizationKey!)}/settings/members`);
  };

  return (
    <Card sx={{ width: 1 }}>
      <Stack
        direction="row"
        alignItems={'baseline'}
        justifyContent={'space-between'}
      >
        <CardHeader title={t('membersCardTitle')} sx={{ p: 3, pb: 0 }} />
        <Button
          variant="text"
          size={'medium'}
          sx={{ mr: 3 }}
          onClick={handleEditButtonClick}
          endIcon={<ChevronRightIcon />}
        >
          {t('edit')}
        </Button>
      </Stack>
      <CardContent sx={{ p: 3 }}>
        <Stack direction="row" spacing={3}>
          {filteredMembers.map((member) => (
            <Stack
              key={member.email}
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <ResourceAvatar
                name={member.name!}
                picture={member.picture}
                variant="circular"
                size={40}
              />
              <Box>
                <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
                  {member.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ lineHeight: 1.5 }}
                >
                  {member.email}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MembersCard;
