import * as types from './actionTypes';
import { groupBy, updateGroupItem } from '../../utils/arrayUtils';

const initialState = {};

export default function invitationsReducer(state = initialState, action) {
  switch (action.type) {
    case types.INVITATIONS_LOADED: {
      const invitations = action.payload.invitations;
      return groupBy(invitations, 'pk');
    }
    case types.INVITATIONS_ADDED: {
      // TODO: Move to common util class, used many reducers
      const workspaceKey = action.payload.workspaceKey;
      const invitations = action.payload.invitations;
      const updatedInvitations = [...(state[workspaceKey] ?? [])];
      for (const invitation of invitations) {
        // Add new invitation to the state if it doesn't contain the item with the same SK
        // already. If it does, the state item will be replaced by the new invitation.
        const index = updatedInvitations.findIndex(
          (item) => item.sk === invitation.sk,
        );
        if (index < 0) {
          updatedInvitations.push(invitation);
        } else {
          updatedInvitations[index] = invitation;
        }
      }

      const newState = { ...state };
      newState[workspaceKey] = updatedInvitations;
      return newState;
    }
    case types.INVITATION_UPDATED: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      return updateGroupItem(state, pk, (status) => status.sk === sk, {
        ...action.payload.data,
        pk: pk,
        sk: sk,
      });
    }
    case types.INVITATION_DELETED: {
      const pk = action.payload.pk;
      const sk = action.payload.sk;
      const newState = { ...state };
      newState[pk] = state[pk].filter((t) => t.sk !== sk);
      return newState;
    }
    case types.INVITATIONS_DELETED: {
      const newState = { ...state };
      delete newState[action.payload.workspaceKey];
      return newState;
    }
    default:
      return state;
  }
}
