import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import {
    isAttachmentBlock,
    isCheckboxListBlock,
    isDateBlock,
    isDropdownBlock, isReportPasswordBlock,
    isTextBlock,
    isTextFieldBlock, isUserEmailBlock
} from '../../utils/formBlockUtils';
import FormTextBlock from "./FormTextBlock";
import FormTextFieldBlock from "./FormTextFieldBlock";
import BlockLabel from "./BlockLabel";
import FormDropdownBlock from "./FormDropdownBlock";
import FormAttachmentsBlock from "./FormAttachmentsBlock/FormAttachmentsBlock";
import FormDateFieldBlock from "./FormDateFieldBlock";
import FormCheckboxListBlock from "./FormCheckboxListBlock";
import { getBlockTemplate } from "./utils";
import FormReportPasswordBlock from './FormReportPasswordBlock';
import FormUserEmailBlock from './FormUserEmailBlock';

const RootBox = styled(Box, { name: "FormBlock" })(({ theme }) => ({}));

const FormBlock = ({ blockIndex, language, mode, ...rest }) => {
    const { getValues } = useFormContext();
    const blockTemplate = getBlockTemplate(getValues, blockIndex);
    const showLabel = () => !isTextBlock(blockTemplate) && !isReportPasswordBlock(blockTemplate) && !isUserEmailBlock(blockTemplate);

    return (
        <RootBox {...rest}>
            {showLabel() && (
                <BlockLabel blockIndex={blockIndex} mode={mode} />
            )}
            {isTextBlock(blockTemplate) && (
                <FormTextBlock blockIndex={blockIndex} language={language} mode={mode} />
            )}
            {isTextFieldBlock(blockTemplate) && (
                <FormTextFieldBlock blockIndex={blockIndex} language={language} mode={mode} />
            )}
            {isDropdownBlock(blockTemplate) && (
                <FormDropdownBlock blockIndex={blockIndex} language={language} mode={mode} />
            )}
            {isCheckboxListBlock(blockTemplate) && (
                <FormCheckboxListBlock blockIndex={blockIndex} language={language} mode={mode} />
            )}
            {isDateBlock(blockTemplate) && (
                <FormDateFieldBlock blockIndex={blockIndex} language={language} mode={mode} />
            )}
            {isAttachmentBlock(blockTemplate) && (
                <FormAttachmentsBlock blockIndex={blockIndex} language={language} mode={mode} />
            )}
            {isReportPasswordBlock(blockTemplate) && (
                <FormReportPasswordBlock disabled={mode === 'edit'} language={language} />
            )}
            {isUserEmailBlock(blockTemplate) && (
                <FormUserEmailBlock disabled={mode === 'edit'} language={language} />
            )}
        </RootBox>
    );
};

FormBlock.propTypes = {
    blockIndex: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(["view", "edit"]),
};

FormBlock.defaultProps = {
    mode: "view",
};

export default FormBlock;
