import React, { useContext, useEffect, useRef } from "react";
import { ApiContext } from "../contexts/ApiContext";
import { ReactReduxContext, useDispatch } from "react-redux";
import { addOrUpdateMessage } from "../state/messages/actions";
import { isMessage, toWorkspaceKey } from '../utils/apiUtils';

export const useReportSubscription = (reportKey) => {
    const { subscriptionApi } = useContext(ApiContext);
    const dispatch = useDispatch();
    const subscriptionRef = useRef();
    const prevReportKeyRef = useRef();
    const isSubscribed = prevReportKeyRef.current === reportKey;

    const subscribe = () => {
        prevReportKeyRef.current = reportKey;

        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }

        const filter = { pk: { eq: reportKey } };
        subscriptionRef.current = subscriptionApi.onCreatePublicResource(handlePublicResourceChanged, filter);
    };

    const handlePublicResourceChanged = async (resource) => {
        if (isMessage(resource)) {
            dispatch(addOrUpdateMessage(resource.pk, resource.sk, resource));
        }
    };

    useEffect(() => {
        if (isSubscribed) {
            return;
        }

        subscribe();
    }, [reportKey]);

    return subscriptionRef.current;
};
