import React, { useContext } from 'react';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { workspaceVoicemailSelector } from '../../state/voicemails/selectors';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useDispatch, useSelector } from 'react-redux';
import { useResourcePassword } from '../../hooks/useResourcePassword';
import SwitchSection from '../../components/SwitchSection/SwitchSection';
import { ApiContext } from '../../contexts/ApiContext';
import { addOrUpdateVoicemail } from '../../state/voicemails/actions';
import CodeBlock from '../../components/CodeBlock';
import PublishingSectionHeader from './PublishingSectionHeader';

const VoicemailPublishingInfo = ({ voicemail }) => {
  const { t } = useTranslation();
  const { mutationApi } = useContext(ApiContext);
  const dispatch = useDispatch();
  const { resourcePassword } = useResourcePassword(
    voicemail.hasPassword ? voicemail.pk : null,
  );
  const active = !voicemail.disabled ?? false;

  const handleVoicemailActiveChange = (event) => {
    const disabled = !event.target.checked;
    if (disabled !== voicemail.disabled) {
      dispatch(addOrUpdateVoicemail(voicemail.pk, { disabled }));
      mutationApi.updatePublicResource(voicemail.pk, voicemail.sk, {
        disabled,
      });
    }
  };

  return (
    <Stack spacing={4}>
      <PublishingSectionHeader
        title={t('voicemail')}
        subtitle={t('voicemailPublishingOptionsSubtitle')}
        active={active}
      />
      <CodeBlock fullWidth label={t('phoneNumber')} text={voicemail.path} />
      {resourcePassword && (
        <CodeBlock
          fullWidth
          label={t('accessCode')}
          text={resourcePassword.password ?? ''}
          description={t('accessCodeDescription')}
        />
      )}
      <SwitchSection
        header={t('active')}
        subheader={t('voicemailActiveDescription')}
        checked={active}
        onChange={handleVoicemailActiveChange}
      />
    </Stack>
  );
};

export default VoicemailPublishingInfo;
