import React, { useContext, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useWorkspaceKey } from '../../hooks/useWorkspaceKey';
import { useNavigate } from 'react-router-dom';
import { getWorkspaceRoute } from '../../utils/routeUtils';
import { useOrganizationKey } from '../../hooks/useOrganizationKey';
import { getFormUrl } from '../../utils/formUtils';
import { useSelector } from 'react-redux';
import { workspaceFormSelector } from '../../state/forms/selectors';
import { WhitelabelContext } from '../../contexts/WhitelabelContext';
import FixedLabelTextField from '../../components/FixedLabelTextField';
import CodeBlock from '../../components/CodeBlock';
import { workspaceInternalPolicySelector } from '../../state/policies/selectors';
import InternalPolicyDialog from './InternalPolicyDialog';

const StyledStepButton = styled(StepButton)(({ theme }) => ({
  '& .MuiStepIcon-root.Mui-active, & .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.secondary.main,
  },
  '& .MuiStepLabel-iconContainer': {
    paddingRight: theme.spacing(3),
  },
  '& .MuiStepLabel-label': {
    ...theme.typography.subtitle1,
  },
}));

const StyledStepContent = styled(StepContent)(({ theme }) => ({
  paddingLeft: theme.spacing(4.5),
}));

const ComplianceCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const whitelabel = useContext(WhitelabelContext);
  const organizationKey = useOrganizationKey();
  const workspaceKey = useWorkspaceKey();
  const form = useSelector(workspaceFormSelector(workspaceKey));
  const formUrl = getFormUrl(form, whitelabel);
  const policy = useSelector(workspaceInternalPolicySelector(workspaceKey));
  const [policyDialogOpen, setPolicyDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useLocalStorage(
    `${workspaceKey}-complianceStep`,
    0,
  );
  const [completedSteps, setCompletedSteps] = useLocalStorage(
    `${workspaceKey}-completedSteps`,
    {},
  );

  const steps = [
    {
      title: 'whistleblowingComplianceStep1Title',
      subtitle: 'whistleblowingComplianceStep1Subtitle',
      buttonLabel: 'whistleblowingComplianceStep1ButtonLabel',
    },
    {
      title: 'whistleblowingComplianceStep2Title',
      subtitle: 'whistleblowingComplianceStep2Subtitle',
      buttonLabel: Boolean(policy) ? 'continue' : 'finish',
    },
    policy
      ? {
          title: 'whistleblowingComplianceStep3Title',
          subtitle: 'whistleblowingComplianceStep3Subtitle',
          buttonLabel: 'whistleblowingComplianceStep3ButtonLabel',
        }
      : undefined,
  ].filter(Boolean); // Filter out any null values;

  //const [activeStep, setActiveStep] = React.useState(0);

  const handleActionButtonClick = () => {
    const newCompleted = completedSteps;
    completedSteps[activeStep] = true;
    setCompletedSteps(newCompleted);

    if (activeStep === 0) {
      navigate(`${getWorkspaceRoute(organizationKey!, workspaceKey!)}/form`, {
        state: { focusRelevantPersons: true },
      });
    } else if (activeStep === 2) {
      setPolicyDialogOpen(true);
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepButtonClick = (index: number) => {
    setActiveStep(index);
  };

  const renderStepContent = () => {
    if (activeStep === 1) {
      return (
        <CodeBlock
          fullWidth
          label={t('formAddress')}
          text={formUrl!}
          sx={{
            pb: 3,
            maxWidth: 480,
          }}
        />
      );
    }
  };

  return (
    <Card sx={{ width: 1 }}>
      <CardHeader title={t('complianceCardTitle')} sx={{ p: 3, pb: 0 }} />
      <CardContent sx={{ p: 3 }}>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step!.title} completed={completedSteps[index]}>
              <StyledStepButton
                onClick={() => handleStepButtonClick(index)}
                optional={
                  <Typography variant="body1">{t(step!.subtitle)}</Typography>
                }
              >
                {t(step!.title)}
              </StyledStepButton>
              <StyledStepContent>
                <Box sx={{ pt: 3 }}>
                  {renderStepContent()}
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleActionButtonClick}
                    >
                      {t(step!.buttonLabel)}
                    </Button>
                    {index > 0 && (
                      <Button onClick={handleBack} sx={{ ml: 3 }}>
                        {t('back')}
                      </Button>
                    )}
                  </Box>
                </Box>
              </StyledStepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>
      {policy && (
        <InternalPolicyDialog
          open={policyDialogOpen}
          onClose={() => setPolicyDialogOpen(false)}
        />
      )}
    </Card>
  );
};

export default ComplianceCard;
