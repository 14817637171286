import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const RootDiv = styled(Box, { name: "AttachmentsContainer" })(({ theme, direction }) => ({
    maxWidth: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    '&>*': {
        margin: theme.spacing(0.5, 1, 0.5, 0),
    }
}));

const AttachmentsContainer = ({direction, children, ...rest}) => {
    return (
        <RootDiv direction={direction} {...rest}>
            {children}
        </RootDiv>
    );
}

AttachmentsContainer.propTypes = {
}

AttachmentsContainer.defaultProps = {
};

export default AttachmentsContainer;