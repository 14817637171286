import * as types from "./actionTypes";
import { VOICEMAIL_UPDATED, VOICEMAILS_LOADED } from './actionTypes';

const initialState = { };

export default function voicemailsReducer(state = initialState, action) {
    switch (action.type) {
        case types.VOICEMAILS_LOADED: {
            const newState = {...state};
            for (const voicemail of action.payload.voicemails) {
                newState[voicemail.pk] = voicemail;
            }
            return newState;
        }
        case types.VOICEMAIL_UPDATED: {
            const pk = action.payload.pk;
            const newState = {...state};
            newState[pk] = {...newState[pk], ...action.payload.data, pk: pk, sk: pk};
            return newState;
        }
        default:
            return state
    }
}